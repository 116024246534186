import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { InvoicingActivitiesRepository } from '../domain/InvoicingActivitiesRepository';

class InvoicingActivityService extends DeclarandoApiService {
    private repository: InvoicingActivitiesRepository;

    constructor(repository: InvoicingActivitiesRepository) {
        super();
        this.repository = repository;
    }

    getInvoicingActivities() {
        return this.execute(() => this.repository.getActivities(), { shouldThrowError: true, bodyAsData: true });
    }
}

export function createInvoicingActivityService(repository: InvoicingActivitiesRepository): InvoicingActivityService {
    return new InvoicingActivityService(repository);
}
