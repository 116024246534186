import { AmountInput } from '@declarando/design_system';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { LinesProps } from '../../Lines';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

export const LineTotal = ({ index, handleLineChange }: LinesProps) => {
    const { formBlocked } = useSalesDocumentFormContext();
    const { control } = useCustomFormContext<InvoiceForm>();
    const withEquivalencySurcharge = useCustomWatch({ control, name: 'withEquivalencySurcharge' });
    const equivalencySurchargeLine = useCustomWatch({ control, name: `lines.${index}.equivalencySurcharge` });
    const productTypeLine = useCustomWatch({ control, name: `lines.${index}.productType` });

    const shouldShowEquivalencySurchargeHelperText =
        withEquivalencySurcharge && equivalencySurchargeLine && productTypeLine === 'GOODS';

    return (
        <ControlledFormField
            control={control}
            name={`lines.${index}.totalLine`}
            Component={({ field, formState: { errors } }) => (
                <AmountInput
                    readOnly={formBlocked.isFormBlocked}
                    error={!!errors?.lines?.[index]?.totalLine?.message}
                    helperText={
                        errors?.lines?.[index]?.totalLine?.message ||
                        (shouldShowEquivalencySurchargeHelperText
                            ? `Recargo de equivalencia ${equivalencySurchargeLine}% incluido`
                            : '')
                    }
                    value={field.value}
                    handleChange={(value) => handleLineChange(index, 'totalLine', value)}
                    customHandlerClearValue={() => handleLineChange(index, 'totalLine', 0)}
                    id={`invoice-concept-total-${index}`}
                    label="Total"
                    tooltipOptions={{
                        title: '¿Cómo se calcula el total?',
                        text: 'El total se calcula multiplicando el número de unidades por el precio unitario, restando el descuento, sumando el IVA y el recargo de equivalencia (si aplica).',
                    }}
                />
            )}
        />
    );
};
