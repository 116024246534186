import { Planes, Planes_Codes } from 'shared/domain/constants/Planes';

import { IUserInfo } from 'shared/domain/interfaces/App';
import Date from 'shared/infra/utils/Date';

type AppInstanceType = { state: { userInfo: IUserInfo } };

export type TPlanHelper = InstanceType<typeof PlanHelper>;

export class PlanHelper {
    static instance = new PlanHelper();

    constructor(private app?: AppInstanceType) {
        this.app = app;
        PlanHelper.instance = this;
        return this;
    }

    get plan() {
        if (!this.app) return {} as any;
        return this.app.state.userInfo.plan;
    }

    isBaja = () => {
        return this.plan.label === Planes.BAJA;
    };
    isFree = () => {
        return this.plan.label === Planes.FREE;
    };

    isEsencial() {
        return this.plan.id === Planes_Codes.CODE_ESENCIAL;
    }

    isPro = () => {
        return this.plan.label === Planes.PRO || this.plan.label === Planes.PROFESIONAL;
    };
    isPlus = () => {
        return this.plan.label === Planes.PLUS;
    };
    isTotal = () => {
        return this.plan.label === Planes.TOTAL;
    };
    isPaid = () => {
        return !this.isFree();
    };

    isOnDemoPeriod = () => {
        if (!this.app) return false;
        const dateDemoUnitDate = Date.formatFullTimeStringtoDate(this.app.state.userInfo.demoUntilDate);
        const currentDate = Date.getNow();

        return Date.isBefore(currentDate, dateDemoUnitDate);
    };

    isAccountingDisabledForFree = () => {
        return !this.isOnDemoPeriod() && PlanHelper.instance.isFree();
    };

    static getVisualName(id: number) {
        const labels: Record<number, string> = {
            1: 'Free',
            2: 'Esencial',
            3: 'Avanzado',
            4: 'Estelar',
        };

        return labels[id] ?? '';
    }
}
