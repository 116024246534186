import { useShouldAppearClientInfo } from './hooks/should-appear-client-info';
import { useGetCountriesList, useGetStatesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { Box } from '@declarando/design_system';
import { EquivalencySurchargeQuestion } from './components/EquivalencySurchargeQuestion/EquivalencySurchargeQuestion';
import { useSalesDocumentFormContext } from '../../../context/SalesDocumentFormProvider';
import { FiscalID } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/FiscalID/FiscalID';
import { RancherForFatteningQuestion } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/RancherFatteningQuestion/RancherFatteningQuestion';
import { ImageRightsQuestion } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/ImageRightsQuestion/ImageRightsQuestion';
import { NIFIVAQuestion } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/NIFIVAQuestion/NIFIVAQuestion';
import { ClientSelection } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/ClientSelection/ClientSelection';
import { ClientCreation } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/ClientCreation/ClientCreation';
import { CardDatosReceptor } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/CardDatosReceptor/CardDatosReceptor';

const ClientDetailsActions = () => {
    const { formBlocked } = useSalesDocumentFormContext();

    if (formBlocked.isFormBlocked) return null;

    return (
        <Box display="flex" flexDirection={{ _: 'column', tablet: 'row' }}>
            <ClientSelection />
            <ClientCreation />
        </Box>
    );
};

export const ClientDetails = () => {
    const { shouldAppearClientInfo, clientInfo } = useShouldAppearClientInfo();
    useGetCountriesList();
    useGetStatesList();

    return (
        <div>
            <Box display="flex" flexDirection="column" gap="24px">
                {shouldAppearClientInfo ? <CardDatosReceptor clientInfo={clientInfo} /> : null}
                <ClientDetailsActions />
            </Box>
            <NIFIVAQuestion />
            <ImageRightsQuestion />
            <RancherForFatteningQuestion />
            <EquivalencySurchargeQuestion />
            <FiscalID />
        </div>
    );
};
