import { ReactNode, useEffect, useState } from 'react';
import { Notifier } from 'shared/infra/utils/Notifier';
import { ClienteForm, ClienteFormKey, useFormularioClienteReducer } from './useFormularioClienteReducer';
import { InputValue } from 'shared/services/formulario/Formulario';
import { IsoCodes } from 'shared/domain/constants/IsoCodes';
import { FormularioTipoClienteSettings } from '../FormularioTipoClienteSettings';
import { TipoCliente } from 'features/Incomes/domain/TipoCliente';
import { TipoFormularioCliente } from 'features/Incomes/domain/TipoFormularioCliente';
import { TipoPais } from 'features/Incomes/domain/TipoPais';
import { InputModel } from 'shared/domain/types/form/InputModel';
import {
    useGetCitiesList,
    useGetCountriesList,
    useGetStatesList,
} from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { City, State, Country as TCountry } from 'features/Ingresos/Parameters/Locations/domain';
import { LegacyClientRequestDTO } from 'features/Ingresos/Clientes/adapters/LegacyClient';
import { NifIvaOptions } from 'features/Incomes/UI/ModalCliente/form/DatosPersonalesCliente';

export const useFormularioCliente = (cliente: LegacyClientRequestDTO): FormularioClienteHook => {
    const [isFormFilledWithRequiredInputs, setIsFormFilledWithRequiredInputs] = useState<boolean>(false);
    const [tipoPais, setTipoPais] = useState<null | TipoPais>(null);
    const [paisId, setPaisId] = useState<string | number | boolean>('');
    const [tipoFormularioCliente, setTipoFormularioCliente] = useState<null | TipoFormularioCliente>(null);
    const { form, updateInput, updatePhoneInput, iniInput, updateInputProp, setError, cleanForm } =
        useFormularioClienteReducer();
    const { countries: listaPaises } = useGetCountriesList();
    const { states: listaProvincias } = useGetStatesList();
    const { cities: listaPoblaciones } = useGetCitiesList(form.provincia.value);

    useEffect(() => {
        checkCountry(form.pais.value);
        checkNifIva();
    }, [form.pais.value]);

    useEffect(() => {
        mutarFormulario();
        checkNifIva();
    }, [tipoFormularioCliente]);

    useEffect(() => {
        mutarFormulario();
        checkTipoCliente();
    }, [form.type.value, tipoPais, paisId]);

    useEffect(() => {
        checkNifIva();
    }, [form?.nifIva?.value]);

    useEffect(() => {
        if (cliente.id) {
            clienteDtoToFormData({ ...cliente });
        }
        if (cliente.type) {
            iniInput('type', cliente.type);
        }
    }, [cliente]);

    useEffect(() => {
        setIsFormFilledWithRequiredInputs(ensureIsFormFilledWithRequiredInputs());
    }, [form]);

    const refresh = () => {
        cleanForm();
        setTipoPais(null);
        setTipoFormularioCliente(null);
    };

    const checkTipoCliente = () => {
        const type = form.type.value;
        let tipoFormularioCliente: TipoFormularioCliente;
        if (!type || !tipoPais) return;
        if (tipoPais === TipoPais.ESP) {
            tipoFormularioCliente =
                type === TipoCliente.PARTICULAR
                    ? TipoFormularioCliente.espParticular
                    : TipoFormularioCliente.espNoParticular;
        } else if (tipoPais === TipoPais.EUR) {
            tipoFormularioCliente =
                type === TipoCliente.PARTICULAR
                    ? TipoFormularioCliente.eurParticular
                    : TipoFormularioCliente.eurNoParticular;
        } else {
            tipoFormularioCliente = TipoFormularioCliente.noEur;
        }
        setTipoFormularioCliente(tipoFormularioCliente);
        checkNifIva();
    };

    const checkNifIva = () => {
        if (tipoFormularioCliente === TipoFormularioCliente.eurNoParticular) {
            const enableCif = String(form.nifIva.value) === String(NifIvaOptions.si);
            updateInputProp('cif', 'visible', enableCif);
            updateInputProp('cif', 'required', enableCif);
            if (!enableCif) {
                iniInput('cif', '');
                updateInputProp('direccion', 'required', false);
            } else {
                updateInputProp('direccion', 'required', true);
            }
        }
    };

    const checkCountry = async (country: string) => {
        if (!country) return;
        const countrySelected = listaPaises.find((countryItem) => countryItem.code === country);
        if (!countrySelected) return;
        setPaisId(countrySelected.id!);
        if (country === IsoCodes.ES) {
            return setTipoPais(TipoPais.ESP);
        }
        if (countrySelected && countrySelected.id) {
            const tipoPais = countrySelected.isEuropean ? TipoPais.EUR : TipoPais.NOEUR;
            setTipoPais(tipoPais);
        }
    };

    const errorHandler = (message: ReactNode = '', title: ReactNode = '') => {
        Notifier.notifyError(message, title, false);
    };

    const ensureIsFormFilledWithRequiredInputs = () => {
        let isFormFilledWithRequiredInputs = true;

        Object.keys(form).forEach((key: string) => {
            const formKey: ClienteFormKey = key as ClienteFormKey;
            const input = form[formKey];
            if (!input.visible) return;
            if (input.required && !input.value) {
                isFormFilledWithRequiredInputs = false;
            }
        });

        return isFormFilledWithRequiredInputs;
    };

    const validateData = () => {
        let isValid = true;

        Object.keys(form).forEach((key: string) => {
            const formKey: ClienteFormKey = key as ClienteFormKey;
            const input = form[formKey];
            if (!input.visible) return;
            if (form[formKey].validate(input.value as never, input.required, input.invalidValues) !== '') {
                isValid = false;
                updateInput(formKey, form[formKey].value);
            }
        });
        if (!isValid) {
            errorHandler('Comprueba que los campos sean correctos', 'El formulario no es válido');
        }
        return isValid;
    };

    const mutarFormulario = () => {
        Object.keys(form).forEach((key: string) => {
            if (!tipoFormularioCliente) return;
            const settings = FormularioTipoClienteSettings[tipoFormularioCliente][key as keyof ClienteForm];
            console.log('lala', tipoFormularioCliente, cliente.id);
            if (cliente.id && tipoFormularioCliente === TipoFormularioCliente.eurNoParticular && key === 'nifIva') {
                updateInputProp(key as keyof ClienteForm, 'visible', true);
            } else {
                updateInputProp(key as keyof ClienteForm, 'visible', settings.visible);
                if (!form[key as keyof ClienteForm].visible) {
                    iniInput(key as keyof ClienteForm, '');
                }
            }
            updateInputProp(key as keyof ClienteForm, 'required', settings.required);
        });
    };

    const submitHandler = (handleSubmit: (args: FormularioSubmitHandler) => void) => {
        if (validateData()) {
            saveCliente(handleSubmit);
        }
    };

    const clienteFormDataToDTO = (form: ClienteForm): LegacyClientRequestDTO => {
        return {
            id: cliente.id ?? null,
            nombre: form['nombre'].value,
            nif_iva: form['nifIva'].value,
            cif: form['cif'].value,
            type: form['type'].value,
            country_id: paisId as string,
            provincia_id: form['provincia'].value,
            poblacion_id: form['poblacion'].value,
            direccion: form['direccion'].value,
            cp: form['cp'].value,
            email: form['email'].value,
            prefijo: form['phone'].value.prefix,
            phone: form['phone'].value.number,
            isCanario: form['isCanario'].value,
        };
    };

    const clienteDtoToFormData = (cliente: LegacyClientRequestDTO) => {
        const pais = listaPaises.find((countryItem) => countryItem.id === cliente.country_id);
        iniInput('nombre', cliente.nombre);
        iniInput('nifIva', cliente.nif_iva);
        iniInput('cif', cliente.cif);
        updateInput('type', cliente.type);
        updateInput('pais', pais?.code);
        iniInput('provincia', cliente.provincia_id);
        iniInput('poblacion', cliente.poblacion_id);
        iniInput('phone', { prefix: cliente.prefijo, number: cliente.phone });
        iniInput('cp', cliente.cp);
        iniInput('email', cliente.email);
        iniInput('direccion', cliente.direccion);
        iniInput('isCanario', cliente.isCanario);
    };

    const saveCliente = (handleSubmit: (args: FormularioSubmitHandler) => void) => {
        const formData = clienteFormDataToDTO(form);
        handleSubmit({ formData, tipoPais: tipoPais as TipoPais });
    };

    return {
        form,
        updateInput,
        updateInputProp,
        iniInput,
        setError,
        tipoPais,
        submitHandler,
        updatePhoneInput,
        validateData,
        listaPaises,
        listaPoblaciones,
        listaProvincias,
        tipoFormularioCliente,
        refresh,
        isStep2: Boolean(form.pais.value && form.type.value),
        isFormFilledWithRequiredInputs,
    };
};

export type FormularioSubmitHandler = {
    formData: LegacyClientRequestDTO;
    tipoPais: TipoPais;
};

export interface FormularioClienteHook {
    form: ClienteForm;
    updateInput: (inputKey: keyof ClienteForm, value: any, validate?: boolean) => void;
    updateInputProp: (inputKey: keyof ClienteForm, prop: keyof InputModel, value: any) => void;
    iniInput: (inputKey: keyof ClienteForm, value: InputValue) => void;
    setError: (inputKey: keyof ClienteForm, value: string) => void;
    tipoPais: null | TipoPais;
    listaPaises: TCountry[];
    listaPoblaciones: City[];
    listaProvincias: State[];
    submitHandler: (handleSubmit: (args: FormularioSubmitHandler) => void) => void;
    updatePhoneInput: (
        inputKey: keyof ClienteForm,
        phonePart: 'number' | 'prefix',
        value: any,
        validate?: boolean,
    ) => void;
    validateData: () => boolean;
    tipoFormularioCliente: null | TipoFormularioCliente;
    refresh: () => any;
    isStep2: boolean;
    esSubvencion?: boolean;
    isFormFilledWithRequiredInputs: boolean;
}
