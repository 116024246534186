import { AlertMessage, Box, ButtonSolid, Grid, Header, P } from '@declarando/design_system';
import imageStep from '../assets/certificado-ok.svg';
import { Step1ContextProvider } from './Step1Context';
import { useContext } from 'react';
import { OnboardingContext } from '../../Onboarding';
import { publishContinuarCertificadoEvent } from '../../../services/events';

export function Step1TramitandoCertificado() {
    const { siguintePaso } = useContext(OnboardingContext);

    const instaladoCertificadoClick = () => {
        siguintePaso();
        publishContinuarCertificadoEvent();
    };
    return (
        <Step1ContextProvider>
            <Box px={'sm'} mx={'auto'} py={'xxl'} maxWidth={800}>
                <Box alignCenter maxWidth={790}>
                    <Box mb="sm">
                        <img src={imageStep} alt={'Certificado'} />
                    </Box>
                    <Header as={'h2'}>Tu certificado digital está en trámite...</Header>
                    <P mt={'xs'}>
                        Próximamente recibirás un correo electrónico para finalizar la tramitación y obtener tu
                        certificado.
                    </P>
                </Box>
                <Grid my={'lg'} mx={'auto'} justifyContent={'center'}>
                    <AlertMessage
                        tone={'success'}
                        title={'Has pagado el coste adicional de 14,56€ (IVA incl.) '}
                        message={'Nuestro equipo está trabajando en la tramitación de tu certificado digital.'}
                    ></AlertMessage>
                </Grid>
                <Box mb={'lg'} mx={'auto'} alignCenter>
                    <ButtonSolid icon={'arrow-right'} iconDirection={'right'} onClick={instaladoCertificadoClick}>
                        Ya he instalado mi nuevo certificado digital
                    </ButtonSolid>
                </Box>
            </Box>
        </Step1ContextProvider>
    );
}
