import DocumentValidator from 'shared/services/DocumentValidator';
import { IngresoConFacturaClientRulesService } from '../../IngresoConFactura/services/IngresoConFacturaRulesService';
import { Schema } from 'shared/validation/Schema';
import { Invoice } from '../../IngresoConFactura/domain/Invoice';
import { errorRequired } from './common-errors';

const phoneSchema = Schema.object({
    prefix: Schema.string().required(),
    number: Schema.string().required(),
})
    .required()
    .nullable();

const countrySchema = Schema.object({
    id: Schema.string().required(),
    code: Schema.string().required(),
    name: Schema.string().required(),
    isEuropean: Schema.boolean().required(),
}).required();

const stateSchema = Schema.object({
    id: Schema.string().required(),
    name: Schema.string().required(),
})
    .required()
    .nullable();

const citySchema = Schema.object({
    id: Schema.string().required(),
    name: Schema.string().required(),
})
    .required()
    .nullable();

export const clientSchema = Schema.object({
    id: Schema.string().required(errorRequired),
    clientType: Schema.string().oneOf(['INDIVIDUAL', 'COMPANY', 'FREELANCE']).required(errorRequired),
    fiscalId: Schema.string()
        .nullable()
        .transform((value) => (value === '' ? null : value))
        .when(['hasEUVatNumber', 'country'], ([hasEUVatNumber, country], schema) => {
            return schema.test('fiscal-id-checker-europeans-no-spanish', (val, ctx) => {
                if (!hasEUVatNumber) return true;
                if (val === null) return ctx.createError({ message: errorRequired });

                const isValidCountryCode = val?.substring(0, 2) === DocumentValidator.getCifCountryCode(country.code);
                if (!isValidCountryCode)
                    return ctx.createError({
                        message:
                            'Debe empezar con el ISO del país ' +
                            DocumentValidator.getCifCountryCode(country.code) +
                            'XXXXXXX...',
                    });

                const isValidEuroCIF = DocumentValidator.isValidEuroCIF(val);
                if (!isValidEuroCIF) return ctx.createError({ message: 'El NIF IVA tiene un formato erróneo' });

                return true;
            });
        })
        .defined(errorRequired),
    name: Schema.string().required(),
    country: countrySchema,
    address: Schema.string()
        .nullable()
        .transform((value) => (value === '' ? null : value))
        .when(['hasEUVatNumber'], ([hasEUVatNumber], schema) => {
            return schema.test({
                name: 'address-required-for-eu-vat',
                test: function (value) {
                    if (hasEUVatNumber && (value === null || value === undefined)) {
                        return this.createError({
                            message: 'Debes informar la dirección para crear el ingreso con factura',
                        });
                    }
                    return true;
                },
            });
        })
        .defined(errorRequired),
    state: stateSchema,
    city: citySchema,
    postalCode: Schema.string().required().nullable(),
    phone: phoneSchema,
    hasEUVatNumber: Schema.boolean().when(['clientType', 'country'], {
        is: (clientType: Invoice['client']['clientType'], country: Invoice['client']['country']) => {
            if (!clientType || !country?.code) return false;

            return IngresoConFacturaClientRulesService.isNIFIVARequired({ clientType, country });
        },
        then: (schema) => schema.required(errorRequired),
        otherwise: (schema) => schema.optional(),
    }),
    email: Schema.string()
        .email('Invalid email')
        .required()
        .nullable()
        .transform((value) => value || null),
});
