import { Modal, Input, Box, Grid } from '@declarando/design_system';
import { useHistory } from 'react-router-dom';
import { Notifier } from 'shared/infra/utils/Notifier';
import { useCustomForm } from 'shared/UI/components/Form/hooks/useCustomForm';
import { useSendEmailWithSalesDocument } from '../../../hooks/useSendEmailWithSalesDocument';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { SalesDocumentType } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

type ModalSendEmailSalesDocumentProps = {
    isOpen: boolean;
    onClose: () => void;
    clientEmail: string | null;
    clientName: string | null;
    salesDocumentId: string;
    salesDocumentNumber: string;
    salesDocumentType: SalesDocumentType;
    callbacks?: {
        onSuccess?: () => void;
        onError?: () => void;
    };
};

const config = {
    invoice: {
        modalTitle: 'Enviar factura por mail',
        labelNumber: 'Número factura',
        eventLabel: 'Envía factura por mail',
        mutation: {
            notifierTitle: {
                success: (salesDocumentNumber: string) => `Factura ${salesDocumentNumber} enviada correctamente`,
                error: 'No se ha podido enviar la factura por mail',
            },
            redirectPathSuccess: '/ingresos/ingresos',
        },
    } as const,
    quote: {
        modalTitle: 'Enviar presupuesto por mail',
        labelNumber: 'Número presupuesto',
        eventLabel: 'Envía presupuesto por mail',
        mutation: {
            notifierTitle: {
                success: (salesDocumentNumber: string) => `Presupuesto ${salesDocumentNumber} enviado correctamente`,
                error: 'No se ha podido enviar el presupuesto por mail',
            },
            redirectPathSuccess: '/ingresos/presupuestos',
        },
    } as const,
};

export const ModalSendEmailSalesDocument: React.FC<ModalSendEmailSalesDocumentProps> = ({
    isOpen,
    onClose,
    clientEmail,
    clientName,
    callbacks,
    salesDocumentId,
    salesDocumentNumber,
    salesDocumentType,
}) => {
    const { sendEmailWithSalesDocument } = useSendEmailWithSalesDocument({ salesDocumentType });
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useCustomForm({ mode: 'onChange', defaultValues: { email: clientEmail ?? '' } });

    const configObject = config[salesDocumentType];

    const onSubmit = (data: { email: string }) => {
        TrackingEvents.clickElement({
            event_label: configObject.eventLabel,
        });

        sendEmailWithSalesDocument.mutate(
            { salesDocumentId, email: data.email },
            {
                onSuccess: () => {
                    onClose();
                    reset();
                    Notifier.success({ title: `${configObject.mutation.notifierTitle.success(salesDocumentNumber)}` });

                    if (callbacks?.onSuccess) return callbacks.onSuccess();

                    history.push(configObject.mutation.redirectPathSuccess);
                },
                onError: () => {
                    Notifier.error({ message: configObject.mutation.notifierTitle.error });
                    callbacks?.onError?.();
                },
            },
        );
    };

    const handleClose = () => {
        onClose();
        reset();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal
                title={configObject.modalTitle}
                actionLabel="Enviar"
                onClose={handleClose}
                isOpen={isOpen}
                variant="small"
                isLoading={sendEmailWithSalesDocument.isPending}
                onAction={handleSubmit(onSubmit)}
            >
                <Box display="flex" flexDirection="column" gap="sm">
                    <Grid gridTemplateColumns="5fr 3fr" gridGap="md">
                        <Box>
                            <Input readOnly value={clientName} label="Nombre cliente" />
                        </Box>
                        <Box>
                            <Input readOnly value={salesDocumentNumber} label={configObject.labelNumber} />
                        </Box>
                    </Grid>
                    <Input
                        {...register('email', {
                            required: 'Este campo es requerido',
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                                message: 'El formato de mail no es correcto',
                            },
                        })}
                        required
                        label="Para"
                        error={!!errors?.email?.message}
                        helperText={errors?.email?.message ?? 'Solo puedes indicar una dirección de correo electrónico'}
                        type="email"
                    />
                </Box>
            </Modal>
        </form>
    );
};
