class EquivalenceSurcharge {
    static NINGUNO = 0;
    static ALGUNOS = 1;
    static TODOS = 2;

    static getQuantity(iaes) {
        let equivalence = EquivalenceSurcharge.NINGUNO;
        let some = false;
        let all = true;

        iaes.forEach((iae) => {
            iae.equivalenceSurcharge ? (some = true) : (all = false);
        });

        if (all) {
            equivalence = EquivalenceSurcharge.TODOS;
        } else if (some) {
            equivalence = EquivalenceSurcharge.ALGUNOS;
        }

        return equivalence;
    }
}

export default EquivalenceSurcharge;
