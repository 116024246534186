import {
    FormControlContainer,
    Grid,
    Input,
    InputPhone,
    Link,
    RadioText,
    Section,
    Select,
} from '@declarando/design_system';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { getInputProps } from 'shared/services/formulario/Formulario';
import { FormTypes } from 'shared/domain/types/FormTypes';
import { FormularioClienteHook } from './hooks/useFormularioCliente';
import { ClienteSchema } from '../../../services/schemas/ClienteSchema';
import { RootContext } from 'shared/UI/context/RootContext/RootContext';
import Country from '../../../../../shared/UI/components/ListOptions/Country';
import { ClienteService } from '../../../services/ClienteService';
import { ViesNotification } from '../../../../../shared/UI/components/FormParts/proveedor/components/ViesNotification';

export enum NifIvaOptions {
    no = '0',
    si = '1',
}

export const DatosPersonalesCliente = ({ formularioCliente }: { formularioCliente: FormularioClienteHook }) => {
    const {
        form,
        setError,
        setIsSubmitting,
        updatePhoneInput,
        iniInput,
        updateInput,
        updateInputProp,
        listaPoblaciones,
        listaProvincias,
        vies,
        setVies,
        validateVies,
    } = formularioCliente;

    const { userIdentity } = useContext(RootContext);
    const onChangeNifIva = (e: ChangeEvent<HTMLInputElement>) => {
        const nifIva = e.currentTarget.value;
        updateInput('nifIva', nifIva);
    };

    const [codigosPostales, setListaCodigoPostales] = useState<any[]>([]);

    const comprobarDatosIniciados = () => {
        setIsSubmitting(true);
        //validateVies();
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (!form.cif.visible) {
            updateInput('cif', '');
        }
    }, [form.cif.visible]);

    useEffect(() => {
        if (form.cif.value && form.nifIva.value === NifIvaOptions.si) {
            comprobarDatosIniciados();
        }
    }, []);

    useEffect(() => {
        updateInputProp(
            'direccion',
            'required',
            form.nifIva.value === NifIvaOptions.si || form.pais.value === Country.iso.SPAIN,
        );
    }, [form.nifIva.value]);

    useEffect(() => {
        if (listaPoblaciones.length && form.poblacion.value) {
            const findCodigosPostales =
                (listaPoblaciones as FormTypes.TownList)?.find((item) => +item.value === +form.poblacion.value)
                    ?.codigos_postales ?? [];
            const codigosPostales = findCodigosPostales.map((cp) => ({ value: cp, label: cp }));
            setListaCodigoPostales(codigosPostales);
            const currentCP = form.cp.value || '';
            const includeCP = codigosPostales.find((item) => item.value === currentCP)?.value || '';
            iniInput('cp', codigosPostales.length === 1 ? codigosPostales[0].value : includeCP);
        }
    }, [setListaCodigoPostales, form.poblacion.value, form.cp.value, listaPoblaciones]);

    return (
        <Section>
            {form.nombre.visible && (
                <Input
                    {...getInputProps('nombre', form)}
                    id="nombre"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateInput('nombre', e.currentTarget.value)}
                />
            )}
            {form.nifIva.visible && (
                <FormControlContainer
                    required={form.nifIva.required}
                    label={form.nifIva.label}
                    error={Boolean(form.nifIva.error)}
                    message={form.nifIva.error}
                    info={
                        <>
                            El NIF-IVA o VAT es un código identificativo que permite realizar operaciones en la Unión
                            Europea y que se obtiene tras la inscripción en el ROI
                            <br />
                            <Link
                                href={'https://ayuda.declarando.es/es/articles/2843194-que-es-el-roi'}
                                styles={{ color: 'white', textDecoration: 'underline', display: 'block' }}
                            >
                                Saber más
                            </Link>
                        </>
                    }
                >
                    <Grid gridTemplateColumns={['100px 100px']}>
                        <RadioText
                            id={'nif_iva_no'}
                            regular
                            radiusLeft
                            height={35}
                            key="radioNo"
                            value={NifIvaOptions.no}
                            className={'d-inline-block'}
                            name="radioNifIva"
                            onChange={onChangeNifIva}
                            checked={form.nifIva.value === NifIvaOptions.no}
                        >
                            No
                        </RadioText>
                        <RadioText
                            id={'nif_iva_si'}
                            regular
                            radiusRight
                            height={35}
                            key="radioSi"
                            value={NifIvaOptions.si}
                            className={'d-inline-block'}
                            name="radioNifIva"
                            onChange={onChangeNifIva}
                            checked={form.nifIva.value === NifIvaOptions.si}
                        >
                            Sí
                        </RadioText>
                    </Grid>
                </FormControlContainer>
            )}
            {form.cif.visible && (
                <>
                    <Input
                        {...getInputProps('cif', form)}
                        id="cif"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setVies({ isValid: null, exception: false });
                            updateInput('cif', String(e.currentTarget.value).toUpperCase().trim(), false);
                        }}
                        onBlur={async () => {
                            const { nifIva, cif } = form;
                            await updateInput('cif', cif.value);
                            const error = ClienteSchema.cif.validate(cif.value, !!cif.required, [userIdentity.nif]);
                            if (cif && !error) {
                                setIsSubmitting(true);
                                const { data: exist } = await new ClienteService().checkExitCif(cif.value);
                                setError('cif', exist ? 'Ya tienes un cliente con este cif' : '');
                                if (nifIva.value === NifIvaOptions.si && !exist) {
                                    validateVies();
                                }
                                setIsSubmitting(false);
                            }
                        }}
                    />

                    {form.nifIva.value === NifIvaOptions.si && (
                        <ViesNotification isValid={vies.isValid} type={'cliente'} exception={vies.exception} />
                    )}
                </>
            )}
            {form.provincia.visible && (
                <Grid gridTemplateColumns={{ _: '1fr', tablet: 'repeat(3, 1fr)' }} gridGap="sm">
                    <Select
                        {...getInputProps('provincia', form)}
                        id="provincia"
                        options={listaProvincias}
                        onChange={(value: string) => {
                            updateInput('provincia', value);
                            if ([38, 35].includes(+value)) {
                                updateInput('isCanario', true);
                            } else {
                                updateInput('isCanario', false);
                            }

                            if (+form.provincia.value !== +value) {
                                iniInput('poblacion', '');
                                iniInput('cp', '');
                                updateInputProp('cp', 'error', '');
                                setListaCodigoPostales([]);
                            }
                        }}
                    />
                    <Select
                        {...getInputProps('poblacion', form)}
                        id="poblacion"
                        options={listaPoblaciones}
                        onChange={(value: string) => {
                            updateInput('poblacion', value);
                            if (+form.poblacion.value !== +value) {
                                iniInput('cp', '');
                                updateInputProp('cp', 'error', '');
                            }
                        }}
                    />
                    {codigosPostales.length >= 1 &&
                        (codigosPostales.length > 1 ? (
                            <Select
                                {...getInputProps('cp', form)}
                                id="cp"
                                options={codigosPostales}
                                onChange={(value: string) => updateInput('cp', value)}
                            />
                        ) : (
                            <Input
                                {...getInputProps('cp', form)}
                                id="cp"
                                inform
                                value={codigosPostales[0].value}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    updateInput('cp', e.currentTarget.value)
                                }
                            />
                        ))}
                </Grid>
            )}

            {form.direccion.visible && (
                <Input
                    {...getInputProps('direccion', form)}
                    id="direccion"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateInput('direccion', e.currentTarget.value)}
                />
            )}
            <Grid gridTemplateColumns={{ _: '1fr', tablet: '1fr 1fr' }} gridColumnGap="sm" gridRowGap="sm">
                {form.phone.visible && (
                    <InputPhone
                        id={'phone'}
                        label={form.phone.label}
                        className="input-phone"
                        placeholder="Introduce el teléfono de tu cliente"
                        prefixValue={form.phone.value.prefix}
                        numberValue={form.phone.value.number}
                        onChangePhonenumber={(phone: string) => updatePhoneInput('phone', 'number', phone, false)}
                        onChangePrefixPhone={(prefijo: any) => updatePhoneInput('phone', 'prefix', prefijo, false)}
                        error={Boolean(form.phone.error)}
                        message={form.phone.error}
                        required={form.phone.required}
                        onBlur={() => {
                            updatePhoneInput('phone', 'prefix', form.phone.value.prefix);
                            updatePhoneInput('phone', 'number', form.phone.value.number);
                        }}
                    />
                )}

                {form.email.visible && (
                    <Input
                        type="email"
                        id="email"
                        {...getInputProps('email', form)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            updateInput('email', e.currentTarget.value, false)
                        }
                        onBlur={() => {
                            updateInput('email', form.email.value || null);
                        }}
                    />
                )}
            </Grid>
        </Section>
    );
};
