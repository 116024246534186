import type { PresentationOfRentaDTO } from 'features/CalculadoraRenta/PresentationOfRenta/adapters/PresentationOfRentaDTO';

export type TPresentationOfRenta = {
    currentStep: PresentationOfRentaDTO['currentStep'];
    classification: PresentationOfRentaDTO['classification'];
    classificationLink: string;
    fiscalInformationLink: string | null;
    preparationLink: string | null;
    conditionsLink: string | null;
    isIncluded: boolean;
};

export const toRentalPresentation = (data: PresentationOfRentaDTO): TPresentationOfRenta => {
    return {
        currentStep: data.currentStep,
        classification: data.classification,
        classificationLink: data.classificationLink,
        fiscalInformationLink: data.fiscalInformationLink ?? null,
        preparationLink: data.preparationLink ?? null,
        conditionsLink: data.conditionsLink ?? null,
        isIncluded: data.isIncluded,
    };
};
