import { useSalesDocumentMutation } from 'features/Ingresos/SalesDocument/shared/UI/hooks/useSalesDocumentMutation';
import { useQuoteMutation } from '../../hooks/useQuoteMutation';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';

export const useCreateQuote = () => {
    const { createQuote } = useQuoteMutation();

    return useSalesDocumentMutation({
        mutation: createQuote,
        trackingEvent: () => TrackingEvents.clickElement({ event_label: 'Guarda presupuesto' }),
        successMessage: {
            title: 'Presupuesto creado correctamente',
            message: 'Puedes consultarlo en el listado de presupuestos.',
            link: { label: 'Ir al listado de presupuestos', href: '/ingresos/presupuestos' },
        },
        errorMessage: {
            title: 'No ha sido posible crear el presupuesto',
            message: 'Inténtalo de nuevo más tarde.',
        },
        getSuccessRedirectPath: (id) => `/ingreso/presupuesto-previsualizar/${id}`,
    });
};
