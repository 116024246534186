export const PresentationOfRentaNavigationSteps = {
    LOADING: 'LOADING',
    TEST_INICIAL: 'TEST_INICIAL',
    REQUISITOS_OK: 'REQUISITOS_OK',
    REQUISITOS_KO: 'REQUISITOS_KO',
    INFORME_DE_RENTA: 'INFORME_DE_RENTA',
    CUENTA_ATRAS: 'CUENTA_ATRAS',
    FORMA_PRESENTACION: 'FORMA_PRESENTACION',
    PRESENTACION: 'PRESENTACION',
    PRESENTACION_EN_ESPERA: 'PRESENTACION_EN_ESPERA',
    RENTA_PRESENTADA: 'RENTA_PRESENTADA',
} as const;
export type TPresentationOfRentaNavigationStep =
    (typeof PresentationOfRentaNavigationSteps)[keyof typeof PresentationOfRentaNavigationSteps];
