import { Schema } from 'shared/validation/Schema';
import { invoicingActivityTypes } from 'features/Ingresos/InvoicingActivities/domain/InvoicingActivity';
import { InvoicingActivitiesTypeChecker } from 'features/Ingresos/InvoicingActivities/domain/InvoicingActivityChecker';
import { IngresoConFacturaClientRulesService } from '../../IngresoConFactura/services/IngresoConFacturaRulesService';
import { Invoice } from '../../IngresoConFactura/domain/Invoice';
import { errorRequired } from './common-errors';

export const activitySchema = Schema.object({
    id: Schema.string().required('Debes seleccionar la actividad'),
    section: Schema.string().required(errorRequired),
    epigraph: Schema.string().required(errorRequired),
    name: Schema.string().required(errorRequired),
    isVatExempt: Schema.boolean().required(errorRequired),
    registrationDate: Schema.string().required(errorRequired),
    withImageRights: Schema.boolean()
        .nullable()
        .when(['activityType'], ([activityType], schema) => {
            return schema.test({
                name: 'with-image-rights-conditional',
                test: function (value, ctx) {
                    if (
                        !activityType ||
                        !InvoicingActivitiesTypeChecker.isArtistic(activityType as Invoice['activity']['activityType'])
                    )
                        return true;

                    const { from } = ctx;
                    if (!from || from.length < 2) return true;
                    const invoice = from[1]?.value as Invoice;

                    if (!invoice.activity.activityType && !invoice.client.clientType && !invoice.client.country)
                        return true;

                    const isRequired = IngresoConFacturaClientRulesService.isImageRightsRequired({
                        activityType: invoice.activity.activityType,
                        clientType: invoice.client.clientType,
                        country: invoice.client.country,
                    });

                    if (!isRequired) return true;

                    if (typeof value !== 'boolean') {
                        return this.createError({
                            message: 'Debes seleccionar si la actividad tiene derechos de imagen',
                        });
                    }

                    return true;
                },
            });
        }),
    forFattening: Schema.boolean()
        .nullable()
        .when(['activityType'], ([activityType], schema) => {
            return schema.test({
                name: 'with-rancher-fattening-conditional',
                test: function (value, ctx) {
                    if (
                        !activityType ||
                        !InvoicingActivitiesTypeChecker.isRancherForFattening(
                            activityType as Invoice['activity']['activityType'],
                        )
                    )
                        return true;

                    const { from } = ctx;
                    if (!from || from.length < 2) return true;
                    const invoice = from[1]?.value as Invoice;

                    if (!invoice.activity.activityType && !invoice.client.clientType && !invoice.client.country)
                        return true;

                    const isRequired = IngresoConFacturaClientRulesService.isForFatteningRequired({
                        activityType: invoice.activity.activityType,
                        clientType: invoice.client.clientType,
                        country: invoice.client.country,
                    });

                    if (!isRequired) return true;

                    if (typeof value !== 'boolean') {
                        return this.createError({
                            message: 'Debes seleccionar si la actividad tiene engorde',
                        });
                    }

                    return true;
                },
            });
        }),
    activityType: Schema.string().oneOf(invoicingActivityTypes).required(errorRequired),
});
