import V2Repository from 'shared/infra/repositories/V2Repository';
import { SalesDocumentSendEmailRepository } from '../../shared/domain/SalesDocumentSendEmailWithDocument';

class InvoiceSendEmailRepository extends V2Repository implements SalesDocumentSendEmailRepository {
    sendEmailWithDocument(args: { email: string; documentId: string }): Promise<void> {
        return this.post(`/invoices/${args.documentId}/document/send-email`, JSON.stringify({ email: args.email }));
    }
}

export const createInvoiceSendEmailRepository = (): SalesDocumentSendEmailRepository => {
    return new InvoiceSendEmailRepository();
};
