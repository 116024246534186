import React, { createContext, useContext, useState } from 'react';
import { RolesHelper } from 'shared/stores/RolesHelper';
import { useTasksListStore } from 'shared/stores/useTasksListStore';
import { useAdvicesListStore } from 'shared/stores/useAdvicesListStore';
import { useContentScroll } from 'shared/stores/useContentScroll';
import { useModel180Store } from 'shared/stores/useModel180Store';
import { IAEType } from 'shared/domain/fiscalStatus/iae/IAEType';
import { TPlanHelper } from 'shared/stores/PlanHelper';
import { IsoCodes } from 'shared/domain/constants/IsoCodes';
import { LugarDeTrabajo } from 'shared/domain/fiscalStatus/LugarDeTrabajo';
import { ValueOfOnboardingSteps } from 'features/Onboarding/domain/OnboardingSteps';
import { TRecurrente } from 'shared/infra/repositories/UserV2Repository';
import { FeatureFlags } from '../../../../config/FeatureFlags';

export const RootContext = createContext<TRootContext>({} as TRootContext);

/** To use in functional components **/
export const useRootContext = () => {
    const context = useContext(RootContext);

    if (!context) {
        throw new Error('useRootContext must be used within a RootContextProvider');
    }

    return context;
};

type RootContextProviderWrapperProps = {
    value: RootContextProviderProps;
    children: React.ReactNode;
};

/*
const useFetchRecurrenteData: () => { data: TRecurrente | null; isLoading: boolean } = () => {
    const { data, status } = useFetch({
        queryKey: ['getRecurrenteData'],
        fetchFn: () => new UserV2Repository().getRecurrenteData(),
        config: {
            refetchOnWindowFocus: false,
            select: (data: TRecurrente) => data ?? null,
        },
    });

    return {
        data: data ?? null,
        isLoading: status === 'pending',
    };
};
*/

export const RootContextProvider = ({ children, value }: RootContextProviderWrapperProps) => {
    const tasksList = useTasksListStore();
    const advicesList = useAdvicesListStore();
    const contentScroll = useContentScroll();
    const Model180Store = useModel180Store();
    const [hideNavbar, setHideNavbar] = useState(false);
    //const { data, isLoading } = useFetchRecurrenteData();

    const isFree = value.planHelper?.isFree() ?? false;
    const isPeriodoDemo = value.isPeriodoDemo ?? false;

    const valueProvider = {
        ...value,
        tasksList,
        advicesList,
        contentScroll,
        Model180Store,
        hideNavbar,
        setHideNavbar,
        isFree,
        isPeriodoDemo,
        userFreeIsPeriodoDemo: FeatureFlags.enableTopBannerPeriodoPrueba() ? isFree && isPeriodoDemo : false,
        userFreeNotPeriodoDemo: FeatureFlags.enableTopBannerPeriodoPrueba() ? isFree && !isPeriodoDemo : false,
    };

    return <RootContext.Provider value={valueProvider}>{children}</RootContext.Provider>;
};

export type RootContextProviderProps = {
    loadUserInfo: () => Promise<any>;
    setOnboarding: (data: { step: string; isActive: boolean }) => void;
    rolesHelper: RolesHelper;
    wide: boolean;
    routes: Route[];
    history: History;
    match: Match;
    perfilBloqueado: boolean;
    phoneValidated: PhoneValidated;
    userIdentity: TUserIdentity;
    userInfo: UserInfo;
    userStatus: UserStatus;
    userAddress: UserAddress;
    userWorkAddress: UserAddress;
    iaesHelper: any;
    iaesChanged: boolean;
    planHelper: TPlanHelper;
    diasRestantesDemo: number;
    isFree: boolean;
    isPeriodoDemo: boolean;
    updateUserIdentity: (field: keyof TUserIdentity, value: unknown) => void;
    updateAsyncUserIdentity: (field: keyof TUserIdentity, value: unknown) => Promise<boolean>;
    updateUserAddressFull: (address: Partial<UserAddress>, callback?: () => void) => void;
    updateUserIdentityFull: (identity: TUserIdentity, callback?: () => void) => void;
    updateUserStatus: (field: keyof UserStatus, value: unknown, callback?: () => void) => void;
    storeUserStatus: () => Promise<{ success: boolean }>;
    storeUserIdentity: () => Promise<{ success: boolean }>;
    regularizacionTarjeta: {
        fechaRecurrenciaProgramada: string | null;
        regularizarTarjetaConCoF: boolean;
        blockedModalRegularizarTarjeta: boolean;
        showModalVisaChange: boolean;
        displayModalVisaChangeHandler: () => void;
        hideModalVisaChangeHandler: () => void;
    };
    onboarding: {
        isActive: boolean;
        step: ValueOfOnboardingSteps;
    };
    recurrenteData: TRecurrente | null;
    openPopUpContrata: () => void;
};

export type TRootContext = {
    tasksList: ReturnType<typeof useTasksListStore>;
    advicesList: ReturnType<typeof useAdvicesListStore>;
    Model180Store: ReturnType<typeof useModel180Store>;
    hideNavbar: boolean;
    setHideNavbar: (value: boolean) => void;
    recurrenteData: TRecurrente | null;
    userFreeIsPeriodoDemo: boolean;
    userFreeNotPeriodoDemo: boolean;
} & RootContextProviderProps;

export type History = {
    length: number;
    action: string;
    location: Location;
    push: (path: string) => void;
};

export type Location = {
    pathname: string;
    search: string;
    hash: string;
};

export type Match<TParams = { [key: string]: any }> = {
    path: string;
    url: string;
    isExact: boolean;
    params: TParams;
};

export type PhoneValidated = {
    validado: boolean;
    vencimiento_telefono: string;
    semana2_dias_restantes: number;
    fecha_limite: string;
};

export type Route = {
    component?: Component;
    name: NameClass | string;
    path: string;
    isPaylandsOk?: boolean;
    hidden?: boolean;
    isAccountingSection?: boolean;
    availableAlwaysForFree?: boolean;
    advicesSection?: string;
    isAccountingDisabledForFree?: boolean;
    contextPath?: string;
    partialMatch?: boolean;
    isRenta?: boolean;
    hiddenDesktop?: boolean;
    role?: string;
    badge?: boolean;
    redirect?: boolean;
    to?: string;
};

export type Component = {
    _payload: Payload;
};

export type Payload = {
    _status: number;
    _result?: object;
};

export type NameClass = {
    key: null;
    ref: null;
    props: Props;
    _owner: null;
    _store: object;
};

export type Props = {
    children: string;
};

export type UserAddress = {
    tipoVia: string;
    nombreVia: string;
    numero: string;
    bloque: string;
    portal: string;
    escalera: string;
    piso: string;
    puerta: string;
    provincia_id: number;
    poblacion_id: number;
    cpostal: string;
    ref_catastral: null;
    provider_id: null;
    superficie: string;
    superficieTotal: string;
};

export type TUserIdentity = {
    nif: string;
    email: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    language: string;
    phone: string;
    prefijo: number;
    iban: string;
    digitalSign: string;
    nationality: IsoCodes;
};

export type UserInfo = {
    id: string;
    name: string;
    isAgent: string;
    plan: Plan;
    registerDate: string;
    demoUntilDate: string;
};

export type Plan = {
    intercomCode: number;
    label: string;
    id: string;
};

export type UserStatus = {
    iaes: IAEType[];
    autonomo: boolean;
    accounting: boolean;
    local: LugarDeTrabajo;
    hasRentedLocal: boolean;
    cert: boolean;
    digitalSign: boolean;
    renta: boolean;
    equivalence: number;
    ivaExempt: number;
    altaDate: string;
    fechaBajaAutonomo: null;
    altaRentedDate: string;
    bajaRentedDate: string;
    autoNumInvoice: boolean;
    prorrataPercent: number;
    maxIrpf: boolean;
    forcedIrpf: null;
    roi: Roi;
};

export type Iae = {
    id: string;
    fechaAlta: string;
    activo: string;
    seccion: string;
    epigrafe: string;
    denominacion: string;
    grupo: string;
    subgrupo: string;
    subgrupoNombre: string;
    cnaeId: null;
    isComercioMayor: boolean;
    main: boolean;
    ivaExempt: boolean;
    equivalenceSurcharge: boolean;
    isRegimenGeneral: boolean;
    iaeDeducible100: boolean;
};

export type Roi = {
    nifIva: null;
    fechaAlta: null;
    fechaBaja: null;
};
