import React, { FC, useContext, useEffect, useState } from 'react';
import { Advices } from 'shared/UI/components/App/AdvicesBar/Advices';
import RepositoryFactory from 'shared/infra/repositories/RepositoryFactory';
import { RootContext } from 'shared/UI/context/RootContext/RootContext';
import { Notifier } from 'shared/infra/utils/Notifier';
import { AdvicesV2Repository } from '../../../../infra/repositories/AdvicesV2Repository';
import { InformCNAERulesClientService } from 'features/Impuestos/InformCNAE/services/InformCNAEService/InformCNAERulesClientService';

export class AdvicesListEvents {
    static refreshAdvicesEvent = new Event('REFRESH_ADVICES');

    static addRefreshEventListener(action: any) {
        document.addEventListener('REFRESH_ADVICES', action);
    }

    static removeRefreshEventListener(action: any) {
        document.removeEventListener('REFRESH_ADVICES', action);
    }

    static dispatchRefreshAdvices() {
        document.dispatchEvent(this.refreshAdvicesEvent);
    }

    static dispatch(event: any) {
        document.dispatchEvent(event);
    }
}

export const AdvicesBarSections: FC<{
    advicesSection: string;
    title: string;
    description: string;
    store?: any;
    tasks: any[];
}> = ({ description, title, advicesSection }) => {
    const { tasksList, advicesList, userStatus } = useContext(RootContext);
    const [advices, setAdvices] = useState(null);
    const [state, setState] = useState(1);
    const [display, setDisplay] = useState<boolean | null>(null);
    const [loading, setLoading] = useState(true);

    const repositoryFactory = new RepositoryFactory();
    const advicesV2Repository = new AdvicesV2Repository();
    const adviceScoreRepository = repositoryFactory.getAdviceScoreRepository();

    async function retrieveAdvices() {
        setLoading(true);
        try {
            if (InformCNAERulesClientService.shouldInformCNAE({ iaes: userStatus.iaes }) && advicesSection === 'taxes')
                return;
            const { data } = await advicesV2Repository.getAdvices(advicesSection);
            setAdvices(data);
        } catch {
            Notifier.notifyError('', 'No se han podido recuperar los consejos');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        //advicesList.reset();
        retrieveAdvices();
        AdvicesListEvents.addRefreshEventListener(retrieveAdvices);
        return () => {
            AdvicesListEvents.removeRefreshEventListener(retrieveAdvices);
        };
    }, []);

    const onRemember = async (advice: any, AdviceState: any, date_option: any) => {
        await advicesV2Repository.changeStateProgramed(advice.id, AdviceState.PROGRAMED, date_option);
        // @ts-ignore
        setAdvices(advices.filter((item) => item.id !== advice.id));
    };

    const onDelete = async (score: any, advice: any, AdviceState: any) => {
        await advicesV2Repository.changeState(advice.id, AdviceState.DELETED);
        adviceScoreRepository.addScore(advice.id, score);
        // @ts-ignore
        setAdvices(advices.filter((item) => item.id !== advice.id));
    };

    return (
        <div className="adviceBar">
            {/*// @ts-ignore */}
            <Advices
                title={title}
                description={description}
                onRemember={onRemember}
                onDelete={onDelete}
                state={state}
                display={display}
                setDisplay={setDisplay}
                setState={setState}
                tasks={tasksList.state.tasks}
                advices={advices}
                tasksList={tasksList}
                advicesList={advicesList}
                loading={loading}
            />
        </div>
    );
};
