type Options = { locale?: string; currency?: string; minimumFractionDigits?: number; maximumFractionDigits?: number };

export const Currency = (value: number, options: Options = {}, miniDecimals = 0) => {
    const {
        locale = 'de-DE',
        currency = 'EUR',
        minimumFractionDigits = value % 1 !== 0 ? 2 : miniDecimals,
        maximumFractionDigits = 2,
    } = options;

    return new Intl.NumberFormat(locale, { style: 'currency', currency, minimumFractionDigits, maximumFractionDigits })
        .format(value)
        .replaceAll(/\s/g, '');
};
