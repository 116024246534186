import { Box, Header, P } from '@declarando/design_system';
import Loader from './assets/Loader.svg';
import './assets/animation.css';
import { useContext, useEffect } from 'react';
import { OnboardingService } from '../../../services/OnboardingService';
import { OnboardingContext } from '../../Onboarding';

export const CertificadoProcesandoPago = () => {
    const { currentStep } = useContext(OnboardingContext);
    useEffect(() => {
        const repetidor = setInterval(async () => {
            const { data } = await new OnboardingService().getOnboarding();
            if (data?.step && data.step !== currentStep) {
                window.location.href = '/';
                clearInterval(repetidor);
                return;
            }
        }, 5000);
        return () => {
            clearInterval(repetidor);
        };
    }, []);

    return (
        <Box
            className={'ReloadContainer'}
            alignCenter
            p={'xxl'}
            bg={'white'}
            height={'100vh'}
            width={'100vw'}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                background: 'white !important',
            }}
        >
            <Box p="xxl">
                <img className={'reloadImg'} src={Loader} alt="Procesando pago" />
                <Header as={'h2'} mt={'sm'} mb={'xs'}>
                    Estamos procesando tu pago...
                </Header>
                <P>El proceso puede llevar unos segundos, por favor, no cierres esta ventana.</P>
            </Box>
        </Box>
    );
};
