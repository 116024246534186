import { Schema } from 'shared/validation/Schema';
import { IngresoConFacturaClientRulesService } from '../../IngresoConFactura/services/IngresoConFacturaRulesService';
import { BaseSalesDocumentForm } from '../domain/SalesDocument';
import { AdditionalValidationTest } from './SalesDocumentSchema';

export const createIssueDateSchema = <T extends BaseSalesDocumentForm>(args: {
    salesDocumentMode: 'create' | 'edit';
    numerationType: 'automatic' | 'manual';
    validations?: {
        additionalTests?: AdditionalValidationTest[];
    };
}) => {
    const baseIssueDateTests: AdditionalValidationTest[] = [
        {
            name: 'contabilidad-presented-or-blocked',
            message: '',
            test: (value, ctx) => {
                const { from } = ctx;
                if (!from) return true;
                const salesDocument = from[0]?.value as T;
                const hasContabilidadPresentedOrBlocked = salesDocument.hasContabilidadPresentedOrBlocked;

                if (!value || !hasContabilidadPresentedOrBlocked) return true;

                return ctx.createError({
                    message: 'Has seleccionado la fecha de un trimestre ya cerrado',
                });
            },
        },
        {
            name: 'is-after-activity-registration-date',
            message: 'No puede ser anterior a la fecha de alta de actividad',
            test: (value, ctx) => {
                const { parent } = ctx;

                if (!parent.activity?.registrationDate) return true;

                return IngresoConFacturaClientRulesService.isExpeditionDateLaterThanActivityRegistrationDate({
                    expeditionDate: value,
                    activityRegistrationDate: parent.activity.registrationDate,
                });
            },
        },
    ];

    const issueDateSchema = Schema.string().required('Debes seleccionar la fecha de emisión');

    const finalIssueDateSchema = [...baseIssueDateTests, ...(args?.validations?.additionalTests || [])].reduce(
        (schema, testConfig) => {
            return schema.test(testConfig.name, testConfig.message, testConfig.test);
        },
        issueDateSchema,
    );

    return finalIssueDateSchema;
};
