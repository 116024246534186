import GastosConFacturaTypes from 'shared/domain/contabilidad/GastosConFacturaTypes';
import GastosSinFacturaTypes, { SubTiposGastosSinFacturaTypes } from 'shared/domain/contabilidad/GastosSinFacturaTypes';
import ProviderTypes from 'shared/domain/proveedores/ProviderTypes';
import { IAEType } from 'shared/domain/fiscalStatus/iae/IAEType';

import { TInvoiceDataGasto } from '../GastoCockpit/types/TInvoiceDataGasto';
import { subtipos100pcDeducibles, tipos100pcDeducibles } from '../infra/TiposDeduciblesRepository';
import { LugarDeTrabajo } from 'shared/domain/fiscalStatus/LugarDeTrabajo';
import { reglasVehiculoPropio } from './ReglasVehiculoPropio';
import { TSubtipado, TTipado } from 'shared/TipadoType';
import { ReglasOtros } from './ReglasOtros';

export const ReglasDeducibilidadMapper = {
    vehiculoPropioEditable: 'vehiculoPropioEditable',
    vehiculoPropioFixed: 'vehiculoPropioFixed',
    otros: 'otros',
} as const;
export type TReglasDeducibilidadMapper = (typeof ReglasDeducibilidadMapper)[keyof typeof ReglasDeducibilidadMapper];

export type TReglasDeducibilidad = {
    type: 'fixed' | 'editable';
    min?: number;
    value: number;
    info?: string;
    hideQuestion?: boolean;
    infoLink?: { label: string; link: string };
    selectReason?: boolean;
    mapper?: TReglasDeducibilidadMapper;
};

export class ReglasDeducibilidad {
    private conFactura: boolean;
    private tipoGasto?: TTipado;
    private subtipo: TSubtipado;
    private spanishProvider = false;

    private get fixed100(): TReglasDeducibilidad {
        return {
            type: 'fixed',
            value: 100,
            info: 'Este gasto es 100% deducible',
        };
    }

    constructor(
        private iae: IAEType,
        private invoiceData: TInvoiceDataGasto,
        private local: LugarDeTrabajo,
        private espacioDedicado: number,
        private totalMetros: number,
    ) {
        const { esConFactura, tipado, subtipado, providerType } = this.invoiceData as TInvoiceDataGasto;
        this.conFactura = !!esConFactura;
        this.tipoGasto = tipado as TTipado;
        this.subtipo = subtipado as TSubtipado;
        this.spanishProvider = !!providerType && +providerType === +ProviderTypes.SPANISH;
    }

    esGastoTipoCasa(): boolean {
        return this.tipoGasto === GastosConFacturaTypes.FOREIGN_GASTO_CASA;
    }

    trabajaEncasa(): boolean {
        return [LugarDeTrabajo.casaEnPropiedad, LugarDeTrabajo.casaEnAlquiler].includes(this.local);
    }

    esGastoImpuestosYTasas(): boolean {
        return this.tipoGasto === GastosSinFacturaTypes.IMPUESTOS_Y_TASAS;
    }

    esGastoComunidadDePropietarios(): boolean {
        return this.tipoGasto === GastosSinFacturaTypes.COMUNIDAD_DE_PROPIETARIOS;
    }

    deducible100(): boolean {
        if (
            [
                GastosSinFacturaTypes.TIQUETS,
                GastosSinFacturaTypes.SEGUROS,
                GastosConFacturaTypes.SPANISH_DIETAS,
                GastosConFacturaTypes.FOREIGN_DIETAS,
            ].includes(this.tipoGasto as GastosConFacturaTypes)
        ) {
            return subtipos100pcDeducibles.includes(this.subtipo);
        } else if (
            this.tipoGasto === GastosSinFacturaTypes.IMPUESTOS_Y_TASAS &&
            this.subtipo === SubTiposGastosSinFacturaTypes.MATRICULACION
        ) {
            return true;
        }
        return tipos100pcDeducibles.includes(this.tipoGasto as GastosConFacturaTypes);
    }

    calcularDeducibilidad = (): TReglasDeducibilidad => {
        return this.conFactura ? this.calcularDeducibilidadConFactura() : this.calcularDeducibilidadSinFactura();
    };

    calcularDeducibilidadConFactura = (): TReglasDeducibilidad => {
        if (!this.tipoGasto || !this.conFactura) {
            return { type: 'fixed', value: 50 };
        }

        if (reglasVehiculoPropio.isVehiculoPropio(this.tipoGasto)) {
            return reglasVehiculoPropio.getDeducibilidad(this.iae);
        }

        if (this.esGastoTipoCasa()) {
            if (this.local === LugarDeTrabajo.otrasSituaciones) {
                return { type: 'editable', value: 0, hideQuestion: true };
            } else if (this.trabajaEncasa()) {
                return {
                    type: 'fixed',
                    value: (+this.espacioDedicado / +this.totalMetros) * 30,
                    info: 'Como trabajas en casa, el porcentaje de deducibilidad es el resultante de aplicar el 30% a la proporción entre los m² de la vivienda y los destinados a la actividad\n',
                };
            } else {
                return {
                    ...this.fixed100,
                    info: 'Como trabajas en una oficina/local alquilado/en propiedad o en otras situaciones, el gasto es 100% deducible',
                };
            }
        }

        if (this.deducible100()) {
            return this.fixed100;
        }

        if (ReglasOtros.esOtros(this.tipoGasto)) {
            return ReglasOtros.getDeducibilidad();
        }

        return { type: 'editable', value: 100 };
    };

    private calcularDeducibilidadSinFactura = (): TReglasDeducibilidad => {
        if (!this.tipoGasto) {
            return { type: 'fixed', value: 50 };
        }

        if (ReglasOtros.esOtros(this.tipoGasto)) {
            return ReglasOtros.getDeducibilidad();
        }

        if (
            [LugarDeTrabajo.otrasSituaciones, LugarDeTrabajo.oficinaLoaclEnAlquilado].includes(this.local) &&
            GastosSinFacturaTypes.IMPUESTOS_Y_TASAS === this.tipoGasto
        ) {
            return this.fixed100;
        }

        if (
            this.local === LugarDeTrabajo.otrasSituaciones &&
            (this.esGastoImpuestosYTasas() || this.esGastoComunidadDePropietarios())
        ) {
            return {
                type: this.subtipo === SubTiposGastosSinFacturaTypes.MATRICULACION ? 'fixed' : 'editable',
                value: this.subtipo === SubTiposGastosSinFacturaTypes.MATRICULACION ? 100 : 0,
                hideQuestion: true,
            };
        }

        if (
            (this.esGastoImpuestosYTasas() && this.subtipo !== SubTiposGastosSinFacturaTypes.MATRICULACION) ||
            this.esGastoComunidadDePropietarios()
        ) {
            return this.trabajaEncasa()
                ? {
                      type: 'fixed',
                      value: (+this.espacioDedicado / +this.totalMetros) * 100,
                      info: 'Como trabajas en casa, el porcentaje de deducibilidad es la proporción entre los m² de la vivienda y los destinados a la actividad',
                  }
                : {
                      type: 'fixed',
                      value: 100,
                      info: 'Como trabajas en una oficina/local alquilado/en propiedad o en otras situaciones, el gasto es 100% deducible',
                  };
        }

        if (this.deducible100()) {
            return this.fixed100;
        }

        return { type: 'editable', value: 100 };
    };
}

/**
 * TODO: Refactor reglas tipo casa

export const reglasTipoCasa = {
    esGastoTipoCasa(tipado: GastosConFacturaTypes | GastosSinFacturaTypes): boolean {
        return tipado === GastosConFacturaTypes.FOREIGN_GASTO_CASA;
    },
    getDeducibilidad(local: LugarDeTrabajo, espacioDedicado: number, totalMetros: number): TReglasDeducibilidad {
      if (local === LugarDeTrabajo.otrasSituaciones) {
        return { type: 'editable', value: 0, hideQuestion: true };
      } else if (this.trabajaEncasa()) {
        return {
          type: 'fixed',
          value: (+espacioDedicado / +totalMetros) * 30,
          info: 'Como trabajas en casa, el porcentaje de deducibilidad es el resultante de aplicar el 30% a la proporción entre los m² de la vivienda y los destinados a la actividad\n',
        };
      } else {
        return {
          ...this.fixed100,
          info: 'Como trabajas en una oficina/local alquilado/en propiedad o en otras situaciones, el gasto es 100% deducible',
        };
      }
    },
};
*/
