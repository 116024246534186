import * as React from 'react';
import { createContext } from 'react';
import { useStep1 } from './useStep1';

export const Step1Context = createContext({} as ReturnType<typeof useStep1>);

export const Step1ContextProvider = ({ children }: { children: React.ReactNode }) => {
    const step1 = useStep1();

    return <Step1Context.Provider value={step1}>{children}</Step1Context.Provider>;
};
