import { Schema } from 'shared/validation/Schema';
import { IngresoConFacturaClientRulesService } from '../../IngresoConFactura/services/IngresoConFacturaRulesService';
import { clientSchema } from './ClientSchema';
import { activitySchema } from './ActivitySchema';
import { paymentOptionSchema } from './PaymentOptionSchema';

export type AdditionalValidationTest = {
    name: string;
    message: string;
    test: (value: any, context: any) => boolean;
};

export const createDocumentNumberSchema = (args: { errorRequired: string }) => {
    return Schema.string().required(args.errorRequired);
};

export const createBaseSalesDocumentSchema = () => {
    const baseSchema = {
        id: Schema.string().default(''),
        minValidIssueDate: Schema.string().nullable(),
        maxValidIssueDate: Schema.string().nullable(),
        dueDate: Schema.string()
            .optional()
            .nullable()
            .when('issueDate', {
                is: (issueDate: string) => issueDate !== '',
                then: (schema) =>
                    schema.test('overdue-date', 'No puede ser anterior a la fecha de emisión', (value, ctx) => {
                        if (!value || value === 'Invalid Date') return true;
                        const { parent } = ctx;

                        if (!parent?.issueDate) return true;

                        //TODO: Common logic between quote and invoice, should be extracted to a common service
                        return IngresoConFacturaClientRulesService.isDueDateValid({
                            expeditionDate: parent?.issueDate,
                            dueDate: value,
                        });
                    }),
                otherwise: (schema) => schema,
            }),
        incomeTax: Schema.number().min(0, 'Income tax cannot be negative').required('Income tax is required'),
        client: clientSchema,
        activity: activitySchema,
        hasContabilidadPresentedOrBlocked: Schema.boolean().nullable(),
        canApplyEquivalencySurcharge: Schema.boolean().nullable(),
        withEquivalencySurcharge: Schema.boolean()
            .nullable()
            .when(['canApplyEquivalencySurcharge'], ([canApplyEquivalencySurcharge], schema) => {
                return schema.test({
                    name: 'is-equivalency-surcharge-required',
                    message: 'Debes seleccionar si tu cliente está en recargo de equivalencia',
                    test: function (value) {
                        if (!canApplyEquivalencySurcharge) {
                            return true;
                        }

                        return typeof value === 'boolean';
                    },
                });
            }),
        paymentOptions: paymentOptionSchema,
        documentNote: Schema.string().optional(),
    };

    return baseSchema;
};
