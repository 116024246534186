import { toInvoiceSummary } from 'features/Ingresos/SalesDocument/IngresoConFactura/adapters/InvoiceListAdapter';
import { GetInvoicesListParams } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/IngresoConFacturaRepository';
import { createIngresoConFacturaApiRepository } from 'features/Ingresos/SalesDocument/IngresoConFactura/infra/IngresoConFacturaRepository';
import { createIngresoConFacturaService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaService';
import { longLivedQueryConfig } from 'shared/UI/config/react-query-config';
import { useFetch } from 'shared/UI/hooks/QueryHooks';

export const useGetIngresosConFacturaList = (
    args: GetInvoicesListParams,
    options?: { shouldTriggerCall?: boolean },
) => {
    const service = createIngresoConFacturaService(createIngresoConFacturaApiRepository());

    const context = useFetch({
        //NOTE: mejorar el key
        queryKey: ['invoices', JSON.stringify(args)],
        fetchFn: () => service.getInvoicesList(args),
        config: {
            select: (data) => ({ ...data, data: data?.data ? data?.data.data.map(toInvoiceSummary) : [] }),
            enabled: options?.shouldTriggerCall,
            ...longLivedQueryConfig,
        },
    });

    return { ingresosConFacturaList: context?.data?.data, isLoading: context.isLoading };
};
