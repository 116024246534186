import { Schema } from 'shared/validation/Schema';
import { IObjectSchema } from 'shared/validation/types';
import { Invoice, InvoiceForm } from '../domain/Invoice';
import { invoiceDocumentNumberUnique } from './InvoiceDocumentNumberUnique';
import { IngresoConFacturaClientRulesService } from '../services/IngresoConFacturaRulesService';
import { SalesDocumentFormMode } from '../../shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import DateWrapper from 'shared/infra/utils/Date';
import { createIssueDateSchema } from '../../shared/validations/IssueDateSchema';
import { createSalesDocumentLineSchema } from '../../shared/validations/LinesSchema';
import {
    createBaseSalesDocumentSchema,
    createDocumentNumberSchema,
} from '../../shared/validations/SalesDocumentSchema';

export const getInvoiceSchema: IObjectSchema<InvoiceForm> = (args: {
    salesDocumentMode: SalesDocumentFormMode;
    defaultValues: InvoiceForm;
    numerationType: 'automatic' | 'manual';
}) => {
    const baseSchema = createBaseSalesDocumentSchema();

    const documentNumberSchema = createDocumentNumberSchema({
        errorRequired: 'Debes introducir un nº de factura',
    }).test('unique', 'Este número de la factura ya existe', () =>
        invoiceDocumentNumberUnique.isInputNumberUniqueValid(),
    );

    const issueDateSchema = createIssueDateSchema<InvoiceForm>({
        numerationType: args.numerationType,
        salesDocumentMode: args.salesDocumentMode,
        validations: {
            additionalTests: [
                {
                    name: 'is-after-last-sales-document',
                    message: '',
                    test: (value, ctx) => {
                        const { parent } = ctx;
                        if (
                            !value ||
                            !parent.minValidIssueDate ||
                            //TODO: Pass the logic to the domain?
                            args.salesDocumentMode === 'edit'
                        )
                            return true;

                        //TODO: Common logic between quote and invoice, should be extracted to a common service
                        const isValid = IngresoConFacturaClientRulesService.isExpeditionDateAfterLastInvoiceDate({
                            currentDate: value,
                            lastInvoiceIssueDate: parent.minValidIssueDate,
                        });

                        if (isValid) return true;

                        const formattedMinValidDate = DateWrapper.convertDateFormatType(parent.minValidIssueDate, {
                            formatFrom: 'yyyy-MM-dd',
                            formatTo: 'dd-MM-yyyy',
                        });

                        return ctx.createError({
                            message: `Tu última factura es del ${formattedMinValidDate}, selecciona una fecha igual o posterior`,
                        });
                    },
                },
                {
                    name: 'is-issueDate-between-range',
                    message: '',
                    test: (value, ctx) => {
                        const { from } = ctx;
                        if (!from) return true;
                        const invoice = from[0]?.value as InvoiceForm;
                        if (
                            !value ||
                            !invoice.minValidIssueDate ||
                            !invoice.maxValidIssueDate ||
                            //TODO: Pass the logic to the domain?
                            args.salesDocumentMode !== 'edit' ||
                            args.numerationType !== 'automatic'
                        )
                            return true;

                        const parsedIssueDate = DateWrapper.formatNativeDateToString(value, 'yyyy-MM-dd');

                        const isValid =
                            IngresoConFacturaClientRulesService.isExpeditionDateBetweenValidRangeWhenEditingAutomaticNumbering(
                                {
                                    expeditionDate: parsedIssueDate,
                                    minValidDate: invoice.minValidIssueDate,
                                    maxValidDate: invoice.maxValidIssueDate,
                                },
                            );

                        if (isValid) return true;

                        const formattedMinValidDate = DateWrapper.convertDateFormatType(invoice.minValidIssueDate, {
                            formatFrom: 'yyyy-MM-dd',
                            formatTo: 'dd-MM-yyyy',
                        });
                        const formattedMaxValidDate = DateWrapper.convertDateFormatType(invoice.maxValidIssueDate, {
                            formatFrom: 'yyyy-MM-dd',
                            formatTo: 'dd-MM-yyyy',
                        });

                        return ctx.createError({
                            message: `Selecciona una fecha entre ${formattedMinValidDate} y el ${formattedMaxValidDate}`,
                        });
                    },
                },
            ],
        },
    });

    const linesSchema = createSalesDocumentLineSchema({
        validations: {
            totalLine: {
                additionalTests: [
                    {
                        name: 'total-lines-should-be-diferent-than-0',
                        message: 'El total de la factura debe ser distinto de 0',
                        test: (_, ctx) => {
                            const { from } = ctx;
                            if (!from || from.length < 2) return true;
                            const invoiceDocument = from[1]?.value as Invoice;

                            return IngresoConFacturaClientRulesService.isTotalAmountDifferentFromZero(invoiceDocument);
                        },
                    },
                ],
            },
        },
    });

    return Schema.object({
        ...baseSchema,
        documentNumber: documentNumberSchema,
        issueDate: issueDateSchema,
        lines: Schema.array().of(linesSchema).required(),
    });
};
