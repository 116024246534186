import { useFetch } from 'shared/UI/hooks/QueryHooks';
import { createLocationService } from '../../services/LocationService';
import { createLocationsRepository } from '../../infra/LocationsRepository';
import { longLivedQueryConfig } from 'shared/UI/config/react-query-config';
import { transformCountryDTO } from '../../adapters/LocationsAdapter';
import { CountryDTO } from '../../adapters/CountryDTO';
import { Country } from '../../domain';

export const useGetCountriesList = () => {
    const service = createLocationService(createLocationsRepository());

    const context = useFetch<CountryDTO[], Country[]>({
        queryKey: ['countries'],
        fetchFn: () => service.getCountries(),
        config: {
            ...longLivedQueryConfig,
            select: (data) => ({ ...data, data: data?.data ? transformCountryDTO(data.data) : [] }),
        },
    });

    return { countries: context.data?.data || [], isLoading: context.isLoading };
};
