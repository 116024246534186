import { ClienteDTO } from 'features/Incomes/domain/Cliente';
import { Client } from '../domain';
import { isClientCanario } from '../domain/Canario';
import { LegacyClientRequestDTO } from './LegacyClient';

/**
 * Temporary function to convert the new client to the legacy client DTO format
 *
 */
export function toLegacyClientDTO(client: Client): LegacyClientRequestDTO {
    return {
        id: client?.id as unknown as number,
        nombre: client.name,
        cif: client.fiscalId || '',
        nif_iva: client.hasEUVatNumber || false,
        type: mapClientType(client.clientType),
        country_id: client.country.id as string,
        provincia_id: client?.state?.id || '',
        poblacion_id: client?.city?.id || '',
        cp: client.postalCode || '',
        direccion: client.address || '',
        email: client.email || (null as unknown as string),
        prefijo: client?.phone?.prefix || (null as unknown as string),
        phone: client?.phone?.number || '',
        isCanario: isClientCanario(client?.state?.id),
    };
}

function mapClientType(type: Client['clientType']): ClienteDTO['type'] {
    switch (type) {
        case 'COMPANY':
            return 'Empresa';
        case 'FREELANCE':
            return 'Autónomo';
        case 'INDIVIDUAL':
            return 'Particular';
        default:
            throw new Error(`Unknown client type: ${type}`);
    }
}
