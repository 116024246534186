import { Grid } from '@declarando/design_system';
import { PaymentOptionsCash } from './components/PaymentOptionsCash';
import { PaymentOptionsOther } from './components/PaymentOptionsOthers';
import { PaymentOptionsTransfer } from './components/PaymentOptionsTransfer';

export const PaymentOptions = () => {
    return (
        <Grid gridTemplateColumns={{ _: '1fr' }} gridColumnGap="md" gridRowGap="md">
            <PaymentOptionsCash />
            <PaymentOptionsTransfer />
            <PaymentOptionsOther />
        </Grid>
    );
};
