import React from 'react';

const FinanciacionStep1 = React.lazy(() => import('features/Financiacion/UI/FinanciacionStep1'));
const FinanciacionFueraDePlazo = React.lazy(() => import('features/Financiacion/UI/FinanciacionFueraDePlazo'));
const FinanciacionOK = React.lazy(() => import('features/Financiacion/UI/FinanciacionOK'));
const FinanciacionNoOk = React.lazy(() => import('features/Financiacion/UI/FinanciacionNoOk'));
const FinanciacionRechazada = React.lazy(() => import('features/Financiacion/UI/FinanciacionRechazada'));
const FinanciacionErrorDatos = React.lazy(() => import('features/Financiacion/UI/FinanciacionErrorDatos'));

const financiacionRoutes = [
    {
        name: 'financiacion-ok',
        path: '/financiacion/ok',
        component: FinanciacionOK,
    },
    {
        name: 'financiacion-no-ok',
        path: '/financiacion/no-ok',
        component: FinanciacionNoOk,
    },
    {
        name: 'financiacion-no-ok',
        path: '/financiacion/error-datos',
        component: FinanciacionErrorDatos,
    },
    {
        name: 'financiacion-rechazada',
        path: '/financiacion/rechazada',
        component: FinanciacionRechazada,
    },
    {
        name: 'financiacion-fuera-de-plazo',
        path: '/financiacion/fuera-de-plazo',
        component: FinanciacionFueraDePlazo,
    },
    {
        name: 'financiacion-step1',
        path: '/financiacion',
        component: FinanciacionStep1,
    },
];

export default financiacionRoutes;
