import { Box, Button, ButtonText, Header, P } from '@declarando/design_system';
import imageStep from '../assets/documentos-Step2Initial.svg';
import * as React from 'react';
import { useContext } from 'react';
import { Step2Context } from './Step2Context';
import { publishImportarDocumentosBankflipEvent } from '../../../services/events';
import { OnboardingContext } from '../../Onboarding';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';

export const Step2Initial = () => {
    const { importarDocumentacionAction } = useContext(Step2Context);
    const { retrocederPaso } = useContext(OnboardingContext);

    const importarDocumentacionClick = () => {
        importarDocumentacionAction();
        publishImportarDocumentosBankflipEvent();
    };

    const retroceder = () => {
        TrackingEvents.clickElement({ event_label: 'Vuelve atrás desde Bankflip' });
        retrocederPaso();
    };

    return (
        <>
            <Box px={'sm'} mx={'auto'} py={'xxl'} maxWidth={800}>
                <Box alignCenter>
                    <Box mb="sm">
                        <img src={imageStep} alt={'Documentación'} />
                    </Box>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={'center'}
                    alignContent={'center'}
                    gap={'xs'}
                    mt={'lg'}
                >
                    <Box alignCenter>
                        <Header as={'h2'} textAlign="center">
                            Importa tu documentación
                        </Header>
                        <P>
                            Estos documentos se utilizarán para configurar algunos parámetros importantes en tu perfil
                        </P>
                    </Box>
                    <Box alignCenter marginTop={'lg'}>
                        <Button
                            label="Importar documentación"
                            onClick={importarDocumentacionClick}
                            size="L"
                            style={{ borderRadius: '40px' }}
                        />
                    </Box>
                    <Box bg={'white'} p={'sm'} mx={'auto'} display={'flex'} justifyContent={'center'}>
                        <ButtonText
                            icon={'arrow-left'}
                            onClick={retroceder}
                            actionLabel={'Todavía no tengo mi certificado digital'}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};
