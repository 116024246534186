import { Modal } from 'shared/UI/components/Modal/Modal';
import ImageSVG from './nuevo-inreso-con-factura.svg';
import { Box, Header, Link, NewDateSelector, Notification, P } from '@declarando/design_system';
import { useState } from 'react';
import { ExpandableContent } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/shared/components/ExpandableContent';
import { useHistory } from 'react-router-dom';

const ROUTES = {
    NEW_INVOICE: '/ingreso/factura',
    LEGACY_INVOICE: '/ingreso/con-factura',
} as const;

const THRESHOLD_YEAR = 2025;

const commonNotificationURL =
    'https://ayuda.declarando.es/es/articles/10259888-como-facturar-en-declarando-a-partir-de-2025';

const NOTIFICATION_CONTENT = {
    after2025: {
        title: 'De 2025 en adelante: avanzamos contigo 🚀',
        message: 'Disfruta de un proceso mejorado, listo para la factura electrónica.',
        link: {
            label: 'Saber más',
            url: commonNotificationURL,
        },
    },
    before2025: {
        title: 'Hasta final de 2024: mantendrás tu proceso habitual 🙏',
        message: 'Te acompañamos a cerrar el año con tranquilidad y sin cambios.',
        link: {
            label: 'Saber más',
            url: commonNotificationURL,
        },
    },
} as const;

export type DatePickerModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export type NotificationVariant = 'after2025' | 'before2025';

export type NotificationInfoProps = {
    pickedDate: Date | null;
};

export const useInvoiceDateNavigation = (onClose: () => void) => {
    const history = useHistory();
    const [pickedDate, setPickedDate] = useState<Date | null>(null);

    const handleSubmit = () => {
        if (!pickedDate) return;

        const isAfterThreshold = pickedDate.getFullYear() >= THRESHOLD_YEAR;
        const url = isAfterThreshold ? ROUTES.NEW_INVOICE : ROUTES.LEGACY_INVOICE;

        history.push(url, { pickedDate });
        setPickedDate(null);
        onClose();
    };

    const handleClose = () => {
        setPickedDate(null);
        onClose();
    };

    return {
        pickedDate,
        setPickedDate,
        handleSubmit,
        handleClose,
    };
};

const NotificationInfo = ({ pickedDate }: NotificationInfoProps) => {
    const getNotificationContent = () => {
        const variant: NotificationVariant =
            pickedDate && pickedDate.getFullYear() >= THRESHOLD_YEAR ? 'after2025' : 'before2025';
        return NOTIFICATION_CONTENT[variant];
    };

    const content = getNotificationContent();

    return (
        <Notification
            title={content.title}
            message={
                <>
                    <Box mb="xxs">{content.message}</Box>
                    <Link href={content.link.url} target="_blank">
                        {content.link.label}
                    </Link>
                </>
            }
            full
            tone="info"
        />
    );
};

export const ModalPickDateNavigateDifferentForms = ({ isOpen, onClose }: DatePickerModalProps) => {
    const { pickedDate, setPickedDate, handleSubmit, handleClose } = useInvoiceDateNavigation(onClose);

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            title=""
            isActionDisabled={!pickedDate}
            variant="small"
            shouldShowGradient
            isHeaderHidden
            actionLabel="Continuar"
            onAction={handleSubmit}
        >
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                <Box marginBottom="xs" width="120px" height="120px">
                    <img
                        src={ImageSVG}
                        alt="detalle novedad de ingreso"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
                <Header as="h2" marginBottom="xxs">
                    Elige la fecha de tu ingreso con factura
                </Header>
                <P textAlign="center" marginBottom="md">
                    Lanzamos una nueva versión de Ingresos con factura para adelantarnos a la normativa de factura
                    electrónica y que el cambio sea fácil para ti.
                </P>
                <P textAlign="center" marginBottom="md">
                    Si la fecha es de 2025 en adelante, usarás el nuevo proceso. Si es de 2024, seguirás con el formato
                    que ya conoces.
                </P>
                <Box maxWidth="300px" width="100%">
                    <form>
                        <NewDateSelector
                            id="input-date-invoice"
                            placeholder="Seleccionar fecha..."
                            label="Fecha de emisión"
                            required
                            onChange={(date) => setPickedDate(date)}
                            value={pickedDate}
                        />
                    </form>
                </Box>
                <Box width="100%" paddingX="md">
                    <ExpandableContent shouldExpand={!!pickedDate}>
                        <Box marginTop="md">
                            <NotificationInfo pickedDate={pickedDate} />
                        </Box>
                    </ExpandableContent>
                </Box>
            </Box>
        </Modal>
    );
};
