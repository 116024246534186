import { Client } from '../domain';
import { ClientDTO } from './ClientDTO';
import { LegacyClientDTO } from './LegacyClientDTO';

export function transformClientDTOToClient(clientDTO: ClientDTO): Client {
    return {
        ...clientDTO,
        country: {
            id: clientDTO.country.id,
            code: clientDTO.country.code,
            name: clientDTO.country.name,
            isEuropean: clientDTO.country.isEuropean,
        },
        hasEUVatNumber: clientDTO.hasEUVatNumber ?? undefined,
        email: clientDTO.email || null,
        phone: clientDTO.phone || null,
        state: clientDTO.state || null,
        city: clientDTO.city || null,
        postalCode: clientDTO.postalCode || null,
        fiscalId: clientDTO.fiscalId || null,
        address: clientDTO.address || null,
    };
}

export function transformClientToClientDTO(client: Client): ClientDTO {
    return {
        ...client,
        country: {
            id: client.country.id,
            code: client.country.code,
            name: client.country.name,
            isEuropean: client.country.isEuropean,
        },
        email: client.email || null,
        phone: client.phone || null,
        state: client.state || null,
        city: client.city || null,
        postalCode: client.postalCode || null,
        fiscalId: client.fiscalId || null,
        address: client.address || null,
    };
}

export function transformLegacyClientDTOToClient(dto: LegacyClientDTO): Client {
    return {
        hasEUVatNumber: dto.nif_iva === '1' ? true : dto.nif_iva === '0' ? false : undefined,
        id: dto.id,
        clientType: mapLegacyClientType(dto.type),
        fiscalId: dto.cif || null,
        name: dto.nombre || dto.name,
        country: {
            id: dto.country_id,
            code: '',
            name: '',
            isEuropean: false,
        },
        address: dto.direccion || null,
        state: dto.provincia_id
            ? {
                  id: `${dto.provincia_id ?? ''}`,
                  name: '',
              }
            : null,
        city: dto.poblacion_id
            ? {
                  id: `${dto.poblacion_id ?? ''}`,
                  name: '',
              }
            : null,
        postalCode: dto.cp || null,
        phone: dto.phone
            ? {
                  prefix: `${dto.prefijo ?? ''}`,
                  number: dto.phone ?? '',
              }
            : null,
        email: dto.email,
    };
}

export function mapLegacyClientType(type: string): Client['clientType'] {
    switch (type.toLowerCase()) {
        case 'empresa':
            return 'COMPANY';
        case 'autónomo':
            return 'FREELANCE';
        case 'particular':
            return 'INDIVIDUAL';
        default:
            throw new Error(`Unknown client type: ${type}`);
    }
}
