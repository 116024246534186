// const edge = require('./assets/img/logo-edge.png');
// const safari = require('./assets/img/logo-safari.png');
// const firefox = require('./assets/img/logo-firefox.png');
// const chrome = require('./assets/img/logo-chrome.png');

// class OldBrowserNotification {
//     static element = `
//          <div style="min-height: 100vh;" class="container h-100vh d-flex justify-content-center align-items-center">
//             <div class="row w-100">
//                 <div class="col-xs-12 card text-center m-0 p-4">
//                     <h2 class="mt-0 font-weight-600 mb-5">¡Vaya! Parece que tu navegador está obsoleto</h2>
//                     <p class='font-weight-500 d-block mb-6'>Declarando es una aplicación puntera que usa lo último en tecnología web para ofrecerte el servicio más rápido y seguro. Si quieres seguir usando Declarando considera descargar un navegador más moderno y seguro.</p>
//                     <h4 class='font-weight-500 d-block mb-2 mt-0'>Navegadores que recomendamos</h4>
//                     <div class="row">
//                         <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
//                             <div class="row">
//                                 <div class="col-xs-12 col-sm-6 pb-2">
//                                     <a
//                                         href="https://www.google.com/chrome/"
//                                         target="_blank"
//                                         rel=”noreferrer noopener”
//                                         class="browser-cta raised d-block p-p5 d-flex align-items-center justify-content-center">
//                                         <img height="30px" width="30px" src=${chrome} alt="">
//                                         <span class="font-weight-500 font-size-16 text-navy ml-p5">Google Chrome</span>
//                                     </a>
//                                 </div>
//                                 <div class="col-xs-12 col-sm-6 pb-2">
//                                     <a
//                                         href="https://www.mozilla.org/es-ES/firefox/new/"
//                                         target="_blank"
//                                         rel=”noreferrer noopener”
//                                         class="browser-cta raised d-block p-p5 d-flex align-items-center justify-content-center">
//                                         <img height="30px" width="30px" src=${firefox} alt="">
//                                         <span class="font-weight-500 font-size-16 text-navy ml-p5">Firefox</span>
//                                     </a>
//                                 </div>
//                                 <div class="col-xs-12 col-sm-6 pb-2">
//                                     <a
//                                         href="https://www.apple.com/es/safari/"
//                                         target="_blank"
//                                         rel=”noreferrer noopener”
//                                         class="browser-cta raised d-block p-p5 d-flex align-items-center justify-content-center">
//                                         <img height="30px" width="30px" src=${safari} alt="">
//                                         <span class="font-weight-500 font-size-16 text-navy ml-p5">Apple Safari</span>
//                                     </a>
//                                 </div>
//                                 <div class="col-xs-12 col-sm-6 pb-2">
//                                     <a
//                                         href="https://www.microsoft.com/es-es/edge"
//                                         target="_blank"
//                                         rel=”noreferrer noopener”
//                                         class="browser-cta raised d-block p-p5 d-flex align-items-center justify-content-center">
//                                         <img height="30px" width="30px" src=${edge} alt="">
//                                         <span class="font-weight-500 font-size-16 text-navy ml-p5">Microsoft Edge</span>
//                                     </a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//       `;
// }

// export default OldBrowserNotification;
export default null;
