import { Schema } from 'shared/validation/Schema';
import { invoicePaymentMethodService } from '../../IngresoConFactura/services/IngresoConFacturaPaymentOptionService';

const errorBankTransfer = 'Debes introducir un número de cuenta válido';

export const paymentOptionSchema = Schema.object().shape({
    cash: Schema.object({
        isChecked: Schema.boolean().required(),
    }),
    bankTransfer: Schema.object({
        isChecked: Schema.boolean().required(),
        details: Schema.string().when('isChecked', {
            is: (isChecked: boolean) => isChecked,
            then: (schema) =>
                schema
                    .test('is-spanish-IBAN', errorBankTransfer, (value) => {
                        if (!value) return true;
                        return invoicePaymentMethodService.isBankTransferPaymentMethodValid(value);
                    })
                    .required(errorBankTransfer),
            otherwise: (schema) => schema.nullable(),
        }),
    }),
    other: Schema.object({
        isChecked: Schema.boolean().required(),
        details: Schema.string().when('isChecked', {
            is: (isChecked: boolean) => isChecked,
            then: (schema) => schema.required('Debes introducir una descripción'),
            otherwise: (schema) => schema.nullable(),
        }),
    }),
});
