import { InputModel } from '../../domain/types/form/InputModel';
import { Errores, ValidationService } from '../ValidationService';

export type InputValue = boolean | Date | string | number | { number: any; prefix: any };

export enum AccionesFormulario {
    init = 'init',
    update = 'update',
    updateProp = 'updateProp',
    updatePhone = 'updatePhone',
    validate = 'validate',
    validatePhone = 'validatePhone',
    error = 'error',
    cleanForm = 'cleanForm',
}

type AccionFormularioDispatcher<T> = ({
    action,
    inputKey,
    value,
    prop,
    phonePart,
    validate,
}: {
    phonePart?: 'number' | 'prefix';
    value?: any;
    inputKey?: keyof T;
    prop?: keyof InputModel;
    action: AccionesFormulario;
    validate?: boolean;
}) => any;

export function useFormulario<T>(form: T, dispatch: AccionFormularioDispatcher<T>) {
    const updateInput = (inputKey: keyof T, value: any, validate: boolean = true) => {
        dispatch({ action: AccionesFormulario.update, inputKey, value, validate });
    };

    const updateInputProp = (inputKey: keyof T, prop: keyof InputModel, value: any) => {
        dispatch({ action: AccionesFormulario.updateProp, inputKey, prop, value });
    };

    const updatePhoneInput = (
        inputKey: keyof T,
        phonePart: 'number' | 'prefix',
        value: any,
        validate: boolean = true,
    ) => {
        dispatch({ action: AccionesFormulario.updatePhone, inputKey, phonePart, value, validate });
    };

    const setError = (inputKey: keyof T, value: string) => {
        dispatch({ action: AccionesFormulario.error, inputKey, value });
    };

    const iniInput = (inputKey: keyof T, value: InputValue) => {
        dispatch({ action: AccionesFormulario.init, inputKey, value });
    };

    const cleanForm = () => {
        dispatch({ action: AccionesFormulario.cleanForm });
    };

    return {
        form,
        updateInput,
        updatePhoneInput,
        updateInputProp,
        iniInput,
        cleanForm,
        setError,
    };
}

export const defaultInputSettings = (required: boolean = true) => ({
    value: null,
    error: Errores.noErrors,
    validate: required ? ValidationService.requiredValidation : () => Errores.noErrors,
    required,
    visible: true,
    invalidValues: [],
});

export function getInputProps<T>(inputKey: keyof T, form: { [Prop in keyof T]: InputModel }) {
    return {
        label: form[inputKey].label,
        error: Boolean(form[inputKey].error),
        message: form[inputKey].error,
        value: form[inputKey].value,
        required: form[inputKey].required,
    };
}
