import { IAEType } from 'shared/domain/fiscalStatus/iae/IAEType';

export type InformCNAERulesClientInterface = {
    shouldInformCNAE: (args: { iaes: IAEType[] }) => boolean;
};

export const informCNAERulesClient: InformCNAERulesClientInterface = {
    shouldInformCNAE: ({ iaes }) => {
        const hasRegimenGeneral = iaes.some((iae) => iae.isRegimenGeneral);

        if (!hasRegimenGeneral) {
            return false;
        }

        return iaes.some((iae) => !iae.cnaeId);
    },
};
