import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { IssueDateAutomaticNumberingCreationMode } from './IssueDateAutomaticNumberingCreationMode';
import { IssueDateAutomaticNumberingEditionMode } from './IssueDateAutomaticNumberingEditionMode';

export const IssueDateAutomaticNumbering = () => {
    const { salesDocumentMode } = useSalesDocumentFormContext();

    return salesDocumentMode === 'edit' ? (
        <IssueDateAutomaticNumberingEditionMode />
    ) : (
        <IssueDateAutomaticNumberingCreationMode />
    );
};
