import { Env } from 'config/Env';
import type { PresentationOfRentaDTO } from 'features/CalculadoraRenta/PresentationOfRenta/adapters/PresentationOfRentaDTO';

import V2Repository from 'shared/infra/repositories/V2Repository';
import DateWrapper from 'shared/infra/utils/Date';
import { UrlParser } from 'shared/infra/utils/UrlParser';

export type IsManagedByUserArgs = {
    isManagedByUser: boolean;
};

const yearRent = DateWrapper.getFullYear(new Date()) - 1;

export class PresentationOfRentaRepository extends V2Repository {
    async getStatus(): Promise<PresentationOfRentaDTO> {
        const currentStep = UrlParser.getQueryParams().currentStep as any;
        if (!Env.isProd() && UrlParser.getQueryParams().currentStep) {
            return {
                currentStep,
                classification: (UrlParser.getQueryParams().classification as any) ?? 'EASY',
                classificationLink: 'https://google.es',
                preparationLink: 'https://google.es',
                fiscalInformationLink: UrlParser.getQueryParams().fiscalInformationLink ?? null,
                conditionsLink: UrlParser.getQueryParams().conditionsLink ?? null,
                isIncluded: !!UrlParser.getQueryParams().isIncluded,
            };
        }

        return this.get('/tax-return/tax-return-presentation/' + yearRent);
    }

    async goToNextStep(): Promise<any> {
        if (Env.isLocal('adrianM')) {
            return {
                success: true,
            };
        }
        return this.put('/tax-return/tax-return-presentation/' + yearRent + '/move-next-step');
    }

    async isManagedByUser(args: IsManagedByUserArgs): Promise<void> {
        return this.put(
            `/tax-return/tax-return-presentation/${yearRent}/managed-by-user`,
            JSON.stringify({ isManagedByUser: args.isManagedByUser }),
        );
    }

    async uploadProof(formData: FormData, year: number): Promise<void> {
        return this.post(`/tax-return/tax-return-presentation/${year}/submit`, formData);
    }
}
