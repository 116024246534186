import { asyncDebounce } from 'shared/utils/debounce';
import { createIngresoConFacturaRulesApiRepository } from '../infra/IngresoConFacturaRulesRepository';
import { createIngresoConFacturaRepositoryRulesService } from '../services/IngresoConFacturaRulesService';
import { SalesDocumentFormMode } from '../../shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

class InvoiceDocumentNumberUnique {
    private isNumberUniqueValid: boolean;

    constructor() {
        this.isNumberUniqueValid = true;
    }

    resetValidation = (): void => {
        this.isNumberUniqueValid = true;
    };

    isInputNumberUniqueValid = (): boolean => this.isNumberUniqueValid;

    validateInputNumberUnique = asyncDebounce(
        async ({
            triggerValidation,
            value,
            defaultValue,
            invoiceMode = 'create',
        }: {
            triggerValidation: () => void;
            value: string;
            defaultValue?: string;
            invoiceMode: SalesDocumentFormMode;
        }) => {
            if (value === '') return;

            if (invoiceMode === 'edit' && value === defaultValue) {
                this.isNumberUniqueValid = true;
                triggerValidation();
                return;
            }

            const service = createIngresoConFacturaRepositoryRulesService(createIngresoConFacturaRulesApiRepository());

            const { isUnique } = await service.checkInvoiceNumberIsUnique(value);

            this.isNumberUniqueValid = isUnique;

            triggerValidation();
        },
        300,
    );
}

export const invoiceDocumentNumberUnique = new InvoiceDocumentNumberUnique();
