import IvaTypes, { IvaNumbersOnly, TIvaTypeValues } from './IvaTypes';
import RecargoEquivalenciaPercents from './RecargoEquivalenciaPercents';
import { Bases } from './InvoiceData';

class Operations {
    static get initialBases(): Bases {
        return {
            base21: 0.0,
            base10: 0.0,
            base7_5: 0.0,
            base5: 0.0,
            base2: 0.0,
            base4: 0.0,
            base0: 0.0,
        };
    }

    static computeValuesFromBases = ({
        bases,
        irpf,
        hasSurcharge,
    }: {
        bases: Bases;
        irpf: number;
        hasSurcharge: any;
    }) => {
        const cuotaIva = this.computeIva(bases);
        const cuotaIrpf = hasSurcharge ? 0 : this.computeIrpf({ bases, irpf });
        const cuotaRecargo = hasSurcharge ? this.computeSurcharge(bases) : 0;
        const total = Operations.computeTotalFromBases(bases, cuotaIva, cuotaIrpf, cuotaRecargo);
        return {
            cuotaIva,
            cuotaIrpf,
            recargo: cuotaRecargo,
            totalAmount: total.toFixed(2),
        };
    };
    static calculaPorcentajeInverso = (total: number, percent: number): number => {
        return parseFloat(total as any) / (1 + percent) || 0;
    };

    static computeTotalFromBases = (
        bases: Bases,
        cuotaIva: number,
        cuotaIrpf: number,
        cuotaRecargo: number,
    ): number => {
        return (
            parseFloat(bases.base21 as any) +
            parseFloat(bases.base10 as any) +
            parseFloat(bases.base4 as any) +
            parseFloat((bases.base2 ?? 0) as any) +
            parseFloat((bases.base5 ?? 0) as any) +
            parseFloat((bases.base7_5 ?? 0) as any) +
            parseFloat(bases.base0 as any) +
            parseFloat(cuotaIva as any) -
            parseFloat(cuotaIrpf as any) +
            parseFloat(cuotaRecargo as any)
        );
    };

    static getTotalFromParts = (
        baseGeneral: number,
        baseNormal: number,
        baseReducida: number,
        baseSin: number,
        cuotaIva: number,
        cuotaIrpf: number,
        cuotaRecargo: number,
    ): number => {
        return (
            parseFloat(baseGeneral as any) +
            parseFloat(baseNormal as any) +
            parseFloat(baseReducida as any) +
            parseFloat(baseSin as any) +
            parseFloat(cuotaIva as any) -
            parseFloat(cuotaIrpf as any) +
            parseFloat(cuotaRecargo as any)
        );
    };

    static getTotalFromBases = (bases: Bases, cuotaIva: number, cuotaIrpf: number, cuotaRecargo: number): number => {
        return (
            parseFloat(bases.base21 as any) +
            parseFloat(bases.base10 as any) +
            parseFloat(bases.base4 as any) +
            parseFloat((bases.base2 ?? 0) as any) +
            parseFloat((bases.base5 ?? 0) as any) +
            parseFloat((bases.base7_5 ?? 0) as any) +
            parseFloat(bases.base0 as any) +
            parseFloat(cuotaIva as any) -
            parseFloat(cuotaIrpf as any) +
            parseFloat(cuotaRecargo as any)
        );
    };

    static getIvaTypeFromBases = (bases: Bases): TIvaTypeValues | null => {
        let iva = null;
        let found = 0;

        if (parseFloat(bases.base21 as any)) {
            iva = IvaTypes.GENERAL;
            found++;
        }
        if (parseFloat(bases.base10 as any)) {
            iva = IvaTypes.NORMAL;
            found++;
        }
        if (parseFloat(bases.base4 as any)) {
            iva = IvaTypes.REDUCIDO;
            found++;
        }
        if (parseFloat(bases.base7_5 as any)) {
            iva = IvaTypes.TYPE_7_5;
            found++;
        }
        if (parseFloat(bases.base5 as any)) {
            iva = IvaTypes.LUZ;
            found++;
        }

        if (parseFloat(bases.base2 as any)) {
            iva = IvaTypes.TYPE_2;
            found++;
        }
        if (parseFloat(bases.base0 as any)) {
            iva = IvaTypes.SINIVA;
            found++;
        }

        if (found > 1) {
            iva = IvaTypes.ALL;
        }
        return iva;
    };

    static computeBaseFromTotal = ({
        selectedIva,
        irpf: _irpf,
        totalAmount,
        hasSurcharge,
    }: {
        selectedIva: IvaNumbersOnly;
        irpf: any;
        totalAmount: number | string;
        hasSurcharge?: boolean;
    }) => {
        let bases = Operations.initialBases;

        const irpf = _irpf || 0;
        const total = +totalAmount;
        const ivaType = selectedIva;
        const ivaCoeficient = IvaTypes.getCoeficient(ivaType);
        const irpfCoeficient = parseInt(irpf) / 100;
        const surchargeCoeficient = hasSurcharge ? RecargoEquivalenciaPercents.getCoeficient(ivaType) : 0;

        const baseAmount = +Operations.calculaPorcentajeInverso(
            total,
            ivaCoeficient - irpfCoeficient + surchargeCoeficient,
        );

        switch (ivaType) {
            case IvaTypes.GENERAL:
                bases = { ...bases, base21: baseAmount };
                break;
            case IvaTypes.NORMAL:
                bases = { ...bases, base10: baseAmount };
                break;
            case IvaTypes.REDUCIDO:
                bases = { ...bases, base4: baseAmount };
                break;
            case IvaTypes.TYPE_7_5:
                bases = { ...bases, base7_5: baseAmount };
                break;
            case IvaTypes.LUZ:
                bases = { ...bases, base5: baseAmount };
                break;
            case IvaTypes.TYPE_2:
                bases = { ...bases, base2: baseAmount };
                break;
            case IvaTypes.SINIVA:
                bases = { ...bases, base0: baseAmount };
                break;
            default:
            // Do nothing, no IVA selected
        }
        const cuotaIva = this.computeIva(bases);
        const cuotaIrpf = hasSurcharge ? 0 : this.computeIrpf({ bases, irpf });
        const recargo = hasSurcharge ? this.computeSurcharge(bases) : 0;

        return {
            bases,
            cuotaIva,
            cuotaIrpf,
            recargo,
        };
    };

    static computeIrpf = ({ bases, irpf: _irpf }: { bases: Bases; irpf: number }) => {
        const safeParseFloat = (value: any): number => {
            if (value === null || value === undefined) return 0;
            const parsed = parseFloat(value);
            return isNaN(parsed) ? 0 : parsed;
        };

        const irpf = safeParseFloat(_irpf);
        const irpfCoeficient = irpf / 100;

        const base21 = safeParseFloat(bases.base21);
        const base10 = safeParseFloat(bases.base10);
        const base7_5 = safeParseFloat(bases.base7_5);
        const base5 = safeParseFloat(bases.base5);
        const base4 = safeParseFloat(bases.base4);
        const base2 = safeParseFloat(bases.base2);
        const base0 = safeParseFloat(bases.base0);

        const totalBases = base21 + base10 + base7_5 + base5 + base4 + base2 + base0;

        return totalBases * irpfCoeficient;
    };

    static computeSurcharge = (bases: Bases) => {
        const total =
            +(+bases.base21 * RecargoEquivalenciaPercents.getCoeficient(IvaTypes.GENERAL)) +
            +(+bases.base10 * RecargoEquivalenciaPercents.getCoeficient(IvaTypes.NORMAL)) +
            +(+bases.base4 * RecargoEquivalenciaPercents.getCoeficient(IvaTypes.REDUCIDO)) +
            +(+(bases.base5 ?? 0) * RecargoEquivalenciaPercents.getCoeficient(IvaTypes.LUZ)) +
            +(+(bases.base7_5 ?? 0) * RecargoEquivalenciaPercents.getCoeficient(IvaTypes.TYPE_7_5)) +
            +(+(bases.base2 ?? 0) * RecargoEquivalenciaPercents.getCoeficient(IvaTypes.TYPE_2));
        return total;
    };

    static computeIva = (bases: Bases) => {
        const base21 = +bases.base21;
        const base10 = +bases.base10;
        const base4 = +bases.base4;
        const base5 = +(bases.base5 ?? 0);
        const base2 = +(bases.base2 ?? 0);
        const base7_5 = +(bases.base7_5 ?? 0);

        return (
            +(base21 * IvaTypes.getCoeficient(IvaTypes.GENERAL)) +
            +(base10 * IvaTypes.getCoeficient(IvaTypes.NORMAL)) +
            +(base5 * IvaTypes.getCoeficient(IvaTypes.LUZ)) +
            +(base4 * IvaTypes.getCoeficient(IvaTypes.REDUCIDO)) +
            +(base2 * IvaTypes.getCoeficient(IvaTypes.TYPE_2)) +
            +(base7_5 * IvaTypes.getCoeficient(IvaTypes.TYPE_7_5))
        );
    };

    static setBases = (ivaType: number, baseAmount: number) => {
        let bases = this.initialBases;
        switch (ivaType) {
            case IvaTypes.GENERAL:
                bases = { ...bases, base21: baseAmount };
                break;
            case IvaTypes.NORMAL:
                bases = { ...bases, base10: baseAmount };
                break;
            case IvaTypes.REDUCIDO:
                bases = { ...bases, base4: baseAmount };
                break;
            case IvaTypes.SINIVA:
                bases = { ...bases, base0: baseAmount };
                break;
            case IvaTypes.LUZ:
                bases = { ...bases, base5: baseAmount };
                break;
            case IvaTypes.TYPE_7_5:
                bases = { ...bases, base7_5: baseAmount };
                break;
            case IvaTypes.TYPE_2:
                bases = { ...bases, base2: baseAmount };
                break;

            default:
                bases = {
                    ...bases,
                    base21: 0,
                    base10: 0,
                    base7_5: 0,
                    base5: 0,
                    base4: 0,
                    base2: 0,
                    base0: 0,
                };
        }
        return bases;
    };

    static sumBases = (bases: Bases): number => {
        return (
            +bases.base21 +
            +bases.base10 +
            +bases.base0 +
            +bases.base4 +
            +(bases.base5 ?? 0) +
            +(bases.base7_5 ?? 0) +
            +(bases.base2 ?? 0)
        );
    };
}

export default Operations;
