enum GastosSinFacturaTypes {
    CUOTA_SEGURIDAD_SOCIAL = '4',
    TIQUETS = '1',
    SEGUROS = '2',
    ALQUILER_VIVIENDA_HABITUAL = '7',
    AMORTIZACION = '3',
    AJUSTES_CONTABLES = '8',
    COMISIONES_E_INTERESES_BANCARIOS = '5',
    OTROS = '6',
    IMPUESTOS_Y_TASAS = '9',
    COMUNIDAD_DE_PROPIETARIOS = '10',
    NOMINAS_EMPLEADOS = '11',
    CUOTA_COLEGIO_PROFESIONAL = '12',
    ARANCELES_ADUANA = '13',
}

export enum SubTiposGastosSinFacturaTypes {
    SALUD = '1',
    BAJA_LABORAL = '2',
    INVALIDEZ = '4',
    VIDA = '5',
    ACCIDENTES = '6',
    RESPONSABILIDAD_CIVIL = '7',
    VEHICULO = '8',
    OFICINA = '9',
    OTROS = '10',
    ORDENADOR = '21',
    TELEFONO_MOVIL = '22',
    TRANSPORTE_PUBLICO = '11',
    PEAJE_AUTOPISTA = '13',
    PARKING = '14',
    ZONA_AZUL = '23',
    MATRICULACION = '17',
    IBI = '15',
    TASAS_Y_BASURAS = '16',
}

export default GastosSinFacturaTypes;
