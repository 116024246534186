import { useHistory, useParams } from 'react-router-dom';
import { SalesDocumentPreviewView } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentPreviewView/SalesDocumentPreviewView';
import { useGetPDFSalesDocument } from 'features/Ingresos/SalesDocument/shared/UI/hooks/useGetPDFSalesDocument';
import { Quote } from '../../domain/Quote';
import { useGetQuote } from '../hooks/useGetQuote';

export const QuotePreviewView = () => {
    const { quoteUUID } = useParams<{ quoteUUID: string }>();
    const history = useHistory();

    const { quoteData, isLoading } = useGetQuote({ quoteUUID });
    const { pdfUrl } = useGetPDFSalesDocument({ salesDocumentUUID: quoteUUID, salesDocumentType: 'quote' });

    if (!quoteUUID) {
        history.push('/ingresos/presupuestos');
        return null;
    }

    return (
        <SalesDocumentPreviewView
            salesDocumentType="quote"
            salesDocumentPDFUrl={pdfUrl as string}
            salesDocumentData={quoteData as Quote}
            isLoading={isLoading}
        />
    );
};
