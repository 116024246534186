import { useFetch } from '@declarando/query-hooks';
import { ModelosPorPresentarService } from './ModelosPorPresentarService';

export const useFetchListadoModelosPendientes = (
    { shouldTriggerCall }: { shouldTriggerCall?: boolean } = { shouldTriggerCall: true },
) => {
    const { data, refetch, isLoading, isFetching } = useFetch({
        queryKey: ['getModelosPorPresentar'],
        fetchFn: () => new ModelosPorPresentarService().getModelosPorPresentar(),
        config: {
            staleTime: 30000,
            refetchOnWindowFocus: false,
            select: (data) => data?.data?.resources,
            enabled: shouldTriggerCall,
        },
    });

    const modelos = data ?? [];

    return {
        modelos,
        totalModelosPendientes: modelos.length,
        isLoading: isLoading || isFetching,
        refetch,
    };
};
