export function validateSpanishIban(iban: string): boolean {
    // Ensure the IBAN is from Spain (starts with 'ES') and is 24 characters long
    if (!iban.match(/^ES\d{22}$/)) return false;

    // Move the four initial characters to the end of the string
    const rearrangedIban = iban.slice(4) + iban.slice(0, 4);

    // Replace each letter in the string with two digits, thereby expanding the string into an integer
    const numericIban = rearrangedIban.replace(/[A-Z]/g, (letter) => String(letter.charCodeAt(0) - 55));

    // Compute the remainder of that number on division by 97
    const remainder = BigInt(numericIban) % BigInt(97);

    // If the remainder is 1, the check digit test is passed and the IBAN might be valid
    return remainder === BigInt(1);
}
