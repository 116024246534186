import IvaTypes from 'shared/domain/contabilidad/IvaTypes';

export default class RecargoEquivalenciaPercents {
    static GENERAL = 5.2;
    static NORMAL = 1.4;
    static REDUCIDO = 0.5;
    static SINIVA = 0;
    static TYPE_7_5 = 1;
    static TYPE_5 = 0.6;
    static TYPE_2 = 0.26;

    static getPercent(ivaType) {
        const matrix = {
            [IvaTypes.GENERAL]: this.GENERAL,
            [IvaTypes.NORMAL]: this.NORMAL,
            [IvaTypes.REDUCIDO]: this.REDUCIDO,
            [IvaTypes.SINIVA]: this.SINIVA,
            [IvaTypes.TYPE_7_5]: this.TYPE_7_5,
            [IvaTypes.TYPE_5]: this.TYPE_5,
            [IvaTypes.TYPE_2]: this.TYPE_2,
        };

        return matrix[ivaType] || 0;
    }

    static getCoeficient(ivaType) {
        return this.getPercent(parseFloat(ivaType)) / 100;
    }
}
