import React, { useReducer } from 'react';

const ADVICE_ID = {
    ADD_CUOTA: 'ADD_CUOTA',
    AJUSTE_CONTABILIDAD_390: 'AJUSTE_CONTABILIDAD_390',
};

export const useAdvicesListStore = () => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            return { ...state, ...action };
        },
        {
            advices: [],
            selected: null,
            selectedAdviceModelColor: 'white',
        },
    );

    const reset = () => {
        setAdvices([]);
        unselect();
    };

    const setSelected = (selected) =>
        dispatch({
            selected,
            selectedAdviceModelColor: getColor(selected ? selected.type : null),
        });

    const setAdvices = (advices) => dispatch({ advices });

    const select = (_select) => {
        setSelected(_select);
    };

    const unselect = () => {
        setSelected(null);
    };

    const addAdvice = (stickyData) => {
        if (!stickyData || +stickyData.sticky === 0 || state.advices.find((item) => item.id === stickyData.id)) {
            return;
        }
        const [text, description, ...notes] = stickyData.advice;
        const { message, ...data } = stickyData;
        const stickData = {
            text,
            description,
            notes,
            ...data,
        };
        state.advices.push(stickData);
    };

    const removeAdvice = (value, key = 'id') => {
        const advices = state.advices.filter((item) => item[key] !== value);
        setAdvices(advices);
        unselect();
    };

    const addAjusteContabilidad = (action) => {
        const data = {
            id: ADVICE_ID.AJUSTE_CONTABILIDAD_390,
            type: 'info',
            advice: [
                'Se ha realizado un ajuste contable para presentar tus impuestos',
                'Tu contabilidad no coincidía con tus impuestos presentados y se ha añadido un gasto para solucionarlo',
            ],
            iconAction: 'folder-plus',
            actionLabel: <>Ver gasto</>,
            action,
        };
        addAdvice(data);
    };

    const removeAjusteContabilidad = () => {
        removeAdvice(ADVICE_ID.AJUSTE_CONTABILIDAD_390);
    };

    const getColor = (type) => {
        switch (type) {
            case 'info':
                return 'primary.Light10';
            case 'warning':
                return 'yellow.100';
            case 'error':
                return 'accent.Light10';
            default:
                return 'white';
        }
    };

    return {
        state,
        reset,
        removeAdvice,
        addAjusteContabilidad,
        removeAjusteContabilidad,
        select,
        unselect,
    };
};
