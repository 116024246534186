import { createInvoicePDFRepository } from '../../IngresoConFactura/infra/InvoicePDFRepository';
import { createInvoicePDFService } from '../../IngresoConFactura/services/InvoicePDFService';
import { createQuotePDFRepository } from '../../Quote/infra/QuotePDFRepository';
import { createQuotePDFService } from '../../Quote/services/QuotePDFService';
import { SalesDocumentType } from '../UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { SalesDocumentPDFService } from './SalesDocumentPDFService';

export class SalesDocumentPDFServiceFactory {
    static create(documentType: SalesDocumentType): SalesDocumentPDFService {
        switch (documentType) {
            case 'invoice':
                return createInvoicePDFService(createInvoicePDFRepository());
            case 'quote':
                return createQuotePDFService(createQuotePDFRepository());
            default:
                throw new Error(`Unsupported document type: ${documentType}`);
        }
    }
}
