//@ts-nocheck
import { IngresoConFacturaPreviewView } from 'features/Ingresos/SalesDocument/IngresoConFactura/UI/IngresoConFacturaPreviewView/IngresoConFacturaPreviewView';
import { IngresoConFacturaCreationView } from 'features/Ingresos/SalesDocument/IngresoConFactura/UI/IngresoConFacturaCreationView/IngresoConFacturaCreationView';
import { IngresoConFacturaEditView } from 'features/Ingresos/SalesDocument/IngresoConFactura/UI/IngresoConFacturaEditView/IngresoConFacturaEditView';
import { Versiones } from 'features/kd/Versiones';
import React from 'react';
import { trackParams } from 'shared/infra/utils/user_events/trackParams';
import { FeatureFlags } from 'config/FeatureFlags';
import { QuoteCreationView } from 'features/Ingresos/SalesDocument/Quote/UI/QuoteCreationView/QuoteCreationView';
import { QuotePreviewView } from 'features/Ingresos/SalesDocument/Quote/UI/QuotePreviewView/QuotePreviewView';
import { QuoteTransformToInvoiceView } from 'features/Ingresos/SalesDocument/Quote/UI/QuoteTransformToInvoiceView/QuoteTransformToInvoiceView';
import { QuoteEditView } from 'features/Ingresos/SalesDocument/Quote/UI/QuoteEditView/QuoteEditView';
import { IngresoConFacturaDuplicationView } from 'features/Ingresos/SalesDocument/IngresoConFactura/UI/IngresoConFacturaDuplicationView/IngresoConFacturaDuplicationView';

const Impuestos = React.lazy(() => import('features/Impuestos/UI/_legacy/Impuestos'));
const InformeRentaLayoutContext = React.lazy(() => import('features/InformeRenta/UI/InformeRentaLayoutContext'));
const AgentsIndex = React.lazy(() => import('features/Agents/AgentsIndex'));
const ExpensesAssistant = React.lazy(() => import('features/Expenses/ExpensesAssistant'));
const ExpensesIndex = React.lazy(() => import('features/Expenses/ExpensesIndex'));
const GastosConFactura = React.lazy(() => import('features/Expenses/GastoConFactura'));
const GastosSinFactura = React.lazy(() => import('features/Expenses/GastoSinFactura'));
const IncomesIndex = React.lazy(() => import('features/Incomes/UI/IncomesIndex'));
const IngresosDeTransaccion = React.lazy(() => import('features/Incomes/UI/IngresoDeTransaccion'));
const IngresosConFactura = React.lazy(() => import('features/Incomes/UI/IngresosConFactura'));
const IngresosSinFactura = React.lazy(() => import('features/Incomes/UI/IngresosSinFactura'));
const IngresosSimplificado = React.lazy(() => import('features/Incomes/UI/IngresosSimplificado'));
const IngresosSubvencion = React.lazy(() => import('features/Incomes/UI/IngresosSubvencion'));

const Inicio = React.lazy(() => import('features/Inicio/UI/Inicio'));
const Rent = React.lazy(() => import('features/CalculadoraRenta/CalculadoraRenta'));
const InvitaAmigo = React.lazy(() => import('features/Invitaciones/Invita'));
const FacturasServicios = React.lazy(() => import('features/Profile/FacturasServicios'));
const Quotations = React.lazy(() => import('features/Quotations/Quotations'));
const ModifyRenting = React.lazy(() => import('features/UserConfig/ModifyRenting/ModifyRenting'));
const UnregisterRenting = React.lazy(() => import('features/UserConfig/ModifyRenting/UnregisterRenting'));
const UserConfig = React.lazy(() => import('features/UserConfig/UserConfig'));
const GastoDeTransaccion = React.lazy(() => import('features/Expenses/GastoDeTransaccion'));
const GastoNuevo = React.lazy(() => import('features/Expenses/GastoNuevo'));
const FinancialAssistant = React.lazy(() => import('features/FinancialAssistant/FinancialAssistant'));

export type RouteParams =
    | {
          component: JSX;
          name: string;
          path: string;
          isPaylandsOk?: boolean;
          hidden?: boolean;
          hiddenDesktop?: boolean;
          isAccountingSection?: boolean;
          track_params?: (typeof trackParams)[keyof typeof trackParams];
          icon?: string;
      }
    | { redirect: true; path: string; to: string; name: string; hidden: boolean };

export const InicioRoutes: RouteParams[] = [
    {
        component: Inicio,
        name: 'Inicio',
        path: '/inicio',
        track_params: trackParams.inicio,
    },
    {
        component: Inicio,
        name: 'Inicio',
        path: '/paylands-success',
        isPaylandsOk: true,
        hidden: true,
        track_params: trackParams.inicio,
    },
    {
        component: Inicio,
        name: 'Inicio',
        path: '/paylands-error',
        isPaylandsOk: false,
        hidden: true,
        track_params: trackParams.inicio,
    },
];

const appRoutes: RouteParams[] = [
    ...InicioRoutes,
    {
        component: IncomesIndex,
        name: 'Ingresos',
        path: '/ingresos/:tab',
        isAccountingSection: true,
        availableAlwaysForFree: true,
        advicesSection: 'incomes',
        hidden: true,
        hiddenDesktop: true,
    },
    {
        component: IncomesIndex,
        name: 'Ingresos',
        path: '/ingresos/ingresos',
        isAccountingSection: true,
        availableAlwaysForFree: true,
        advicesSection: 'incomes',
    },
    {
        component: ExpensesIndex,
        name: 'Gastos',
        path: '/gastos/:tab',
        isAccountingSection: true,
        isAccountingDisabledForFree: true,
        advicesSection: 'expenses',
        hidden: true,
        hiddenDesktop: true,
    },
    {
        component: ExpensesIndex,
        name: 'Gastos',
        path: '/gastos/gastos',
        isAccountingSection: true,
        isAccountingDisabledForFree: true,
        advicesSection: 'expenses',
    },

    {
        component: InformeRentaLayoutContext,
        name: 'Informe renta',
        path: '/impuestos/informe-renta',
        contextPath: '/impuestos',
        hidden: true,
        track_params: trackParams.informe_renta,
    },
    {
        component: Impuestos,
        name: 'Impuestos',
        path: '/impuestos',
        advicesSection: 'taxes',
        partialMatch: true,
    },
    {
        component: Rent,
        name: 'Renta',
        path: '/calculadora-renta/:tab',
        advicesSection: 'rent',
        isRenta: true,
        hidden: true,
    },
    {
        component: Rent,
        name: 'Renta',
        path: '/calculadora-renta',
        advicesSection: 'rent',
        isRenta: true,
        track_params: trackParams.planificador_renta,
    },
    {
        name: 'Gasto con factura',
        path: '/gasto/con-factura/:invoiceId?',
        component: GastosConFactura,
        hidden: true,
        track_params: trackParams.gasto_con_factura,
    },
    {
        name: 'Gasto sin factura',
        path: '/gasto/sin-factura/:invoiceId?',
        component: GastosSinFactura,
        hidden: true,
        track_params: trackParams.nuevo_gasto_sin_factura,
    },
    {
        name: 'Nuevo gasto',
        path: '/gasto/nuevo',
        component: GastoNuevo,
        hidden: true,
        hiddenDesktop: true,
        track_params: trackParams.nuevo_gasto,
    },
    {
        name: 'Gasto movimiento bancario',
        path: '/gasto/transaccion/:invoiceId?',
        component: GastoDeTransaccion,
        hidden: true,
    },
    {
        name: 'Ingreso sin factura',
        path: '/ingreso/sin-factura/:invoiceId?',
        component: IngresosSinFactura,
        hidden: true,
        track_params: trackParams.ingreso_sin_factura,
    },
    {
        name: 'Presupuesto',
        path: '/presupuesto/:quotationId?/:convertir?',
        component: Quotations,
        hidden: true,
        track_params: trackParams.nuevo_presupuesto,
    },
    {
        name: 'Presupuesto 2025',
        path: '/ingreso/presupuesto',
        component: QuoteCreationView,
        hidden: true,
    },
    {
        name: 'Presupuesto 2025',
        path: '/ingreso/presupuesto-previsualizar/:quoteUUID',
        component: QuotePreviewView,
        hidden: true,
    },
    {
        name: 'Presupuesto 2025',
        path: '/ingreso/presupuesto-convertir/:quoteUUID',
        component: QuoteTransformToInvoiceView,
        hidden: true,
    },
    {
        name: 'Presupuesto 2025',
        path: '/ingreso/presupuesto-editar/:quoteUUID',
        component: QuoteEditView,
        hidden: true,
    },
    {
        name: 'Ingreso con factura',
        path: '/ingreso/con-factura/:invoiceId?',
        component: IngresosConFactura,
        hidden: true,
        hiddenDesktop: true,
        track_params: trackParams.ingreso_con_factura,
    },
    {
        name: 'Ingreso con factura 2025',
        path: FeatureFlags.shouldNewInvoiceFormBeEnabled().getInvoiceFormURL(),
        component: IngresoConFacturaCreationView,
        hidden: true,
        hiddenDesktop: true,
        track_params: trackParams.factura,
    },
    {
        name: 'Ingreso con factura 2025',
        path: `${FeatureFlags.shouldNewInvoiceFormBeEnabled().getInvoiceFormURL()}-duplicar/:invoiceUUID`,
        component: IngresoConFacturaDuplicationView,
        hidden: true,
        hiddenDesktop: true,
        track_params: trackParams.factura,
    },
    {
        //TODO: Hacer un Switch para que se muestre en la ruta /ingreso/con-factura/[form]/[success]/[etc]
        name: 'Ingreso con factura 2025',
        path: '/ingreso/factura-editar/:invoiceUUID',
        component: IngresoConFacturaEditView,
        hidden: true,
        hiddenDesktop: true,
    },
    {
        //TODO: Hacer un Switch para que se muestre en la ruta /ingreso/con-factura/[form]/[success]/[etc]
        name: 'Ingreso con factura 2025',
        path: '/ingreso/factura-previsualizar/:invoiceUUID',
        component: IngresoConFacturaPreviewView,
        hidden: true,
        hiddenDesktop: true,
    },
    {
        name: 'Ingreso con factura simplificada',
        path: '/ingreso/simplificado/:invoiceId?',
        component: IngresosSimplificado,
        hidden: true,
        hiddenDesktop: true,
        track_params: trackParams.nuevo_ingreso_factura_simplificada,
    },
    {
        name: 'Ingreso subvención',
        path: '/ingreso/subvencion/:invoiceId?',
        component: IngresosSubvencion,
        hidden: true,
        hiddenDesktop: true,
        track_params: trackParams.nuevo_ingreso_de_subvencion,
    },
    {
        name: 'Ingreso de movimiento bancario',
        path: '/ingreso/transaccion/:transactionId?',
        component: IngresosDeTransaccion,
        hidden: true,
    },
    {
        name: 'Asistente de gastos',
        path: '/gasto/asistente/:type?',
        component: ExpensesAssistant,
        hidden: true,
    },
    {
        name: 'Configuración',
        path: '/user/profile/:tab',
        component: UserConfig,
        hidden: true,
        hiddenDesktop: true,
    },
    {
        name: 'Configuración',
        path: '/user/profile',
        component: UserConfig,
        hidden: true,
        hiddenDesktop: true,
    },
    {
        name: 'Facturas',
        path: '/listado-facturas',
        component: FacturasServicios,
        hidden: true,
        hiddenDesktop: true,
        track_params: trackParams.forma_de_pago,
    },
    {
        name: 'Alquileres',
        path: '/alquileres',
        component: ModifyRenting,
        hidden: true,
    },
    {
        name: 'Baja alquileres',
        path: '/bajaalquileres',
        component: UnregisterRenting,
        hidden: true,
    },
    {
        name: 'Invita a un amigo',
        path: '/invita-amigo',
        component: InvitaAmigo,
        hidden: true,
        hiddenDesktop: true,
        track_params: trackParams.invita_a_un_amigo,
    },
    { name: 'Agente', path: '/agente', component: AgentsIndex, hidden: true, track_params: trackParams.tus_clientes },
    {
        component: FinancialAssistant,
        name: 'Finanzas',
        path: '/finanzas',
        role: 'ROLE_ASESOR',
    },
    {
        name: 'Versiones',
        path: '/about/versiones',
        component: Versiones,
        hidden: true,
    },
    { redirect: true, path: '/', to: '/inicio', name: 'Inicio', hidden: true },
];

export default appRoutes;
