import { FormProvider, UseFormReturn, FieldValues } from 'react-hook-form';

type FormProps<TFieldValues extends FieldValues> = {
    form: UseFormReturn<TFieldValues>;
    onSubmit: (data: TFieldValues) => void;
    onInvalid?: any;
    children: React.ReactNode;
};

export function Form<TFieldValues extends FieldValues>({
    form,
    onSubmit,
    onInvalid,
    children,
}: FormProps<TFieldValues>) {
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit, onInvalid)}>{children}</form>
        </FormProvider>
    );
}
