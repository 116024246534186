import { ingresoConFacturaRulesClient } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/IngresoConFacturaRulesClient';
import { Invoice } from '../../domain/Invoice';
import { Client } from 'features/Ingresos/Clientes/domain';
import { invoiceCalculationsService } from '../IngresoConFacturaCalculationService';
import DateWrapper from 'shared/infra/utils/Date';
import { InvoicingActivity } from 'features/Ingresos/InvoicingActivities/domain/InvoicingActivity';

export const IngresoConFacturaClientRulesService = {
    isDueDateValid({ dueDate, expeditionDate }: { dueDate: string; expeditionDate: string }) {
        const parsedDueDate = DateWrapper.formatNativeDateToString(new Date(dueDate), 'yyyy-MM-dd');
        const parsedIssueDate = DateWrapper.formatNativeDateToString(new Date(expeditionDate), 'yyyy-MM-dd');

        return ingresoConFacturaRulesClient.isDueDateLaterThanExpeditionDate({
            dueDate: parsedDueDate,
            expeditionDate: parsedIssueDate,
        });
    },

    isExpeditionDateLaterThanActivityRegistrationDate({
        expeditionDate,
        activityRegistrationDate,
    }: {
        expeditionDate: string;
        activityRegistrationDate: string;
    }) {
        const parsedDate = DateWrapper.formatNativeDateToString(new Date(expeditionDate), 'yyyy-MM-dd');

        return ingresoConFacturaRulesClient.isExpeditionDateLaterThanActivityRegistrationDate({
            expeditionDate: parsedDate,
            activityRegistrationDate,
        });
    },

    isExpeditionDateAfterLastInvoiceDate(args: { currentDate: string; lastInvoiceIssueDate: string }) {
        const parsedCurrentDate = DateWrapper.formatNativeDateToString(new Date(args.currentDate), 'yyyy-MM-dd');
        const parsedLastInvoiceIssueDate = DateWrapper.formatNativeDateToString(
            new Date(args.lastInvoiceIssueDate),
            'yyyy-MM-dd',
        );

        return ingresoConFacturaRulesClient.isExpeditionDateAfterLastInvoiceDate({
            currentDate: parsedCurrentDate,
            lastInvoiceIssueDate: parsedLastInvoiceIssueDate,
        });
    },

    isTotalAmountDifferentFromZero(invoice: Invoice) {
        const totalAmounts = invoiceCalculationsService.calculateInvoiceTotals({
            invoiceLines: invoice.lines,
            withEquivalencySurcharge: invoice.withEquivalencySurcharge,
            incomeTax: invoice.incomeTax,
        });

        return ingresoConFacturaRulesClient.isTotalAmountDifferentFromZero(totalAmounts.totalPayable);
    },

    isTotalAmountLessThanMaximalAmount(invoice: Invoice) {
        const totalAmounts = invoiceCalculationsService.calculateInvoiceTotals({
            incomeTax: invoice.incomeTax,
            invoiceLines: invoice.lines,
            withEquivalencySurcharge: invoice.withEquivalencySurcharge,
        });

        return ingresoConFacturaRulesClient.isTotalAmountLessOrEqualThanMaximalAmount(totalAmounts.totalPayable);
    },

    isNIFIVARequired(args: { country: Client['country']; clientType: Client['clientType'] }) {
        return ingresoConFacturaRulesClient.isNIFIVARequired(args);
    },

    isImageRightsRequired(args: {
        activityType: InvoicingActivity['activityType'];
        clientType: Client['clientType'];
        country: Client['country'];
    }) {
        return ingresoConFacturaRulesClient.isImageRightsRequired(args);
    },

    isForFatteningRequired(args: {
        activityType: InvoicingActivity['activityType'];
        clientType: Client['clientType'];
        country: Client['country'];
    }) {
        return ingresoConFacturaRulesClient.isForFatteningRequired(args);
    },

    hasConsistentVAT({ client, lines }: { client: Invoice['client']; lines: Invoice['lines'] }) {
        return ingresoConFacturaRulesClient.hasConsistentVAT({ client, lines });
    },

    isExpeditionDateBetweenValidRangeWhenEditingAutomaticNumbering({
        expeditionDate,
        minValidDate,
        maxValidDate,
    }: {
        expeditionDate: string;
        minValidDate: string;
        maxValidDate: string;
    }) {
        return ingresoConFacturaRulesClient.isExpeditionDateBetweenValidRangeWhenEditingAutomaticNumbering({
            expeditionDate,
            minValidDate,
            maxValidDate,
        });
    },

    hasClientIncompleteProfile({ client }: { client: Client }) {
        return ingresoConFacturaRulesClient.hasClientIncompleteProfile(client);
    },

    shouldApplyZeroVATByDefault({ client, isFieldVATDirty }: { client: Client; isFieldVATDirty: boolean }) {
        return ingresoConFacturaRulesClient.shouldApplyZeroVATByDefault({ client, isFieldVATDirty });
    },
};
