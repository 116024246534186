type Params = { [key: string]: string };

export const UrlParser = {
    getQueryParams: (): Params =>
        new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams: any, prop: string) => searchParams.get(prop),
        }),
    setQueryParams: (params: any): string => {
        const queryString = Object.keys(params)
            .map((key: any) => key + '=' + params[key])
            .join('&');
        return queryString;
    },
};
