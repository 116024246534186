// @ts-check
import { OptionList } from 'shared/domain/types/FormTypes';
import V2Repository from './V2Repository';

class LocationsRepository extends V2Repository {
    isNoEUCountry(countryId: number | string | boolean) {
        return this.get('/countries/check/isNOEU/' + countryId);
    }

    getProvinces(all: boolean): Promise<{ data: { label: string; value: string }[] }> {
        const sendValue = all ? 1 : 0;
        return this.get('/provincias/' + sendValue);
    }

    async getTowns(province: number | string): Promise<{ data: OptionList & { codigos_postales: number[] } }> {
        return this.get('/poblaciones/' + province);
    }

    getViaTypes() {
        return this.get('/tipos-via/search');
    }

    getCountries() {
        return this.get('/paises/search');
    }
}

export default LocationsRepository;
