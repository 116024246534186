import { NewDateSelector } from '@declarando/design_system';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { QuoteForm } from 'features/Ingresos/SalesDocument/Quote/domain/Quote';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

const DueDateQuoteInput = ({ readOnly }: { readOnly?: boolean }) => {
    const { control } = useCustomFormContext<QuoteForm>();
    return (
        <ControlledFormField
            control={control}
            name="dueDate"
            Component={({ field, formState: { errors } }) => (
                <NewDateSelector
                    ref={field.ref}
                    error={!!errors?.dueDate?.message}
                    helperText={errors?.dueDate?.message}
                    id="quote-due-date"
                    readOnly={readOnly}
                    allowClear
                    label="Fecha vencimiento"
                    onChange={(value) => {
                        field.onChange(value);
                    }}
                    value={field.value ? new Date(field.value) : null}
                />
            )}
        />
    );
};

export const DueDateQuote = () => {
    const { formBlocked } = useSalesDocumentFormContext();

    if (formBlocked.isFormBlocked) return <DueDateQuoteInput readOnly />;

    return <DueDateQuoteInput />;
};
