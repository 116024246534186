import { useFetch } from 'shared/UI/hooks/QueryHooks';
import { createContabilidadPresentedOrBlockedService } from '../../services/ContabilidadPresentedOrBlockedService';
import { createContabilidadPresentedOrBlockedRepository } from '../../infra/ContabilidadPresentedOrBlockedRepository';
import { ContabilidadPresentedOrBlocked } from '../../domain/ContabilidadPresentedOrBlocked/ContabilidadPresentedOrBlocked';
import { ContabilidadPresentedOrBlockedDTO } from '../../adapter/ContabilidadPresentedOrBlockedDTO';
import { toContabilidadPresentedOrBlocked } from '../../adapter/ContabilidadPresentedOrBlockedAdapter';

export const useGetContabilidadPresentedOrBlocked = (
    selectedDate: string,
    options?: { shouldTriggerApiCall: boolean },
) => {
    const service = createContabilidadPresentedOrBlockedService(createContabilidadPresentedOrBlockedRepository());

    const context = useFetch<ContabilidadPresentedOrBlockedDTO, ContabilidadPresentedOrBlocked>({
        queryKey: ['contabilidad', 'presentedOrBlocked', selectedDate],
        fetchFn: () => service.hasContabilidadPresentedOrBlocked(selectedDate),
        config: {
            select: (data) => ({ ...data, data: toContabilidadPresentedOrBlocked(data.data!) }),
            enabled: options?.shouldTriggerApiCall ?? !!selectedDate,
        },
    });

    return { contabilidadPresentedOrBlocked: context.data?.data, isLoading: context.isLoading };
};
