import { Client } from '../domain';
import { City, Country, State } from '../domain/Client';

export function addCityNameToClient(client: Client, cities: City[]): Client {
    if (!client.city || !client.city.id) return client;

    const cityInfo = cities.find((city) => city.id === client?.city?.id);
    if (!cityInfo) return client;

    return {
        ...client,
        city: {
            ...client.city,
            name: cityInfo.name,
        },
    };
}

export function addStateNameToClient(client: Client, states?: State[]): Client {
    if (!client.state || !client.state.id) return client;

    const stateInfo = states?.find((state) => state.id === client?.state?.id);
    if (!stateInfo) return client;

    return {
        ...client,
        state: {
            ...client.state,
            name: stateInfo.name,
        },
    };
}

/** Utility function to fix the conversion of legacy client interface to new one being used in the new invoice's form */
export function addCountryNameToClient(client: Client, countries?: Country[]): Client {
    if (!client.country) return client;

    const countryInfoById = countries?.find((country) => country.id === client?.country?.id);
    const countryInfoByCode = countries?.find((country) => country.code === client?.country?.code);

    if (!countryInfoById && !countryInfoByCode) return client;

    return {
        ...client,
        country: {
            ...client.country,
            id: countryInfoById?.id || countryInfoByCode?.id,
            name: (countryInfoById?.name || countryInfoByCode?.name) as string,
            code: (countryInfoById?.code || countryInfoByCode?.code) as string,
            isEuropean: (countryInfoById?.isEuropean || countryInfoByCode?.isEuropean) ?? false,
        },
    };
}
