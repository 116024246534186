import { CheckBox } from '@declarando/design_system';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { useCashMethod } from '../hooks/use-cash-method';

export const PaymentOptionsCash = () => {
    const { shouldBeDisabled, control } = useCashMethod();

    return (
        <ControlledFormField
            control={control}
            name="paymentOptions.cash.isChecked"
            Component={({ field }) => (
                <CheckBox
                    id="invoice-payment-cash"
                    label="En efectivo (1.000€ máximo)"
                    tooltipOptions={{
                        title: 'Límite de cobros en efectivo',
                        text: 'Según la ley 11/2021 el límite de pago en efectivo es de 1.000€.',
                        link: {
                            text: 'Saber más',
                            href: 'https://declarando.es/observatorio-juridico/los-nuevos-limites-del-pago-en-efectivo-para-autonomos',
                        },
                    }}
                    disabled={shouldBeDisabled}
                    checked={field.value}
                    onChange={(e) => {
                        field.onChange(e);
                        if (e.target.checked) {
                            TrackingEvents.fillField({ item_id: 'En efectivo', event_label: 'Añade método de pago' });
                        }
                    }}
                />
            )}
        />
    );
};
