import { Box, Header, ModalChoice, P } from '@declarando/design_system';
import { ShareScreen } from 'shared/infra/utils/ShareScreen';
import { useModal } from 'shared/UI/hooks/useModal';
import Mentor from './assets/Mentor.png';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import { useEffect } from 'react';
import UserV2Repository from '../../../../../../shared/infra/repositories/UserV2Repository';

export const ModalPendientePago = () => {
    const modal = useModal();
    const onClick = () => {
        modal.setBusy(true);
        new UserV2Repository()
            .getActivationPlan()
            .then((data) => {
                window.location.href = data.url;
            })
            .finally(() => modal.setBusy(false));
    };

    const { recurrenteData } = useRootContext();
    useEffect(() => {
        if (recurrenteData && recurrenteData.estado === 'IMPAGADO_FINAL' && recurrenteData.renovacion.puedeRenovar) {
            modal.showModal();
        }
    }, [recurrenteData]);

    return (
        <ModalChoice
            id={'modal-plan-pendiente-pago'}
            busy={modal.busy}
            title={'¡Atención! Tu plan está pendiente de pago'}
            isHeaderHidden={true}
            isOpen={modal.show}
            handleAction={onClick}
            handleCancel={() => {
                ShareScreen.openCrankwheelForTelNumber();
            }}
            labelAction="Pagar y reactivar mi cuenta"
            labelCancel="Hablar con un asesor"
        >
            <Box alignCenter paddingTop={'md'}>
                <img src={Mentor} alt="Plan pendiente de pago" />
                <Header as="h2" paddingTop={'md'} marginBottom={'xxs'}>
                    ¡Atención! Tu plan está pendiente de pago
                </Header>
                <P paddingBottom={'md'}>Abona el importe pendiente y reactiva tu cuenta en Declarando.</P>
            </Box>
        </ModalChoice>
    );
};
