import PublicV2Repository from './PublicV2Repository';

export default class AuthV2Repository extends PublicV2Repository {
    register(email, pass, phone, altaStatus, gclid, prefijo) {
        return this.post(
            '/register',
            JSON.stringify({
                email: email,
                pass: pass,
                phone: phone,
                altaStatus: altaStatus,
                gclid: gclid,
                prefijo,
            }),
        );
    }

    askResetMail(email) {
        return this.post(
            '/send-reset-mail',
            JSON.stringify({
                email,
            }),
        );
    }

    resetPass(data) {
        return this.post(
            '/reset-password',
            JSON.stringify({
                data,
            }),
        );
    }
}
