import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { ContabilidadPresentedOrBlockedRepository } from '../domain/ContabilidadPresentedOrBlocked/ContabilidadPresentedOrBlockedRepository';
import DateWrapper from 'shared/infra/utils/Date';

class ContabilidadPresentedOrBlockedService extends DeclarandoApiService {
    private repository: ContabilidadPresentedOrBlockedRepository;

    constructor(repository: ContabilidadPresentedOrBlockedRepository) {
        super();
        this.repository = repository;
    }

    hasContabilidadPresentedOrBlocked(selectedDate: string) {
        const parsedDate = DateWrapper.formatNativeDateToString(new Date(selectedDate));

        return this.execute(() => this.repository.getContabilidadPresentedOrBlocked(parsedDate), {
            shouldThrowError: true,
        });
    }
}

export const createContabilidadPresentedOrBlockedService = (
    repository: ContabilidadPresentedOrBlockedRepository,
): ContabilidadPresentedOrBlockedService => {
    return new ContabilidadPresentedOrBlockedService(repository);
};
