import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import {
    GetTaxesArgs,
    IngresoConFacturaRulesRepository,
} from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/IngresoConFacturaRulesRepository';
import { createIngresoConFacturaApiRepository } from '../../infra/IngresoConFacturaRepository';
import { createIngresoConFacturaService } from '../IngresoConFacturaService';

class IngresoConFacturaRepositoryService extends DeclarandoApiService {
    private repository: IngresoConFacturaRulesRepository;

    constructor(repository: IngresoConFacturaRulesRepository) {
        super();
        this.repository = repository;
    }

    async checkInvoiceNumberIsUnique(invoiceNumber: string) {
        const invoiceService = createIngresoConFacturaService(createIngresoConFacturaApiRepository());
        const { data } = await invoiceService.getInvoicesList({ documentNumber: invoiceNumber, limit: 1 });

        //TODO: Call a domain method to check if the data is unique passing the invoice list as parameter
        const isUnique = data?.data?.length === 0;

        return { isUnique };
    }

    getAvailableTaxes(args: GetTaxesArgs) {
        return this.execute(() => this.repository.getTaxes(args), { bodyAsData: true, shouldThrowError: true });
    }
}

export const createIngresoConFacturaRepositoryRulesService = (
    repository: IngresoConFacturaRulesRepository,
): IngresoConFacturaRepositoryService => {
    return new IngresoConFacturaRepositoryService(repository);
};
