import { TTipado } from 'shared/TipadoType';
import GastosConFacturaTypes from 'shared/domain/contabilidad/GastosConFacturaTypes';
import GastosSinFacturaTypes from 'shared/domain/contabilidad/GastosSinFacturaTypes';
import { TReglasDeducibilidad } from './ReglasDeducibilidad';

export const ReglasOtros = {
    esOtros(tipado: TTipado): boolean {
        return [
            GastosConFacturaTypes.SPANISH_OTROS,
            GastosConFacturaTypes.FOREIGN_OTROS,
            GastosSinFacturaTypes.OTROS,
        ].includes(tipado);
    },

    getDeducibilidad(): TReglasDeducibilidad {
        return {
            mapper: 'otros',
            type: 'editable',
            value: 0,
            hideQuestion: true,
            info: 'No podemos sugerir un porcentaje de deducibilidad para los gastos del tipo ‘otros’ ya que es un campo libre. Te recomendamos utilizar este tipo solo en casos excepcionales',
        };
    },
};
