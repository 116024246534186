import V2Repository from 'shared/infra/repositories/V2Repository';
import { InvoicingActivitiesRepository as TInvoicingActivitiesRepository } from '../domain/InvoicingActivitiesRepository';

class InvoicingActivitiesRepository extends V2Repository implements TInvoicingActivitiesRepository {
    getActivities() {
        return this.get('/invoicing-activities');
    }
}

export function createInvoicingActivitiesRepository(): TInvoicingActivitiesRepository {
    return new InvoicingActivitiesRepository();
}
