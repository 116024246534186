import MixPanel from '../../utils/user_events/MixPanel';

type TrackEvent = {
    type: string;
    payload?: object;
};
export const publishTrackEvent = ({ type, payload }: TrackEvent) => {
    console.log('PUBLISH EVENT:', type, payload);
    try {
        MixPanel.track(type, payload);
    } catch (e) {
        console.error(e);
    }
};
