import RepositoryFactory from 'shared/infra/repositories/RepositoryFactory';
import { useReducer } from 'react';
import { ImpuestosV2Repository } from 'features/Impuestos/Infra/ImpuestosV2Repository';

export const useModel180Store = () => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            return { ...state, ...action };
        },
        {
            provider: {},
            property: {},
            selectProviderList: [],
            selectProvider: [],
            valid: false,
        },
    );

    const setProvider = (provider) => dispatch({ provider });
    const setProperty = (property) => dispatch({ property });
    const setSelectProviderList = (selectProviderList) => dispatch({ selectProviderList });
    const setSelectProvider = (selectProvider) => dispatch({ selectProvider });
    const setValid = (valid) => dispatch({ valid });

    const propertyJSON = () => {
        const property = state.property;
        property.ref_catastral = property.referenciaCatastral;
        return property;
    };

    const gastosRepository = () => {
        return RepositoryFactory.instance.getGastosRepository();
    };

    const impuestosRepository = () => {
        return new ImpuestosV2Repository();
    };

    const init = async (modelYear) => {
        return impuestosRepository()
            .checkValidDataModel180(modelYear)
            .then(async (response) => {
                const { provider, property } = response.data;
                setProvider({ ...state.provider, ...provider });
                setProperty({ ...state.property, ...property });
                await loadProviders();
                setValid(response.data.valid);
                return response;
            });
    };

    const handleCompleted = async (callback) => {
        await impuestosRepository().sendDataModel180(state.provider, state.property);
        if (callback) {
            callback();
        }
    };

    const updateProvider = (field, value) => {
        setProvider({ ...state.provider, [field]: value });
        if (field === 'id') {
            loadProviderData(value);
        }
    };

    const updateInmueble = (field, value) => {
        setProperty({ ...state.property, [field]: value });
    };

    const loadProviders = () => {
        return gastosRepository()
            .getProveedoresGastosAlquiler()
            .then((response) => {
                if (response.data) {
                    const returnedProvidersList = response.data.map((provider) => {
                        return {
                            value: provider.value,
                            label: provider.label,
                        };
                    });
                    setSelectProviderList(returnedProvidersList);
                }
            });
    };

    const loadProviderData = (providerId) => {
        gastosRepository()
            .getProviderDataByIdOrUuId(providerId)
            .then((response) => {
                setSelectProvider(response.data);
                setProvider({ ...response.data });
            });
    };

    return {
        state,
        propertyJSON,
        init,
        handleCompleted,
        updateProvider,
        updateInmueble,
        loadProviders,
        loadProviderData,
    };
};
