import V2Repository from './V2Repository';

export class AdvicesV2Repository extends V2Repository {
    async getAdvices(section: string) {
        switch (section) {
            case 'rent':
                return this.post(`/consejos/RENTA/ANY/search`);
            case 'expenses':
                return this.post(`/consejos/GASTOS/ANY/search`);
            case 'incomes':
                return this.post(`/consejos/INGRESOS/ANY/search`);
            case 'taxes':
                return this.post(`/consejos/IMPUESTOS/ANY/search`);
            default:
                return { data: [] };
        }
    }

    changeState(id: any, estado: any) {
        return this.post(
            '/consejo/estado/update',
            JSON.stringify({
                id,
                estado,
            }),
        );
    }

    changeStateProgramed(id: any, estado: any, date_option: any) {
        return this.post(
            '/consejo/estado-programado/update',
            JSON.stringify({
                id,
                estado,
                date_option,
            }),
        );
    }
}
