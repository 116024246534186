import { Box, CheckBox, Grid, TextArea } from '@declarando/design_system';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { ExpandableContent } from '../../../shared/components/ExpandableContent';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { SalesDocumentType, useSalesDocumentFormContext } from '../../../../context/SalesDocumentFormProvider';
import { ingresoConFacturaRulesClient } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/IngresoConFacturaRulesClient';

const HelperTextMapper: Record<SalesDocumentType, string> = {
    invoice: 'El texto se mostrará en la factura junto a los otros métodos de pago',
    quote: 'El texto se mostrará en el presupuesto junto a los otros métodos de pago',
};

export const PaymentOptionsOther = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const { formBlocked, salesDocumentType } = useSalesDocumentFormContext();
    const shouldShowTextArea = useCustomWatch({ control, name: 'paymentOptions.other.isChecked' });

    return (
        <Box display="flex" flexDirection="column" gap="8px">
            <ControlledFormField
                control={control}
                name="paymentOptions.other.isChecked"
                Component={({ field }) => (
                    <CheckBox
                        disabled={formBlocked.isFormBlocked}
                        checked={field.value}
                        onChange={(e) => {
                            field.onChange(e);
                            if (e.target.checked) {
                                TrackingEvents.fillField({
                                    item_id: 'Otros',
                                    event_label: 'Añade método de pago',
                                });
                            }
                        }}
                        id="sales-document-payment-others"
                        label="Otros"
                    />
                )}
            />
            <ExpandableContent shouldExpand={shouldShowTextArea}>
                <Grid gridTemplateColumns={{ _: '1fr', mediumScreen: '6fr 4fr' }}>
                    <ControlledFormField
                        control={control}
                        name="paymentOptions.other.details"
                        Component={({ field, formState: { errors } }) => (
                            <TextArea
                                readOnly={formBlocked.isFormBlocked}
                                placeholder="Añade la descripción de otro método de pago"
                                id="sales-document-details-description"
                                value={field.value}
                                maxLength={ingresoConFacturaRulesClient.MAX_CHARACTERS_PAYMENT_TYPE_OTHERS}
                                onChange={field.onChange}
                                required
                                resize="vertical"
                                error={!!errors?.paymentOptions?.other?.details?.message}
                                shouldHideMaxCharacters
                                helperText={
                                    errors?.paymentOptions?.other?.details?.message ??
                                    HelperTextMapper[salesDocumentType]
                                }
                                minHeight="48px"
                                rows={1}
                            />
                        )}
                    />
                </Grid>
            </ExpandableContent>
        </Box>
    );
};
