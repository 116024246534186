//@ts-nocheck
import { useFetchListadoModelosPendientes } from 'features/Impuestos/Services/useFetchListadoModelosPendientes';
import React, { ComponentProps, memo, useEffect, useRef, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Box, DropdownMenu, Flex, Icon, Menu, SelectMenu, Stack, Tag } from '@declarando/design_system';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import { Logo } from 'shared/UI/components/Navbar/Logo';
import routes from 'routes/appRoutes';
import { AddDropdown } from 'shared/UI/components/Navbar/AddDropdown';
import { UserMenuDropdown } from 'shared/UI/components/Navbar/UserMenuDropdown';
import useWindowSize from 'shared/UI/hooks/useWindowSize';

import { NotificationSystem } from 'features/NotificationSystem/UI/NotificationSystem';
import { TrackingEvents } from '../../../infra/events/tracking/trackingEvents';
import { InformCNAERulesClientService } from 'features/Impuestos/InformCNAE/services/InformCNAEService/InformCNAERulesClientService';
import { FeatureFlags } from 'config/FeatureFlags';
import { handleTagDependingOnDate } from 'shared/infra/utils/HandleTagDependingOnDate';

export const Navbar = memo(({ isAccountingDisabledForFree, openPopUpContrata, userInfo, userStatus, roles }: any) => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { planHelper, hideNavbar } = useRootContext();
    const isNotOnboarding = !pathname.includes('/inicial');

    const navbarRef = useRef(null);
    const [navbarHeight, setNavbarHeight] = useState(57);

    useEffect(() => {
        if (navbarRef.current) {
            setNavbarHeight(navbarRef.current.offsetHeight + 1);
        }
    }, []);

    useEffect(() => {
        const notificationDropdownMenuButton = document.querySelector('.button-notifications');

        const handleClickEntrada = () => {
            TrackingEvents.clickElement({ event_label: 'Despliega feed notificaciones' });
        };

        notificationDropdownMenuButton?.addEventListener('click', handleClickEntrada);

        return () => {
            notificationDropdownMenuButton?.removeEventListener('click', handleClickEntrada);
        };
    }, []);

    const { width } = useWindowSize();
    const isMobile = width < 1024;
    const shouldInformCNAE = InformCNAERulesClientService.shouldInformCNAE({ iaes: userStatus.iaes });
    const { totalModelosPendientes } = useFetchListadoModelosPendientes({ shouldTriggerCall: !shouldInformCNAE });

    const popups =
        ({ isRenta, isAccountingSection, availableAlwaysForFree }) =>
        (event) => {
            if (
                (isAccountingSection && isAccountingDisabledForFree && !availableAlwaysForFree) ||
                (isRenta && userStatus.renta && planHelper.isBaja())
            ) {
                event.preventDefault();
                return openPopUpContrata();
            } else {
                return null;
            }
        };

    const tagData = handleTagDependingOnDate({ context: 'navbar' });

    const filterRoute = (route) => {
        if (route.isAccountingDisabledForFree && planHelper.isAccountingDisabledForFree()) {
            return false;
        }
        if (route.role && !roles.includes(route.role)) return false;
        return (route.hiddenDesktop || !route.hidden) && !route.hidden;
    };

    let filteredRoutes = routes.filter(filterRoute);
    filteredRoutes = filteredRoutes.map((route) => {
        if (route.name === 'Impuestos') {
            route.badge = totalModelosPendientes < 10 ? totalModelosPendientes : '+9';
        } else if (route.name === 'Renta' && FeatureFlags.enabledFlujoRenta()) {
            route.routeTag = userStatus.renta ? tagData : undefined;
        } else {
            route.routeTag = undefined;
        }

        return route;
    });

    if (hideNavbar) {
        return <Box width="100vw" height="5vw" id="Navbar" styled={{ zIndex: 99 }}></Box>;
    }

    const iconRigthProps: ComponentProps<typeof Icon> = {
        name: 'external-link-alt',
        color: '#ACACAE',
    };

    return (
        <Box
            display="flex"
            px={{ _: 'sm', desktop: 'lg' }}
            py="xs"
            justifyContent="center"
            bg="white"
            id="Navbar"
            ref={navbarRef}
            styled={{ zIndex: 99 }}
        >
            <Flex
                justifyContent="space-between"
                height="48px"
                width="100%"
                maxWidth={{ _: 'unset', bigScreen: '1440px' }}
            >
                <Stack align="center" direction={['row']} space={['xs', 'xl']}>
                    <Logo />
                    {isMobile ? (
                        <SelectMenu
                            onClickItem={(path: string) => history.push(path)}
                            routes={filteredRoutes}
                            pathname={pathname}
                            isMobile={isMobile}
                            mobileTopPosition={navbarHeight}
                        />
                    ) : (
                        <Menu
                            NavLink={NavLink}
                            routes={filteredRoutes}
                            pathname={pathname}
                            popups={popups}
                            isMobile={isMobile}
                        />
                    )}
                </Stack>

                <Flex style={{ gap: 4 }}>
                    {isNotOnboarding && (
                        <AddDropdown
                            openPopUpContrata={openPopUpContrata}
                            userStatus={userStatus}
                            navbarHeight={navbarHeight}
                        />
                    )}
                    <NotificationSystem popoverMobileTopPosition={navbarHeight} />

                    <DropdownMenu
                        className={'help'}
                        triggerType="button"
                        triggerProps={{ icon: 'question-circle', variant: 'secondary', size: 'M', isIconOnly: true }}
                        position="right"
                        mobileTopPosition={navbarHeight}
                        options={[
                            {
                                children: 'Centro de ayuda',
                                value: 'ayuda',
                                icon: 'question-circle',
                                onClick: () => {
                                    window.open('https://ayuda.declarando.es/es/', '_blank');
                                },
                                iconRigthProps,
                            },
                            {
                                children: 'Mentorías',
                                value: 'mentorias',
                                icon: 'graduation-cap',
                                onClick: () => {
                                    window.open(
                                        'https://unmarred-mushroom-c93.notion.site/Declarando-Mentor-as-online-55e21cf8969648c384eb7ccf75edf4bd',
                                        '_blank',
                                    );
                                },
                                iconRigthProps,
                            },
                            {
                                children: 'Píldoras formativas',
                                value: 'pildoras',
                                icon: 'youtube',
                                onClick: () => {
                                    window.open('https://www.youtube.com/@DeclarandoEs/videos', '_blank');
                                },
                                iconRigthProps,
                            },
                            {
                                children: 'Novedades',
                                value: 'novedades',
                                icon: 'rocket',
                                divider: 'top',
                                onClick: () => {
                                    window.open('https://intercom.news/declarando', '_blank');
                                },
                                iconRigthProps,
                            },
                            {
                                children: 'Sugerir una idea',
                                value: 'ideas',
                                icon: 'megaphone',
                                onClick: () => {
                                    window.open('https://declarando1.typeform.com/to/f6jyo5xU', '_blank');
                                },
                                iconRigthProps,
                            },
                        ]}
                    />

                    <UserMenuDropdown
                        navbarHeight={navbarHeight}
                        filteredRoutes={filteredRoutes}
                        history={history}
                        userInfo={userInfo}
                        isMobile={isMobile}
                    />
                </Flex>
            </Flex>
        </Box>
    );
});
