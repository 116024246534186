import AppRoutesRepository from 'shared/infra/repositories/Locations/AppRoutesRepository';
import JWT from 'shared/infra/utils/JWT';
import ExpensesLocationRepository from '../shared/infra/repositories/Locations/ExpensesLocationRepository';

export class AppRouter {
    static routes = new AppRoutesRepository();
    static history;

    static goHome = () => {
        window.location.href = import.meta.env.VITE_DEFAULT_HOME_URI;
    };

    static goSetupNoAFFA = (step) => {
        this.history.push(this.routes.getSetupNoAFFAUri(step));
    };

    static goProfile = () => {
        this.history.push(this.routes.getProfileUri());
    };

    static goProfileAltaAutonomo = () => {
        this.history.push(this.routes.getProfileAltaAutonomoUri());
    };

    static goProfileActividad = () => {
        this.history.push(this.routes.getProfileUri() + '/actividad');
    };

    static goToDeclarandoCard = () => {
        this.history.push(this.routes.getDeclarandoCardUri());
    };

    static handleLogout = () => {
        JWT.removeToken();
        this.goLogin();
    };

    static goLogin = (shouldRedirect = true) => {
        let url = this.routes.getLoginUri();
        const path = window.location.pathname;
        if (!shouldRedirect) {
            window.location.href = url;
            return;
        }
        if (shouldRedirect && path !== '/' && path.startsWith('/')) {
            url += '?redirect-to=' + path;
        } else {
            console.error('La ruta no es válida:', path);
        }
        window.location.href = url;
    };

    static goToPath = (path) => {
        this.history.push(path);
    };

    static goBack() {
        this.history.goBack();
    }

    static goToImpuestos = () => {
        this.history.push(this.routes.getImpuestosUri());
    };

    static goToModelosPresentados = (modelo) => {
        this.history.push(this.routes.getImpuestosModelosPresentadosUri(modelo ?? null));
    };

    static goToInformeRenta() {
        this.history.push(this.routes.getInformeRentaUri());
    }

    static goToInformeRentaCuentaPropia() {
        this.history.push(this.routes.getInformeRentaCuentaPropiaUri());
    }

    static goToActivacionTarjeta() {
        this.history.push(this.routes.getActivacionTarjetaUri());
    }

    static goToValidate3dsModal() {
        this.history.push(this.routes.getValidate3dsModalUri());
    }

    static goToListaFacturas() {
        this.history.push(this.routes.getValidate3dsModalUri());
    }

    static goToTransferencia() {
        this.history.push(this.routes.getTransferenciaUri());
    }

    static goToIngresos = () => {
        this.history.push(this.routes.getIncomesUri());
    };

    static goToGastos = () => {
        this.history.push(this.routes.getExpensesUri());
    };

    static goToRenta = () => {
        this.history.push('/calculadora-renta');
    };

    static goToStartCompletingTaxReturn = () => {
        this.history.push('calculadora-renta/presentacion-renta');
    };

    static goToMovimientosBancarios = () => {
        this.history.push(this.routes.getMovimientosBancarios());
    };

    static goToFinanciacionFueraDePlazo = () => {
        this.history.push('/financiacion/fuera-de-plazo');
    };
    static goToFinanciacion = () => {
        this.history.push('/financiacion');
    };

    static goToFinanciacionErrorDatos = () => {
        this.history.push('/financiacion/error-datos');
    };

    static gotoConvertirPresupuesto(id) {
        this.history.push(`/presupuesto/${id}/convertir`);
    }

    static goToPresupuestos() {
        this.history.push(`/ingresos/presupuestos`);
    }

    static goToGastoNuevo() {
        this.history.push(`/gasto/nuevo`);
    }

    static goToPlan() {
        this.history.push(`/contrata/planes`);
    }

    static fomaDePago() {
        this.history.push(`/listado-facturas`);
    }

    static goToAnalizarGastos(type = '') {
        this.history.push(new ExpensesLocationRepository().goToNewExpensesAssistant(type));
    }
}
