enum GastosConFacturaTypes {
    LIMIT_AMOUNT_GASTO_INVERSION = 3005.06,
    SPANISH_ALQUILER = 'GES11',

    SPANISH_GASTOS_DE_CASA = 'GES01',
    SPANISH_DIETAS = 'GES02',
    SPANISH_VIAJES = 'GES03',
    SPANISH_VEHICULO_PROPIO = 'GES04',
    SPANISH_PUBLICIDAD = 'GES05',
    SPANISH_TELEFONO_MOVIL = 'GES06',
    SPANISH_ORDENADOR = 'GES07',
    SPANISH_PAGINA_WEB = 'GES08',
    SPANISH_MAQUINARIA = 'GES09',
    SPANISH_MOBILIARIO_OFICINA = 'GES10',
    SPANISH_OTROS = 'GES12',
    SPANISH_MATERIAS_PRIMAS = 'GES13',
    ADUANA = 'GES14',
    SERVICIO_DECLARANDO = 'GES15',
    // NO ESPAÑOLES
    FOREIGN_MATERIAS_PRIMAS = 'GEU01',
    FOREIGN_DIETAS = 'GEU02',
    FOREIGN_VUELOS = 'GEU03',
    FOREIGN_PUBLICIDAD = 'GEU04',
    FOREIGN_TRANSPORTE_PUBLICO = 'GEU05',
    FOREIGN_OTROS = 'GEU06',

    // DEPRECATED KEYS
    FOREIGN_GASTO_CASA = 'GES01',
    FOREIGN_VIAJES = 'GES03',
    FOREIGN_VEHICULO_PROPIO = 'GES04',
    FOREIGN_EUNOEU_OTROS = 'GEU06',
    PUBLICIDAD = 'GES05',
}

export enum SubTiposGastosConFacturaTypes {
    SPANISH_AUTOBUS = 'GESAU',
    SPANISH_AVION = 'GESAV',
    SPANISH_METRO = 'GESM',
    SPANISH_TAXI = 'GESTA',
    SPANISH_TREN = 'GESTR',
    SPANISH_COMIDAS_CON_CLIENTES = 'GESCC',
    SPANISH_COMIDAS = 'GESCOM',
    SPANISH_HOTELES_Y_OTROS_ALOJAMIENTOS = 'GESHA',
    FOREIGN_COMIDAS_CON_CLIENTES = 'GEUCC',
    FOREIGN_COMIDAS = 'GEUC',
    FOREIGN_HOTELES_Y_OTROS_ALOJAMIENTOS = 'GEUHA',
    SPANISH_VEHICULO_NUEVO = 'GESVN',
    SPANISH_VEHICULO_SEGUNDA_MANO = 'GESVSM',
}
export default GastosConFacturaTypes;
