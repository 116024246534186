import { FeatureFlags } from 'config/FeatureFlags';
import { useIngresoConFactura } from '../../hooks/useIngresoConFactura';
import { useSalesDocumentMutation } from '../../../../shared/UI/hooks/useSalesDocumentMutation';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';

export const useCreateIngreso = () => {
    const { createInvoice } = useIngresoConFactura();

    return useSalesDocumentMutation({
        mutation: createInvoice,
        trackingEvent: () => TrackingEvents.clickElement({ event_label: 'Guarda ingreso con factura' }),
        successMessage: {
            title: 'Ingreso creado correctamente',
            message: 'Puedes consultarlo en el listado de ingresos.',
            link: { label: 'Ir al listado de ingresos', href: '/ingresos/ingresos' },
        },
        errorMessage: {
            title: 'No ha sido posible crear la factura',
            message: 'Inténtalo de nuevo más tarde.',
        },
        getSuccessRedirectPath: (id) => FeatureFlags.shouldNewInvoiceFormBeEnabled().getInvoicePreviewURL(id),
    });
};
