import { useEffect } from 'react';

function getAppTitle() {
    const env = import.meta.env.VITE_ENV;
    const titleCommon = 'Declarando App';

    switch (env) {
        case 'local':
            return `${titleCommon} (Local)`;
        case 'test':
            return `${titleCommon} (Testing)`;
        case 'staging':
            return `${titleCommon} (Staging)`;
        default:
            return titleCommon;
    }
}

export const RenderTitleDocument = () => {
    useEffect(() => {
        document.title = getAppTitle();
    }, []);
};
