export const Planes = {
    ARR: ['Profesional', 'Bajas', 'Free', 'Pro', 'Plus', 'Total'],
    BAJA: 'Bajas',
    FREE: 'Free',
    PLUS: 'Plus',
    PROFESIONAL: 'Profesional',
    PRO: 'Pro',
    TOTAL: 'Total',
};

export const Planes_Codes = {
    CODE_BAJA: 9,
    CODE_FREE: 1,
    /** @deprecated rename CODE_PLUS to CODE_AVANZADO (plan id 3) */
    CODE_PLUS: 3,
    CODE_AVANZADO: 3,
    /** @deprecated rename CODE_PRO to CODE_ESENCIAL   (plan id 2) */
    CODE_PRO: 2,
    CODE_ESENCIAL: 2,
    /** @deprecated rename CODE_TOTAL to CODE_ESTELAR  (plan id 4) */
    CODE_TOTAL: 4,
    CODE_ESTELAR: 4,
};
