import { SelectorGroup } from '@declarando/design_system';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { LinesProps } from '../../Lines';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { SalesDocumentLine } from 'features/Ingresos/SalesDocument/shared/domain/SalesDocument';

const useGetTooltipOptions = (args: { withImageRights: boolean }) => {
    //NOTE: For now its only withImageRights, its prepared for more options like fattening
    return args.withImageRights
        ? { text: 'Los derechos de explotación de imagen solo pueden ser servicios.' }
        : undefined;
};

export const LineProductType = ({ index, handleLineChange }: LinesProps) => {
    const { control, getValues } = useCustomFormContext<InvoiceForm>();
    const { formBlocked } = useSalesDocumentFormContext();
    const watchWithImageRights = useCustomWatch({ control, name: 'activity.withImageRights' });
    const tooltipOptions = useGetTooltipOptions({ withImageRights: !!watchWithImageRights });

    const selectors: { id: SalesDocumentLine['productType']; label: string; disabled: boolean }[] = [
        { id: 'SERVICES', label: 'Servicios', disabled: !!watchWithImageRights || formBlocked.isFormBlocked },
        { id: 'GOODS', label: 'Bienes', disabled: !!watchWithImageRights || formBlocked.isFormBlocked },
    ];

    const handleChange = () => {
        const lineValues = getValues(`lines.${index}`);

        //TODO: Improve how to change the VAT instead of using a hardcoded value
        if (lineValues.productType === 'GOODS' && lineValues.vat === 5) {
            handleLineChange(index, 'vat', 21);
        }
    };

    return (
        <ControlledFormField
            control={control}
            name={`lines.${index}.productType`}
            Component={({ field, formState: { errors } }) => (
                <SelectorGroup
                    tooltipOptions={tooltipOptions}
                    error={!!errors?.lines?.[index]?.productType?.message}
                    helperText={errors?.lines?.[index]?.productType?.message}
                    id={`sales-document-concept-type-${index}`}
                    label="Tipo"
                    required
                    onChange={(newSelectedIds) => {
                        handleChange();
                        TrackingEvents.option({
                            option_label: `Selecciona ${newSelectedIds[0] === 'GOODS' ? 'Bienes' : 'Servicios'}`,
                        });
                        handleLineChange(index, 'productType', newSelectedIds[0]);
                    }}
                    orientation="horizontal"
                    selectors={selectors.map((selector) => ({
                        ...selector,
                        checked: selector.id === field.value,
                    }))}
                    variant="radio"
                />
            )}
        />
    );
};
