import V2Repository from './V2Repository';

export default class UserRepository extends V2Repository {
    storeLeadScoreAutonomo(data) {
        return this.put(
            '/leadScore/autonomo/onboarding',
            JSON.stringify({
                data,
            }),
        );
    }

    getLeadScoreAutonomo() {
        return this.get('/leadScore/autonomo/onboarding');
    }

    storeLeadScoreNoAutonomo(data) {
        return this.put(
            '/leadScore/no-autonomo/onboarding',
            JSON.stringify({
                data,
            }),
        );
    }

    getLeadScoreNoAutonomo() {
        return this.get('/leadScore/no-autonomo/onboarding');
    }
}
