import { css, DropdownMenu, Icon, styled } from '@declarando/design_system';
import { memo, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RootContext } from 'shared/UI/context/RootContext/RootContext';
import RepositoryFactory from 'shared/infra/repositories/RepositoryFactory';
import { IngresoSimplificadoValidador } from 'features/Incomes/domain/IngresoSimplificadoValidador';
import EquivalenceSurcharge from '../ListOptions/EquivalenceSurcharge';
import IvaExempt from '../ListOptions/IvaExempt';
import { ModalPickDateNavigateDifferentForms } from 'features/Incomes/UI/ModalPickDateNavigateDifferentForms/ModalPickDateNavigateDifferentForms';

export const AddDropdown = memo(({ openPopUpContrata, navbarHeight }) => {
    const repositoryFactory = new RepositoryFactory();
    const incomePaths = repositoryFactory.getIncomesLocations();
    const { planHelper, userStatus } = useContext(RootContext);
    const [showModalPickDateToNavigateDifferentInvoiceForms, setShowModalPickDateToNavigateDifferentInvoiceForms] =
        useState(false);
    const history = useHistory();
    const onSelect = (option) => {
        if (option.metric === 'addGasto' && !hasIAE(userStatus.iaes)) {
            return history.push('/gastos/gastos#action=noIAE');
        }
        history.push(option.path);
    };

    const hasIaeRG = new IngresoSimplificadoValidador().hasIaeRG(userStatus.iaes);

    const list = [
        {
            sectionName: 'Ingresos',
        },
        {
            children: 'Añadir ingreso con factura',
            icon: 'file-check-alt',
            onClick: () => setShowModalPickDateToNavigateDifferentInvoiceForms(true),
        },
        {
            children: 'Añadir ingreso con factura simplificada ',
            description: 'Solo para ingresos inferiores a 400€',
            icon: 'file-check',
            onClick: () => {
                onSelect({
                    metric: 'ingresoSimplificada',
                    event: 'Empieza Informar Ingreso con factura simplificada',
                    path: incomePaths.getNewSimpleIncomeUrl(),
                });
            },
            disabled: !hasIaeRG,
        },
        {
            children: 'Añadir ingreso sin factura',
            icon: 'file-times-alt',
            onClick: () => {
                onSelect({
                    metric: 'ingresoSinFactura',
                    event: 'Empieza Informar Ingreso sin factura',
                    path: incomePaths.getNewUninvoicedIncomeUrl(),
                });
            },
            disabled: !(
                userStatus.equivalence !== EquivalenceSurcharge.NINGUNO || userStatus.ivaExempt !== IvaExempt.NINGUNO
            ),
        },
        {
            children: 'Añadir ingreso de subvención ',
            icon: 'file-bookmark-alt',
            divider: 'bottom',
            onClick: () => {
                onSelect({
                    metric: 'ingresoSubvencion',
                    event: 'Empieza Informar Ingreso subvención',
                    path: incomePaths.getNewSubvencionIncomeUrl(),
                });
            },
        },
        {
            sectionName: 'Presupuestos',
        },
        {
            children: 'Añadir presupuesto',
            icon: 'file-edit-alt',
            divider: 'bottom',
            onClick: () => {
                onSelect({
                    metric: 'presupuesto',
                    event: 'Empieza Informar Presupuesto',
                    path: repositoryFactory.getQuotationsLocations().getNewQuotationUrl(),
                });
            },
        },
        {
            sectionName: 'Gastos',
        },
        {
            tag: 'addGasto',
            children: 'Añadir gasto con/sin factura',
            icon: 'file-alt',
            onClick: () => {
                onSelect({
                    metric: 'addGasto',
                    event: 'Empieza Gasto',
                    path: repositoryFactory.getExpensesLocations().getNewGastoUrl(),
                });
            },
        },
    ];

    const filteredList = list.filter((option) => [undefined, false].includes(option?.disabled));

    const MenuContainer = styled.div(
        css({
            height: 'xxl',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: 'sm',
            minWidth: 'xxl',
        }),
    );

    const hasIAE = (iaes) => {
        if (iaes.length === 0) return false;
        const iaesReduce = iaes.reduce((item, item2) => {
            if (item.id) return item.id;
            else if (item2.id) return item2.id;
            else return null;
        });
        return iaesReduce && iaesReduce.id !== null;
    };

    return planHelper.isAccountingDisabledForFree() ? (
        <MenuContainer onClick={openPopUpContrata}>
            <Icon name="plus" fontSize="xl" color="contentStatus.Default" />
        </MenuContainer>
    ) : (
        <>
            <DropdownMenu
                className={'new'}
                triggerType="button"
                triggerProps={{ icon: 'plus', size: 'M', isIconOnly: true }}
                position="right"
                options={filteredList}
                mobileTopPosition={navbarHeight}
            />
            <ModalPickDateNavigateDifferentForms
                isOpen={showModalPickDateToNavigateDifferentInvoiceForms}
                onClose={() => setShowModalPickDateToNavigateDifferentInvoiceForms(false)}
            />
        </>
    );
});
