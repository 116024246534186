import React from 'react';
import NeighborhoodForm from './NeighborhoodForm';
import LocationsRepository from 'shared/infra/repositories/LocationsRepository';
import { Box, Grid, Input, Select } from '@declarando/design_system';

const t_errorViaType = 'Debes introducir el tipo de vía';
const t_errorViaName = 'Debes introducir el nombre de la via';
const t_errorViaNumber = 'Debes introducir el número, o S/N';
const t_labelViaType = 'Tipo de vía';
const t_placeholderFilter = 'Filtra aquí...';
const t_labelViaName = 'Nombre de la vía';
const t_labelViaNumber = 'Número';
const t_labelViaBlock = 'Bloque';
const t_labelViaPortal = 'Portal';
const t_labelViaStair = 'Escalera';
const t_labelViaFloor = 'Piso';
const t_labelViaDoor = 'Puerta';
// NeighborhoodForm
const t_errorTown = 'Debes introducir la población';
const t_errorProvince = 'Debes introducir la provincia';
const t_errorCP = 'Debes introducir un código postal válido';
const t_labelProvince = 'Provincia';
const t_labelTown = 'Población';
const t_labelPostalCode = 'Código Postal';

class DireccionFormPart extends React.Component {
    constructor(props) {
        super(props);

        this.locationsRepository = new LocationsRepository();
        this.componentRef = null;

        this.state = {
            viaTypes: [],
            errorTipoVia: '',
            errorNombreVia: '',
            errorNumero: '',
        };
    }

    componentDidMount() {
        this.loadViaTypes();
    }

    loadViaTypes() {
        this.locationsRepository.getViaTypes().then((response) => {
            this.setState({
                viaTypes: response.data,
            });
        });
    }

    getTowns = (province) => {
        return this.locationsRepository.getTowns(province);
    };

    getProvinces = () => {
        return this.locationsRepository.getProvinces(true);
    };

    isValidated() {
        const validation = this._validateData();

        const errors = this._validationErrors(validation);

        this.setState({
            errorTipoVia: errors.tipoViaValMsg,
            errorNombreVia: errors.nombreViaValMsg,
            errorNumero: errors.numeroValMsg,
        });

        return (
            validation.tipoViaVal && validation.nombreViaVal && validation.numeroVal && this.componentRef.isValidated()
        );
    }

    _validateData() {
        return {
            tipoViaVal: this.props.address.tipoVia && this.props.address.tipoVia.length > 0,
            nombreViaVal: this.props.address.nombreVia && this.props.address.nombreVia.length > 0,
            numeroVal:
                this.props.address.numero &&
                this.props.address.numero.length > 0 &&
                (!isNaN(this.props.address.numero) || this.props.address.numero === 'S/N'),
        };
    }

    _validationErrors(obj) {
        return {
            tipoViaValMsg: obj.tipoViaVal ? '' : t_errorViaType,
            nombreViaValMsg: obj.nombreViaVal ? '' : t_errorViaName,
            numeroValMsg: obj.numeroVal ? '' : t_errorViaNumber,
        };
    }

    render() {
        return (
            <div>
                <Grid gridTemplateColumns={['1fr', '1fr 1fr 1fr']} gridRowGap="md" gridColumnGap="sm">
                    <Select
                        required
                        label={t_labelViaType}
                        name="tipoVia"
                        id={'select-tipoVia-' + this.props.formName}
                        value={this.props.address.tipoVia}
                        options={this.state.viaTypes}
                        clearable={false}
                        placeholder={t_placeholderFilter}
                        onChange={(value) => this.props.updateField('tipoVia', value)}
                        error={!!this.state.errorTipoVia}
                        message={this.state.errorTipoVia}
                    />
                    <Input
                        required
                        label={t_labelViaName}
                        type="text"
                        name={'nombreVia-' + this.props.formName}
                        value={this.props.address.nombreVia}
                        onChange={(e) => this.props.updateField('nombreVia', e.target.value)}
                        error={this.state.errorNombreVia}
                        message={this.state.errorNombreVia}
                    />
                    <Input
                        required
                        label={t_labelViaNumber}
                        type="text"
                        name={'numero-' + this.props.formName}
                        value={this.props.address.numero}
                        onChange={(e) => this.props.updateField('numero', e.target.value)}
                        error={!!this.state.errorNumero}
                        message={this.state.errorNumero}
                    />
                    <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridRowGap="md" gridColumnGap="sm">
                        <Box>
                            <Input
                                label={t_labelViaBlock}
                                type="text"
                                name={'bloque-' + this.props.formName}
                                value={this.props.address.bloque}
                                onChange={(e) => this.props.updateField('bloque', e.target.value)}
                            />
                        </Box>
                        <Box>
                            <Input
                                label={t_labelViaPortal}
                                noPB
                                type="text"
                                name={'portal-' + this.props.formName}
                                value={this.props.address.portal}
                                onChange={(e) => this.props.updateField('portal', e.target.value)}
                            />
                        </Box>
                    </Grid>
                    <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridRowGap="md" gridColumnGap="sm">
                        <Box>
                            <Input
                                label={t_labelViaStair}
                                noPB
                                type="text"
                                name={'escalera-' + this.props.formName}
                                value={this.props.address.escalera}
                                onChange={(e) => this.props.updateField('escalera', e.target.value)}
                            />
                        </Box>
                        <Box>
                            <Input
                                noPB
                                label={t_labelViaFloor}
                                type="text"
                                name={'piso-' + this.props.formName}
                                value={this.props.address.piso}
                                onChange={(e) => this.props.updateField('piso', e.target.value)}
                            />
                        </Box>
                    </Grid>
                    <Box>
                        <Input
                            label={t_labelViaDoor}
                            noPB
                            type="text"
                            name={'puerta-' + this.props.formName}
                            value={this.props.address.puerta}
                            onChange={(e) => this.props.updateField('puerta', e.target.value)}
                        />
                    </Box>
                </Grid>
                <Box mt={'md'}>
                    <NeighborhoodForm
                        required={true}
                        labelErrorTown={t_errorTown}
                        labelErrorProvince={t_errorProvince}
                        labelErrorCP={t_errorCP}
                        labelProvince={t_labelProvince}
                        labelTown={t_labelTown}
                        labelPostalCode={t_labelPostalCode}
                        placeholderFilter={'Filtrar aquí...'}
                        getProvinces={this.getProvinces}
                        getTowns={this.getTowns}
                        formName={this.props.formName}
                        cp={this.props.address.cpostal}
                        province={this.props.address.provincia_id}
                        town={this.props.address.poblacion_id}
                        handleProvinciaChange={(value, cbk) => this.props.updateField('provincia_id', value, cbk)}
                        handlePoblacionChange={(value) => {
                            this.props.updateField('poblacion_id', value);
                        }}
                        handleCpChange={(value, cbk) => {
                            this.props.updateField('cpostal', value, cbk);
                        }}
                        ref={(ref) => {
                            if (ref) this.componentRef = ref;
                        }}
                        {...this.props}
                    />
                </Box>
            </div>
        );
    }
}

DireccionFormPart.defaultProps = {
    formName: Math.floor(Math.random() * 10000) + 1,
};

export default DireccionFormPart;
