import { OnboardingSteps, ValueOfOnboardingSteps } from '../domain/OnboardingSteps';
import { TNoAFFANavigationKey } from './hooks/useOnboarding';

export const StepNavigationMapper: { [key in ValueOfOnboardingSteps]: TNoAFFANavigationKey } = {
    [OnboardingSteps.BIENVENIDA]: 0,
    [OnboardingSteps.CERTIFICADO_INICIO]: 1,
    [OnboardingSteps.CERTIFICADO_EN_TRAMITE]: 1,
    [OnboardingSteps.CERTIFICADO_PROCESANDO_PAGO]: 1,
    [OnboardingSteps.DOCUMENTACION_INICIO]: 2,
    [OnboardingSteps.DOCUMENTACION_EN_REVISION]: 3,
    [OnboardingSteps.CONTABILIDAD_INICIO]: 4,
    [OnboardingSteps.CONTABILIDAD_PROCESANDO_PAGO]: 4,
    [OnboardingSteps.CONTABILIDAD_PAGADA]: 4,
    [OnboardingSteps.CONTABILIDAD_EN_REVISION]: 4,
    [OnboardingSteps.CONTABILIDAD_REVISADA]: 4,
    [OnboardingSteps.FINALIZADO]: 4,
};
