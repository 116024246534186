import { QuoteDTO } from './QuoteDTO';
import { Quote } from '../domain/Quote';
import { toInvoice, toInvoiceDTO } from '../../IngresoConFactura/adapters/InvoiceAdapter';

export function toQuote(dto: QuoteDTO): Quote {
    const base = toInvoice(dto);
    return {
        ...base,
        transformedToInvoiceId: dto.transformedToInvoiceId,
    };
}

export const toQuoteDTO = (quote: Quote): QuoteDTO => {
    console.log('Quote before transforming to DTO', quote);
    const baseDTO = toInvoiceDTO(quote);
    return {
        ...baseDTO,
        transformedToInvoiceId: quote.transformedToInvoiceId,
    };
};
