import V2Repository from 'shared/infra/repositories/V2Repository';

export interface NotificationsSavedConfigResponse {
    success: boolean;
    data: boolean;
    message: string;
}

export interface NotificationsConfigResponse {
    notificaciones: { ingresos_sms_tarjetas: boolean; gastos_sms_tarjetas: boolean };
}

class NotificationsRepository extends V2Repository {
    async retrieveNotifications(): Promise<NotificationsConfigResponse> {
        return this.get('/notificaciones').catch((e) => e);
        //return await import("../mocks/notifications.json").catch(e => e);
    }

    async saveNotifications(name: string, status: boolean): Promise<NotificationsSavedConfigResponse> {
        return this.post(`/notificaciones/sms/${name}/${status ? 1 : 0}`).catch((e) => e);
        //return await import("../mocks/save_notification.json");
    }
}

export default NotificationsRepository;
