import { Switch } from 'react-router-dom';

import { AdvicesBarSections } from './AdvicesBarSections';
import React from 'react';
import AdvicesRouter from './AdvicesRoute';

export const AdvicesSection = ({ routes, userName }) => {
    return (
        <Switch>
            {routes.map((prop, key) => {
                let adviseSectionDescription;

                if (prop.advicesSection) {
                    switch (prop.advicesSection) {
                        case 'incomes':
                            adviseSectionDescription = <>Consejos para optimizar tus ingresos</>;
                            break;
                        case 'expenses':
                            adviseSectionDescription = <>Consejos para optimizar tus gastos</>;
                            break;
                        case 'rent':
                            adviseSectionDescription = <>Consejos para optimizar tu renta</>;
                            break;
                        default:
                            adviseSectionDescription = '';
                            break;
                    }
                    return (
                        <AdvicesRouter
                            path={prop.path}
                            key={key}
                            render={() => (
                                <AdvicesBarSections
                                    advicesSection={prop.advicesSection}
                                    title={`¡Hola ${userName}!`}
                                    description={adviseSectionDescription}
                                />
                            )}
                        />
                    );
                }
                return null;
            })}
        </Switch>
    );
};
