class AccountNumber {
    static calculateControlDigit(value) {
        const vals = [1, 2, 4, 8, 5, 10, 9, 7, 3, 6];
        const candidate = vals.reduce((acc, weight, index) => acc + weight * value[index], 0);

        const control = 11 - (candidate % 11);

        return (
            {
                11: '0',
                10: '1',
            }[control] || `${control}`
        );
    }

    static validateAccountDC({ office, entity, accountNumber, control }) {
        return (
            control === this.calculateControlDigit(`00${entity}${office}`) + this.calculateControlDigit(accountNumber)
        );
    }

    static accountHasValidLengths({ office, entity, accountNumber, control }) {
        return accountNumber.length === 10 && control.length === 2 && office.length === 4 && entity.length === 4;
    }

    static calculateIbanFromAccount(account) {
        if (AccountNumber.accountHasValidLengths(account)) {
            const { entity, office, control, accountNumber } = account;
            const ccc = `${entity}${office}${control}${accountNumber}`;

            const countryCode = 'ES';
            const convertedCountryCode = `${countryCode.charCodeAt(0) - 55}${countryCode.charCodeAt(1) - 55}`;

            const cccEx = `${ccc}${convertedCountryCode}00`;

            const ibanControlDigit = 98 - parseInt(AccountNumber.module(cccEx, 97), 10);

            const zero = ibanControlDigit < 10 ? 0 : '';
            const newIban = `${countryCode}${zero}${ibanControlDigit}`;

            return `${newIban}${ccc}`;
        } else {
            return '';
        }
    }

    static module(cyphers, divider) {
        return cyphers.match(/.{1,10}/g).reduce((acc, el) => `${acc}${el}` % divider, 0);
    }

    static calculateAccountFromIban(iban) {
        return {
            entity: iban.substring(4, 8),
            office: iban.substring(8, 12),
            control: iban.substring(12, 14),
            accountNumber: iban.substring(14, 24),
        };
    }
}

export default AccountNumber;
