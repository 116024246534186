import { Box, SelectorGroup } from '@declarando/design_system';
import { ExpandableContent } from '../../../../shared/components/ExpandableContent';
import { useShouldShowImageRightsQuestion } from './useShouldShowImageRightsQuestion';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { useSalesDocumentLineCalculations } from '../../../../hooks/useSalesDocumentCalculations';

const ImageRightsQuestionContent = () => {
    const { control, getValues } = useCustomFormContext<InvoiceForm>();
    const { formBlocked } = useSalesDocumentFormContext();
    const { handleLineChange } = useSalesDocumentLineCalculations();

    const handleChange = () => {
        const linesValues = getValues(`lines`);

        //TODO: Improve how to change the VAT instead of using a hardcoded value
        linesValues.forEach((lineValues, index) => {
            handleLineChange(index, 'productType', 'SERVICES' as const);

            if (lineValues.vat === 5) {
                handleLineChange(index, 'vat', 21);
            }
        });
    };

    return (
        <ControlledFormField
            name="activity.withImageRights"
            control={control}
            Component={({ field }) => (
                <SelectorGroup
                    label="¿Estás facturando derechos de explotación de imagen?"
                    required
                    tooltipOptions={{
                        title: 'Derechos de explotación de imagen',
                        text: (
                            <>
                                Son los derechos de captar y reproducir por cualquier medio aquellos rasgos que hacen
                                reconocible a una persona (imagen, nombre y voz).{' '}
                                <strong>Solo aplica a los servicios.</strong>
                            </>
                        ),
                        link: {
                            text: 'Saber más',
                            href: 'https://ayuda.declarando.es/es/articles/7732144-como-facturar-derechos-de-imagen',
                        },
                    }}
                    variant="radio"
                    selectors={[
                        { label: 'Sí', id: 'yes', checked: field.value === true, disabled: formBlocked.isFormBlocked },
                        { label: 'No', id: 'no', checked: field.value === false, disabled: formBlocked.isFormBlocked },
                    ]}
                    {...field}
                    onChange={(selectedIds) => {
                        field.onChange(selectedIds[0] === 'yes');
                        handleChange();
                    }}
                />
            )}
        />
    );
};

export const ImageRightsQuestion = () => {
    const { shouldShowImageRightsQuestion } = useShouldShowImageRightsQuestion();

    return (
        <ExpandableContent shouldExpand={shouldShowImageRightsQuestion}>
            <Box marginTop="md">
                <ImageRightsQuestionContent />
            </Box>
        </ExpandableContent>
    );
};
