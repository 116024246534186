import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Navbar } from 'shared/UI/components/Navbar/Navbar';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';

export const ConditionalNavbar = ({
    isAccountingDisabledForFree,
    openPopUpContrata,
    openPopUpNotEnabled,
    roles,
}: {
    isAccountingDisabledForFree: boolean;
    openPopUpContrata: () => void;
    openPopUpNotEnabled: () => void;
    roles: string[];
}) => {
    const { hideNavbar, setHideNavbar, isFree, isPeriodoDemo, userFreeNotPeriodoDemo, userInfo, userStatus } =
        useRootContext();
    const { pathname } = useLocation();
    const history = useHistory() as any;

    useEffect(() => {
        if (userFreeNotPeriodoDemo && pathname !== '/inicio') {
            setHideNavbar(true);
        } else {
            setHideNavbar(false);
        }
    }, [isFree, isPeriodoDemo, userFreeNotPeriodoDemo, pathname]);

    return hideNavbar ? null : (
        <Navbar
            history={history}
            isAccountingEnabled={userStatus.accounting}
            isAccountingDisabledForFree={isAccountingDisabledForFree}
            openPopUpContrata={openPopUpContrata}
            openPopUpNotEnabled={openPopUpNotEnabled}
            userInfo={userInfo}
            userStatus={userStatus}
            roles={roles}
        />
    );
};
