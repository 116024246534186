import { Box, SelectorGroup, Divider } from '@declarando/design_system';
import { ClientOption } from '../ClientSelection';

export const FrequentClientList: React.FC<{
    frequentClientOptions: ClientOption[];
    setSelectedIds: (ids: string[]) => void;
    showDivider: boolean;
}> = ({ frequentClientOptions, setSelectedIds, showDivider }) => (
    <>
        {frequentClientOptions.length > 0 && (
            <>
                <Box marginBottom="md">
                    <SelectorGroup
                        label="Clientes usados recientemente"
                        selectors={frequentClientOptions}
                        orientation="vertical"
                        onChange={setSelectedIds}
                    />
                </Box>
                {showDivider && (
                    <Box marginY="sm">
                        <Divider />
                    </Box>
                )}
            </>
        )}
    </>
);
