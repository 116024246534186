import V2Repository from 'shared/infra/repositories/V2Repository';
import { SalesDocumentPDFRepository } from '../../shared/domain/SalesDocumentPDF';

class QuotePDFRepository extends V2Repository implements SalesDocumentPDFRepository {
    getDocumentPDF(id: string): Promise<any> {
        return this.getBlobResponse(`/quotes/${id}/document`);
    }
}

export const createQuotePDFRepository = (): SalesDocumentPDFRepository => {
    return new QuotePDFRepository();
};
