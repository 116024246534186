import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { InvoiceFrequentClientsRepository } from '../domain/InvoiceFrequentClientsRepository';

class InvoiceFrequentClientsService extends DeclarandoApiService {
    private repository: InvoiceFrequentClientsRepository;

    constructor(repository: InvoiceFrequentClientsRepository) {
        super();
        this.repository = repository;
    }

    getFrequentClients() {
        return this.execute(() => this.repository.getFrequentClients(), { shouldThrowError: true });
    }
}

export const createInvoiceFrequentClientsService = (
    repository: InvoiceFrequentClientsRepository,
): InvoiceFrequentClientsService => {
    return new InvoiceFrequentClientsService(repository);
};
