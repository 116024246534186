import Quarters from '../../UI/components/ListOptions/Quarters';
import DateWrapper from './Date';

const LAST_APRIL_DAY = 20;
const LAST_JULY_DAY = 21;
const LAST_OCTOBER_DAY = 20;

export default class FiscalDates {
    static getFiscalYear(date = DateWrapper.getNativeDateNow()) {
        let year = date.getFullYear();
        const fecha = date.getMonth() === 0 ? --year : year;
        return fecha;
    }

    static getFiscalQuarter(date = DateWrapper.getNativeDateNow()) {
        const year = date.getFullYear();

        const firstQuarterStart = new Date(`${year}-02-01`);
        const secondQuarterStart = new Date(`${year}-04-${LAST_APRIL_DAY + 1}`);
        const thirdQuarterStart = new Date(`${year}-07-${LAST_JULY_DAY + 1}`);
        const fourthQuarterStart = new Date(`${year}-10-${LAST_OCTOBER_DAY + 1}`);

        if (date > fourthQuarterStart || date < firstQuarterStart) {
            return Quarters.fourth;
        } else if (date > thirdQuarterStart) {
            return Quarters.third;
        } else if (date > secondQuarterStart) {
            return Quarters.second;
        } else {
            return Quarters.first;
        }
    }

    static IsBeforeRenta(date = DateWrapper.getNativeDateNow()) {
        return date.getMonth() < 6;
    }

    static isTaxesPeriod(date = DateWrapper.getNativeDateNow()) {
        const month = date.getMonth();
        const day = date.getDate();

        return (
            month === 0 ||
            (month === 3 && day <= LAST_APRIL_DAY) ||
            (month === 6 && day <= LAST_JULY_DAY) ||
            (month === 9 && day <= LAST_OCTOBER_DAY)
        );
    }
    static getCurrentQuarter() {
        const mesActual = new Date().getMonth();
        const trimestreActual = Math.floor(mesActual / 3) + 1;
        return trimestreActual + 'T';
    }
}
