/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, InputPhone, ModalChoice, P } from '@declarando/design_system';

import RepositoryFactory from 'shared/infra/repositories/RepositoryFactory';
import Validators from 'shared/infra/utils/Validators';
import { Planes } from 'shared/domain/constants/Planes';

declare const Intercom: any;

type Props = {
    prefijo: number;
    phone: string;
    blocked: boolean;
    show: boolean;
    cancelAction?: any;
    plan: string;
    onValidatedPhone?: (prefijo: number, phone: string) => any;
    limitDate: string;
};

enum Statuses {
    initial,
    error,
    success,
}

const PrefixError = <>El prefijo no es válido</>;
const PhoneExistError = <>El teléfono ya existe</>;
const PhoneNotValidError = <>El teléfono no es válido</>;
const ESP_PREFIX = 34;

export const ModalConfirmationPhone = ({
    limitDate,
    onValidatedPhone = (prefijo, number) => null,
    prefijo: initPrefijo,
    phone: initPhone,
    blocked = false,
    show: open,
    plan,
    cancelAction,
}: Props) => {
    const [status, setStatus] = useState<Statuses>(Statuses.initial);
    const [show, setShow] = useState<boolean>(false);
    const [prefijo, setPrefijo] = useState<number>(initPrefijo || ESP_PREFIX);
    const [phone, setPhone] = useState<string>(initPhone);
    const [valid, setValid] = useState<boolean | null>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const phoneRepository = RepositoryFactory.instance.getPhoneRepository();

    useEffect(() => {
        setShow(open);
    }, [open]);

    useEffect(() => {
        setPhone(initPhone);
    }, [initPhone]);

    useEffect(() => {
        setError(null);
        const validPrefix: boolean = Validators.isValidPrefix(prefijo);
        if (!validPrefix) {
            setValid(false);
            return setError(PrefixError);
        }
        const validPhone: boolean = Validators.isValidPhone(prefijo, phone) as boolean;
        setValid(validPhone);
        if (validPhone && phone !== initPhone) {
            setSending(true);
            phoneRepository
                .checkExistPhone(prefijo, phone)
                .then((result: { exists: boolean }) => {
                    setValid(!result.exists);
                    if (result.exists) {
                        setError(PhoneExistError);
                    }
                })
                .catch((e: any) => {
                    setValid(false);
                })
                .finally(() => setSending(false));
        }
    }, [phone, prefijo]);

    const labelConfirnBtn = <>Verificar móvil</>;
    const labelCancelBtn = blocked ? <>Solicitar ayuda</> : <>Cancelar</>;

    const helpHandler = () => {
        if (!sessionStorage.getItem('plan') && (plan === Planes.PLUS || plan === Planes.TOTAL)) {
            return Intercom(
                'showNewMessage',
                'Tengo la cuenta bloqueada por no haber verificado mi número de móvil y necesito ayuda.',
            );
        }

        let mail = 'soporte@declarando';
        if (plan === Planes.FREE || sessionStorage.getItem('plan') === 'free') {
            mail = 'hola@declarando';
        }
        const body = 'Tengo la cuenta bloqueada por no haber verificado mi número de móvil y necesito ayuda.';
        const subject = 'Tengo la cuenta bloqueada, necesito ayuda';
        window.location.href = `mailto:${mail}?subject=${subject}&body=${body}`;
    };

    const cancelHandler = () => setShow(false);

    const handleCancel = blocked ? helpHandler : cancelAction ? cancelAction : cancelHandler;

    const confirmPhone = async () => {
        if (!phone || !valid) {
            return;
        }
        setSending(true);
        const result = await phoneRepository.storePhone(prefijo, phone);
        setSending(false);

        if (result.data.valid) {
            setStatus(Statuses.success);
            setTimeout(() => setShow(false), 3000);
            onValidatedPhone(prefijo, phone);
        } else {
            setError(PhoneNotValidError);
        }
    };

    return (
        <ModalChoice
            labelAction={labelConfirnBtn}
            labelCancel={labelCancelBtn}
            isOpen={show}
            busy={sending}
            title={<>Verifica tu número de móvil</>}
            disabledAction={!valid}
            handleAction={confirmPhone}
            handleCancel={handleCancel}
            successChildren={<div>Success</div>}
            success={status === Statuses.success}
        >
            <P small pb="sm">
                <>
                    Desde Declarando queremos siempre ofrecerte el servicio más eficiente y seguro. Por eso te pedimos
                    que confirmes tú número de móvil.
                </>
            </P>
            {blocked ? (
                <P small danger pb="sm">
                    La verificación es imprescindible para continuar con el uso de la plataforma.
                </P>
            ) : (
                <P small pb="sm">
                    Tienes tiempo para hacerlo hasta {limitDate}. Una vez pasado este tiempo, la cuenta se bloqueará
                    hasta que confirmes el número de móvil.
                </P>
            )}
            <Box mb="sm">
                <InputPhone
                    label="Teléfono móvil"
                    placeholder="Introduce tu número de teléfono"
                    id="phone"
                    prefixValue={prefijo}
                    numberValue={phone}
                    onChangePhonenumber={(phone: string) => setPhone(phone)}
                    onChangePrefixPhone={(prefijo: number) => setPrefijo(prefijo)}
                    error={Boolean(error)}
                    message={error}
                />
            </Box>
        </ModalChoice>
    );
};
