import * as React from 'react';
import { useContext } from 'react';
import { AlertMessage, Box, Button, FileuploaderSection, P, Section } from '@declarando/design_system';

import { Step3Context } from './Step3Context';
import {
    publishCargarFacturasOtrosFormatosEvent,
    publishPagarCosteSubidaContabilidadEvent,
    publishSubirContabilidadOtrosFormatosEvent,
} from '../../../services/events';

export const Step3TabOtrosFormatos = () => {
    const { errorFile, isPagada, solicitudPagoContabilidad, importarContabilidad, setSelectedFile, file } =
        useContext(Step3Context);

    const subirContabilidad = () => {
        importarContabilidad();
        publishSubirContabilidadOtrosFormatosEvent();
    };
    const pagarCosteAdicionalClick = () => {
        solicitudPagoContabilidad();
        publishPagarCosteSubidaContabilidadEvent();
    };

    const selecionarArchivo = (file: File | null) => {
        setSelectedFile(file);
        publishCargarFacturasOtrosFormatosEvent();
    };

    const instructions = [
        'Paga el coste adicional del servicio.',
        'Crea una carpeta comprimida (.zip o .rar) con toda la documentación de tu contabilidad: excel ingresos/gastos, facturas en PDF, etc.',
        'Selecciona o arrastra el archivo para importar tu contabilidad.',
    ];

    return (
        <>
            <Section mt={'sm'}>
                {isPagada ? (
                    <AlertMessage
                        tone={'success'}
                        full
                        title={'Has pagado el coste adicional de 121€ (IVA incl.)'}
                        message={'Sube los archivos de tu contabilidad para que nuestro equipo la importe.'}
                    />
                ) : (
                    <AlertMessage
                        full
                        title={'Este servicio lleva un coste adicional de 121€ (IVA incl.)'}
                        message={'Nuestro equipo procesará e importará manualmente tu contabilidad'}
                    />
                )}

                <Box display="flex" flexDirection="column" marginLeft="8px">
                    {instructions.map((text, index) => (
                        <Box key={index} display="flex" gap={index === 0 ? '7px' : '3px'}>
                            <P>{index + 1}.</P>
                            <P>{text}</P>
                        </Box>
                    ))}
                </Box>

                <FileuploaderSection
                    disabled={!isPagada}
                    uploading={false}
                    icon={'upload'}
                    actionLabel="Selecciona el archivo o arrástralo aquí"
                    filecopy="El archivo debe ser un zip o un rar y no exceder los 5MB"
                    formats={[
                        'x-compressed',
                        'zip',
                        'x-zip-compressed',
                        'zip-compressed',
                        'rar',
                        'x-rar',
                        'x-rar-compressed',
                        'rar-compressed',
                        'vnd.rar',
                    ]}
                    loaderLabel="Subiendo archivo"
                    maxMb={5}
                    onChangeFile={selecionarArchivo}
                    errorMessage={errorFile}
                />
            </Section>
            <Box
                mt={'lg'}
                display={{ _: 'grid', desktop: 'flex' }}
                gridTemplateColumns={{ _: '1fr', tablet: 'auto' }}
                justifyContent={'center'}
                gridGap={'xs'}
            >
                {isPagada ? (
                    <Button label="Subir mi contabilidad" disabled={!file} onClick={subirContabilidad} />
                ) : (
                    <Button label="Pagar el coste adicional" onClick={pagarCosteAdicionalClick} />
                )}
            </Box>
        </>
    );
};
