export type ErrorEvent = {
    message: string;
    stack?: string;
    context?: Record<string, any>;
};

export type ErrorReporter = {
    init: () => void;
    captureError: (error: ErrorEvent, context?: Record<string, any>) => void;
    captureMessage: (message: string, context?: Record<string, any>) => void;
    setUserInfo: (userInfo: Record<string, any>) => void;
};

export class ErrorReporting {
    private static instance: ErrorReporting;
    private reporter: ErrorReporter;

    private constructor(reporter: ErrorReporter) {
        this.reporter = reporter;
    }

    static getInstance(reporter: ErrorReporter): ErrorReporting {
        if (!ErrorReporting.instance) {
            ErrorReporting.instance = new ErrorReporting(reporter);
        }

        return ErrorReporting.instance;
    }

    init() {
        this.reporter.init();
    }

    captureError(error: ErrorEvent, context?: Record<string, any>) {
        this.reporter.captureError(error, context);
    }

    captureMessage(message: string, context?: Record<string, any>) {
        this.reporter.captureMessage(message, context);
    }

    setUserInfo(userInfo: Record<string, any>) {
        this.reporter.setUserInfo(userInfo);
    }
}
