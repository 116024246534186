import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { GetPrintedQuotesListParams, GetQuotesListParams, QuoteRepository } from '../domain/QuoteRepository';
import { Quote } from '../domain/Quote';

class QuoteService extends DeclarandoApiService {
    private repository: QuoteRepository;

    constructor(repository: QuoteRepository) {
        super();
        this.repository = repository;
    }

    createQuote(quote: Quote) {
        return this.execute(() => this.repository.create(quote), { shouldThrowError: true });
    }

    editQuote(quote: Quote) {
        return this.execute(() => this.repository.edit(quote), { shouldThrowError: true });
    }

    deleteQuote(id: string) {
        return this.execute(() => this.repository.deleteQuote(id), { shouldThrowError: true });
    }

    getQuoteByUUID(id: string) {
        return this.execute(() => this.repository.getByUUID(id), { shouldThrowError: true });
    }

    getQuotesList(args?: GetQuotesListParams) {
        return this.execute(() => this.repository.getQuotesList(args), { shouldThrowError: true, bodyAsData: true });
    }

    getPrintedList(args: GetPrintedQuotesListParams) {
        return this.execute(() => this.repository.getPrintedList(args), { shouldThrowError: true });
    }

    convertToInvoice(args: { invoiceId: string; quoteId: string }) {
        return this.execute(() => this.repository.convertToInvoice(args), { shouldThrowError: true });
    }
}

export const createQuoteService = (repository: QuoteRepository): QuoteService => {
    return new QuoteService(repository);
};
