import V2Repository from 'shared/infra/repositories/V2Repository';
import { InvoiceFrequentClientsRepository as InvoiceFrequentClientsRepositoryInterface } from '../domain/InvoiceFrequentClientsRepository';
import { ClientDTO } from '../adapters/ClientDTO';

class InvoiceFrequentClientsRepository extends V2Repository implements InvoiceFrequentClientsRepositoryInterface {
    getFrequentClients(): Promise<ClientDTO[]> {
        return this.get(`/invoices/clients`);
    }
}

export const createInvoiceFrequentClientsRepository = (): InvoiceFrequentClientsRepositoryInterface => {
    return new InvoiceFrequentClientsRepository();
};
