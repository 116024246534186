import V2Repository from 'shared/infra/repositories/V2Repository';

export default class TasksRepository extends V2Repository {
    async checkC604() {
        return this.get('/models/100/casilla604');
    }

    async saveC604(value: number) {
        return this.post('/models/100/casilla604', JSON.stringify({ value }));
    }
}
