import V2Repository from './V2Repository';

class PublicV2Repository extends V2Repository {
    constructor() {
        super();
        this.apiPath = this.apiPath + '/public';
    }
}

export default PublicV2Repository;
