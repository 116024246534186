import { Invoice } from '../../domain/Invoice';
import { useEditIngreso } from './hooks/useEditIngresoConFactura';
import { addCountryNameToClient } from 'features/Ingresos/Clientes/services/AssignLocationNameToClient';
import { Country } from 'features/Ingresos/Parameters/Locations/domain';
import { IngresoConFacturaBaseView } from '../IngresoConFacturaCommonView/IngresoConFacturaCommonView';

export const IngresoConFacturaEditView = () => {
    const { handleSubmit, isMutationPending } = useEditIngreso();

    const customizeDefaultValues = (invoiceData: Invoice, countries: Country[]) => ({
        ...invoiceData,
        client: addCountryNameToClient(invoiceData.client, countries),
    });

    return (
        <IngresoConFacturaBaseView
            mode="edit"
            title="Editar ingreso con factura"
            handleSubmit={handleSubmit}
            isMutationPending={isMutationPending}
            customizeDefaultValues={customizeDefaultValues}
        />
    );
};
