import {
    useWatch,
    Control,
    FieldValues,
    FieldPath,
    FieldPathValue,
    FieldPathValues,
    DeepPartialSkipArrayKey,
} from 'react-hook-form';

type UseWatchProps<TFieldValues extends FieldValues> = {
    control?: Control<TFieldValues>;
    defaultValue?: DeepPartialSkipArrayKey<TFieldValues>;
    disabled?: boolean;
    exact?: boolean;
};

type UseWatchFieldProps<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>> = {
    control?: Control<TFieldValues>;
    name: TFieldName;
    defaultValue?: FieldPathValue<TFieldValues, TFieldName>;
    disabled?: boolean;
    exact?: boolean;
};

type UseWatchFieldsProps<TFieldValues extends FieldValues, TFieldNames extends readonly FieldPath<TFieldValues>[]> = {
    control?: Control<TFieldValues>;
    name: readonly [...TFieldNames];
    defaultValue?: DeepPartialSkipArrayKey<TFieldValues>;
    disabled?: boolean;
    exact?: boolean;
};

export function useCustomWatch<TFieldValues extends FieldValues = FieldValues>(
    props?: UseWatchProps<TFieldValues>,
): DeepPartialSkipArrayKey<TFieldValues>;

export function useCustomWatch<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: UseWatchFieldProps<TFieldValues, TFieldName>): FieldPathValue<TFieldValues, TFieldName>;

export function useCustomWatch<
    TFieldValues extends FieldValues = FieldValues,
    TFieldNames extends readonly FieldPath<TFieldValues>[] = readonly FieldPath<TFieldValues>[],
>(props: UseWatchFieldsProps<TFieldValues, TFieldNames>): FieldPathValues<TFieldValues, TFieldNames>;

export function useCustomWatch(props?: any): any {
    return useWatch(props);
}
