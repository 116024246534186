import { PredicatorResponse } from 'shared/domain/interfaces/PredicatorInterface';
import V2Repository from 'shared/infra/repositories/V2Repository';

class AfinandoRepository extends V2Repository {
    async getPredicator(): Promise<PredicatorResponse | { values: number[] }> {
        try {
            const result = await this.get('/afinando/predictor/benefits');
            return result;
        } catch (e) {
            return { values: [] };
        }
    }

    async getIncomesPrediction(months: number = 6): Promise<PredicatorResponse | { values: any[] }> {
        try {
            const result = await this.get(`/afinando/predictor/incomes/${months}`);
            return { values: result };
        } catch (e) {
            return { values: [] };
        }
    }

    getIndicator(): Promise<any> {
        return this.get('/afinando/clientes/indicadores');
    }

    getClients(): Promise<any> {
        return this.get('/afinando/clientes/resumen');
    }

    getDefaulters(): Promise<any> {
        return this.get('/afinando/clientes/morosos');
    }

    getHaveDefaulters(): Promise<Boolean> {
        return this.get('/afinando/clientes/checkmorosos');
    }

    getIncomesSummary(month: any): Promise<any> {
        return this.get('/afinando/ingresos/resumen/' + month);
    }

    getAdvicesCaja(): Promise<{ title: string; description: string }[]> {
        return this.get('/afinando/consejos/caja');
    }

    getAdvicesIngresos(): Promise<{ title: string; description: string }[]> {
        return this.get('/afinando/consejos/ingresos');
    }

    getClientesMorosos(): Promise<{ nombre: string; cliente_email: string; id: number; checked?: boolean }[]> {
        return this.get('/afinando/clientes/morosos/select');
    }

    getMessageMorosos(): Promise<{ texto: string }> {
        return this.get('/afinando/clientes/morosos/mensaje');
    }

    setDefaultMessageMorosos() {
        return this.delete('/afinando/clientes/morosos/mensaje/setdefault', null);
    }

    sendMorosos(mensaje: string, clientes: { id: number; cliente_email: string }[], mensaje_modificado: boolean) {
        return this.post(
            '/afinando/clientes/morosos/mensajes/send',
            JSON.stringify({
                mensaje,
                clientes,
                mensaje_modificado,
            }),
        );
    }
}

export default AfinandoRepository;
