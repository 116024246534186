import { Box, Search, ActionLink } from '@declarando/design_system';

export const ClientSearchSection: React.FC<{
    searchValue: string;
    setSearchValue: (value: string) => void;
    setSelectedIds: (ids: string[]) => void;
    onAddNewClient: () => void;
}> = ({ searchValue, setSearchValue, setSelectedIds, onAddNewClient }) => (
    <Box display="flex" flexDirection="column" gap="4px">
        <Search
            value={searchValue}
            onChange={(e) => {
                setSearchValue(e.target.value);
                setSelectedIds([]);
            }}
            label="Buscar cliente"
            placeholder="Buscar por nombre del cliente o identificador fiscal"
        />
        <ActionLink label="Añadir nuevo cliente" size="M" icon="plus" iconPosition="left" onClick={onAddNewClient} />
    </Box>
);
