import React from 'react';
import { OnboardingSteps, ValueOfOnboardingSteps } from '../domain/OnboardingSteps';
import { SetupBienvenida } from './steps/Step0/SetupBienvenida';
import { Step1 } from './steps/Step1/Step1';
import { Step1TramitandoCertificado } from './steps/Step1/Step1TramitandoCertificado';
import { Step2 } from './steps/Step2/Step2';
import { Step3 } from './steps/Step3/Step3';
import { CertificadoProcesandoPago } from './ProcesandoPago/ui/CertificadoProcesandoPago';
import { StepRevision } from './steps/StepRevision/StepRevision';
import { StepRevisada } from './steps/StepFinalizado/StepRevisada';
import { StepWaitingRoom } from './steps/Step3/StepWaitingRoom';

export const OnboardingMapper: { [key in ValueOfOnboardingSteps]: JSX.Element } = {
    [OnboardingSteps.BIENVENIDA]: <SetupBienvenida />,
    [OnboardingSteps.CERTIFICADO_INICIO]: <Step1 />,
    [OnboardingSteps.CERTIFICADO_PROCESANDO_PAGO]: <CertificadoProcesandoPago />,
    [OnboardingSteps.CERTIFICADO_EN_TRAMITE]: <Step1TramitandoCertificado />,
    [OnboardingSteps.DOCUMENTACION_INICIO]: <Step2 />,
    [OnboardingSteps.DOCUMENTACION_EN_REVISION]: <StepWaitingRoom />, //Step 3  //Cambiar step cuando nos lo den desde BACK
    [OnboardingSteps.CONTABILIDAD_INICIO]: <Step3 />,
    [OnboardingSteps.CONTABILIDAD_PROCESANDO_PAGO]: <CertificadoProcesandoPago />,
    [OnboardingSteps.CONTABILIDAD_PAGADA]: <Step3 />,
    [OnboardingSteps.CONTABILIDAD_EN_REVISION]: <StepRevision />, // Step 3
    [OnboardingSteps.CONTABILIDAD_REVISADA]: <StepRevisada />, // Step 3
    [OnboardingSteps.FINALIZADO]: <StepRevisada />, // Step 3
};
