import Decimal from 'decimal.js';
import { Invoice } from '../Invoice';
import { LineAmounts, invoiceLineCalculations } from '../InvoiceLineCalculations';

type VATGroup = {
    subtotal: number;
    discountAmount: number;
    baseAmount: number;
    vatAmount: number;
    reAmount: number;
};

export type InvoiceDetailedTotals = {
    vatGroups: { [vatRate: string]: VATGroup };
    totalSubtotal: number;
    totalDiscountAmount: number;
    totalBaseAmount: number;
    totalVATAmount: number;
    totalREAmount: number;
    totalAmount: number;
    totalIRPF: number;
    totalPayable: number;
};

const initializeVATGroup = (): VATGroup => ({
    subtotal: 0,
    discountAmount: 0,
    baseAmount: 0,
    vatAmount: 0,
    reAmount: 0,
});

const addAmountsToGroup = (group: VATGroup, amounts: LineAmounts): VATGroup => ({
    subtotal: new Decimal(group.subtotal).plus(amounts.subtotal).toNumber(),
    discountAmount: new Decimal(group.discountAmount).plus(amounts.discountAmount).toNumber(),
    baseAmount: new Decimal(group.baseAmount).plus(amounts.baseAmount).toNumber(),
    vatAmount: new Decimal(group.vatAmount).plus(amounts.vatAmount).toNumber(),
    reAmount: new Decimal(group.reAmount).plus(amounts.reAmount).toNumber(),
});

export type CalculateDetailedInvoiceTotalsArgs = {
    invoiceLines: Invoice['lines'];
    withEquivalencySurcharge: Invoice['withEquivalencySurcharge'];
    incomeTax: Invoice['incomeTax'];
};

export const calculateDetailedInvoiceTotals = (args: CalculateDetailedInvoiceTotalsArgs): InvoiceDetailedTotals => {
    const lineAmounts = args.invoiceLines.map((line) =>
        invoiceLineCalculations.calculateLineAmounts(line, args.withEquivalencySurcharge ?? false),
    );

    const vatGroups = args.invoiceLines.reduce(
        (groups, line, index) => {
            const vatRateKey = line.vat.toString();
            if (!groups[vatRateKey]) {
                groups[vatRateKey] = initializeVATGroup();
            }
            return {
                ...groups,
                [vatRateKey]: addAmountsToGroup(groups[vatRateKey], lineAmounts[index]),
            };
        },
        {} as { [vatRate: string]: VATGroup },
    );

    const totals = lineAmounts.reduce(
        (acc, curr) => ({
            totalSubtotal: new Decimal(acc.totalSubtotal).plus(curr.subtotal),
            totalDiscountAmount: new Decimal(acc.totalDiscountAmount).plus(curr.discountAmount),
            totalBaseAmount: new Decimal(acc.totalBaseAmount).plus(curr.baseAmount),
            totalVATAmount: new Decimal(acc.totalVATAmount).plus(curr.vatAmount),
            totalREAmount: new Decimal(acc.totalREAmount).plus(curr.reAmount),
            totalAmount: new Decimal(acc.totalAmount).plus(curr.totalLine),
        }),
        {
            totalSubtotal: new Decimal(0),
            totalDiscountAmount: new Decimal(0),
            totalBaseAmount: new Decimal(0),
            totalVATAmount: new Decimal(0),
            totalREAmount: new Decimal(0),
            totalAmount: new Decimal(0),
        },
    );

    const irpfAmount = totals.totalBaseAmount.times(new Decimal(args.incomeTax).dividedBy(100));
    const totalPayable = totals.totalAmount.minus(irpfAmount);

    return {
        vatGroups,
        totalSubtotal: totals.totalSubtotal.toDecimalPlaces(5).toNumber(),
        totalDiscountAmount: totals.totalDiscountAmount.toDecimalPlaces(5).toNumber(),
        totalBaseAmount: totals.totalBaseAmount.toDecimalPlaces(5).toNumber(),
        totalVATAmount: totals.totalVATAmount.toDecimalPlaces(5).toNumber(),
        totalREAmount: totals.totalREAmount.toDecimalPlaces(5).toNumber(),
        totalAmount: totals.totalAmount.toDecimalPlaces(5).toNumber(),
        totalIRPF: irpfAmount.toDecimalPlaces(5).toNumber(),
        totalPayable: totalPayable.toDecimalPlaces(2).toNumber(),
    };
};

export type InvoiceCalculations = typeof invoiceCalculations;

export const invoiceCalculations = {
    calculateDetailedInvoiceTotals,
};
