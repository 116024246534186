import V2Repository from 'shared/infra/repositories/V2Repository';
import {
    SalesDocumentNextNumberAutomaticRepository as Interface,
    GetNextNumberArgs,
} from '../../shared/domain/SalesDocumentNextNumberAutomaticRepository/SalesDocumentNextNumberAutomaticRepository';

class QuoteNextNumberAutomaticRepository extends V2Repository implements Interface {
    async getNextNumber(args: GetNextNumberArgs): Promise<string> {
        return this.post('/quotes/series', JSON.stringify(args));
    }
}

export const createQuoteNextNumberAutomaticRepository = (): Interface => {
    return new QuoteNextNumberAutomaticRepository();
};
