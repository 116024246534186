import { useFieldArray, FieldValues, UseFieldArrayReturn, Control, FieldArrayPath } from 'react-hook-form';

export function useCustomFieldArray<
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
    TKeyName extends string = 'id',
>(props: {
    name: TFieldArrayName;
    control: Control<TFieldValues>;
    keyName?: TKeyName;
}): UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName> {
    const { name, control, keyName } = props;

    return useFieldArray<TFieldValues, TFieldArrayName, TKeyName>({
        name,
        control,
        keyName,
    });
}
