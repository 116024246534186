import App from 'shared/UI/layouts/App';

export class RolesHelper {
    constructor(private app?: App) {}

    get roles() {
        if (!this.app) return [];
        return this.app.state.roles;
    }

    static retriveTmpRoles() {
        const tmproles = sessionStorage.getItem('tmp_roles');
        if (tmproles) {
            try {
                const data = JSON.parse(tmproles);
                return data.roles;
            } catch (e) {
                console.error(e);
            }
        }
        return [];
    }

    isConciliacionEnabled = () => {
        return this.roles.includes('ROLE_CONCILIACION');
    };

    isAsesorFinancieroEnabled = () => {
        return this.roles.includes('ROLE_ASESOR');
    };

    isBankCardEnabled = () => {
        return this.roles.includes('ROLE_TARJETAS') || this.roles.includes('ROLE_CONCILIACION');
    };

    isDeclarandoCardEnabled = () => {
        return this.roles.includes('ROLE_TARJETAS');
    };
}
