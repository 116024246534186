import { Box, Skeleton } from '@declarando/design_system';

export const LoadingSkeleton: React.FC = () => (
    <Box display="flex" gap="8px" flexDirection="column">
        <Skeleton width="225px" height="17px" />
        {Array.from(Array(5).keys()).map((key) => (
            <Skeleton key={key} height="70px" />
        ))}
    </Box>
);
