import { IAEType } from 'shared/domain/fiscalStatus/iae/IAEType';

export class IngresoSimplificadoValidador {
    private readonly limitAmount = 400;
    validateTotalAmount(amount: number): boolean {
        return isNaN(amount) || Number(amount) < this.limitAmount;
    }
    validateIAE(iae: IAEType): boolean {
        return iae.isRegimenGeneral;
    }

    hasIaeRG(_iaes: IAEType[]) {
        const iaes = _iaes || [];
        const iaesIngresoSimplificado = iaes.filter((iae) => {
            return this.validateIAE(iae);
        });
        return iaesIngresoSimplificado.length > 0;
    }
}
