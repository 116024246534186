import { BrowserStorage } from 'shared/infra/repositories/BrowserStorageRepository/BrowserStorage';

class LocalStorageRepository extends BrowserStorage {
    poblacionNombre(id: string | number) {
        return this.createStorageAccessor('poblacion_nombre_' + id);
    }

    provinciaNombre(id: string | number) {
        return this.createStorageAccessor('provincia_nombre' + id);
    }
}

export const localSotrageRepository = new LocalStorageRepository(localStorage);
