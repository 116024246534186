import { Box, Select } from '@declarando/design_system';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { SkeletonInput } from 'shared/UI/components/Skeletons/Input/SkeletonInput';
import { useGetTaxes } from '../../../ClientDetails/hooks/get-available-taxes';
import { LinesProps } from '../../Lines';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

const useSingleTaxOption = (
    options: { label: string; value: number }[] | undefined,
    index: number,
    setValue: UseFormSetValue<InvoiceForm>,
    handleLineChange: LinesProps['handleLineChange'],
) => {
    useEffect(() => {
        if (options?.length === 1) {
            setValue(`lines.${index}.vat`, options[0].value);
            handleLineChange(index, 'vat', options[0].value, { shouldTriggerValidation: false });
        }
    }, [options, setValue, index, handleLineChange]);

    return { isSingleOption: options?.length === 1 };
};

export const LineVAT = ({ index, handleLineChange }: LinesProps) => {
    const { formBlocked } = useSalesDocumentFormContext();
    const { control, getValues, setValue, clearErrors } = useCustomFormContext<InvoiceForm>();
    const watchProductType = useCustomWatch({ control, name: `lines.${index}.productType` });
    const { data, isLoading } = useGetTaxes();
    const key = watchProductType === 'GOODS' ? 'goods' : 'services';

    const options = data?.data?.taxes[key].map((tax) => ({
        label: `${tax.vat}%`,
        value: tax.vat,
    }));

    const { isSingleOption } = useSingleTaxOption(options, index, setValue, handleLineChange);

    if (isLoading) {
        return (
            <Box marginTop="5px" maxWidth="124px" overflow="hidden">
                <SkeletonInput />
            </Box>
        );
    }

    const clearValidationErrors = () => {
        //NOTE: Clear validations that happened after submitting the form, ex: VAT consistency
        const lines = getValues(`lines`);

        lines.forEach((_, i) => {
            clearErrors(`lines.${i}.vat`);
        });
    };

    return (
        <ControlledFormField
            control={control}
            name={`lines.${index}.vat`}
            Component={({ field, fieldState }) => (
                <Select
                    id={`sales-document-concept-iva-${index}`}
                    options={options ?? []}
                    error={!!fieldState.error?.message}
                    label="IVA"
                    readOnly={isSingleOption || formBlocked.isFormBlocked}
                    required
                    {...field}
                    onChange={(value: number) => {
                        clearValidationErrors();
                        handleLineChange(index, 'vat', value, { shouldDirty: true });
                    }}
                />
            )}
        />
    );
};
