import { useFetch } from 'shared/UI/hooks/QueryHooks';
import { useMemo, useEffect } from 'react';
import { SalesDocumentType } from '../SalesDocumentFormView/context/SalesDocumentFormProvider';
import { SalesDocumentPDFServiceFactory } from '../../services/SalesDocumentPDFServiceFactory';

const QueryKeyMapper: Record<SalesDocumentType, string> = {
    invoice: 'invoices',
    quote: 'quotes',
};

export const useGetPDFSalesDocument = (args: { salesDocumentUUID: string; salesDocumentType: SalesDocumentType }) => {
    const service = SalesDocumentPDFServiceFactory.create(args.salesDocumentType);

    const { data, isLoading, error } = useFetch<Blob | null, Blob>({
        queryKey: [QueryKeyMapper[args.salesDocumentType], 'document', args.salesDocumentUUID],
        fetchFn: () => service.getSalesDocumentPDF(args.salesDocumentUUID),
        config: {
            enabled: !!args.salesDocumentUUID,
        },
    });

    const pdfUrl = useMemo(() => {
        if (data?.data) {
            return URL.createObjectURL(data?.data);
        }
        return null;
    }, [data?.data]);

    useEffect(() => {
        return () => {
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [pdfUrl]);

    return { pdfUrl, isLoading, error };
};
