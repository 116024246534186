import { useContext, useEffect } from 'react';
import { Section } from '@declarando/design_system';
import { DatosFiscalesCliente } from './DatosFiscalesCliente';
import { DatosPersonalesCliente } from './DatosPersonalesCliente';
import { FormularioClienteHook } from './hooks/useFormularioCliente';
import { RootContext } from 'shared/UI/context/RootContext/RootContext';

type Props = {
    formularioCliente: FormularioClienteHook;
};

export const FormularioCliente = ({ formularioCliente }: Props) => {
    const { userIdentity } = useContext(RootContext);

    useEffect(() => {
        formularioCliente.updateInputProp('cif', 'invalidValues', [userIdentity.nif]);
    }, [userIdentity.nif]);

    return (
        <Section>
            <DatosFiscalesCliente
                formularioCliente={formularioCliente}
                form={formularioCliente.form}
                updateInput={formularioCliente.updateInput}
            />
            <>{formularioCliente.isStep2 && <DatosPersonalesCliente formularioCliente={formularioCliente} />}</>
        </Section>
    );
};
