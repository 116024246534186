import React from 'react';

const Autonomo = React.lazy(() => import('features/Inicial/Autonomo/Autonomo'));
const NoAutonomo = React.lazy(() => import('features/Inicial/NoAutonomo/NoAutonomo'));

const inicialRoutes = [
    { name: 'Autonomo', path: '/inicial/autonomo', icon: 'icon-home', hideNavbar: true, component: Autonomo },
    { name: 'No Autonomo', path: '/inicial/no-autonomo', icon: 'icon-home', hideNavbar: true, component: NoAutonomo },
];

export default inicialRoutes;
