import React from 'react';
import { Box, MainLoader, Section } from '@declarando/design_system';
import App from './App';
import { RootContextProvider } from 'shared/UI/context/RootContext/RootContext';
import { RolesHelper } from '../../stores/RolesHelper';
import LogoDeclarando from 'assets/img/logo_declarando_azul.svg';
import AuthenticatedRoute from '../components/Auth/AuthenticatedRoute';
import JWT from '../../infra/utils/JWT';
import { Switch } from 'react-router-dom';

class FinnanciacionLayout extends App {
    render() {
        this.spreads = {
            ...this.props,
        };

        const contextProperties = {
            ...this.spreads,
            userIdentity: this.state.userIdentity,
            userInfo: this.state.userInfo,
            userStatus: this.state.userStatus,
            userAddress: this.state.userAddress,
            userWorkAddress: this.state.userWorkAddress,
            storeUserIdentity: this.storeUserIdentity,
            storeUserStatus: this.storeUserStatus,
            storeUserAddress: this.storeUserAddress,
            storeUserWorkAddress: this.storeUserWorkAddress,
            rolesHelper: new RolesHelper(this),
            planHelper: this.planHelper,
        };
        return (
            <RootContextProvider value={contextProperties}>
                {this.state.showMainLoader ? <MainLoader /> : null}
                {this.state.loadedUserInfo && (
                    <Box margin={0} bg="white">
                        <Section maxWidth={750} margin={'auto'}>
                            <Box alignCenter margin={'md'}>
                                <img src={LogoDeclarando} alt="Logo declarando" width={234} />
                            </Box>
                        </Section>
                        <Switch>
                            {this.props.routes.map((prop, key) => {
                                return (
                                    <AuthenticatedRoute
                                        getToken={JWT.getToken}
                                        path={prop.path}
                                        key={key}
                                        render={(props) => (
                                            <prop.component {...this.spreads} {...props} currentRoute={prop} />
                                        )}
                                    />
                                );
                            })}
                        </Switch>
                    </Box>
                )}
            </RootContextProvider>
        );
    }
}

export default FinnanciacionLayout;
