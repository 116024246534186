import V2Repository from '../V2Repository';
import { ClienteDTO } from '../../../../features/Incomes/domain/Cliente';
import { ClienteResponse } from '../../../domain/types/api/ClienteResponse';

export default class ClientesRepository extends V2Repository {
    guardarCliente(client: ClienteDTO) {
        const clienteDTO = { ...client, isCanario: Boolean(client.isCanario) };
        return client.id
            ? this.put('/cliente', JSON.stringify({ client: clienteDTO }))
            : this.post('/cliente', JSON.stringify({ client: clienteDTO }));
    }

    getCliente(clienteId: string) {
        return this.get('/cliente/' + clienteId);
    }

    // @ts-ignore
    listaClientes(page, rows, filters, orders) {
        return this.post(
            '/clientes',
            JSON.stringify({
                page,
                rows,
                filters,
                orders,
            }),
        );
    }

    getUrlPrintList(
        listType: string,
        year: string,
        period: string,
        type: string,
        country: string,
        filterIae: string,
        filter: string,
    ) {
        let url = `${this.apiPath}/reporte/clientes/${listType}/${year}/${period}/${type}/${country}/${filterIae}`;
        if (filter) {
            url += '/' + filter;
        }
        return url;
    }

    async comprobarPrimerEuParticular(): Promise<ClienteResponse.ComprobarPrimerEuParticular> {
        return this.get('/cliente/check/esprimerparticularEU');
    }

    getClients() {
        return this.get('/clientes/selector');
    }
}
