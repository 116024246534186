import { Box, Notification } from '@declarando/design_system';
import { TipoPais } from 'features/Incomes/domain/TipoPais';
import { ModalCliente } from 'features/Ingresos/Clientes/UI/ModalCliente/ModalCliente';
import { transformLegacyClientDTOToClient } from 'features/Ingresos/Clientes/adapters/ClientAdapter';
import { toLegacyClientDTO } from 'features/Ingresos/Clientes/adapters/LegacyClientAdapter';
import { LegacyClientDTO } from 'features/Ingresos/Clientes/adapters/LegacyClientDTO';
import {
    addStateNameToClient,
    addCountryNameToClient,
} from 'features/Ingresos/Clientes/services/AssignLocationNameToClient';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useGetStatesList, useGetCountriesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { Notifier } from 'shared/infra/utils/Notifier';
import { useVIESCheck } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/VIESChecker/hooks/useVIESCheck';
import { useAssignZeroVATByDefault } from '../../../Lines/hooks/useAssignZeroVATByDefault';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const NotificationEdition = () => {
    return (
        <Box marginBottom="sm">
            <Notification
                full
                tone="info"
                title="La información que edites solo se guardará en esta factura y no en la ficha del cliente:"
                message={
                    <>
                        <div>
                            <span>• Estos cambios pueden afectar a las reglas de IVA e IRPF</span>
                        </div>
                        <div>
                            <span>
                                • Si quieres actualizar los datos permanentemente, deberás hacerlo en la ficha del
                                cliente
                            </span>
                        </div>
                    </>
                }
            />
        </Box>
    );
};

export const EditClientModal: React.FC<Props> = ({ isOpen, onClose }) => {
    const { setValue, control, getValues } = useCustomFormContext<InvoiceForm>();
    const { states } = useGetStatesList();
    const { countries } = useGetCountriesList();
    const client = useCustomWatch({ control, name: 'client' });
    const { resetCheckVIES } = useVIESCheck();
    const { assignVATZeroByDefault } = useAssignZeroVATByDefault();

    function handleSubmit(args: { formData: LegacyClientDTO; tipoPais: TipoPais }) {
        console.log('data', args.formData, args.tipoPais);

        const clientTransformed = transformLegacyClientDTOToClient(args.formData);
        const clientWithState = addStateNameToClient(clientTransformed, states);
        const clientWithCountry = addCountryNameToClient(clientWithState, countries);

        if (!clientWithCountry) throw new Error('Client couldnt be transformed');

        if (clientWithCountry) {
            const previousClient = getValues('client');

            setValue('client', clientWithCountry);
            setValue('client.hasEUVatNumber', clientWithCountry.hasEUVatNumber ?? undefined);
            setValue('withEquivalencySurcharge', null);
            setValue('activity.withImageRights', undefined as unknown as boolean);
            setValue('activity.forFattening', undefined as unknown as boolean);
            resetCheckVIES();

            if (previousClient?.id) {
                Notifier.notifyFeedBack({ title: 'Los datos del receptor se han actualizado' });
            }

            assignVATZeroByDefault({ client: clientWithCountry });

            onClose();
        }
    }

    function getLegacyClient() {
        const countryId = countries.find((country) => country.code === client.country.code)?.id;
        return toLegacyClientDTO({ ...client, country: { ...client.country, id: countryId } });
    }

    return (
        <ModalCliente
            CustomHeader={<NotificationEdition />}
            titleModal="Editar datos del receptor en la factura"
            labelAction="Guardar cambios"
            formMode="edit"
            isOpen={isOpen}
            isLoading={false}
            cliente={getLegacyClient()}
            closeModal={onClose}
            onSubmit={handleSubmit}
        />
    );
};
