import PublicV2Repository from './PublicV2Repository';

export default class InvoiceByTokenV2Repository extends PublicV2Repository {
    invoiceByToken(data) {
        return this.get('/invoice/token/' + data);
    }

    invoicePdfByToken(data) {
        return import.meta.env.VITE_API_URL + '/v2/public/file/invoice/pdf/' + data;
    }
}
