import Decimal from 'decimal.js';
import { Invoice } from './Invoice';

export type LineAmounts = {
    price: number;
    subtotal: number;
    discountAmount: number;
    baseAmount: number;
    vatAmount: number;
    reAmount: number;
    totalLine: number;
};

type RECalculator = (baseAmount: Decimal, equivalenceSurcharge: number) => Decimal;

const goodsRECalculator: RECalculator = (baseAmount, equivalenceSurcharge) =>
    baseAmount.times(new Decimal(equivalenceSurcharge).dividedBy(100));

const servicesRECalculator: RECalculator = () => new Decimal(0);

const getRECalculator = (productType: Invoice['lines'][number]['productType']): RECalculator =>
    productType === 'GOODS' ? goodsRECalculator : servicesRECalculator;

const calculateLineAmounts = (line: Invoice['lines'][number], withRE: boolean): LineAmounts => {
    const quantity = new Decimal(line?.quantity.toString() === 'NaN' ? 0 : line?.quantity);
    const price = new Decimal(line?.price || 0);
    const discountPercent = new Decimal(line?.discount || 0).dividedBy(100);
    const vatPercent = new Decimal(line?.vat || 0).dividedBy(100);

    const subtotal = quantity.times(price);
    const discountAmount = subtotal.times(discountPercent);
    const baseAmount = subtotal.minus(discountAmount);
    const vatAmount = baseAmount.times(vatPercent);

    const reCalculator = getRECalculator(line.productType);
    const reAmount = withRE ? reCalculator(baseAmount, line?.equivalencySurcharge || 0) : new Decimal(0);

    const totalLine = baseAmount.plus(vatAmount).plus(reAmount);

    return {
        price: price.toDecimalPlaces(5).toNumber(),
        subtotal: subtotal.toDecimalPlaces(5).toNumber(),
        discountAmount: discountAmount.toDecimalPlaces(5).toNumber(),
        baseAmount: baseAmount.toDecimalPlaces(5).toNumber(),
        vatAmount: vatAmount.toDecimalPlaces(5).toNumber(),
        reAmount: reAmount.toDecimalPlaces(5).toNumber(),
        totalLine: totalLine.toDecimalPlaces(5).toNumber(),
    };
};

const calculatePriceFromTotal = (
    totalLine: number,
    quantity: number,
    discount: number,
    vat: number,
    equivalenceSurcharge: number,
    withRE: boolean,
): number => {
    const total = new Decimal(totalLine);
    const qty = new Decimal(quantity);
    const discountPercent = new Decimal(discount).dividedBy(100);
    const vatPercent = new Decimal(vat).dividedBy(100);
    const rePercent = withRE ? new Decimal(equivalenceSurcharge).dividedBy(100) : new Decimal(0);

    const baseAmount = total.dividedBy(Decimal.add(1, vatPercent).add(rePercent));
    const priceWithoutDiscount = baseAmount.dividedBy(qty);
    const price = priceWithoutDiscount.dividedBy(Decimal.sub(1, discountPercent));

    return price.toDecimalPlaces(5).toNumber();
};

export type InvoiceLineCalculations = typeof invoiceLineCalculations;

export const invoiceLineCalculations = {
    calculateLineAmounts,
    calculatePriceFromTotal,
};
