import { EnvKeys } from './EnvKeys';
import { UrlParser } from '../shared/infra/utils/UrlParser';

type Developers = 'adrianM' | 'adrian' | 'frontend';

export const Env = {
    envQueryParams: () => {
        if (import.meta.env.VITE_ENV === 'prod') {
            return;
        }
        const envParams = UrlParser.getQueryParams().env;
        if (envParams) {
            const values = envParams.split(',');
            values.forEach((data) => {
                const [key, value] = data.split(':');
                if (key && value) {
                    import.meta.env[key] = value;
                }
            });
        }
    },
    get: (envkey: EnvKeys) => {
        return import.meta.env[envkey];
    },
    isLocal: (developer?: Developers): boolean => {
        if (developer && developer !== import.meta.env.VITE_DEVELOPER) {
            return false;
        }
        const env = import.meta.env.VITE_ENV;
        if (env === 'test' && developer === 'adrianM') {
            return true;
        }
        return env === 'local';
    },

    isTest: () => {
        return import.meta.env.VITE_ENV === 'test';
    },

    isProd: () => {
        return import.meta.env.VITE_ENV === 'prod';
    },

    isModeTest: () => {
        return import.meta.env.MODE === 'test';
    },

    localFlags: (): string => {
        const localFlags = import.meta.env.VITE_LOCAL_FALGS;
        if (!localFlags) return '';
        return localFlags;
    },
};
