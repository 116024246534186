import { ComponentProps, ReactNode } from 'react';
import { Sections, Box } from '@declarando/design_system';
import { useHistory } from 'react-router-dom';
import { AppRouter } from '../../../../routes/AppRouter';

type Props = {
    contextPath: ReactNode;
    title: ReactNode;
    tabs?: any[];
    activeSection?: number;
    setActiveSection?: (index: number) => any;
    info?: boolean;
    infoCta?: () => any;
    extraLinks?: SectionMain['helpLinks'];
    type?: ComponentProps<typeof Sections.SubSection>['type'];
};

type SectionMain = ComponentProps<typeof Sections.Main>;

export const SubHeader = ({
    contextPath,
    title,
    tabs,
    activeSection,
    setActiveSection = () => null,
    info,
    infoCta,
    extraLinks,
    type,
}: Props) => {
    const history = useHistory();

    const tabHandler = (tab: any, index: number) => {
        setActiveSection(index);
        history.push(tab.path);
    };

    return (
        <Sections>
            <Box
                pt={{ _: 'xs', desktop: 'md' }}
                px={{ _: 'md', desktop: 'lg' }}
                display="flex"
                width="100%"
                justifyContent="center"
            >
                <Box width="100%" maxWidth={{ _: 'unset', bigScreen: '1440px' }}>
                    {contextPath ? (
                        <Sections.SubSection
                            title={title}
                            type={type}
                            helpLinks={extraLinks}
                            handleAction={() => AppRouter.goToPath(contextPath)}
                        />
                    ) : (
                        <Sections.Main
                            title={title}
                            info={info}
                            infoCta={infoCta}
                            activeTab={activeSection as number}
                            helpLinks={extraLinks}
                            tabs={tabs?.map((tab, index) => {
                                return {
                                    tabSelector: tab.tabSelector,
                                    id: index,
                                    label: tab.name,
                                    onClickTab: () => tabHandler(tab, index),
                                    badge: tab.badge
                                        ? {
                                              variant: 'notification',
                                              children: tab.badge,
                                          }
                                        : null,
                                };
                            })}
                            setActiveTab={(i: any) => setActiveSection(i)}
                        >
                            {tabs?.map((tab, index) => {
                                return (
                                    <Sections.Tab
                                        key={index}
                                        badge={tab.badge}
                                        clickHandler={() => tabHandler(tab, index)}
                                        isActive={activeSection === index}
                                    >
                                        {tab.name}
                                    </Sections.Tab>
                                );
                            })}
                        </Sections.Main>
                    )}
                </Box>
            </Box>
        </Sections>
    );
};
