import { Box, SelectorGroup } from '@declarando/design_system';
import { ExpandableContent } from '../../../../shared/components/ExpandableContent';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useShouldShowRancherFatteningQuestion } from './useShouldShowRancherFatteningQuestion';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

const RancherForFatteningQuestionContent = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const { formBlocked } = useSalesDocumentFormContext();

    return (
        <ControlledFormField
            name="activity.forFattening"
            control={control}
            Component={({ field }) => (
                <SelectorGroup
                    label="¿Estás facturando un ingreso de engorde?"
                    required
                    variant="radio"
                    selectors={[
                        { label: 'Sí', id: 'yes', checked: field.value === true, disabled: formBlocked.isFormBlocked },
                        { label: 'No', id: 'no', checked: field.value === false, disabled: formBlocked.isFormBlocked },
                    ]}
                    {...field}
                    onChange={(selectedIds) => {
                        field.onChange(selectedIds[0] === 'yes');
                    }}
                />
            )}
        />
    );
};

export const RancherForFatteningQuestion = () => {
    const { shouldShowRancherFatteningQuestion } = useShouldShowRancherFatteningQuestion();

    return (
        <ExpandableContent shouldExpand={shouldShowRancherFatteningQuestion}>
            <Box marginTop="md">
                <RancherForFatteningQuestionContent />
            </Box>
        </ExpandableContent>
    );
};
