import { usePresentationOfRenta } from 'features/CalculadoraRenta/PresentationOfRenta/ui/hooks/usePresentationOfRenta';
import { createContext, type ReactNode, useContext } from 'react';

export const useRentalPresentationContext = () => {
    try {
        return useContext(PresentationOfRentaContext);
    } catch (error) {
        throw new Error('Failed PresentationOfRentaContext');
    }
};

export const PresentationOfRentaContext = createContext({} as ReturnType<typeof usePresentationOfRenta>);

export const PresentationOfRentaProvider = ({ children }: { children: ReactNode }) => {
    const value = usePresentationOfRenta();
    return <PresentationOfRentaContext.Provider value={value}>{children}</PresentationOfRentaContext.Provider>;
};
