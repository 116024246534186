import V2Repository from './V2Repository';

class AdviceScoreRepository extends V2Repository {
    addScore(adviceId, score) {
        return this.post(
            '/advice/score',
            JSON.stringify({
                adviceId,
                score,
            }),
        );
    }
}

export default AdviceScoreRepository;
