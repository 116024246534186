import React from 'react';
import { trackParams } from 'shared/infra/utils/user_events';

const Login = React.lazy(() => import('features/Register/Login'));
const Password = React.lazy(() => import('features/Register/Password'));
const Reset = React.lazy(() => import('features/Register/Reset'));
const Invoice = React.lazy(() => import('features/Download/Invoice'));
const Quotation = React.lazy(() => import('features/Download/Quotation'));
// const RegisterFromLandingCockpit = React.lazy(() => import('features/Register/RegisterFromLandingCockpit'));

const publicRoutes = [
    // { name: 'Registro', path: '/registro', component: RegisterFromLandingCockpit },
    { name: 'Login', path: '/login', component: Login, track_params: trackParams.login },
    { name: 'Password', path: '/password', component: Password },
    { name: 'Reset', path: '/reset/:key/:salt', component: Reset },
    { name: 'Invoice', path: '/mi-factura/:key', component: Invoice },
    { name: 'Quotation', path: '/mi-presupuesto/:key', component: Quotation },
];

export default publicRoutes;
