import V2Repository from './V2Repository';

export default class LoggerV2Repository extends V2Repository {
    logEvent(message, stack) {
        this.post(
            '/error',
            JSON.stringify({
                message: message,
                stack: stack,
            }),
        ).then((r) => []);
    }
}
