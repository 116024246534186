import { CheckBox, Grid, InputIBAN } from '@declarando/design_system';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { ExpandableContent } from '../../../shared/components/ExpandableContent';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { useSalesDocumentFormContext } from '../../../../context/SalesDocumentFormProvider';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';

export const PaymentOptionsTransfer = () => {
    const { formBlocked } = useSalesDocumentFormContext();
    const { control, setValue } = useCustomFormContext<InvoiceForm>();
    const { userIdentity } = useRootContext();
    const shouldShowInput = useCustomWatch({ control, name: 'paymentOptions.bankTransfer.isChecked' });
    const ibanValue = useCustomWatch({ control, name: 'paymentOptions.bankTransfer.details' });

    return (
        <Grid gridRowGap="xs">
            <ControlledFormField
                control={control}
                name="paymentOptions.bankTransfer.isChecked"
                Component={({ field }) => (
                    <CheckBox
                        disabled={formBlocked.isFormBlocked}
                        checked={field.value}
                        onChange={(e) => {
                            field.onChange(e);
                            if (e.target.checked) {
                                TrackingEvents.fillField({
                                    item_id: 'Transferencia bancaria',
                                    event_label: 'Añade método de pago',
                                });
                                if (!userIdentity?.iban || ibanValue) return;
                                setValue('paymentOptions.bankTransfer.details', userIdentity.iban);
                            }
                        }}
                        id="invoice-payment-bank-transfer"
                        label="Transferencia bancaria"
                    />
                )}
            />
            <ExpandableContent shouldExpand={shouldShowInput}>
                <Grid gridTemplateColumns={{ _: '1fr', mediumScreen: '6fr 4fr' }}>
                    <ControlledFormField
                        control={control}
                        name="paymentOptions.bankTransfer.details"
                        Component={({ field, formState: { errors } }) => (
                            <InputIBAN
                                readOnly={formBlocked.isFormBlocked}
                                placeholder="Introduce el IBAN de tu cuenta bancaria"
                                id="invoice-payment-bank-account"
                                custom
                                error={!!errors?.paymentOptions?.bankTransfer?.details?.message}
                                helperText={
                                    errors?.paymentOptions?.bankTransfer?.details?.message ??
                                    'Solo se admiten números de IBAN españoles'
                                }
                                {...field}
                                customHandlerClearValue={() => field.onChange('')}
                                maxLength={29}
                            />
                        )}
                    />
                </Grid>
            </ExpandableContent>
        </Grid>
    );
};
