import { useFetch } from 'shared/UI/hooks/QueryHooks';
import { createInvoiceFrequentClientsRepository } from '../../infra/InvoiceFrequentClientsRepository';
import { createInvoiceFrequentClientsService } from '../../services/InvoiceFrequentClients';
import { longLivedQueryConfig } from 'shared/UI/config/react-query-config';
import { Client } from '../../domain';
import { ClientDTO } from '../../adapters/ClientDTO';
import { transformClientDTOToClient } from '../../adapters/ClientAdapter';

export const useGetFrequentInvoiceClients = () => {
    const service = createInvoiceFrequentClientsService(createInvoiceFrequentClientsRepository());

    const context = useFetch<ClientDTO[], Client[]>({
        queryKey: ['invoices', 'frequentClients'],
        fetchFn: () => service.getFrequentClients(),
        config: {
            ...longLivedQueryConfig,
            select: (data) => ({
                ...data,
                data: data.data?.map((clientDTO) => transformClientDTOToClient(clientDTO)) || [],
            }),
        },
    });

    return { frequentInvoiceClients: context.data?.data || [], isLoading: context.isLoading };
};
