import { Box, ContentContainer, Header, Divider, P, Button } from '@declarando/design_system';
import { Currency } from 'shared/infra/utils/Currency';
import { Fragment } from 'react';
import { useGetSalesDocumentTotals } from './hooks/get-sales-document-totals';
import { useShouldExpandForm } from '../SalesDocumentFormContent/hooks/use-should-expand-form';
import { SalesDocumentType, useSalesDocumentFormContext } from '../context/SalesDocumentFormProvider';

type Props = React.FC<{ isSubmitButtonDisabled: boolean }>;

const getSubmitLabel = (args: { salesDocumentType: SalesDocumentType }) => {
    const MAPPER_TITLE: Record<SalesDocumentType, string> = {
        invoice: 'Crear ingreso con factura',
        quote: 'Crear presupuesto',
    };

    return MAPPER_TITLE[args.salesDocumentType];
};

const IngresoConFacturaResumenSubmitButton: Props = ({ isSubmitButtonDisabled }) => {
    const { salesDocumentMode, formBlocked, salesDocumentType } = useSalesDocumentFormContext();

    if (formBlocked.isFormBlocked) return null;

    return (
        <Button
            isLoading={isSubmitButtonDisabled}
            type="submit"
            fill
            size="L"
            label={salesDocumentMode === 'create' ? getSubmitLabel({ salesDocumentType }) : 'Guardar'}
        />
    );
};

export const IngresoConFacturaResumen: Props = ({ isSubmitButtonDisabled }) => {
    const { shouldShowRestOfFields } = useShouldExpandForm();
    const { detailedSums, withEquivalenceSurcharge, incomeTaxAmount, incomeTaxPercentage, totalWithIncomeTax } =
        useGetSalesDocumentTotals();

    return (
        <Box display="block" height="100%" position="relative">
            <Box position="sticky" top="20px" maxHeight="calc(100vh - 40px)" overflowY="auto">
                <ContentContainer padding="none">
                    <Box p="md">
                        <Header as="h3">Resumen</Header>
                    </Box>
                    <Divider />
                    <Box p="md" display="flex" gap="8px" flexDirection="column">
                        {shouldShowRestOfFields ? (
                            <>
                                {Object.entries(detailedSums.vatGroups).map(([vatRate, group]) => (
                                    <Fragment key={vatRate}>
                                        <Box display="flex" justifyContent="space-between">
                                            <dt>
                                                <P>Base imponible {vatRate}%</P>
                                            </dt>
                                            <dd>
                                                <P>{Currency(group.baseAmount, { minimumFractionDigits: 2 })}</P>
                                            </dd>
                                        </Box>
                                    </Fragment>
                                ))}
                                <Divider />
                            </>
                        ) : null}
                        <Box display="flex" justifyContent="space-between">
                            <dt>
                                <P>Total bases imponibles</P>
                            </dt>
                            <dd>
                                <P>{Currency(detailedSums.totalBaseAmount, { minimumFractionDigits: 2 })}</P>
                            </dd>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <dt>
                                <P>Total IVA</P>
                            </dt>
                            <dd>
                                <P>{Currency(detailedSums.totalVATAmount, { minimumFractionDigits: 2 })}</P>
                            </dd>
                        </Box>
                        {withEquivalenceSurcharge && detailedSums.totalREAmount > 0 && (
                            <Box display="flex" justifyContent="space-between">
                                <dt>
                                    <P>Total RE</P>
                                </dt>
                                <dd>
                                    <P>{Currency(detailedSums.totalREAmount, { minimumFractionDigits: 2 })}</P>
                                </dd>
                            </Box>
                        )}
                        <Box display="flex" justifyContent="space-between">
                            <dt>
                                <P>IRPF {incomeTaxPercentage}%</P>
                            </dt>
                            <dd>
                                <P>-{Currency(incomeTaxAmount, { minimumFractionDigits: 2 })}</P>
                            </dd>
                        </Box>
                    </Box>
                    <Divider />
                    <Box p="md" display="flex" flexDirection="column" gap="24px">
                        <Box display="flex" justifyContent="space-between">
                            <Header as="h2">Total</Header>
                            <Header as="h2">{Currency(totalWithIncomeTax, { minimumFractionDigits: 2 })}</Header>
                        </Box>
                        <IngresoConFacturaResumenSubmitButton isSubmitButtonDisabled={isSubmitButtonDisabled} />
                    </Box>
                </ContentContainer>
            </Box>
        </Box>
    );
};
