import Cookies from 'js-cookie';

export default class JWT {
    static getToken = () => {
        return Cookies.get(import.meta.env.VITE_TOKEN_NAME);
    };

    static removeToken() {
        if (window.location.hostname.includes('localhost')) {
            Cookies.remove(import.meta.env.VITE_TOKEN_NAME, { path: '/', domain: 'localhost' });
        } else {
            Cookies.remove(import.meta.env.VITE_TOKEN_NAME, { path: '/', domain: '.declarando.es' });
        }
    }

    static parseToken() {
        const token = Cookies.get(import.meta.env.VITE_TOKEN_NAME);
        if (!token) return {};
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(''),
        );

        return JSON.parse(jsonPayload);
    }
}
