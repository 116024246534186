import V2Repository from 'shared/infra/repositories/V2Repository';
import { GetTaxesArgs, IngresoConFacturaRulesRepository } from '../domain/IngresoConFacturaRulesRepository';
import { InvoiceTaxesDTO } from '../adapters/InvoiceTaxesDTO';

class IngresoConFacturaRulesApiRepository extends V2Repository implements IngresoConFacturaRulesRepository {
    async getTaxes(args: GetTaxesArgs): Promise<InvoiceTaxesDTO> {
        return this.post('/invoices/taxes', JSON.stringify(args));
    }
}

export const createIngresoConFacturaRulesApiRepository = (): IngresoConFacturaRulesRepository => {
    return new IngresoConFacturaRulesApiRepository();
};
