import { ReactNode } from 'react';
import { useModalCliente } from './useModalCliente';
import { FormularioCliente } from './form/FormularioCliente';
import { TipoPais } from 'features/Incomes/domain/TipoPais';
import { LegacyClientRequestDTO } from '../../adapters/LegacyClient';
import { Modal } from 'shared/UI/components/Modal/Modal';

type Props = {
    cliente: LegacyClientRequestDTO;
    isOpen: boolean;
    closeModal: () => void;
    onSubmit: (args: { formData: any; tipoPais: TipoPais }) => void;
    formMode: 'create' | 'edit';
    isLoading: boolean;
    labelAction: string;
    titleModal?: string;
    CustomHeader?: ReactNode;
};

const title = {
    create: 'Crear nuevo cliente',
    edit: 'Editar cliente',
};

export const ModalClienteContent = ({
    onSubmit,
    cliente,
    closeModal,
    titleModal,
    formMode,
    isOpen,
    isLoading,
    labelAction,
    CustomHeader,
}: Props) => {
    const { handleSubmit, handleClose, formularioCliente } = useModalCliente({
        onSubmit,
        cliente,
        closeModal,
    });

    return (
        <Modal
            title={titleModal ?? title[formMode]}
            variant="medium"
            isOpen={isOpen}
            onClose={handleClose}
            onAction={() => handleSubmit()}
            isLoading={isLoading}
            actionLabel={labelAction}
            isActionDisabled={isLoading || !formularioCliente.isFormFilledWithRequiredInputs}
        >
            {CustomHeader ? CustomHeader : null}
            <FormularioCliente formularioCliente={formularioCliente} />
        </Modal>
    );
};

export const ModalCliente = (props: Props) => {
    if (!props.isOpen) return null;

    return <ModalClienteContent {...props} />;
};
