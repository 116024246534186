import { AlertMessage, Box, Header, P } from '@declarando/design_system';
import imageStep from '../assets/revision.svg';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { OnboardingContext } from '../../Onboarding';

export const StepRevision = () => {
    const { setInfoTagStep } = useContext(OnboardingContext);
    useEffect(() => {
        setInfoTagStep('En revisión');
        return () => {
            setInfoTagStep('');
        };
    }, []);
    return (
        <>
            <Box px={'sm'} mx={'auto'} py={'xxl'} maxWidth={800}>
                <Box alignCenter>
                    <Box mb="sm">
                        <img src={imageStep} alt={'Certificado'} />
                    </Box>
                    <Header as={'h2'}>Estamos revisando los datos aportados</Header>
                    <P mt={'xs'}>
                        Tu mentor está comprobando la información para garantizar que la importación sea correcta.
                    </P>

                    <Box maxWidth={570} mx={'auto'} mt={'lg'}>
                        <AlertMessage
                            customIcon="info-circle"
                            full
                            message="Te notificaremos por mensaje de texto y correo electrónico cuando esté listo"
                            tone="info"
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};
