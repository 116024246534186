import React, { useEffect } from 'react';
import { Box, Grid, P, SuccessCheck } from '@declarando/design_system';

const SetPinSuccessIframe = () => {
    useEffect(() => {
        //@ts-ignore
        window.top.postMessage('setpin-success', '*');
    }, []);

    return (
        <Box pt="lg" bg="white" height="100vh" width="100%">
            <Grid alignItems="center">
                <SuccessCheck />
                <P textAlign="center">Pin establecido</P>
            </Grid>
        </Box>
    );
};

const PaylandsSuccess = () => {
    useEffect(() => {
        //@ts-ignore
        window.top.postMessage('validacion-3ds-success', '*');
    }, []);

    return <></>;
};

const PaylandsError = () => {
    useEffect(() => {
        //@ts-ignore
        window.top.postMessage('validacion-3ds-error', '*');
    }, []);

    return <></>;
};

export const callbackRoutes = [
    {
        component: SetPinSuccessIframe,
        name: <>Número pin establecido</>,
        path: '/callback/marqueta-setpin-success',
    },
    {
        component: PaylandsSuccess,
        name: <>Número pin establecido</>,
        path: '/callback/validacion-3ds-success',
    },
    {
        component: PaylandsError,
        name: <>Número pin establecido</>,
        path: '/callback/validacion-3ds-error',
    },
];
