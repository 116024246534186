import { FeatureFlags } from 'config/FeatureFlags';
import { Country } from 'features/Ingresos/Clientes/domain/Client';
import { useGetContabilidadPresentedOrBlocked } from 'features/Ingresos/Contabilidad/UI/hooks/useGetContabilidadPresentedOrBlocked';
import { useGetInvoicingActivities } from 'features/Ingresos/InvoicingActivities/UI/hooks/useGetInvoicingActivities';
import { useGetCountriesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { MainLoaderSalesDocument } from 'features/Ingresos/SalesDocument/shared/UI/MainLoader/MainLoader';
import { SalesDocumentFormLayout } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormLayout';
import { useParams, useHistory } from 'react-router-dom';
import DateWrapper from 'shared/infra/utils/Date';
import { SubHeader } from 'shared/UI/components/SubHeader/SubHeader';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import { Invoice } from '../../domain/Invoice';
import { getInvoiceSchema } from '../../validations/InvoiceSchema';
import { useGetIngresoConFactura } from '../hooks/useGetIngresoConFactura';

type IngresoConFacturaBaseViewProps = {
    mode: 'create' | 'edit';
    title: string;
    handleSubmit: (values: any) => void;
    isMutationPending: boolean;
    customizeDefaultValues: (invoiceData: Invoice, countries: Country[], userStatus: any) => Invoice;
};

export const IngresoConFacturaBaseView = ({
    mode,
    title,
    handleSubmit,
    isMutationPending,
    customizeDefaultValues,
}: IngresoConFacturaBaseViewProps) => {
    const { invoiceUUID } = useParams<{ invoiceUUID: string }>();
    const history = useHistory();
    const { userStatus } = useRootContext();

    if (!FeatureFlags.shouldNewInvoiceFormBeEnabled().checker()) {
        history.push('/ingresos/ingresos');
    }

    const {
        isLoading: isLoadingIngresoConFactura,
        invoiceData,
        isError: isErrorFetchingInvoiceData,
    } = useGetIngresoConFactura({ invoiceUUID });
    const { isLoading: isLoadingInvoicingActivities } = useGetInvoicingActivities();
    const { isLoading: isLoadingCountries, countries } = useGetCountriesList();
    const { isLoading: isLoadingContabilidadPresentedOrBlocked, contabilidadPresentedOrBlocked } =
        useGetContabilidadPresentedOrBlocked(
            (invoiceData?.issueDate as string) ?? DateWrapper.formatNativeDateToString(new Date(), 'yyyy-MM-dd'),
        );

    if (isErrorFetchingInvoiceData) {
        history.push('/ingresos/ingresos');
        return null;
    }

    const isLoading =
        isLoadingIngresoConFactura ||
        isLoadingInvoicingActivities ||
        isLoadingCountries ||
        isLoadingContabilidadPresentedOrBlocked;

    if (isLoading) {
        return (
            <div style={{ lineHeight: '24px' }}>
                <SubHeader title={title} contextPath="/ingresos/ingresos" />
                <MainLoaderSalesDocument />
            </div>
        );
    }

    if (!invoiceData || !countries) {
        return null;
    }

    const invoiceDefaultValues = customizeDefaultValues(invoiceData, countries, userStatus);

    return (
        <div style={{ lineHeight: '24px' }}>
            <SubHeader title={title} contextPath="/ingresos/ingresos" />
            <SalesDocumentFormLayout
                salesDocumentSchema={getInvoiceSchema({
                    salesDocumentMode: mode,
                    defaultValues: invoiceDefaultValues,
                    numerationType: userStatus?.autoNumInvoice ? 'automatic' : 'manual',
                })}
                salesDocumentType="invoice"
                formBlocked={{
                    isFormBlocked: contabilidadPresentedOrBlocked?.hasContabilidadBlocked ?? false,
                    blockedReason: 'contabilidad-bloqueada',
                }}
                salesDocumentMode={mode}
                handleSubmit={handleSubmit}
                isMutationPending={isMutationPending}
                defaultValues={invoiceDefaultValues}
            />
        </div>
    );
};
