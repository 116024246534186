import { useGetIngresosConFacturaList } from 'features/Ingresos/shared/UI/hooks/useGetIngresosConFacturaList';
import DateWrapper from 'shared/infra/utils/Date';

type UseInvoiceDateValidationProps = {
    formattedDate: string | null;
};

export const useInvoiceDateValidation = ({ formattedDate }: UseInvoiceDateValidationProps) => {
    const endOfYear = DateWrapper.formatNativeDateToString(
        DateWrapper.getEndOfYear(formattedDate ?? new Date()),
        'yyyy-MM-dd',
    );

    return useGetIngresosConFacturaList(
        {
            issueDateFrom: formattedDate ?? DateWrapper.formatNativeDateToString(new Date(), 'yyyy-MM-dd'),
            issueDateTo: endOfYear,
            sort: '-series',
            offset: 0,
            limit: 1,
        },
        { shouldTriggerCall: !!formattedDate },
    );
};
