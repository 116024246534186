import { useHistory, useParams } from 'react-router-dom';
import { SubHeader } from 'shared/UI/components/SubHeader/SubHeader';
import { SalesDocumentFormLayout } from '../../../shared/UI/SalesDocumentFormView/SalesDocumentFormLayout';
import { useGetInvoicingActivities } from 'features/Ingresos/InvoicingActivities/UI/hooks/useGetInvoicingActivities';
import { useGetCountriesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { addCountryNameToClient } from 'features/Ingresos/Clientes/services/AssignLocationNameToClient';
import { ComponentProps } from 'react';
import { Country } from 'features/Ingresos/Parameters/Locations/domain';
import { MainLoaderSalesDocument } from 'features/Ingresos/SalesDocument/shared/UI/MainLoader/MainLoader';
import { getQuoteSchema } from '../../validations/QuoteSchema';
import { Quote } from '../../domain/Quote';
import { useGetQuote } from '../hooks/useGetQuote';
import { useEditQuote } from '../hooks/useEditQuote';

type QuoteEditViewContentProps = Omit<
    ComponentProps<typeof SalesDocumentFormLayout>,
    'salesDocumentMode' | 'defaultValues' | 'salesDocumentType' | 'salesDocumentSchema'
> & { quoteData: Quote; countries: Country[] };

const QuoteEditViewContent = ({
    handleSubmit,
    isMutationPending,
    quoteData,
    countries,
    formBlocked,
}: QuoteEditViewContentProps) => {
    const quoteDefaultValues = {
        ...quoteData,
        client: addCountryNameToClient(quoteData.client, countries),
    };

    return (
        <SalesDocumentFormLayout
            salesDocumentSchema={getQuoteSchema({
                salesDocumentMode: 'edit',
                defaultValues: quoteDefaultValues,
            })}
            salesDocumentType="quote"
            formBlocked={formBlocked}
            salesDocumentMode="edit"
            handleSubmit={handleSubmit}
            isMutationPending={isMutationPending}
            defaultValues={quoteDefaultValues}
        />
    );
};

export const QuoteEditView = () => {
    const { quoteUUID } = useParams<{ quoteUUID: string }>();
    const history = useHistory();

    const { handleSubmit, isMutationPending } = useEditQuote();
    const { isLoading: isLoadingQuote, quoteData, isError: isErrorFetchingQuoteData } = useGetQuote({ quoteUUID });
    const { isLoading: isLoadingInvoicingActivities } = useGetInvoicingActivities();
    const { isLoading: isLoadingCountries, countries } = useGetCountriesList();

    if (isErrorFetchingQuoteData) {
        return history.push('/ingresos/presupuestos');
    }

    const isLoading = isLoadingQuote || isLoadingInvoicingActivities || isLoadingCountries;

    return (
        <div style={{ lineHeight: '24px' }}>
            <SubHeader title="Editar presupuesto" contextPath="/ingresos/presupuestos" />
            {isLoading ? (
                <MainLoaderSalesDocument />
            ) : (
                <QuoteEditViewContent
                    handleSubmit={handleSubmit}
                    isMutationPending={isMutationPending}
                    quoteData={quoteData as Quote}
                    countries={countries}
                    formBlocked={{
                        isFormBlocked: !!quoteData?.transformedToInvoiceId,
                        blockedReason: 'quote-converted-to-invoice',
                    }}
                />
            )}
        </div>
    );
};
