import { Box, Header, P, Tabs } from '@declarando/design_system';
import { Step3TabPlantillaDeclarando } from './Step3TabPlantillaDeclarando';
import { Step3TabOtrosFormatos } from './Step3TabOtrosFormatos';
import { useContext } from 'react';
import { Step3Context } from './Step3Context';
import { publishTabOtrosFormatosContabilidadEvent, publishTabPlantillaExcelEvent } from '../../../services/events';

export const Step3Initial = () => {
    const { tab, setTab, isInicio } = useContext(Step3Context);

    const onChangeTab = (id: string | number) => {
        setTab(+id);
        id === 0 ? publishTabPlantillaExcelEvent() : publishTabOtrosFormatosContabilidadEvent();
    };

    return (
        <>
            <Box px={'sm'} mx={'auto'} py={'xxl'} width={'100%'} maxWidth={700}>
                <Box alignCenter>
                    <Header as={'h2'}>Importa tu contabilidad</Header>
                    <P mt={'xs'}>Para trasladar la información de tu negocio a tu cuenta en Declarando.</P>
                </Box>
                <Box my={'lg'} mx={'auto'}>
                    <Tabs
                        bottomLine
                        activeTab={tab}
                        variant={'fill'}
                        onChange={onChangeTab}
                        tabs={[
                            {
                                id: 0,
                                label: 'En excel',
                                icon: 'file-declarando',
                                isDisabled: !isInicio,
                                tag: { children: 'Gratis', size: 'small', variant: 'success' },
                            },
                            { id: 1, label: 'Otros formatos', icon: 'folder-upload' },
                        ]}
                    />

                    {tab === 0 ? <Step3TabPlantillaDeclarando /> : <Step3TabOtrosFormatos />}
                </Box>
            </Box>
        </>
    );
};
