import { usePost } from 'shared/UI/hooks/QueryHooks';
import { createClientsService } from '../../services/ClientsService';
import { createClientsRepository } from '../../infra/ClientRepository';
import { Client } from '../../domain';

export const useCreateClient = () => {
    const service = createClientsService(createClientsRepository());

    const createClient = usePost<Client, string>({
        queryKey: ['clients'],
        mutationFn: (client) => service.createClient(client),
    });

    return { createClient };
};
