import SecureRepository from './SecureRepository';

export default class ImpuestosRepository extends SecureRepository {
    gotoModifyRenting() {
        return '/alquileres/';
    }

    gotoUnregisterRenting() {
        return '/bajaalquileres/';
    }
}
