import V2Repository from '../../../shared/infra/repositories/V2Repository';
import { Env } from '../../../config/Env';
import { TOnboarding } from '../domain/TOnboarding';

export type TSiguentePasoArgs = { data?: string };
export type TImportarContabildadArgs = FormData;
export class OnboardingRepository extends V2Repository {
    async siguientePaso(data?: TSiguentePasoArgs): Promise<TOnboarding> {
        if (Env.get('VITE_DEVELOPER') === 'adrianM') {
            return Promise.resolve({
                isActive: true,
                step: Env.get('VITE_NOAFFA_SIGUIENTE'),
            });
        }
        return this.post('/onboarding/siguiente-paso', JSON.stringify({ data }));
    }

    async retrocederPaso(): Promise<TOnboarding> {
        if (Env.get('VITE_DEVELOPER') === 'adrianM') {
            return Promise.resolve({
                isActive: false,
                step: Env.get('VITE_NOAFFA_SIGUIENTE'),
            });
        }
        return this.post('/onboarding/retroceder-paso');
    }

    async solicitudPagoCertificado(): Promise<{ url: string }> {
        if (Env.get('VITE_DEVELOPER') === 'adrianM') {
            return Promise.resolve({
                url: '/configuracion?env=VITE_NOAFFA_STATE:CERTIFICADO_PROCESANDO_PAGO',
            });
        }
        return this.post(
            '/pedidos/solicitud-pago',
            JSON.stringify({
                productoCodigo: 'CERTIFICADO_DIGITAL',
                origen: 'CERTIFICADO_DIGITAL_ONBOARDING',
            }),
        );
    }

    async solicitudPagoContabilidad(): Promise<{ url: string }> {
        if (Env.get('VITE_DEVELOPER') === 'adrianM') {
            return Promise.resolve({
                url: '/configuracion?env=VITE_NOAFFA_STATE:CONTABILIDAD_PROCESANDO_PAGO',
            });
        }
        return this.post(
            '/pedidos/solicitud-pago',
            JSON.stringify({
                productoCodigo: 'CONTABILIDAD_IMPORTACION',
                origen: 'CONTABILIDAD_IMPORTACION_ONBOARDING',
            }),
        );
    }

    async getOnboarding(): Promise<TOnboarding> {
        if (Env.get('VITE_DEVELOPER') === 'adrianM') {
            return Promise.resolve({
                isActive: true,
                step: Env.get('VITE_NOAFFA_STATE'),
            });
        }
        return this.get('/onboarding');
    }

    importarContabilidad(formData: TImportarContabildadArgs): Promise<{ success: boolean }> {
        if (Env.get('VITE_DEVELOPER') === 'adrianM') {
            return Promise.resolve({ success: true });
        }

        return this.post('/onboarding/contabilidad/importar', formData);
    }
}
