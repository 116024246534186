import { Box, Confetti, Header, ModalChoice, P } from '@declarando/design_system';
import Mentor from './assets/mentor.svg';
import { useModal } from '../../../hooks/useModal';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const ModalReactivacionCuentaSuccess = () => {
    const history = useHistory();
    const modal = useModal();
    useEffect(() => {
        const location = history.location;
        if (location.search.includes('modal-reactivacion-cuenta=success')) {
            setTimeout(() => {
                new Confetti('confetti-canvas').run();
            }, 600);
            modal.showModal();
        }
    }, []);

    return (
        <ModalChoice
            isHeaderHidden
            title={'Tu período de prueba de 14 días ha finalizado'}
            isOpen={modal.show}
            showCancel={false}
            handleAction={() => {
                history.push('/inicio');
                modal.closeModal();
            }}
            labelAction={'Finalizar reactivación'}
        >
            <Box alignCenter paddingTop={'24px'}>
                <img src={Mentor} alt="Periodo de prueba finalizado" />
                <Header as="h2" paddingTop={'md'} marginBottom={'xxs'}>
                    ¡Enhorabuena! Has reactivado tu cuenta.
                </Header>
                <P paddingBottom={'lg'}>
                    Recupera el control de tu negocio y alcanza la tranquilidad fiscal con Declarando.
                </P>
            </Box>
        </ModalChoice>
    );
};
