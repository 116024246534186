import { useUpdate } from '../../../shared/UI/hooks/QueryHooks';
import { TImportarContabildadArgs } from './OnboardingRepository';
import { OnboardingService } from './OnboardingService';

export const useImportarContabilidad = () => {
    return useUpdate({
        queryKey: ['onboardingImportarContabilidad'],
        mutationFn: (data: TImportarContabildadArgs) => new OnboardingService().importarContabilidad(data),
    });
};
