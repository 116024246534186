import { Env } from 'config/Env';
import mixpanel from 'mixpanel-browser';

export default class MixPanel {
    // This function initializes a new instance of the Mixpanel tracking object.
    // docs: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelinit
    static init() {
        try {
            mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, { debug: Env.isLocal(), track_pageview: true });
        } catch {
            console.error('MixPanel.init error', import.meta.env.VITE_MIXPANEL_TOKEN);
        }
    }

    // This function is used to track an event
    // docs: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack
    static track(event: string, props?: object) {
        try {
            mixpanel.track(event, props);
        } catch {
            console.error('MixPanel.track error', event, props);
        }
    }

    // The alias method creates an alias which Mixpanel will use to remap one id to another. Multiple aliases can point to the same identifier.
    // e.g. we have a PUBLIC random id from a user navigating from outside the APP and we want to match their events after they signup
    //docs: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelalias
    static alias(new_user_id: string, exisiting_user_id: string) {
        try {
            mixpanel.alias(new_user_id, exisiting_user_id);
        } catch {
            console.error('MixPanel.alias error', new_user_id, exisiting_user_id);
        }
    }

    // Identify a user with a unique ID to track user activity across devices. If left blank it assigns a random ID, this is useful in PUBLIC.
    // docs: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelidentify
    static identify(user_id?: string) {
        try {
            mixpanel.identify(user_id);
        } catch {
            console.error('MixPanel.identify error', user_id);
        }
    }

    // Register a set of super properties, which are included with all events. This will overwrite previous super property values.
    // docs: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelunregister
    static unregister(prop: string) {
        if (typeof prop !== 'string') {
            throw new Error(
                `MixPanel.register received a param of type '${typeof prop}' and it expects to receive param of type 'object'.`,
            );
        } else {
            try {
                mixpanel.unregister(prop);
            } catch {
                console.error('MixPanel.unregister error', prop);
            }
        }
    }

    static setProfileData(props: Record<string, number | string>) {
        try {
            mixpanel.people.set(props);
        } catch {
            console.error('MixPanel.setProfileData', props);
        }
    }

    static trackPageView(props: object) {
        try {
            mixpanel.track_pageview(props);
        } catch {
            console.error('MixPanel.trackPageView', props);
        }
    }
}
