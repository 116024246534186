import {
    InvoiceDetailedTotals,
    InvoiceCalculations,
    invoiceCalculations,
    CalculateDetailedInvoiceTotalsArgs,
} from '../domain/IngresoConFacturaCalculations';
import { Invoice } from '../domain/Invoice';
import { invoiceLineCalculations, InvoiceLineCalculations } from '../domain/InvoiceLineCalculations';

export type CalculateInvoiceLineArgs = {
    fromField: 'price' | 'totalLine';
    line: Invoice['lines'][number];
    withEquivalencySurcharge: boolean;
};

export const createInvoiceLineCalculationService = (invoiceLineCalculations: InvoiceLineCalculations) => {
    return {
        calculateInvoiceLine: (args: CalculateInvoiceLineArgs) => {
            const { fromField, line, withEquivalencySurcharge } = args;

            if (fromField === 'totalLine') {
                const newPrice = invoiceLineCalculations.calculatePriceFromTotal(
                    line.totalLine ?? 0,
                    line.quantity,
                    line.discount ?? 0,
                    line.vat,
                    line.equivalencySurcharge ?? 0,
                    withEquivalencySurcharge,
                );
                return invoiceLineCalculations.calculateLineAmounts(
                    { ...line, price: newPrice },
                    withEquivalencySurcharge,
                );
            } else {
                return invoiceLineCalculations.calculateLineAmounts(line, withEquivalencySurcharge);
            }
        },
    };
};

const createInvoiceCalculationsService = (invoiceCalculations: InvoiceCalculations) => {
    return {
        calculateInvoiceTotals: (args: CalculateDetailedInvoiceTotalsArgs): InvoiceDetailedTotals =>
            invoiceCalculations.calculateDetailedInvoiceTotals(args),
    };
};

export const invoiceLineCalculationsService = createInvoiceLineCalculationService(invoiceLineCalculations);
export const invoiceCalculationsService = createInvoiceCalculationsService(invoiceCalculations);
