import { SalesDocumentSendEmailRepository } from '../../shared/domain/SalesDocumentSendEmailWithDocument';
import { SalesDocumentSendEmailService } from '../../shared/services/SalesDocumentSendEmailService';

class QuoteSendEmailService extends SalesDocumentSendEmailService {
    constructor(repository: SalesDocumentSendEmailRepository) {
        super(repository);
    }
}

export const createQuoteSendEmailService = (repository: SalesDocumentSendEmailRepository): QuoteSendEmailService => {
    return new QuoteSendEmailService(repository);
};
