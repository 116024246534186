import V2Repository from './V2Repository';

class CuotaRepository extends V2Repository {
    checkCuotaCompensar(year) {
        return this.get('/advices/cuotacompensar/' + year);
    }

    checkComplementaria() {
        return this.get('/advices/complementaria');
    }
}

export default CuotaRepository;
