export enum ClienteTypesMapper {
    Empresa = 'Empresa',
    Particular = 'Particular',
    'Autónomo' = 'Autónomo',
}

export enum ClienteTypes {
    Empresa = 'Empresa',
    Particular = 'Particular',
    'Autónomo' = 'Autónomo',
}
