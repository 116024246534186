import React from 'react';
import { trackParams } from 'shared/infra/utils/user_events';
import { RouteParams } from './appRoutes';

const PlanData = React.lazy(() => import('features/Contrataciones/Planes/PlanData'));
const Contratacion = React.lazy(() => import('features/Contrataciones/Contratacion'));
const SepaComplete = React.lazy(() => import('features/Contrataciones/SepaComplete'));
const VisaCorrect = React.lazy(() => import('features/Contrataciones/VisaCorrect'));
const VisaError = React.lazy(() => import('features/Contrataciones/VisaError'));
// const OldPlanData = React.lazy(() => import('features/Contrataciones/OldPlanData'));

const contrataRoutes: RouteParams[] = [
    {
        name: 'Planes',
        path: '/contrata/planes',
        icon: 'icon-home',
        component: PlanData,
        track_params: trackParams.tu_plan,
    },
    {
        name: 'Contratacion',
        path: '/contrata/plan/:plan/:type?',
        icon: 'icon-home',
        component: Contratacion,
    },
    { name: 'SepaComplete', path: '/contrata/sepa/completado', icon: 'icon-home', component: SepaComplete },
    { name: 'VisaCorrecto', path: '/contrata/visa/completado', icon: 'icon-home', component: VisaCorrect },
    { name: 'VisaFallo', path: '/contrata/visa/error', icon: 'icon-home', component: VisaError },
];

export default contrataRoutes;
