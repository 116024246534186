export type TDeclarandoApiResponse<T> = {
    success: boolean;
    error: boolean;
    message: string;
    data?: T;
    code?: number;
    body: any;
};

export class DeclarandoApiResponse<T> implements TDeclarandoApiResponse<T> {
    success = false;
    error = false;
    message = '';
    data?: T;
    code?: number;
    body: any;

    setData(data: T) {
        this.success = true;
        this.data = data;
    }

    setErrorMessage(message: string, code?: number) {
        this.error = true;
        this.message = message;
        this.code = code;
    }
}
