export function debounce(func, timeout = 100) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export class Debounce {
    timer;

    registerAction(func, timeout = 100) {
        return (...args) => {
            clearTimeout(this.timer);
            this.timer = setTimeout(function () {
                func.apply(this, args);
            }, timeout);
        };
    }
}
