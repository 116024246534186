import { FeatureFlags } from 'config/FeatureFlags';
import { OnboardingUrlPathMapper } from '../../../../routes/setupNoAFFA.jsx';

export default class AppRoutesRepository {
    getLoginUri() {
        return '/login';
    }

    getRegisterUri() {
        if (FeatureFlags.cypressTest()) {
            return '/registro';
        }
        return 'https://declarando.es/?registrate';
    }

    getResetUri() {
        return '/password';
    }

    getHomeUri() {
        return '/inicio';
    }

    getOnboardingAutonomoUri() {
        return '/inicial/autonomo';
    }

    getOnboardingNoAutonomoUri() {
        return '/inicial/no-autonomo';
    }

    getPlanUri(id) {
        return '/contrata/plan/' + id;
    }

    getProfileUri() {
        return '/user/profile';
    }

    getSetupNoAFFAUri(step) {
        if (step && OnboardingUrlPathMapper[step]) {
            return OnboardingUrlPathMapper[step];
        }
        return '/configuracion';
    }

    getAgentUri() {
        return '/agente';
    }

    getInvoicesListUri() {
        return '/listado-facturas';
    }

    getInviteFriend() {
        return '/invita-amigo';
    }

    getPlansUri() {
        return '/contrata/planes';
    }

    getBanksUri() {
        return '/user/profile/bancos';
    }

    getFiscalUri() {
        return '/user/fiscal';
    }

    getIncomesUri() {
        return '/ingresos/ingresos';
    }

    getMovimientosBancarios() {
        return '/ingresos/movimientos-bancarios';
    }

    getInvoicedIncomesUri() {
        return '/ingreso/con-factura';
    }

    getUninvoicedIncomesUri() {
        return '/ingreso/sin-factura';
    }

    getExpensesUri() {
        return '/gastos/gastos';
    }

    getInvoicedExpensesUri() {
        return '/gasto/con-factura';
    }

    getUninvoicedExpensesUri() {
        return '/gasto/sin-factura';
    }

    getExpenseFromTransacctionUri() {
        return '/gasto/transaccion';
    }

    getImpuestosUri() {
        return '/impuestos';
    }

    getImpuestosModelosPresentadosUri(modelo = '') {
        const path = '/impuestos/modelos-presentados';
        if (!modelo) {
            return path;
        }
        return `${path}?modelo=${modelo}`;
    }

    getCalculadoraUri() {
        return '/calculadora-renta';
    }

    getDeclarandoCardUri() {
        return '/tarjeta/informacion';
    }

    getInformeRentaUri() {
        return '/impuestos/informe-renta';
    }

    getInformeRentaCuentaPropiaUri() {
        return '/impuestos/informe-renta/cuenta-propia';
    }

    getActivacionTarjetaUri() {
        return '/tarjeta/informacion/activacion-tarjeta';
    }

    getValidate3dsModalUri() {
        return '/listado-facturas';
    }

    getListaFacturasUri() {
        return '/listado-facturas';
    }

    getTransferenciaUri() {
        return '/tarjeta/transferencia';
    }

    getProfileAltaAutonomoUri() {
        return '/user/profile/alta-autonomo';
    }
}
