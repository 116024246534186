import { Button, TopBanner } from '@declarando/design_system';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import { AppRouter } from '../../../../../routes/AppRouter';

const useBannerPeriodoPrueba = () => {
    const { isPeriodoDemo, diasRestantesDemo } = useRootContext();

    /*if (Env.isLocal('adrianM') && FeatureFlags.enableTopBannerPeriodoPrueba()) {
        return {
            isPeriodoDemo: true,
            diasRestantesDemo: 0,
        };
    }*/

    return {
        isPeriodoDemo,
        diasRestantesDemo,
    };
};

export const BannerPeriodoPrueba = () => {
    const { isPeriodoDemo, diasRestantesDemo } = useBannerPeriodoPrueba();

    return isPeriodoDemo ? (
        <TopBanner
            variant={'neutral'}
            icon={'clock'}
            text={
                diasRestantesDemo < 1 ? (
                    <>Hoy finaliza tu período de prueba</>
                ) : (
                    <>
                        Te quedan{' '}
                        <b>
                            {' '}
                            {diasRestantesDemo} {diasRestantesDemo === 1 ? 'día' : 'días'}
                        </b>{' '}
                        para finalizar tu período de prueba{' '}
                    </>
                )
            }
            ctaButton={<Button size="M" label="Contrata tu plan" onClick={() => AppRouter.goToPlan()} />}
        />
    ) : null;
};
