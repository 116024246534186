import { useCallback, useMemo } from 'react';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { InvoiceTaxes } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/InvoiceTaxes';
import { invoiceLineCalculationsService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaCalculationService';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { SalesDocumentLine } from 'features/Ingresos/SalesDocument/shared/domain/SalesDocument';

//TODO: Move to domain
const getEquivalenceSurcharge = (line: SalesDocumentLine, taxes?: InvoiceTaxes['taxes'], vat?: number) => {
    if (!taxes || line.productType === 'SERVICES') return 0;
    const taxesProduct = taxes['goods'];
    return taxesProduct?.find((tax) => tax.vat === (vat ?? line.vat))?.equivalencySurcharge ?? 0;
};

const calculateLineValues = (args: {
    line: SalesDocumentLine;
    withEquivalencySurcharge: boolean;
    fromField: 'price' | 'totalLine';
}) => {
    return invoiceLineCalculationsService.calculateInvoiceLine(args);
};

export const useSalesDocumentLineCalculations = (taxes?: InvoiceTaxes['taxes']) => {
    const { getValues, setValue, trigger } = useCustomFormContext<InvoiceForm>();

    const handleLineChange = useCallback(
        (
            index: number,
            field: keyof SalesDocumentLine,
            value: any,
            options: { shouldTriggerValidation?: boolean; shouldDirty?: boolean } = { shouldTriggerValidation: true },
        ) => {
            setValue(`lines.${index}.${field}`, value, { shouldDirty: options?.shouldDirty });
            const withEquivalencySurcharge = getValues('withEquivalencySurcharge');
            const lineValues = getValues(`lines.${index}`);

            const equivalencySurchargeValue = getEquivalenceSurcharge(
                lineValues,
                taxes,
                field === 'vat' ? value : undefined,
            );

            const updatedLine = {
                ...lineValues,
                equivalencySurcharge: equivalencySurchargeValue,
                [field]: value,
            } as SalesDocumentLine;

            const fromField = field === 'totalLine' ? 'totalLine' : 'price';
            const calculatedValues = calculateLineValues({
                line: updatedLine,
                withEquivalencySurcharge: withEquivalencySurcharge ?? false,
                fromField,
            });

            setValue(
                `lines.${index}.${fromField === 'totalLine' ? 'price' : 'totalLine'}`,
                calculatedValues[fromField === 'totalLine' ? 'price' : 'totalLine'],
            );
            setValue(`lines.${index}.equivalencySurcharge`, equivalencySurchargeValue);

            if (options?.shouldTriggerValidation) {
                trigger(`lines.${index}.price`);
                trigger(`lines.${index}.totalLine`);
            }

            //NOTE: Manual trigger to validate totalLine rules when changing values of a line
            const lines = getValues('lines');
            lines.forEach((_: SalesDocumentLine, index: number) => {
                if (options?.shouldTriggerValidation) {
                    trigger(`lines.${index}.totalLine`);
                }
            });
        },

        [setValue, getValues, taxes, trigger],
    );

    const updateEquivalencySurchargeSalesDocumentLines = useCallback(
        (args: { withEquivalencySurcharge: boolean }) => {
            const lines = getValues('lines');
            const updatedLines = lines.map((line: SalesDocumentLine) => {
                const equivalencySurchargeValue = args.withEquivalencySurcharge
                    ? getEquivalenceSurcharge(line, taxes)
                    : 0;
                const updatedLine = { ...line, equivalencySurcharge: equivalencySurchargeValue };
                const recalculatedLine = calculateLineValues({
                    line: updatedLine,
                    withEquivalencySurcharge: args.withEquivalencySurcharge,
                    fromField: 'price',
                });

                return {
                    ...line,
                    ...recalculatedLine,
                    equivalencySurcharge: equivalencySurchargeValue,
                };
            });
            setValue('lines', updatedLines as SalesDocumentLine[]);
        },
        [getValues, setValue, taxes],
    );

    return useMemo(
        () => ({
            handleLineChange,
            updateEquivalencySurchargeSalesDocumentLines,
        }),
        [handleLineChange, updateEquivalencySurchargeSalesDocumentLines],
    );
};
