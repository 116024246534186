import { CalculateDetailedInvoiceTotalsArgs } from '../domain/IngresoConFacturaCalculations';
import {
    ingresoConFacturaPaymentOptionsRules,
    IngresoConFacturaPaymentOptionsRules,
} from '../domain/IngresoConFacturaPaymentOptionsRules/IngresoConFacturaPaymentOptionsRules';
import { invoiceCalculationsService } from './IngresoConFacturaCalculationService';

const createInvoicePaymentMethodService = (invoicePaymentMethodsRule: IngresoConFacturaPaymentOptionsRules) => {
    return {
        isCashPaymentAmountValid: (args: CalculateDetailedInvoiceTotalsArgs) => {
            const amount = invoiceCalculationsService.calculateInvoiceTotals(args).totalAmount;

            return invoicePaymentMethodsRule.cash.isLessOrEqualThanMaximumAllowed(amount);
        },
        isBankTransferPaymentMethodValid: (iban: string) => {
            return invoicePaymentMethodsRule.bankAccount.isSpanishBankAccountCorrect(iban);
        },
    };
};

export const invoicePaymentMethodService = createInvoicePaymentMethodService(ingresoConFacturaPaymentOptionsRules);
