import { useGetQuote } from '../hooks/useGetQuote';
import { useHistory, useParams } from 'react-router-dom';
import { IngresoConFacturaCreationView } from 'features/Ingresos/SalesDocument/IngresoConFactura/UI/IngresoConFacturaCreationView/IngresoConFacturaCreationView';
import { MainLoaderSalesDocument } from 'features/Ingresos/SalesDocument/shared/UI/MainLoader/MainLoader';
import { Invoice } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { Quote } from '../../domain/Quote';
import { invoiceDefaultValues } from 'features/Ingresos/SalesDocument/IngresoConFactura/UI/invoice-default-values';
import { FeatureFlags } from 'config/FeatureFlags';
import { useIngresoConFactura } from 'features/Ingresos/SalesDocument/IngresoConFactura/UI/hooks/useIngresoConFactura';
import { useSalesDocumentMutation } from 'features/Ingresos/SalesDocument/shared/UI/hooks/useSalesDocumentMutation';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { useUpdate } from 'shared/UI/hooks/QueryHooks';
import { createQuoteRepository } from '../../infra/QuoteRepository';
import { createQuoteService } from '../../services/QuoteService';
import { useGetCountriesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { addCountryNameToClient } from 'features/Ingresos/Clientes/services/AssignLocationNameToClient';

function convertQuoteDefaultValuesToInvoiceDefaultValues(quoteDefaultValues: Quote): Invoice {
    return {
        ...quoteDefaultValues,
        documentNumber: invoiceDefaultValues.documentNumber,
        issueDate: invoiceDefaultValues.issueDate,
        dueDate: invoiceDefaultValues.dueDate,
    };
}

export const useQuoteTransformToInvoiceMutation = () => {
    const service = createQuoteService(createQuoteRepository());
    const updateQuoteWithInvoice = useUpdate<{ invoiceId: string; quoteId: string }, string>({
        queryKey: ['quotes'],
        mutationFn: (data) => service.convertToInvoice(data),
    });

    return { updateQuoteWithInvoice };
};

export const useTransformQuoteToInvoice = (quoteId: string) => {
    const { createInvoice } = useIngresoConFactura();
    const { updateQuoteWithInvoice } = useQuoteTransformToInvoiceMutation();

    return useSalesDocumentMutation({
        mutation: {
            ...createInvoice,
            mutate: async (data, options) => {
                try {
                    const invoiceResult = await createInvoice.mutateAsync(data);

                    await updateQuoteWithInvoice.mutateAsync({
                        quoteId,
                        invoiceId: invoiceResult.data as string,
                    });

                    options?.onSuccess?.(invoiceResult, data, null);
                } catch (error) {
                    const errorData = error as Error;
                    options?.onError?.(errorData, data, null);
                }
            },
        },
        trackingEvent: () => TrackingEvents.clickElement({ event_label: 'Transforma presupuesto a factura' }),
        successMessage: {
            title: 'Ingreso creado correctamente',
            message: 'Puedes consultarlo en el listado de ingresos.',
            link: { label: 'Ir al listado de ingresos', href: '/ingresos/ingresos' },
        },
        errorMessage: {
            title: 'No ha sido posible crear el ingreso',
            message: 'Por favor, vuelve a intentarlo.',
        },
        getSuccessRedirectPath: (id) => FeatureFlags.shouldNewInvoiceFormBeEnabled().getInvoicePreviewURL(id),
    });
};

export const QuoteTransformToInvoiceView = () => {
    const { quoteUUID } = useParams<{ quoteUUID: string }>();
    const { handleSubmit, isMutationPending } = useTransformQuoteToInvoice(quoteUUID);
    const { quoteData, isLoading, isError: isErrorFetchingQuote } = useGetQuote({ quoteUUID });
    const { isLoading: isLoadingCountries, countries } = useGetCountriesList();

    const history = useHistory();

    if (!quoteUUID || isErrorFetchingQuote || quoteData?.transformedToInvoiceId) {
        history.push('/ingresos/presupuestos');
        return null;
    }

    if (isLoading || isLoadingCountries) return <MainLoaderSalesDocument />;

    const baseDefaultValues = convertQuoteDefaultValuesToInvoiceDefaultValues(quoteData as Quote);
    const invoiceDefaultValues = {
        ...baseDefaultValues,
        client: addCountryNameToClient(baseDefaultValues.client, countries),
    };

    return (
        <IngresoConFacturaCreationView
            defaultValues={invoiceDefaultValues}
            mutationHook={{ handleSubmit, isMutationPending }}
        />
    );
};
