import V2Repository from './V2Repository';
import { DeclarandoApiResponse } from '../../domain/http/DeclarandoApiResponse';
import { TProfile } from '../../../features/UserConfig/domain/TProfile';
import { Env } from '../../../config/Env';

export default class UserV2Repository extends V2Repository {
    getRentaResult() {
        return this.get('/user/stats/renta');
    }

    getIaeBorrable(iaeId: string | number) {
        return this.get(`/user/iae/${iaeId}/borrable`);
    }

    getProfile(): Promise<TProfile> {
        return this.get('/user/profile');
    }

    getInvoiceStyleInfo() {
        return this.get('/user/invoiceStyleInfo');
    }

    storeInvoiceStyleInfo(invoiceStyleInfo: any) {
        return this.post('/user/invoiceStyleInfo', JSON.stringify(invoiceStyleInfo));
    }

    getUserIaes() {
        return this.get('/user/iaes');
    }

    storeIdentity(identity: any) {
        return this.post('/user/identity', JSON.stringify(identity));
    }

    storeStatus(status: any) {
        return this.post('/user/fiscalStatus', JSON.stringify(status));
    }

    storeAddress(address: any) {
        return this.post('/user/address', JSON.stringify(address));
    }

    storeWorkAddress(workAddress: any) {
        return this.post('/user/workAddress', JSON.stringify(workAddress));
    }

    getResumeStats(period: string): Promise<
        DeclarandoApiResponse<{
            beneficio: number;
            ahorro: number;
            impuestosPeriod: string;
            objetivoAhorro: number;
        }>
    > {
        return this.get('/user/stats/resume/' + period);
    }

    getImpuestos(): Promise<{ impuestos: number }> {
        return this.get('/user/stats/impuestos');
    }

    getObjetivoAhorroAnual(): Promise<{ ahorro: number; 'objetivo-ahorro': number }> {
        return this.get('/user/objetivo-ahorro-anual');
    }

    getOperationsStats(period: string): Promise<
        DeclarandoApiResponse<{
            ingresos: number;
            gastos: number;
        }>
    > {
        return this.get('/user/stats/operations/' + period);
    }

    getTotalIncomesAndExpenses(month: string | number) {
        return this.get('/user/stats/operationsAF/' + month);
    }

    getInformeRentaResults(year: string | number) {
        const url = year ? '/user/informe-renta?year=' + year : '/user/informe-renta';
        return this.get(url);
    }

    getFacturasServicios() {
        return this.get('/facturas-declarando');
        /*.then(
            (response: { id: string; fecha: string; numero: string; proveedor: string; importe: string }[]) => {
                return response.map((data) => {
                    return {
                        uuid: data.id,
                        fecha: data.fecha,
                        numeracion: data.numero,
                        nombreProveedor: data.proveedor,
                        total: data.importe,
                    };
                });
            },
        );*/
        // return this.get('/gastos-declarando');
    }

    isVisaCustomer() {
        return this.get('/user/visacustomer');
    }

    getAllIaes() {
        return this.get('/iaes/lista');
    }

    triggerEvent(event: string) {
        return this.post(
            '/evento',
            JSON.stringify({
                event: event,
            }),
        );
    }

    checkEmail(email: string) {
        return this.post('/user/email/existe', JSON.stringify({ email: email }));
    }

    gastosFrecuentes(): Promise<string[]> {
        return this.get('/user/tipos-gastos/mas-frecuentes');
    }

    actualizarPassword(password: { actual: string; nueva: string; confirmacion: string }) {
        return this.put('/user/password', JSON.stringify(password));
    }

    async getActivationPlan(): Promise<{ url: string }> {
        if (Env.isLocal('adrianM')) {
            return { url: 'https://google.com' };
        }
        return this.get('/user/suscription/reactivate');
    }

    // async getRecurrenteData(): Promise<TRecurrente> {
    //     return {
    //         estado: 'IMPAGADO_FINAL',
    //         renovacion: {
    //             intentos: 1,
    //             maximoIntentos: 3,
    //         },
    //     };
    // }

    async getRecurrenteData(): Promise<TRecurrente> {
        if (Env.isLocal('adrianM')) {
            return {
                estado: 'IMPAGADO_FINAL',
                renovacion: {
                    intentos: 1,
                    maximoIntentos: 3,
                    puedeRenovar: true,
                },
            };
        }
        return this.get('/user/recurrente');
    }
}

export type TRecurrente = {
    estado: 'PAGADO' | 'IMPAGADO_NUEVO' | 'IMPAGADO_FINAL' | 'CANCELADO' | 'PENDIENTE' | null;
    renovacion: {
        intentos: number;
        maximoIntentos: number;
        puedeRenovar: boolean;
    };
};
