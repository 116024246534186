import React from 'react';

const Thanks = React.lazy(() => import('shared/UI/components/Card/Thanks'));
const Calendar = React.lazy(() => import('shared/UI/components/Card/Calendar'));

const contratadoRoutes = [
    { name: 'Decision', path: '/contratado/final', icon: 'icon-home', component: Calendar, wide: false },
    { name: 'Gracias', path: '/contratado/gracias', icon: 'icon-home', component: Thanks, wide: false },
];

export default contratadoRoutes;
