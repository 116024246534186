import { ContentLoader } from '@declarando/design_system';
import * as React from 'react';
import { ReactNode, useContext, useEffect } from 'react';
import { Step1Initial } from './Step1Initial';
import { Step1Context, Step1ContextProvider } from './Step1Context';

export const Step1Mapper = () => {
    const { StateKey, state } = useContext(Step1Context);

    const StatesMapper: { [keys: string]: ReactNode } = {
        [StateKey.INITIAL]: <Step1Initial />,
        [StateKey.LOADING]: <ContentLoader text={'Cargando...'} />,
    };

    return <>{StatesMapper[state] ?? StatesMapper[StateKey.INITIAL]}</>;
};

export const Step1 = () => {
    return (
        <Step1ContextProvider>
            <Step1Mapper />
        </Step1ContextProvider>
    );
};
