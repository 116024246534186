import { Button, TopBanner } from '@declarando/design_system';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';

import { AppRouter } from '../../../../../routes/AppRouter';

export const BannerImpagado = () => {
    const { recurrenteData } = useRootContext();
    const onClick = () => {
        AppRouter.fomaDePago();
    };

    if (!recurrenteData || recurrenteData.estado !== 'IMPAGADO_NUEVO') {
        return null;
    }

    const { intentos, maximoIntentos } = recurrenteData.renovacion;

    const dias = maximoIntentos - intentos;

    return (
        <TopBanner
            variant={'warning'}
            icon={'exclamation-triangle'}
            title="Intento de cobro fallido"
            text={
                <>
                    {dias > 1 ? 'Quedan' : 'Queda'}{' '}
                    <b>
                        {dias} {dias > 1 ? 'días' : 'día'}
                    </b>{' '}
                    para bloquear tu cuenta. Revisa tu <b>forma de pago y saldo disponible</b> lo antes posible.
                </>
            }
            ctaButton={<Button size="M" label="Mi forma de pago" onClick={onClick} />}
        />
    );
};
