import { Input } from '@declarando/design_system';
import { invoiceDocumentNumberUnique } from 'features/Ingresos/SalesDocument/IngresoConFactura/validations/InvoiceDocumentNumberUnique';
import { useEffect } from 'react';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { SkeletonInput } from 'shared/UI/components/Skeletons/Input/SkeletonInput';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import { useGetNextSalesDocumentNumber } from '../hooks/useGetNextSalesDocumentNumber';
import { useSalesDocumentFormContext } from '../../../../context/SalesDocumentFormProvider';
import { BaseSalesDocumentForm } from 'features/Ingresos/SalesDocument/shared/domain/SalesDocument';

type SalesDocumentNumberInputProps = {
    isReadOnly?: boolean;
    onCustomChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SalesDocumentNumberInput = ({ isReadOnly = false, onCustomChange }: SalesDocumentNumberInputProps) => {
    const { control } = useCustomFormContext<BaseSalesDocumentForm>();

    return (
        <ControlledFormField
            control={control}
            name="documentNumber"
            Component={({ field, formState: { errors } }) => (
                <Input
                    {...field}
                    onChange={(e) => {
                        field.onChange(e);
                        onCustomChange?.(e);
                    }}
                    readOnly={isReadOnly}
                    helperText={errors?.documentNumber?.message}
                    error={!!errors?.documentNumber?.message}
                    id="sales-document-number"
                    label="Nº de documento"
                    required
                />
            )}
        />
    );
};

const SalesDocumentNumberAutomaticNumerationCreationMode = () => {
    const { setValue } = useCustomFormContext<BaseSalesDocumentForm>();
    const { isLoading, nextSalesDocumentNumber } = useGetNextSalesDocumentNumber();

    useEffect(() => {
        if (!isLoading && nextSalesDocumentNumber) {
            setValue('documentNumber', nextSalesDocumentNumber);
        }
    }, [setValue, nextSalesDocumentNumber, isLoading]);

    if (isLoading) return <SkeletonInput labelHeight="22px" />;

    return <SalesDocumentNumberInput isReadOnly />;
};

const SalesDocumentNumberAutomaticNumerationEditionMode = () => {
    const { isLoading } = useGetNextSalesDocumentNumber();
    if (isLoading) return <SkeletonInput labelHeight="22px" />;

    return <SalesDocumentNumberInput isReadOnly />;
};

const SalesDocumentNumberAutomaticNumeration = () => {
    const { salesDocumentMode } = useSalesDocumentFormContext();

    return salesDocumentMode === 'create' ? (
        <SalesDocumentNumberAutomaticNumerationCreationMode />
    ) : (
        <SalesDocumentNumberAutomaticNumerationEditionMode />
    );
};

const SalesDocumentNumberManualNumeration = () => {
    const { trigger } = useCustomFormContext<BaseSalesDocumentForm>();
    const { defaultValues, salesDocumentMode } = useSalesDocumentFormContext();

    const handleManualNumeration = (e: React.ChangeEvent<HTMLInputElement>) => {
        //NOTE: If is edition, compare value with default value and dont trigger the validation
        invoiceDocumentNumberUnique.validateInputNumberUnique({
            triggerValidation: () => trigger('documentNumber'),
            value: e.target.value,
            defaultValue: defaultValues?.documentNumber,
            invoiceMode: salesDocumentMode,
        });
    };

    return <SalesDocumentNumberInput onCustomChange={handleManualNumeration} />;
};

export const SalesDocumentNumber = () => {
    const { salesDocumentType } = useSalesDocumentFormContext();
    const { userStatus } = useRootContext();
    const { formBlocked } = useSalesDocumentFormContext();

    if (formBlocked.isFormBlocked) return <SalesDocumentNumberInput isReadOnly />;

    return userStatus?.autoNumInvoice || salesDocumentType === 'quote' ? (
        <SalesDocumentNumberAutomaticNumeration />
    ) : (
        <SalesDocumentNumberManualNumeration />
    );
};
