import { useContext, useEffect, useState } from 'react';
import { useModal } from '../../../../../shared/UI/hooks/useModal';
import { OnboardingContext } from '../../Onboarding';
import { Notifier } from '../../../../../shared/infra/utils/Notifier';
import { useSolicitudPagoCertificado } from '../../../services/useSolicitudPagoCertificado';

export const useStep1 = () => {
    enum StateKey {
        LOADING = 'loading',
        INITIAL = 'INITIAL',
        TRAMITANDO = 'TRAMITANDO',
    }

    const { mutate: solicitarPagoCertificado } = useSolicitudPagoCertificado();
    const [state, setState] = useState(StateKey.LOADING);

    useEffect(() => {
        setState(StateKey.INITIAL);
    }, []);

    const modal = useModal();
    const { siguintePaso } = useContext(OnboardingContext);

    const solicitarPagoCertificadoAction = () => {
        modal.closeModal();
        setState(StateKey.LOADING);
        solicitarPagoCertificado(null, {
            onSuccess: ({ data }) => {
                if (data?.url) {
                    window.location.href = data.url;
                }
            },
            onError: () => {
                setState(StateKey.INITIAL);
                Notifier.notifyError();
            },
        });
    };

    return {
        StateKey,
        state,
        solicitarPagoCertificadoAction,
        modal,
    };
};
