import { TNumeroModelo } from 'features/Impuestos/domain/TNumeroModelo';

type Presentacion = {
    trimestre: '1T' | '2T' | '3T' | '4T';
    year: number;
    limite: string;
    inicio: string;
};

export enum Periodicidad {
    ANUAL = 'ANUAL',
    TRIMESTRAL = 'TRIMESTRAL',
}

export type TItemModelo = {
    nombre: TNumeroModelo;
    periodicidad: Periodicidad;
    presentacion: Presentacion;
    fechaPresentado: string | null;
    categoria: string;
    resultado: number;
    tieneCuotaACompensarMayorQueResultado?: boolean;
    isInformativo: boolean;
    estado?: string;
    isProximoPeriodo?: boolean;
};

export type TModeloPendiente = TItemModelo & {
    estado: 'PENDIENTE' | 'FUERA_DE_PLAZO' | 'PRESENTADO' | 'BLOQUEADO';
    mensaje?: string | null;
};

export type TModeloProximo = TItemModelo & {
    mensaje?: string | null;
};

export type TModeloCompletado = TItemModelo & {
    tipoDeclaracionCodigo: string;
    estado: 'COMPLETADO' | 'PREVIO_DECLARANDO';
};

export type TModeloLinks = {
    CERT: string;
    PIN: string;
    PER: string | null;
};
