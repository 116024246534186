import { Button, TopBanner, Box } from '@declarando/design_system';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import UserV2Repository from '../../../../infra/repositories/UserV2Repository';
import React, { useState } from 'react';
import { ModalPendientePago } from '../../../../../features/Impuestos/UI/_legacy/Modals/ModalPlanPendientePago/ModalPlanPendientePago';

export const BannerImpagadoFinal = () => {
    const { recurrenteData } = useRootContext();
    const [loading, setLoading] = useState(false);
    if (
        !recurrenteData ||
        recurrenteData.estado !== 'IMPAGADO_FINAL' ||
        (recurrenteData.estado === 'IMPAGADO_FINAL' && !recurrenteData.renovacion.puedeRenovar)
    ) {
        return null;
    }
    const { maximoIntentos } = recurrenteData.renovacion;

    const onClick = () => {
        setLoading(true);
        new UserV2Repository()
            .getActivationPlan()
            .then((data) => {
                window.location.href = data.url;
            })
            .finally(() => setLoading(false));
    };

    return (
        <Box style={{ position: 'absolute', zIndex: 99999, width: '100%' }}>
            <TopBanner
                variant={'error'}
                icon={'lock'}
                title="Tu cuenta se ha bloqueado"
                text={
                    <>
                        Se han agotado los <b>{`${maximoIntentos} días`}</b> para cobrar tu plan. Completa el pago para
                        reactivar tu cuenta.
                    </>
                }
                ctaButton={
                    <Button
                        data-testid={'button-modal-plan-pendiente-pago'}
                        size="M"
                        isLoading={loading}
                        label="Pagar y reactivar mi cuenta"
                        onClick={onClick}
                    />
                }
            />
            <ModalPendientePago />
        </Box>
    );
};
