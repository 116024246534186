import { useUpdate } from 'shared/UI/hooks/QueryHooks';
import { OnboardingService } from './OnboardingService';
import { TSiguentePasoArgs } from './OnboardingRepository';

export const useSiguientePaso = () => {
    return useUpdate({
        queryKey: ['onboardingSiguientePaso'],
        mutationFn: (data: TSiguentePasoArgs) => new OnboardingService().siguientePaso(data),
    });
};
