import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { AmountInput } from '@declarando/design_system';
import { invoiceLineCalculationsService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaCalculationService';
import { Currency } from 'shared/infra/utils/Currency';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { LinesProps } from '../../Lines';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

export const LineDiscount = ({ index, handleLineChange }: LinesProps) => {
    const { formBlocked } = useSalesDocumentFormContext();
    const { control } = useCustomFormContext<InvoiceForm>();
    const lineValues = useCustomWatch({ control, name: `lines.${index}` });
    const withEquivalencySurcharge = useCustomWatch({ control, name: 'withEquivalencySurcharge' });

    const amounts = invoiceLineCalculationsService.calculateInvoiceLine({
        fromField: 'price',
        line: lineValues,
        withEquivalencySurcharge: withEquivalencySurcharge ?? false,
    });

    return (
        <ControlledFormField
            control={control}
            name={`lines.${index}.discount`}
            Component={({ field }) => (
                <AmountInput
                    readOnly={formBlocked.isFormBlocked}
                    value={field.value}
                    handleChange={(value) => handleLineChange(index, 'discount', value)}
                    showClearButton={false}
                    ref={field.ref}
                    id={`sales-document-concept-discount-${index}`}
                    helperText={lineValues.discount ? `-${Currency(amounts.discountAmount)}` : ''}
                    label="Descuento"
                    placeholder="0"
                    icon="percentage"
                    max={100}
                    isAllowed={(values) => {
                        const { floatValue } = values;
                        if (floatValue === undefined) return true;
                        return floatValue >= 0 && floatValue <= 100;
                    }}
                    decimalScale={0}
                />
            )}
        />
    );
};
