import { NewDateSelector } from '@declarando/design_system';
import { BaseSalesDocumentForm } from 'features/Ingresos/SalesDocument/shared/domain/SalesDocument';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import DateWrapper from 'shared/infra/utils/Date';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';

export const IssueDateInput = ({ readOnly }: { readOnly?: boolean }) => {
    const { control, trigger } = useCustomFormContext<BaseSalesDocumentForm>();
    const { salesDocumentType } = useSalesDocumentFormContext();

    return (
        <ControlledFormField
            control={control}
            name="issueDate"
            Component={({ field, formState: { errors } }) => (
                <NewDateSelector
                    error={!!errors?.issueDate?.message}
                    helperText={errors?.issueDate?.message}
                    ref={field.ref}
                    readOnly={readOnly}
                    id="sales-document-date"
                    disabledDate={(current) =>
                        current < DateWrapper.formatStringToNativeDate('01-01-2025', 'dd-MM-yyyy')
                    }
                    {...(salesDocumentType === 'invoice' && {
                        tooltipDisabledDay:
                            '<strong>No puedes elegir fechas anteriores a 2025</strong>\n\nPara crear un ingreso con fecha anterior:\n1. Añade un nuevo ingreso con factura\n2. Elige una fecha de 2024\n3. El sistema te llevará al proceso anterior',
                    })}
                    required
                    label="Fecha emisión"
                    onChange={(value) => {
                        field.onChange(value ?? new Date());
                        trigger('dueDate');
                    }}
                    value={field.value ? new Date(field?.value) : null}
                />
            )}
        />
    );
};
