import { useLocation } from 'react-router-dom';
import { SubHeader } from 'shared/UI/components/SubHeader/SubHeader';
import { SalesDocumentFormLayout } from '../../../shared/UI/SalesDocumentFormView/SalesDocumentFormLayout';
import { invoiceDefaultValues } from '../invoice-default-values';
import { useCreateIngreso } from './hooks/useCreateIngreso';
import Intercom from 'shared/UI/components/External/Intercom';
import { Articulos } from 'features/Impuestos/Services/Articulos/Articulos';
import { getInvoiceSchema } from '../../validations/InvoiceSchema';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import { BaseSalesDocument } from 'features/Ingresos/SalesDocument/shared/domain/SalesDocument';

export const IngresoConFacturaCreationView = ({
    defaultValues,
    mutationHook,
}: {
    defaultValues?: BaseSalesDocument;
    mutationHook?: {
        handleSubmit: ReturnType<typeof useCreateIngreso>['handleSubmit'];
        isMutationPending: boolean;
    };
}) => {
    const location = useLocation<{ pickedDate: Date | null }>();
    const { userStatus } = useRootContext();

    const invoiceDefaultValuesComposed = {
        ...invoiceDefaultValues,
        ...defaultValues,
    };

    const defaultMutationHook = useCreateIngreso();

    const { handleSubmit, isMutationPending } = mutationHook ?? defaultMutationHook;

    return (
        <div style={{ lineHeight: '24px' }}>
            <SubHeader
                title="Nuevo ingreso con factura"
                contextPath="/ingresos/ingresos"
                extraLinks={[
                    {
                        icon: 'question-circle',
                        cta: () => Intercom.showArticle(Articulos.invoicing.id),
                        text: 'Saber más',
                    },
                    {
                        text: 'Enviar opinión',
                        cta: () =>
                            window.open(
                                'https://declarando1.typeform.com/to/lNbFV0FR?typeform-source=desa-rollo.atlassian.net',
                                '_blank',
                            ),
                        icon: 'megaphone',
                    },
                ]}
            />
            <SalesDocumentFormLayout
                salesDocumentSchema={getInvoiceSchema({
                    salesDocumentMode: 'create',
                    defaultValues: invoiceDefaultValuesComposed,
                    numerationType: userStatus?.autoNumInvoice ? 'automatic' : 'manual',
                })}
                salesDocumentType="invoice"
                salesDocumentMode="create"
                handleSubmit={handleSubmit}
                isMutationPending={isMutationPending}
                defaultValues={{
                    ...invoiceDefaultValuesComposed,
                    issueDate: location.state?.pickedDate ?? (undefined as unknown as string),
                }}
                formBlocked={{ isFormBlocked: false }}
            />
        </div>
    );
};
