import App from 'shared/UI/layouts/App';

import appRoutes from './appRoutes';
import inicialRoutes from '../routes/inicial';
import contrataRoutes from './contrata';
import contratadoRoutes from '../routes/contratado';
import Public from 'shared/UI/layouts/Public';
import publicRoutes from './public';
import { Callback } from 'shared/UI/layouts/Callback';
import { callbackRoutes } from './callbackRoutes';
import financiacionRoutes from './financiacion';
import FinnanciacionLayout from '../shared/UI/layouts/FinanciacionLayout';
import SetupNoAFFALayout from 'shared/UI/layouts/SetupNoAFFALayout';
import onboardingRoutes from 'routes/setupNoAFFA';
import { CertificadoPagoNoOK } from 'features/Onboarding/UI/ProcesandoPago/ui/CertificadoPagoNoOK';
import { CertificadoProcesandoPago } from '../features/Impagado/UI/ProcesandoPago/CertificadoProcesandoPago';

let indexRoutes = [
    {
        name: 'reactivacioncompletado',
        path: '/contrata/suscripcion/reactivacion-completado',
        component: CertificadoProcesandoPago,
    },
    { name: 'reactivacionerror', path: '/contrata/suscripcion/reactivacion-error', component: CertificadoPagoNoOK },

    {
        name: 'Inicial',
        path: '/inicial',
        component: App,
        routes: inicialRoutes,
        wide: true,
    },
    {
        name: 'Contrataciones',
        path: '/contrata',
        component: App,
        routes: contrataRoutes,
        wide: true,
    },
    {
        name: 'ContratacionesWide',
        path: '/contratado',
        component: App,
        routes: contratadoRoutes,
        wide: true,
    },
    {
        name: 'Login',
        path: '/login',
        component: Public,
        routes: publicRoutes,
    },
    {
        name: 'Register',
        path: '/registro',
        component: Public,
        routes: publicRoutes,
        registerLanding: false,
    },
    {
        name: 'Password',
        path: '/password',
        component: Public,
        routes: publicRoutes,
    },
    {
        name: 'Reset',
        path: '/reset',
        component: Public,
        routes: publicRoutes,
    },
    {
        name: 'Invoice',
        path: '/mi-factura',
        component: Public,
        routes: publicRoutes,
    },
    {
        name: 'Quotation',
        path: '/mi-presupuesto',
        component: Public,
        routes: publicRoutes,
    },
    {
        name: 'CertificadoPagoNoOK',
        path: '/onboarding/pago-no-ok',
        component: CertificadoPagoNoOK,
    },
    {
        name: 'Onboarding',
        path: '/configuracion',
        component: SetupNoAFFALayout,
        routes: onboardingRoutes,
    },
    {
        name: 'SetupNoAFFA',
        path: '/setup-no-affa',
        component: SetupNoAFFALayout,
        routes: onboardingRoutes,
    },
    {
        name: 'Financiacion',
        path: '/financiacion',
        component: FinnanciacionLayout,
        routes: financiacionRoutes,
    },
    {
        name: 'Callback',
        path: '/callback',
        component: Callback,
        routes: callbackRoutes,
    },
    {
        name: 'Home',
        path: '/',
        component: App,
        routes: appRoutes,
        wide: false,
    },
];

export default indexRoutes;
