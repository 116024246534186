const IVA_TYPES = {
    GENERAL: 21,
    NORMAL: 10,
    REDUCIDO: 4,
    TYPE_7_5: 7.5,
    TYPE_5: 5,
    TYPE_2: 2,
    LUZ: 5,
    SINIVA: 0,
    ALL: 'MULTI',
} as const;

type ValueOf<T> = T[keyof T];
type NumericValuesOnly = ValueOf<typeof IVA_TYPES>;

type FilterNumericTypes<T> = T extends number ? T : never;
export type IvaNumbersOnly = FilterNumericTypes<NumericValuesOnly>;

export type TIvaTypeValues = (typeof IVA_TYPES)[keyof typeof IVA_TYPES] | null;

const IvaTypes = {
    GENERAL: IVA_TYPES.GENERAL,
    NORMAL: IVA_TYPES.NORMAL,
    REDUCIDO: IVA_TYPES.REDUCIDO,
    LUZ: IVA_TYPES.LUZ,
    SINIVA: IVA_TYPES.SINIVA,
    ALL: IVA_TYPES.ALL,
    TYPE_7_5: IVA_TYPES.TYPE_7_5,
    TYPE_5: IVA_TYPES.TYPE_5,
    TYPE_2: IVA_TYPES.TYPE_2,

    isNotAllType: (value: TIvaTypeValues): value is Exclude<TIvaTypeValues, 'MULTI'> => {
        return value !== IVA_TYPES.ALL;
    },

    isNumericValue: (value: TIvaTypeValues): value is IvaNumbersOnly => {
        if (value === 'MULTI') return false;

        return Object.values(IVA_TYPES).includes(value as any);
    },

    getCoeficient: (ivaType: IvaNumbersOnly) => {
        return +ivaType / 100;
    },

    toIntIva: (iva: string | number) => {
        return +iva as NumericValuesOnly;
    },

    isValid: (ivaType: TIvaTypeValues) => {
        if (ivaType === null) return false;

        return Object.values(IVA_TYPES).includes(ivaType);
    },
};

export default IvaTypes;
