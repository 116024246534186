import IncomesTableRepository from './TableRepositories/IncomesTableRepository';
import ExpensesLocationRepository from './Locations/ExpensesLocationRepository';
import IncomesLocationRepository from './Locations/IncomesLocationRepository';
import ImpuestosRepository from './ImpuestosRepository';
import ClientsTableRepository from './TableRepositories/ClientsTableRepository';
import QuotationsLocationRepository from './Locations/QuotationsLocationRepository';
import ModelsDownloadLocations from './Locations/ModelsDownloadLocations';
import LeadScoreRepository from './LeadScoreRepository';
import AppRoutesRepository from './Locations/AppRoutesRepository';
import V2Repository from './V2Repository';
import ContabilidadRepository from 'shared/infra/repositories/ContabilidadRepository';
import LocationsRepository from './LocationsRepository';
import GastosRepository from './GastosRepository';
import CuotaRepository from './CuotaCompensarRepository';
import AdviceScoreRepository from './AdviceScoreRepository';
import AfinandoRepository from './AfinandoRepository';
import TasksRepository from './TasksRepository';
import PhoneRepository from './PhoneRepository';
import ContratacionRepository from './ContratacionRepository';
import NotificationsRepository from './NotificationsRepository';
import IngresosTableV2Repository from './TableRepositories/IncomesTableV2Repository';
import AuthV2Repository from './AuthV2Repository';
import InvoiceByTokenV2Repository from './InvoiceByTokenV2Repository';
import QuotationByTokenV2Repository from './QuotationByTokenV2Repository';

export default class RepositoryFactory {
    static instance = new RepositoryFactory();

    // V2 Symfony
    getV2Repository() {
        return new V2Repository();
    }

    getContabilidadRepository() {
        return new ContabilidadRepository();
    }

    getLocationsRepository() {
        return new LocationsRepository();
    }

    // Public

    getAuthV2Repository() {
        return new AuthV2Repository();
    }

    getInvoiceByTokenV2Repository() {
        return new InvoiceByTokenV2Repository();
    }

    // Secure

    getLeadScoreRepository() {
        return new LeadScoreRepository();
    }

    getModelDownloadLocationRepository() {
        return new ModelsDownloadLocations();
    }

    getIncomesTableRepository() {
        return new IncomesTableRepository();
    }

    getIncomesTableV2Repository() {
        return new IngresosTableV2Repository();
    }

    getImpuestosRepository() {
        return new ImpuestosRepository();
    }

    getExpensesLocations() {
        return new ExpensesLocationRepository();
    }

    getIncomesLocations() {
        return new IncomesLocationRepository();
    }

    getQuotationsLocations() {
        return new QuotationsLocationRepository();
    }

    getClientsTableRepository() {
        return new ClientsTableRepository();
    }

    getQuotationV2ByToken() {
        return new QuotationByTokenV2Repository();
    }

    getRoutesRepository() {
        return new AppRoutesRepository();
    }

    getGastosRepository() {
        return new GastosRepository();
    }

    getCuotaRepository() {
        return new CuotaRepository();
    }

    getAdviceScoreRepository() {
        return new AdviceScoreRepository();
    }

    getAfinandoRepository() {
        return new AfinandoRepository();
    }

    getTasksRepository() {
        return new TasksRepository();
    }

    getPhoneRepository() {
        return new PhoneRepository();
    }

    getContratacionRepository() {
        return new ContratacionRepository();
    }

    getNotificationsRepository() {
        return new NotificationsRepository();
    }
}
