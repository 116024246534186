import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { AppRouter } from '../../../../routes/AppRouter';

class AuthenticatedRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
        };
    }

    componentDidMount() {
        const token = this.props.getToken();

        if (token) {
            this.setState({
                authenticated: true,
            });
        } else {
            AppRouter.goLogin();
        }
    }

    render() {
        try {
            document.getElementById('MainContent').scrollTo(0, 0);
        } catch (e) {}
        if (!this.state.authenticated) {
            return <div>Loading...</div>;
        }

        return <Route path={this.props.path} render={this.props.render} />;
    }
}

export default withRouter(AuthenticatedRoute);
