import { TNotification } from 'features/NotificationSystem/domain/models';
import { NotificationsRepository } from 'features/NotificationSystem/infra';
import { NotificationsService } from 'features/NotificationSystem/services';
import { longLivedQueryConfig } from 'shared/UI/config/react-query-config';
import { useFetch, useUpdate } from 'shared/UI/hooks/QueryHooks';
import { TDeclarandoApiResponse } from 'shared/domain/http/DeclarandoApiResponse';

type UpdateReadStatusNotification = {
    leida: TNotification['leida'];
};

type UpdateStatusNotification = {
    estado: TNotification['estado'];
};

type WithID = {
    id: string;
};

type UpdateStatusNotificationArgs = UpdateStatusNotification & WithID;

type UpdateReadStatusNotificationArgs = UpdateReadStatusNotification & WithID;

type UpdaterFn<T> = (
    oldData: TDeclarandoApiResponse<TNotification[]>,
    newData: T,
) => TDeclarandoApiResponse<TNotification[]>;

const notificationsService = new NotificationsService(new NotificationsRepository());

const notificationCachedKey = ['notifications'];

export const useGetNotifications = () => {
    return useFetch<TNotification[]>({
        queryKey: notificationCachedKey,
        fetchFn: () => notificationsService.getNotifications(),
        config: {
            ...longLivedQueryConfig,
        },
    });
};

export const useUpdateReadStatusNotification = (updater: UpdaterFn<UpdateReadStatusNotificationArgs>) => {
    return useUpdate<UpdateReadStatusNotificationArgs, UpdateReadStatusNotification, TNotification[]>({
        queryKey: notificationCachedKey,
        mutationFn: (data) => notificationsService.updateReadStatus({ leida: data.leida, notificationId: data.id }),
        updater,
    });
};

export const useUpdateStatusNotification = (updater: UpdaterFn<UpdateStatusNotificationArgs>) => {
    return useUpdate<UpdateStatusNotificationArgs, UpdateStatusNotification, TNotification[]>({
        queryKey: notificationCachedKey,
        mutationFn: (data) => notificationsService.updateState({ estado: data.estado, notificationId: data.id }),
        updater,
    });
};
