import { Notification } from '@declarando/design_system';
import { useGetTaxes } from '../../../ClientDetails/hooks/get-available-taxes';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { ExpandableContent } from '../../../../shared/components/ExpandableContent';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';

export const LineTaxExemption = ({ index }: { index: number }) => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const watchProductType = useCustomWatch({ control, name: `lines.${index}.productType` });
    const watchConceptVAT = useCustomWatch({ control, name: `lines.${index}.vat` });
    const { data } = useGetTaxes();

    const shouldShowTaxExemption =
        watchConceptVAT === 0 &&
        (data?.data?.taxExemptions.goods?.length || data?.data?.taxExemptions.services?.length);

    const getTaxExemption = () => {
        if (watchProductType === 'SERVICES') {
            return data?.data?.taxExemptions.services[0].description ?? '';
        }

        if (watchProductType === 'GOODS') {
            return data?.data?.taxExemptions.goods[0].description ?? '';
        }
    };

    return (
        <ExpandableContent shouldExpand={!!shouldShowTaxExemption}>
            <Notification tone="info" message={getTaxExemption()} />
        </ExpandableContent>
    );
};
