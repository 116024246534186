import { SalesDocumentPDFRepository } from '../../shared/domain/SalesDocumentPDF';
import { SalesDocumentPDFService } from '../../shared/services/SalesDocumentPDFService';

class InvoicePDFService extends SalesDocumentPDFService {
    constructor(repository: SalesDocumentPDFRepository) {
        super(repository);
    }
}

export const createInvoicePDFService = (repository: SalesDocumentPDFRepository): InvoicePDFService => {
    return new InvoicePDFService(repository);
};
