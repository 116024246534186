export const OnboardingSteps = {
    BIENVENIDA: 'BIENVENIDA',
    CERTIFICADO_INICIO: 'CERTIFICADO_INICIO',
    CERTIFICADO_PROCESANDO_PAGO: 'CERTIFICADO_PROCESANDO_PAGO',
    CERTIFICADO_EN_TRAMITE: 'CERTIFICADO_EN_TRAMITE',
    DOCUMENTACION_INICIO: 'DOCUMENTACION_INICIO',
    DOCUMENTACION_EN_REVISION: 'DOCUMENTACION_EN_REVISION', //Cambiar step cuando nos lo den desde BACK
    CONTABILIDAD_INICIO: 'CONTABILIDAD_INICIO',
    CONTABILIDAD_PROCESANDO_PAGO: 'CONTABILIDAD_PROCESANDO_PAGO',
    CONTABILIDAD_PAGADA: 'CONTABILIDAD_PAGADA',
    CONTABILIDAD_EN_REVISION: 'CONTABILIDAD_EN_REVISION',
    CONTABILIDAD_REVISADA: 'CONTABILIDAD_REVISADA',
    FINALIZADO: 'FINALIZADO',
} as const;

export type ValueOfOnboardingSteps = (typeof OnboardingSteps)[keyof typeof OnboardingSteps];
