import { useInfiniteFetch } from 'shared/UI/hooks/QueryHooks';
import { createClientsRepository } from '../../infra/ClientRepository';
import { createClientsService } from '../../services/ClientsService';
import { ClientListDTO, ClientListParams } from '../../adapters/ClientListDTO';
import { useMemo } from 'react';
import { longLivedQueryConfig } from 'shared/UI/config/react-query-config';
import { transformClientDTOToClient } from '../../adapters/ClientAdapter';

const defaultParams: ClientListParams = {
    limit: 100,
    offset: 0,
    search: '',
};

export const useGetClientsList = ({ filter }: { filter: ClientListParams['search'] }) => {
    const params: ClientListParams = {
        ...defaultParams,
        search: filter || defaultParams.search,
    };
    const service = createClientsService(createClientsRepository());

    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteFetch<ClientListDTO | null>({
        queryKey: ['clients', params.search],
        fetchFn: (nextOffset) => service.getClientsList({ ...params, offset: nextOffset }),
        config: {
            getNextPageParam: (lastPage) => {
                if (lastPage?.data?.data.length === 0) return undefined;
                if ((lastPage?.data?.data.length ?? 0) < (lastPage?.data?.limit ?? 0)) return undefined;
                return (lastPage?.data?.offset ?? 0) + (lastPage?.data?.limit ?? 0);
            },
            ...longLivedQueryConfig,
            refetchOnMount: 'always',
        },
    });

    const clientsFlattened = useMemo(() => {
        if (!data?.pages) return [];
        return data.pages.flatMap(
            (page) => page.data?.data?.map((clientDTO) => transformClientDTOToClient(clientDTO)) || [],
        );
    }, [data]);

    return {
        clientsList: clientsFlattened,
        isLoading: isLoading,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    };
};
