import { usePost, useUpdate } from 'shared/UI/hooks/QueryHooks';
import { createQuoteService } from '../../services/QuoteService';
import { createQuoteRepository } from '../../infra/QuoteRepository';
import { Quote } from '../../domain/Quote';

export const useQuoteMutation = () => {
    const service = createQuoteService(createQuoteRepository());

    const createQuote = usePost<Quote, string>({
        queryKey: ['quotes'],
        mutationFn: (quote) => service.createQuote(quote),
    });

    const editQuote = useUpdate<Quote, string>({
        queryKey: ['quotes'],
        mutationFn: (quote) => service.editQuote(quote),
    });

    return {
        createQuote,
        editQuote,
    };
};
