import * as Sentry from '@sentry/react';
import { ErrorReporter, ErrorEvent } from './ErrorReporting';

const IGNORED_ERROR_PATTERNS = [
    /recurrent order not found/i,
    /jquery is not defined/i,
    /Can't find variable: jQuery/i,
    /Failed to fetch dynamically imported module/i,
    /Importing a module script failed/i,
    /Load failed/i,
    /undefined is not an object (evaluating 't.Domain')/i,
] as const;

export const shouldIgnoreError = (error: Error | null | undefined): boolean => {
    if (!error?.message) return false;

    return IGNORED_ERROR_PATTERNS.some((pattern) => (error?.message ? pattern.test(error.message) : false));
};

export class SentryReporter implements ErrorReporter {
    init() {
        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environment: import.meta.env.VITE_ENV,
            enabled: import.meta.env.VITE_ENV !== 'local',
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            beforeSend(event, hint) {
                const error = hint.originalException as Error;

                if (shouldIgnoreError(error)) {
                    return null;
                }

                return event;
            },
            // Tracing
            tracesSampler: ({ name, parentSampled }) => {
                // Do not sample health checks ever
                if (name.includes('healthcheck')) {
                    // Drop this completely by setting its sample rate to 0%
                    return 0;
                }

                // These are important - take a big sample
                if (name.includes('auth')) {
                    return 1;
                }

                // These are less important or happen much more frequently - only take 1%
                if (name.includes('comment')) {
                    return 0.01;
                }

                // Continue trace decision, if there is any parentSampled information
                if (typeof parentSampled === 'boolean') {
                    return parentSampled;
                }

                return 0.5;
            },
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }

    captureError(error: ErrorEvent, context?: Record<string, any>) {
        Sentry.captureException(error), { extra: context };
    }

    captureMessage(message: string, context?: Record<string, any>) {
        Sentry.captureMessage(message, { extra: context });
    }

    setUserInfo(userInfo: Record<string, any>) {
        Sentry.setUser(userInfo);
    }
}
