import { OnboardingSteps } from '../features/Onboarding/domain/OnboardingSteps';
import Onboarding from '../features/Onboarding/UI/Onboarding';

export const OnboardingUrlPathMapper = {
    [OnboardingSteps.BIENVENIDA]: '/configuracion/bienvenida',
    [OnboardingSteps.CERTIFICADO_INICIO]: '/configuracion/certificado-inicio',
    [OnboardingSteps.CERTIFICADO_PROCESANDO_PAGO]: '/configuracion/certificado-procesando-pago',
    [OnboardingSteps.CERTIFICADO_EN_TRAMITE]: '/configuracion/certificado-en-tramite',
    [OnboardingSteps.DOCUMENTACION_INICIO]: '/configuracion/documentacion-inicio',
    [OnboardingSteps.DOCUMENTACION_EN_REVISION]: '/configuracion/documentacion-en-revision',
    [OnboardingSteps.CONTABILIDAD_INICIO]: '/configuracion/contabilidad-inicio',
    [OnboardingSteps.CONTABILIDAD_PROCESANDO_PAGO]: '/configuracion/contabilidad-procesando-pago',
    [OnboardingSteps.CONTABILIDAD_PAGADA]: '/configuracion/contabilidad-pagada',
    [OnboardingSteps.CONTABILIDAD_EN_REVISION]: '/configuracion/contabilidad-en-revision',
    [OnboardingSteps.CONTABILIDAD_REVISADA]: '/configuracion/contabilidad-revisada',
    [OnboardingSteps.FINALIZADO]: '/configuracion/finalizado',
};

const onboardingRoutes = [
    ...Object.keys(OnboardingUrlPathMapper).map((key) => ({
        name: key,
        path: OnboardingUrlPathMapper[key],
        component: Onboarding,
    })),
    {
        name: 'setup',
        path: '/',
        component: Onboarding,
    },
];

export default onboardingRoutes;
