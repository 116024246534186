import { useFetch } from 'shared/UI/hooks/QueryHooks';
import { createLocationService } from '../../services/LocationService';
import { createLocationsRepository } from '../../infra/LocationsRepository';
import { longLivedQueryConfig } from 'shared/UI/config/react-query-config';
import { transformCitiesDTO } from '../../adapters/LocationsAdapter';

export const useGetCitiesList = (stateId: string | null) => {
    const service = createLocationService(createLocationsRepository());

    const context = useFetch({
        queryKey: ['cities', `${stateId}`],
        fetchFn: () => service.getCitiesByState(stateId as unknown as number),
        config: {
            enabled: !!stateId,
            ...longLivedQueryConfig,
            select: (data) => ({ ...data, data: data?.data ? transformCitiesDTO(data.data) : [] }),
        },
    });

    return { cities: context.data?.data || [], isLoading: context.isLoading };
};
