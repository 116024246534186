import React, { Component } from 'react';
import Date from 'shared/infra/utils/Date';
import { debounce } from 'shared/infra/utils/Function';

class Intercom extends Component {
    constructor(props) {
        super(props);

        const APP_ID = import.meta.env.VITE_INTERCOM_API_ID;
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function () {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === 'complete') {
                    l();
                } else if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();

        this._uploadState = debounce(() => this.updateState(), 3000);

        this.appId = import.meta.env.VITE_INTERCOM_API_ID;

        this.state = {
            user_id: this.props.userInfo.id,
            user_hash: this.getHash(props.userInfo.id),
            email: this.props.userIdentity.email,
            name: this.props.userIdentity.firstName,
            last_name: this.props.userIdentity.lastName,
            phone: `+${this.props.userIdentity.prefijo}${this.props.userIdentity.phone}`,
            plan: this.props.userInfo.plan.intercomCode,
            futuro_autonomo: this.getFuturoAutonomo(),
            fecha_alta: this.props.userStatus.altaDate,
            created_at: Date.formatFullTimeStringtoDate(this.props.userInfo.registerDate).format('X'),
            ahorro_fiscal: this.getNumber(this.props.ahorro),
            beneficio: this.getNumber(this.props.beneficio),
        };
    }

    componentDidMount() {
        window.Intercom('boot', {
            app_id: this.appId,
            ...this.state,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            userIdentity: { firstName, lastName, prefijo, phone },
            userInfo: { plan },
            userStatus: { autonomo, altaDate },
            ahorro,
            beneficio,
        } = this.props;

        // Compara con prevProps
        const propsHanCambiado =
            prevProps.userIdentity.firstName !== firstName ||
            prevProps.userIdentity.lastName !== lastName ||
            // Para comparar el teléfono formateado:
            `+${prevProps.userIdentity.prefijo}${prevProps.userIdentity.phone}` !== `+${prefijo}${phone}` ||
            // Plan:
            prevProps.userInfo.plan.intercomCode !== plan.intercomCode ||
            // futuro_autonomo:
            prevProps.userStatus.autonomo !== autonomo ||
            // fecha_alta:
            prevProps.userStatus.altaDate !== altaDate ||
            // ahorro_fiscal:
            prevProps.ahorro !== ahorro ||
            // beneficio:
            prevProps.beneficio !== beneficio;

        if (propsHanCambiado) {
            this._uploadState();
        }
    }

    updateState() {
        window.Intercom('update', {
            name: this.props.userIdentity.firstName,
            last_name: this.props.userIdentity.lastName,
            phone: `+${this.props.userIdentity.prefijo}${this.props.userIdentity.phone}`,
            plan: this.props.userInfo.plan.intercomCode,
            futuro_autonomo: this.props.userStatus.autonomo ? 'No' : 'Sí',
            fecha_alta: this.props.userStatus.altaDate,
            ahorro_fiscal: this.getNumber(this.props.ahorro),
            beneficio: this.getNumber(this.props.beneficio),
        });
    }

    getFuturoAutonomo() {
        return this.props.userStatus.autonomo ? 'No' : 'Sí';
    }

    getHash(id) {
        const hash = window.CryptoJS.HmacSHA256(id, import.meta.env.VITE_INTERCOM_KEY);
        return window.CryptoJS.enc.Hex.stringify(hash);
    }

    render() {
        return <></>;
    }

    getNumber = (string) => {
        return Math.floor(string);
    };

    static show() {
        window.Intercom('show');
    }

    static showArticle(id) {
        window.Intercom('showArticle', id);
    }

    static showNewMessage() {
        window.Intercom('showNewMessage');
    }
}

export default Intercom;
