import React from 'react';
import { css, Grid, styled, Text } from '@declarando/design_system';
import AdvicesLoaderSvg from '../../../../../assets/img/empty-state-advices.svg';

const EmptyContainer = styled(Grid)(
    css({
        width: ['100%', '328px'],
        justifyItems: 'center',
        height: 'calc(100vh - (64px + 72px))',
        gap: 'sm',
        backgroundColor: 'white',
        '#EmptyAdvices': {
            alignSelf: 'end',
        },
    }),
);

export const EmptyAdvices = () => {
    return (
        <EmptyContainer>
            <object id="EmptyAdvices" data={AdvicesLoaderSvg} type="image/svg+xml" width="140">
                <img src={AdvicesLoaderSvg} alt="Cargando" />
            </object>
            <Text color="neutral.Main" fontSize="sm">
                Aún no tienes ningún consejo
            </Text>
        </EmptyContainer>
    );
};
