import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PublicLayout, Toast } from '@declarando/design_system';
import JWT from 'shared/infra/utils/JWT';
import { TrackPage } from 'shared/infra/utils/user_events';
import MixPanel from 'shared/infra/utils/user_events/MixPanel';
import LogoDeclarando from 'assets/img/logo_declarando_azul.svg';
import { Notifier } from '../../infra/utils/Notifier';

class Public extends Component {
    componentDidMount() {
        Notifier.init(this.refs.notifSystem);
    }

    componentWillMount() {
        MixPanel.init();
        MixPanel.identify(null);
        const token = JWT.getToken();
        if (token && !window.location.pathname.match(/(mi-presupuesto|mi-factura)/)) {
            this.goHome();
        }
    }

    goHome = () => {
        window.location.href = import.meta.env.VITE_DEFAULT_HOME_URI;
    };

    render() {
        return (
            <>
                <Toast ref="notifSystem" />
                <PublicLayout
                    registerLanding={this.props.registerLanding}
                    logo={LogoDeclarando}
                    logoLink={'/'}
                    children={
                        <Switch>
                            {this.props.routes.map((prop, key) => {
                                if (!prop.directLink)
                                    return (
                                        <Route
                                            path={prop.path}
                                            key={key}
                                            render={(props) => (
                                                <TrackPage {...prop.track_params}>
                                                    <prop.component {...this.spreads} {...props} />
                                                </TrackPage>
                                            )}
                                        />
                                    );
                                return null;
                            })}
                        </Switch>
                    }
                />
            </>
        );
    }
}

export default Public;
