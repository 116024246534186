import { Box, Select } from '@declarando/design_system';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useEffect, useMemo } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { SkeletonInput } from 'shared/UI/components/Skeletons/Input/SkeletonInput';
import { useShouldExpandForm } from '../../../../hooks/use-should-expand-form';
import { useGetTaxes } from '../../hooks/get-available-taxes';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

const useSingleTaxOption = (
    options: { label: string; value: number }[] | undefined,
    setValue: UseFormSetValue<InvoiceForm>,
    tax: InvoiceForm['incomeTax'],
) => {
    useEffect(() => {
        if (options && options?.length > 0 && !options.find((item) => item.value === tax)) {
            setValue('incomeTax', options[0].value, { shouldDirty: false });
        }
    }, [options, setValue]);

    return { isSingleOption: options?.length === 1 };
};

const commonHelpLink =
    'https://ayuda.declarando.es/es/articles/2348824-diferentes-de-irpf-en-tus-facturas-de-ingresos?_ga=2.186258857.1459737717.1718783164-41921962.1718783164';

const commonTextLink = 'Saber más';

const useGetTooltipOptions = (args: { withImageRights: boolean; options: { label: string; value: number }[] }) => {
    const optionValues = args.options.map((option) => option.value);

    //TODO: Refactor, now its using directly the values from the API
    if (optionValues.includes(1)) {
        return {
            title: 'Actividades ganaderas',
            text: 'Como tu actividad es ganadera y estás informando un ingreso de engorde, el IRPF que aplica es del 1%.',
            link: {
                href: commonHelpLink,
                text: commonTextLink,
            },
        };
    }

    if (optionValues.includes(2)) {
        return {
            title: 'Actividades ganaderas',
            text: 'Como tu actividad es ganadera, el IRPF que aplica es del 2%.',
            link: {
                href: commonHelpLink,
                text: commonTextLink,
            },
        };
    }

    if (optionValues.includes(15) && optionValues.includes(7)) {
        return {
            title: 'Actividades profesionales y artísticas',
            text: 'Se aplica un 15% de retención como regla general pero se puede aplicar un IRPF reducido del 7% en algunos casos concretos.',
            link: {
                href: commonHelpLink,
                text: commonTextLink,
            },
        };
    }

    if (args.withImageRights)
        return {
            title: 'Ingreso de derechos de imagen',
            text: 'Como estás informando un ingreso de derechos de explotación de imagen, el IRPF que aplica es del 24%.',
            link: {
                href: commonHelpLink,
                text: commonTextLink,
            },
        };
};

export const InputIRPF = () => {
    const { control, setValue, getValues } = useCustomFormContext<InvoiceForm>();
    const { formBlocked } = useSalesDocumentFormContext();
    const watchWithImageRights = useCustomWatch({ control, name: 'activity.withImageRights' });
    const { data, isLoading } = useGetTaxes();

    const options = useMemo(
        () =>
            data?.data?.incomeTax.map((tax) => ({ label: `${tax === 0 ? 'No aplica' : `${tax}%`}`, value: tax })) ?? [],
        [data?.data?.incomeTax],
    );

    const tooltipOptions = useGetTooltipOptions({ withImageRights: !!watchWithImageRights, options });

    const { isSingleOption } = useSingleTaxOption(options, setValue, getValues('incomeTax'));

    const { shouldShowRestOfFields: shouldShowInputIRPF } = useShouldExpandForm();

    if (!shouldShowInputIRPF) return null;

    if (isLoading) {
        return (
            <Box marginTop="16px">
                <SkeletonInput labelWidth="50px" />
            </Box>
        );
    }

    return (
        <ControlledFormField
            control={control}
            name="incomeTax"
            Component={({ field }) => (
                <Select
                    required
                    label="IRPF"
                    readOnly={isSingleOption || formBlocked.isFormBlocked}
                    tooltipOptions={tooltipOptions}
                    options={options}
                    {...field}
                />
            )}
        />
    );
};
