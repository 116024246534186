import React, { createContext, useContext, useEffect, useState } from 'react';
import { RootContext, TRootContext } from 'shared/UI/context/RootContext/RootContext';
import { TipoDeGasto } from '../../shared/domain/contabilidad/TipoDeGasto';
import { Notifier } from '../../shared/infra/utils/Notifier';
import FiscalDates from '../../shared/infra/utils/FiscalDates';
import IrpfTypes, { TIRPF_VALUES } from '../../shared/domain/contabilidad/irpf/IrpfTypes';
import GastoCockpit from './GastoCockpit';
import { SubTiposGastosConFacturaTypes } from '../../shared/domain/contabilidad/GastosConFacturaTypes';
import { GastosService } from './services/GastosService';
import { ProveedorModel } from '../../shared/domain/proveedores/ProveedorModel';
import Country from '../../shared/UI/components/ListOptions/Country';
import DateWrapper from '../../shared/infra/utils/Date';
import { TGastoCockpitState } from './GastoCockpit/types/TGastoCockpitState';
import { TInvoiceDataGasto } from './GastoCockpit/types/TInvoiceDataGasto';
import Operations from '../../shared/domain/contabilidad/Operations';

export const GastoContext = createContext<TGastoContext>({} as TGastoContext);

interface GastoContextProviderProps {
    children: React.ReactNode;
    checkReglasAlquilerLocal: (total: string | number, year: number, id?: string) => Promise<any>;
    invoiceData: TInvoiceDataGasto;
    snapshotInvoiceData: TInvoiceDataGasto | null;
    updateIrpf: (value: TIRPF_VALUES) => any;
    gastoState: TGastoCockpitState;
    updateGastoState: (value: Partial<TGastoCockpitState>, cb?: () => void) => void;
    updateInvoiceData: GastoCockpit['updateInvoiceData'];
    providerData: ProveedorModel;
}

type TGastoContext = TRootContext & {
    gasto: {
        invoiceData: TInvoiceDataGasto;
        isGastoDeAlquiler: () => null | undefined | boolean;
        providerData: ProveedorModel;
        deducibilidadDietasComidas: any;
        esDietasComidas: boolean;
    };
    gastoBusy: boolean;
    setGastoBusy: (value: ((prevState: boolean) => boolean) | boolean) => void;
    irpfForzado: number | null;
    setIrpfForzado: (value: ((prevState: number | null) => number | null) | number) => void;
    irpfForzadoMessage: string;
    setIrpfForzadoMessage: (value: ((prevState: string) => string) | string) => void;
    reglasAlquilerLocal: (canIAddDefaultIrpf: boolean) => Promise<undefined | void>;
    resetIrpfForzado: () => void;
    updateInvoiceData: GastoCockpit['updateInvoiceData'];
    gastoState: TGastoCockpitState;
    updateGastoState: (value: Partial<TGastoCockpitState>, cb?: () => void) => void;
};

export const GastoContextProvider = ({
    children,
    invoiceData,
    snapshotInvoiceData,
    checkReglasAlquilerLocal,
    updateIrpf,
    updateInvoiceData,
    providerData,
    gastoState,
    updateGastoState,
}: GastoContextProviderProps) => {
    const context = useContext(RootContext);
    const [irpfForzado, setIrpfForzado] = useState<number | null>(null);
    const [irpfForzadoMessage, setIrpfForzadoMessage] = useState('');
    const [gastoBusy, setGastoBusy] = useState(false);

    const resetIrpfForzado = () => {
        setIrpfForzado(null);
        setIrpfForzadoMessage('');
    };

    const reglasAlquilerLocal = async (canIAddDefaultIrpf: boolean) => {
        if (!isGastoDeAlquiler()) {
            resetIrpfForzado();
            return;
        }
        setIrpfForzadoMessage('Si el importe anual de tu alquiler es igual o mayor a 900€ el IRPF debe ser del 19%');
        canIAddDefaultIrpf && updateIrpf(IrpfTypes.ALQUILER_LOCAL);

        const { data, error, message } = await checkReglasAlquilerLocal(
            +invoiceData.totalAmount | 0,
            FiscalDates.getFiscalYear(),
            invoiceData.id,
        );

        if (error) {
            return Notifier.notifyError(message);
        }

        if (data?.alquiler_irpf_forzado !== null) {
            setIrpfForzado(data.alquiler_irpf_forzado);
            updateIrpf(data.alquiler_irpf_forzado);
        } else {
            setIrpfForzado(null);
        }
    };

    const isGastoDeAlquiler = () => {
        return invoiceData?.tipado && new TipoDeGasto(invoiceData.tipado).isAlquilerLocal();
    };

    return (
        <GastoContext.Provider
            value={{
                ...context,
                gasto: {
                    invoiceData,
                    isGastoDeAlquiler,
                    providerData,
                    ...useTipoGastoChecker({ invoiceData, snapshotInvoiceData, providerData }),
                },
                gastoBusy,
                setGastoBusy,
                irpfForzado,
                setIrpfForzado,
                irpfForzadoMessage,
                setIrpfForzadoMessage,
                reglasAlquilerLocal,
                resetIrpfForzado,
                updateInvoiceData,
                gastoState,
                updateGastoState,
            }}
        >
            {children}
        </GastoContext.Provider>
    );
};

const useTipoGastoChecker = ({
    invoiceData,
    snapshotInvoiceData,
    providerData,
}: {
    providerData: any;
    invoiceData: TInvoiceDataGasto;
    snapshotInvoiceData: TInvoiceDataGasto | null;
}) => {
    const [esDietasComidas, setEsDietasComidas] = useState(false);
    const [deducibilidadDietasComidas, setDeducibilidadComidas] = useState<any>({ loading: true });

    useEffect(() => {
        const isSubtipadoComidas = [
            SubTiposGastosConFacturaTypes.FOREIGN_COMIDAS,
            SubTiposGastosConFacturaTypes.SPANISH_COMIDAS,
        ].includes(invoiceData.subtipado as SubTiposGastosConFacturaTypes);
        let debounceTimeout: any;
        if (
            !!providerData?.country_id &&
            isSubtipadoComidas &&
            (invoiceData?.isPernocta === true || invoiceData?.isPernocta === false)
        ) {
            const country_id = providerData?.country_id;
            debounceTimeout = setTimeout(() => fetchGastoComidas(), 500);
        }
        setEsDietasComidas(isSubtipadoComidas);
        return () => clearTimeout(debounceTimeout);
    }, [
        invoiceData.isPernocta,
        providerData?.country_id,
        invoiceData.tipado,
        invoiceData.subtipado,
        invoiceData.totalAmount,
    ]);

    const getSnpashotTotalAmount = () => {
        if (snapshotInvoiceData && snapshotInvoiceData.bases) {
            return Operations.sumBases(snapshotInvoiceData.bases);
        }
        return 0;
    };

    const fetchGastoComidas = async () => {
        setDeducibilidadComidas({ ...deducibilidadDietasComidas, loading: true });

        const { data, error, message } = await new GastosService().gastoDeducibilidadDiaria({
            isSpain: +providerData.country_id === Country.id.SPAIN,
            fecha: DateWrapper.formatDateToString(invoiceData.date),
            isRectificativo: +invoiceData.totalAmount < 0,
            isPernocta: invoiceData.isPernocta as boolean,
            gastoUuid: invoiceData.id,
        });
        if (error) {
            setDeducibilidadComidas({
                loading: false,
            });
            return Notifier.notifyError(message);
        }
        const { limite, consumido, isSpain, isPernocta } = data as any;
        setDeducibilidadComidas({
            limite,
            consumido: consumido - getSnpashotTotalAmount(),
            isSpain,
            isPernocta,
            loading: false,
        });
    };

    return {
        esDietasComidas,
        deducibilidadDietasComidas,
    };
};
