import { useState } from 'react';
import { useDebounce } from 'shared/UI/hooks/useDebounce';

export const useClientSearch = () => {
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 500);

    const handleResetSearch = () => {
        setSearchValue('');
    };

    return {
        searchValue,
        setSearchValue,
        debouncedSearchValue,
        handleResetSearch,
    };
};
