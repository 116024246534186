import GastosConFacturaTypes from './GastosConFacturaTypes';
import GastosSinFacturaTypes from './GastosSinFacturaTypes';

export class TipoDeGasto {
    constructor(private type: GastosConFacturaTypes | GastosSinFacturaTypes) {}

    isAlquilerLocal() {
        return this.type === GastosConFacturaTypes.SPANISH_ALQUILER;
    }

    isAduana() {
        return this.type === GastosConFacturaTypes.ADUANA;
    }
}
