import IncomesTypes from '../../../../features/Incomes/UI/IncomesTypes';

export default class IncomesLocationRepository {
    gotoModifyIncome(idfac, type, isSimplificado) {
        if (isSimplificado) {
            return '/ingreso/simplificado/' + idfac;
        }

        if (type === IncomesTypes.SUBVENCION) {
            return '/ingreso/subvencion/' + idfac;
        }
        if (type === 'IREN') {
            return '/ingreso/sin-factura/' + idfac;
        } else {
            return '/ingreso/con-factura/' + idfac;
        }
    }

    getIncomesUrl() {
        return '/ingresos';
    }

    getQuotationsUrl() {
        return '/ingresos/presupuestos';
    }

    getNewInvoicedIncomeUrl() {
        return '/ingreso/con-factura';
    }

    getNewUninvoicedIncomeUrl() {
        return '/ingreso/sin-factura';
    }

    getNewSimpleIncomeUrl() {
        return '/ingreso/simplificado';
    }

    getNewSubvencionIncomeUrl() {
        return '/ingreso/subvencion';
    }

    getUrlIncomePdf(id) {
        return `${import.meta.env.VITE_API_URL}/v2/fichero/ingreso/${id}/factura`;
    }

    getUrlUninvoicedIncomePdf(id) {
        return `${import.meta.env.VITE_API_URL}/v2/fichero/ingreso/${id}/factura`;
    }

    getUrlPreviewIncomePdf(id) {
        return this.getUrlIncomePdf(id) + '/1';
    }

    getUrlQuotationPdf(id) {
        return import.meta.env.VITE_API_URL + '/v2/fichero/presupuesto/' + id + '/factura';
    }

    getUrlPreviewQuotationPdf(id) {
        return this.getUrlQuotationPdf(id) + '/1';
    }

    getIncomesFromTransactionUrl(id) {
        return '/ingreso/transaccion/' + id;
    }
}
