import { Box, Divider, Header, Icon, ModalChoice, P, theme } from '@declarando/design_system';
import { useModal } from 'shared/UI/hooks/useModal';
import Mentor from './assets/mentor.png';
import { AppRouter } from 'routes/AppRouter';
import { ShareScreen } from 'shared/infra/utils/ShareScreen';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';

export const ModalPeriodoPruebaFinalizado = () => {
    const modal = useModal();

    const location = useLocation();

    const { userFreeNotPeriodoDemo } = useRootContext();

    useEffect(() => {
        if (userFreeNotPeriodoDemo && location.pathname.includes('/inicio')) {
            modal.showModal();
        }
    }, [location.pathname]);

    return (
        <ModalChoice
            isHeaderHidden={true}
            title={'Tu período de prueba de 14 días ha finalizado'}
            isOpen={modal.show}
            handleAction={() => {
                AppRouter.goToPlan();
                modal.closeModal();
            }}
            handleCancel={() => {
                ShareScreen.openCrankwheelForEmail();
            }}
            labelAction={'Contrata tu plan'}
            labelCancel={'Hablar con un asesor'}
        >
            <Box alignCenter paddingTop={'24px'}>
                <img src={Mentor} alt="Periodo de prueba finalizado" />
                <Header as="h2" paddingTop={'md'} marginBottom={'xxs'}>
                    Tu período de prueba de 14 días ha finalizado
                </Header>
                <P paddingBottom={'lg'}>Contrata un plan y alcanza la tranquilidad fiscal con Declarando.</P>
            </Box>
            <Divider />
            <Box
                display={'grid'}
                gridTemplateColumns={{ _: '1fr', tablet: '1fr 1fr' }}
                gridColumnGap={'md'}
                gridRowGap={'xs'}
                marginTop={'lg'}
                marginBottom={'md'}
                marginX={'sm'}
                justifyItems={'start'}
            >
                {[
                    {
                        icon: 'usd-circle',
                        text: 'Ahorra una media de 4.000€ al año',
                    },
                    { icon: 'chart-line', text: 'Planifica tu renta y evita sorpresas' },
                    {
                        icon: 'file-check-alt',
                        text: 'Factura electrónica (¡pronto obligatoria!)',
                    },
                    {
                        icon: 'university',
                        text: 'Sin errores ni sanciones de Hacienda',
                    },
                    {
                        icon: 'sync',
                        text: 'Automatización de ingresos y gastos',
                    },
                    {
                        icon: 'book-reader',
                        text: 'Con el apoyo de un mentor fiscal',
                    },
                ].map((item, key) => {
                    return (
                        <>
                            <Box
                                key={key}
                                display={'grid'}
                                gridTemplateColumns={'auto 1fr'}
                                alignItems={'start'}
                                style={{ lineHeight: '14px' }}
                            >
                                <Icon
                                    color={theme.colors.semantic.text.mainStatus.default}
                                    marginRight={'xxs'}
                                    fontSize={'24px'}
                                    name={item.icon}
                                />
                                <P>{item.text}</P>
                            </Box>
                        </>
                    );
                })}
            </Box>
        </ModalChoice>
    );
};
