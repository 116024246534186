import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { VIESRepositoryInterface } from '../domain/VIES/VIESRepositoryInterface';

class VIESService extends DeclarandoApiService {
    private repository: VIESRepositoryInterface;

    constructor(repository: VIESRepositoryInterface) {
        super();
        this.repository = repository;
    }

    checkVAT(args: { countryCode: string; VAT: string }) {
        return this.execute(() => this.repository.checkVAT(args), {
            shouldThrowError: true,
        });
    }
}

export const createVIESService = (repository: VIESRepositoryInterface): VIESService => {
    return new VIESService(repository);
};
