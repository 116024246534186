import SecureRepository from '../SecureRepository';

export default class ClientsTableRepository extends SecureRepository {
    getData(page, rows, filters, orders) {
        return this.post(
            '/clients',
            JSON.stringify({
                page,
                rows,
                filters,
                orders,
            }),
        );
    }

    getClientById(clientId) {
        return this.get('/clients/' + clientId);
    }

    updateClient(client) {
        return this.post(
            '/clients/' + client.id,
            JSON.stringify({
                client,
            }),
        );
    }

    addClient(client) {
        return this.post(
            '/clients/create',
            JSON.stringify({
                client,
            }),
        );
    }
}
