// @ts-nocheck
import V2Repository from './V2Repository';

class ContabilidadRepository extends V2Repository {
    check303ComplementarioIngresoDelete(id) {
        return this.get('/check/complementario/ingreso/delete/' + id);
    }

    check303ComplementarioIngresoInsert(data) {
        return this.post(
            '/check/complementario/ingreso/insert',
            JSON.stringify({
                data,
            }),
        );
    }

    check303ComplementarioGastoDelete(uuid) {
        return this.get('/check/complementario/gasto/delete/' + uuid);
    }

    check303ComplementarioGastoInsert(data) {
        return this.post(
            '/check/complementario/gasto/insert',
            JSON.stringify({
                data,
            }),
        );
    }

    enable303Complementario() {
        return this.get('/check/complementario/enable');
    }

    presentadaBloqueada(fechaFactura): Promise<{
        isAccountingBlockedForInvoicedExpenses: boolean;
        isAccountingBlockedForNonInvoicedExpenses: boolean;
        isAccountingBlockedForIncomes: boolean;
        quarter: {
            code: string;
            year: number;
            dateRange: {
                fromDate: string;
                toDate: string;
            };
        };
    }> {
        return this.get('/accounting-blocked-status?date=' + fechaFactura);
    }
}

export default ContabilidadRepository;
