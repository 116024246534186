import { TipoFormularioCliente } from 'features/Incomes/domain/TipoFormularioCliente';
import { LegacyClient } from 'features/Ingresos/Clientes/adapters/LegacyClient';

type FormControlVisible = { [key in keyof LegacyClient]: { required: boolean; visible: boolean } };
type Settings = { [key in TipoFormularioCliente]: FormControlVisible };

export const FormularioTipoClienteSettings: Settings = {
    espParticular: {
        pais: { visible: true, required: true },
        type: { visible: true, required: true },
        nifIva: { visible: false, required: false },
        nombre: { visible: true, required: true },
        cif: { visible: true, required: true },
        phone: { visible: true, required: false },
        email: { visible: true, required: false },
        provincia: { visible: true, required: true },
        poblacion: { visible: true, required: true },
        cp: { visible: true, required: true },
        direccion: { visible: true, required: true },
        isCanario: { visible: true, required: false },
    },
    espNoParticular: {
        pais: { visible: true, required: true },
        type: { visible: true, required: true },
        nifIva: { visible: false, required: false },
        nombre: { visible: true, required: true },
        cif: { visible: true, required: true },
        phone: { visible: true, required: false },
        email: { visible: true, required: false },
        provincia: { visible: true, required: true },
        poblacion: { visible: true, required: true },
        cp: { visible: true, required: true },
        direccion: { visible: true, required: true },
        isCanario: { visible: true, required: false },
    },
    eurParticular: {
        pais: { visible: true, required: true },
        type: { visible: true, required: true },
        nifIva: { visible: false, required: false },
        nombre: { visible: true, required: true },
        cif: { visible: false, required: false },
        phone: { visible: true, required: false },
        email: { visible: true, required: false },
        provincia: { visible: false, required: false },
        poblacion: { visible: false, required: false },
        cp: { visible: false, required: false },
        direccion: { visible: true, required: false },
        isCanario: { visible: false, required: false },
    },
    eurNoParticular: {
        pais: { visible: true, required: true },
        type: { visible: true, required: true },
        nifIva: { visible: true, required: true },
        cif: { visible: true, required: false },
        nombre: { visible: true, required: true },
        phone: { visible: true, required: false },
        email: { visible: true, required: false },
        provincia: { visible: false, required: false },
        poblacion: { visible: false, required: false },
        cp: { visible: false, required: false },
        direccion: { visible: true, required: false },
        isCanario: { visible: false, required: false },
    },
    noEur: {
        pais: { visible: true, required: true },
        type: { visible: true, required: true },
        nifIva: { visible: false, required: false },
        cif: { visible: false, required: false },
        nombre: { visible: true, required: true },
        phone: { visible: true, required: false },
        email: { visible: true, required: false },
        provincia: { visible: false, required: false },
        poblacion: { visible: false, required: false },
        cp: { visible: false, required: false },
        direccion: { visible: true, required: false },
        isCanario: { visible: false, required: false },
    },
};
