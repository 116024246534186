import { Confetti } from '@declarando/design_system';
import {
    PresentationOfRentaNavigationSteps,
    type TPresentationOfRentaNavigationStep,
} from 'features/CalculadoraRenta/PresentationOfRenta/domain/PresentationOfRentaNavigationSteps';
import { useRentalPresentationService } from 'features/CalculadoraRenta/PresentationOfRenta/services/PresentationOfRentaService';
import { useGetPresentationOfRentaStep } from 'features/CalculadoraRenta/PresentationOfRenta/ui/hooks/useGetPresentationOfRentaStep';
import { useState } from 'react';
import { IsManagedByUserArgs } from '../../infra/PresentationOfRentaRepository';

export const usePresentationOfRenta = () => {
    const [isMutationLoading, setIsMutationLoading] = useState(false);
    const [navigationStep, setNavigationStep] = useState<TPresentationOfRentaNavigationStep>(
        PresentationOfRentaNavigationSteps.LOADING,
    );

    const service = useRentalPresentationService();

    const changeNavigationStep = (step: TPresentationOfRentaNavigationStep) => {
        setNavigationStep(step);
    };

    const data = useGetPresentationOfRentaStep();

    const celebrate = () => {
        goToNextStep();
        setTimeout(() => {
            new Confetti('confetti-canvas').run();
        }, 100);
    };

    const goToNextStep = async () => {
        setIsMutationLoading(true);
        try {
            const { success } = await service.goToNextStep();
            if (success) {
                data.refetch();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsMutationLoading(false);
        }
    };

    const updateIsManagedByUser = async (args: Pick<IsManagedByUserArgs, 'isManagedByUser'>) => {
        setIsMutationLoading(true);
        try {
            const { success } = await service.isManagedByUser(args);
            if (success) {
                data.refetch();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsMutationLoading(false);
        }
    };

    return {
        ...data,
        navigationStep,
        isMutationLoading,
        changeNavigationStep,
        updateIsManagedByUser,
        goToNextStep,
        celebrate,
    };
};
