import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { SalesDocumentSendEmailRepository } from '../domain/SalesDocumentSendEmailWithDocument';

export abstract class SalesDocumentSendEmailService extends DeclarandoApiService {
    constructor(private repository: SalesDocumentSendEmailRepository) {
        super();
    }

    sendEmail(args: { email: string; documentId: string }) {
        return this.execute(() => this.repository.sendEmailWithDocument(args), { shouldThrowError: true });
    }
}
