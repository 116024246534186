import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { ImpuestosV2Repository } from '../Infra/ImpuestosV2Repository';
import { Periodicidad, TModeloPendiente } from '../domain/TModeloPendiente';
import { FilesV2Repository } from '../../../shared/infra/repositories/FilesV2Repository';

export class ModelosPorPresentarService extends DeclarandoApiService {
    constructor(private repository = new ImpuestosV2Repository()) {
        super();
    }
    getModelosPorPresentar() {
        return this.execute(() => this.repository.getModelosPorPresentar(), { shouldThrowError: true });
    }

    getModelosProximos() {
        return this.execute(() => this.repository.getModelosProximos());
    }

    getTiposDeclaracion(modelo: TModeloPendiente) {
        return this.execute(() => this.repository.getTiposDeclaracion(modelo));
    }

    updateMetodoPago({
        modelNumber,
        year,
        period,
        paymentType,
    }: {
        modelNumber: string;
        paymentType: string;
        year: number;
        period: string;
    }) {
        return this.execute(() => this.repository.updatePaymentType(modelNumber, paymentType, year, period));
    }

    downloadTxtModelo(modelo: TModeloPendiente, paymentMethod?: string | null) {
        if (modelo.periodicidad === Periodicidad.TRIMESTRAL) {
            return this.execute(() =>
                this.repository.downloadModelTrimestral(
                    modelo.nombre,
                    modelo.presentacion.year,
                    modelo.presentacion.trimestre,
                    paymentMethod,
                ),
            );
        } else {
            return this.execute(() => this.repository.downloadModelAnual(modelo.nombre, modelo.presentacion.year));
        }
    }

    getLinksModelo(modelo: TModeloPendiente) {
        return this.execute(() => this.repository.getLinksModelo(modelo));
    }

    presentarModelo(modelo: TModeloPendiente, formData: FormData) {
        return this.execute(() =>
            new FilesV2Repository().postModelPresentation(
                modelo.nombre,
                modelo.presentacion.trimestre,
                modelo.presentacion.year,
                formData,
            ),
        );
    }
}
