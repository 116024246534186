import V2Repository from './V2Repository';

export class FilesV2Repository extends V2Repository {
    get037() {
        return this.get('/fichero/037/check');
    }

    store037(formData: any) {
        return this.post('/fichero/037', formData);
    }

    delete037() {
        return this.delete('/fichero/037');
    }

    postModelPresentation(model: string, quarter: string, year: string | number, formData: any) {
        return this.post('/fichero/justificante/' + model + '/' + year + '/' + quarter, formData);
    }

    getModelPresentationUrl(model: string, quarter: string, year: string | number) {
        return import.meta.env.VITE_API_URL + '/v2/fichero/justificante/' + model + '/' + year + '/' + quarter;
    }

    postInvoiceLogo(formData: any, iae: string) {
        return this.post('/fichero/logo-factura/' + iae, formData);
    }

    getInvoiceLogoUrl(iae: string) {
        return import.meta.env.VITE_API_URL + '/v2/fichero/logo-factura/get/' + iae;
    }

    deleteInvoiceLogo(iae: string) {
        return this.delete('/fichero/logo-factura/' + iae);
    }

    hasInvoiceLogo() {
        return this.get('/fichero/logo-factura/check');
    }

    getUrlInvoicePdf(invoiceId: string) {
        return import.meta.env.VITE_API_URL + '/v2/fichero/factura/' + invoiceId;
    }

    getProductUrl(productId: string) {
        return import.meta.env.VITE_API_URL + '/v2/fichero/producto/' + productId;
    }
    getUrlGastoDeclarandoPdf(invoiceId: string) {
        return import.meta.env.VITE_API_URL + `/v2/facturas-declarando/${invoiceId}/descargar`;
        //return import.meta.env.VITE_API_URL + `/v2/gastos-declarando/${invoiceId}/factura`;
    }
}
