import { InvoicingActivityType } from './InvoicingActivity';

export const InvoicingActivitiesTypeChecker = {
    isRancherNotFattening: (activity: InvoicingActivityType): boolean => activity === 'RANCHER_NOT_FATTENING',

    isRancherForFattening: (activity: InvoicingActivityType): boolean => activity === 'RANCHER_FOR_FATTENING',

    isBusiness: (activity: InvoicingActivityType): boolean => activity === 'BUSINESS',

    isProfessional: (activity: InvoicingActivityType): boolean => activity === 'PROFESSIONAL',

    isArtistic: (activity: InvoicingActivityType): boolean => activity === 'ARTISTIC',

    isRent: (activity: InvoicingActivityType): boolean => activity === 'RENT',
};
