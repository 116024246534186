import { publishTrackEvent } from './publishTrackEvent';

const createTrackingEvent =
    <T extends Record<string, string>>(type: string) =>
    (payload: T) =>
        publishTrackEvent({ type, payload });

export const TrackingEvents = {
    clickElement: createTrackingEvent<{ event_label: string }>('Click-element'),
    clickNextStep: createTrackingEvent<{ event_label: string }>('Click-next-step'),
    clickDetail: createTrackingEvent<{ event_label: string }>('Click-detail'),
    option: createTrackingEvent<{ option_label: string }>('Option'),
    tab: createTrackingEvent<{ tab_label: string }>('Tab'),
    fileUpload: createTrackingEvent<{ event_label: string }>('File-upload'),
    fillField: createTrackingEvent<{ event_label: string; item_id?: string }>('Fill-field'),
};
