import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import { useFetch } from 'shared/UI/hooks/QueryHooks';
import DateWrapper from 'shared/infra/utils/Date';
import { SalesDocumentType, useSalesDocumentFormContext } from '../../../../context/SalesDocumentFormProvider';
import { useEffect } from 'react';
import { Notifier } from 'shared/infra/utils/Notifier';
import { SalesDocumentNumberServiceFactory } from 'features/Ingresos/SalesDocument/shared/services/SalesDocumentNumberServiceFactory';

const ErrorMessageMapper: Record<SalesDocumentType, string> = {
    invoice:
        'Para seguir con el proceso de creación de factura tienes que rellenar la dirección de trabajo en tu perfil',
    quote: 'Para seguir con el proceso de creación del presupuesto tienes que rellenar la dirección de trabajo en tu perfil',
};

const QueryKeyMapper: Record<SalesDocumentType, string> = {
    invoice: 'invoices',
    quote: 'quotes',
};

const useGetNextSalesDocumentNumberArgs = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const watchIssueDate = useCustomWatch({ control, name: 'issueDate' });

    const year = DateWrapper.getFullYear(watchIssueDate ?? new Date()).toString();

    return { year };
};

export const useGetNextSalesDocumentNumber = () => {
    const { userStatus } = useRootContext();
    const { salesDocumentMode, salesDocumentType } = useSalesDocumentFormContext();
    const service = SalesDocumentNumberServiceFactory.create(salesDocumentType);
    const { year } = useGetNextSalesDocumentNumberArgs();

    const context = useFetch<string>({
        queryKey: [QueryKeyMapper[salesDocumentType], 'series', year],
        fetchFn: () => service.getNextNumber({ year }),
        config: {
            enabled: (userStatus.autoNumInvoice || salesDocumentType === 'quote') && salesDocumentMode === 'create',
        },
    });

    useEffect(() => {
        if (
            context.isError &&
            //@ts-expect-error: Property 'error' does not exist on type 'never'.
            context.error.body.code === 'DEK_INV.PERSON_USER_NOT_EXIST' &&
            //@ts-expect-error: Property 'error' does not exist on type 'never'.
            context.error.body.message.includes('with no working address')
        ) {
            Notifier.error({
                message: ErrorMessageMapper[salesDocumentType],
                link: { href: '/user/profile/vivienda', label: 'Ir a mi perfil' },
                options: { toastId: 'no-working-address' },
            });
        }
    }, [context.data?.error, context.error, context.isError, salesDocumentType]);

    return {
        nextSalesDocumentNumber: context.data?.data,
        isLoading: context.isLoading,
    };
};
