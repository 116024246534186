import { IAEType } from './IAEType';
import IrpfTypes from '../../contabilidad/irpf/IrpfTypes';

export class IAEChecker {
    readonly ALQUILER_DE_VIVIENDAS_ID = 1072;

    constructor(private iae: IAEType) {}

    isAlquilerDeViviendas() {
        return +this.iae.id === this.ALQUILER_DE_VIVIENDAS_ID;
    }

    getIrpfAlquilerDeViviendas() {
        return IrpfTypes.NONE;
    }
}
