import {
    useMutation,
    useQuery,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
    QueryCache,
    useInfiniteQuery,
    UseMutationResult,
    useQueries,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { UseQueryOptions, QueryClientConfig, UseInfiniteQueryOptions, InfiniteData } from '@tanstack/react-query';

type TypeKey = string;
type QueryKeyT = (TypeKey | object)[];

export type UseFetchParams<T, S = T> = {
    queryKey: TypeKey[];
    config?: Partial<UseQueryOptions<T, Error, S, QueryKeyT>>;
    fetchFn: () => Promise<T>;
};

/**
 * @file 'Use wrapper from the web app: shared/UI/hooks/QueryHooks'
 * @param {Array} typeKey - Specific key for the query, used to clean the cache.
 * @param {UseQueryOptions} config - React Query configuration object.
 * @generic T - Argument for response from the server.
 * @generic S - Argument for modified data in select function, if it's not passed, default will be the response from the server.
 */
export const useFetch = <T, S = T>({ queryKey, config, fetchFn }: UseFetchParams<T, S>) => {
    const context = useQuery<T, Error, S, QueryKeyT>({
        queryKey: [...queryKey],
        queryFn: fetchFn,
        ...config,
    });
    return context;
};

export type UseQueriesParams<T, S = T> = {
    queries: {
        queryKey: TypeKey[];
        config?: Partial<UseQueryOptions<T, Error, S, QueryKeyT>>;
        fetchFn: () => Promise<T>;
    }[];
};

/**
 * Hook for handling multiple queries in parallel
 * @param queries Array of query configurations
 * @returns Array of query results
 */
export const useFetchMultiple = <T, S = T>({ queries }: UseQueriesParams<T, S>) => {
    return useQueries({
        queries: queries.map(({ queryKey, config, fetchFn }) => ({
            queryKey: [...queryKey],
            queryFn: fetchFn,
            ...config,
        })),
    });
};

export type UseCommonMutationParams = {
    queryKey: TypeKey[];
};

export type GenericMutationParams<T, S, U = T, R = S> = {
    mutationFn: (data: T) => Promise<S>;
    queryKey: QueryKeyT;
    updater?: (oldData: U, newData: R) => U;
};

export const useGenericMutation = <T, S, U = T, R = S>({
    mutationFn,
    queryKey,
    updater,
}: GenericMutationParams<T, S, U, R>) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onMutate: async (data) => {
            await queryClient.cancelQueries({ queryKey: queryKey });

            const previousData = queryClient.getQueryData(queryKey);
            queryClient.setQueryData(queryKey, (oldData: U) =>
                updater ? updater(oldData!, data as unknown as R) : previousData,
            );
            return previousData;
        },
        onError: (_error, _, context) => {
            queryClient.setQueryData(queryKey, context);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKey });
        },
    });
};

export type UseLoadMoreParams<T, S = T> = {
    queryKey: TypeKey[];
    config?: Partial<UseInfiniteQueryOptions<T, Error, InfiniteData<S>, QueryKeyT>>;
    fetchFn: (pageParam: number) => Promise<T>;
};

export const useLoadMore = <T, S = T>({ fetchFn, queryKey, config }: UseLoadMoreParams<T, S>) => {
    const context = useInfiniteQuery<T, Error, InfiniteData<S>, QueryKeyT>({
        queryKey: [...queryKey],
        gcTime: config?.gcTime,
        staleTime: config?.staleTime as number,
        refetchOnMount: config?.refetchOnMount ?? (true as any),
        queryFn: ({ pageParam }) => fetchFn(pageParam as number),
        initialPageParam: 0,
        getNextPageParam:
            config?.getNextPageParam ||
            ((lastPage: any, _, lastPageParam) => {
                if (lastPage.data?.data?.rows.length === 0) return undefined;
                return (lastPageParam as number) + 1;
            }),
    });

    return context;
};

export { QueryClient, QueryClientProvider, ReactQueryDevtools, QueryCache };

export type { UseMutationResult, QueryClientConfig };
