import { NewDateSelector } from '@declarando/design_system';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { useState, useEffect } from 'react';
import DateWrapper from 'shared/infra/utils/Date';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';

const optionsDay = [
    { value: 1, label: '1 día' },
    { value: 7, label: '7 días' },
    { value: 15, label: '15 días' },
    { value: 30, label: '30 días' },
];

const DueDateInvoiceInput = ({
    daySelected,
    setDaySelected,
    readOnly,
}: {
    daySelected?: number | null;
    setDaySelected?: React.Dispatch<React.SetStateAction<number | null>>;
    readOnly?: boolean;
}) => {
    const { control } = useCustomFormContext<InvoiceForm>();
    return (
        <ControlledFormField
            control={control}
            name="dueDate"
            Component={({ field, formState: { errors } }) => (
                <NewDateSelector
                    ref={field.ref}
                    error={!!errors?.dueDate?.message}
                    helperText={errors?.dueDate?.message}
                    id="invoice-due-date"
                    withDropdown
                    readOnly={readOnly}
                    optionsDropdown={optionsDay}
                    setOptionSelectedDropdown={(value) => setDaySelected && setDaySelected(value)}
                    optionDropdownSelected={daySelected as number}
                    allowClear
                    label="Fecha vencimiento"
                    tooltipOptions={{
                        title: '¿Qué es la fecha de vencimiento?',
                        text: 'Es la fecha límite para recibir el pago de la factura. Indicar la fecha de vencimiento, te ayudará a llevar el control de tus finanzas.',
                    }}
                    onChange={(value) => {
                        field.onChange(value);
                    }}
                    value={field.value ? new Date(field.value) : null}
                />
            )}
        />
    );
};

const DueDateInvoiceContent = () => {
    const { control, setValue, trigger } = useCustomFormContext<InvoiceForm>();
    const { salesDocumentMode } = useSalesDocumentFormContext();
    const [daySelected, setDaySelected] = useState<number | null>(salesDocumentMode === 'create' ? 1 : null);
    const watchIssueDate = useCustomWatch({ control, name: 'issueDate' });

    useEffect(() => {
        if (!daySelected) return;

        const newDueDate = DateWrapper.addDays(watchIssueDate, daySelected);
        setValue('dueDate', newDueDate);
        trigger('dueDate');
    }, [watchIssueDate, setValue, daySelected, trigger]);

    return <DueDateInvoiceInput daySelected={daySelected} setDaySelected={setDaySelected} />;
};

export const DueDateInvoice = () => {
    const { formBlocked } = useSalesDocumentFormContext();

    if (formBlocked.isFormBlocked) return <DueDateInvoiceInput readOnly />;

    return <DueDateInvoiceContent />;
};
