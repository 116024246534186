export const trackParams = {
    login: {
        titulo: 'Login',
        tipo: 'Acceso',
        seccion: 'General',
    },
    inicio: {
        titulo: 'Dashboard',
        tipo: 'Dashboard',
        seccion: 'General',
        // url: 'https://app.declarando.es/inicio',
    },
    listado_ingresos: {
        titulo: 'Ingresos',
        tipo: 'Listado',
        seccion: 'Ingresos',
        // url: 'https://app.declarando.es/ingresos/ingresos',
    },
    ingreso_con_factura: {
        titulo: 'Ingreso con factura',
        tipo: 'Formulario',
        seccion: 'Ingresos',
        // url: 'https://app.declarando.es/ingreso/con-factura',
    },
    ingreso_sin_factura: {
        titulo: 'Ingreso sin factura',
        tipo: 'Formulario',
        seccion: 'Gastos',
        // url: 'https://app.declarando.es/ingreso/sin-factura',
    },
    factura: {
        titulo: 'Nuevo ingreso con factura 2025',
        tipo: 'Formulario',
        seccion: 'Ingresos',
    },
    gasto_con_factura: {
        titulo: 'Gasto con factura',
        tipo: 'Formulario',
        seccion: 'Gastos',
    },
    nuevo_gasto: {
        titulo: 'Gasto',
        tipo: 'Formulario',
        seccion: 'Gastos',
    },
    nuevo_gasto_sin_factura: {
        titulo: 'Gasto sin factura',
        tipo: 'Formulario',
        seccion: 'Gastos',
    },
    gasto_sin_factura: {
        titulo: 'Gasto sin factura',
        tipo: 'Formulario',
        seccion: 'Gastos',
    },
    nuevo_ingreso_factura_simplificada: {
        titulo: 'Ingreso factura simplificada',
        tipo: 'Formulario',
        seccion: 'Ingresos',
        // url: 'https://app.declarando.es/ingreso/simplificado',
    },
    ingreso_factura_simplificada: {
        titulo: 'Ingreso factura simplificada',
        tipo: 'Formulario',
        seccion: 'Ingresos',
        // url: 'https://app.declarando.es/ingreso/simplificado',
    },
    nuevo_ingreso_de_subvencion: {
        titulo: 'Ingreso de subvención',
        tipo: 'Formulario',
        seccion: 'Ingresos',
        // url: 'https://app.declarando.es/ingreso/subvencion',
    },
    presupuestos: {
        titulo: 'Presupuestos',
        tipo: 'Listado',
        seccion: 'Ingresos',
        // url: 'https://app.declarando.es/ingresos/presupuestos',
    },
    nuevo_presupuesto: {
        titulo: 'Presupuesto',
        tipo: 'Formulario',
        seccion: 'Ingresos',
        // url: 'https://app.declarando.es/presupuesto',
    },
    clientes: {
        titulo: 'Clientes',
        tipo: 'Listado',
        seccion: 'Ingresos',
        // url: 'https://app.declarando.es/ingresos/clientes',
    },
    movimientos_bancarios_ingresos: {
        titulo: 'Movimientos bancarios ingresos',
        tipo: 'Listado',
        seccion: 'Ingresos',
        // url: 'https://app.declarando.es/ingresos/movimientos-bancarios',
    },
    gastos: {
        titulo: 'Gastos',
        tipo: 'Listado',
        seccion: 'Gastos',
        // url: 'https://app.declarando.es/gastos/gastos',
    },
    proveedores: {
        titulo: 'Proveedores',
        tipo: 'Listado',
        seccion: 'Gastos',
        // url: 'https://app.declarando.es/gastos/proveedores',
    },
    movimientos_bancarios_gastos: {
        titulo: 'Movimientos bancarios gastos',
        tipo: 'Listado',
        seccion: 'Gastos',
        // url: 'https://app.declarando.es/gastos/movimientos-bancarios',
    },
    reglas_de_autocontabilizacion: {
        titulo: 'Reglas de autocontabilización',
        tipo: 'Listado',
        seccion: 'Gastos',
        // url: 'https://app.declarando.es/gastos/reglas-de-autocontabilizacion',
    },
    modelos_pendientes: {
        titulo: 'Modelos pendientes',
        tipo: 'Listado',
        seccion: 'Impuestos',
        // url: 'https://app.declarando.es/impuestos',
    },
    modelos_completados: {
        titulo: 'Modelos completados',
        tipo: 'Listado',
        seccion: 'Impuestos',
        // url: 'https://app.declarando.es/impuestos/modelos-presentados',
    },
    planificador_renta: {
        titulo: 'Planificador renta',
        tipo: 'Listado',
        seccion: 'Planificador renta',
        // url: 'https://app.declarando.es/calculadora-renta',
    },
    nivel_de_riesgo: {
        titulo: 'Nivel de riesgo',
        tipo: 'Dashboard',
        seccion: 'Finanzas',
        // url: 'https://app.declarando.es/finanzas',
    },
    caja: {
        titulo: 'Caja',
        tipo: 'Dashboard',
        seccion: 'Finanzas',
        // url: 'https://app.declarando.es/finanzas',
    },
    ingresos_finanzas: {
        titulo: 'Ingresos finanzas',
        tipo: 'Dashboard',
        seccion: 'Finanzas',
        // url: 'https://app.declarando.es/finanzas',
    },
    datos_personales: {
        titulo: 'Datos personales',
        tipo: 'Formulario',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile',
    },
    vivienda: {
        titulo: 'Vivienda',
        tipo: 'Formulario',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile/vivienda',
    },
    datos_fiscales: {
        titulo: 'Datos fiscales',
        tipo: 'Formulario',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile/alta-autonomo',
    },
    actividad: {
        titulo: 'Actividad',
        tipo: 'Formulario',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile/actividad',
    },
    estilo_facturas: {
        titulo: 'Estilo facturas',
        tipo: 'Formulario',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile/estilos-facturas',
    },
    renta_configuracion: {
        titulo: 'Renta perfil',
        tipo: 'Formulario',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile/renta',
    },
    conexion_bancaria: {
        titulo: 'Conexión bancaria perfil',
        tipo: 'Formulario',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile/conexion-bancaria',
    },
    notificaciones: {
        titulo: 'Notificaciones',
        tipo: 'Formulario',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile/notificaciones',
    },
    integraciones: {
        titulo: 'Integraciones',
        tipo: 'Formulario',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile/integraciones',
    },
    registros_de_acceso: {
        titulo: 'Registros de acceso',
        tipo: 'Listado',
        seccion: 'Configuracion perfil',
        // url: 'https://app.declarando.es/user/profile/registro-de-acceso',
    },
    tus_clientes: {
        titulo: 'Tus clientes',
        tipo: 'Distribuidor',
        seccion: 'Otras perfil',
        // url: 'https://app.declarando.es/agente',
    },
    forma_de_pago: {
        titulo: 'Forma de pago',
        tipo: 'Listado',
        seccion: 'Otras perfil',
        // url: 'https://app.declarando.es/listado-facturas',
    },
    tu_plan: {
        titulo: 'Tu plan',
        tipo: 'Distribuidor',
        seccion: 'Otras perfil',
        // url: 'https://app.declarando.es/contrata/planes',
    },
    contratacion_plan_esencial: {
        titulo: 'Contratación plan Esencial',
        tipo: 'Formulario',
        seccion: 'Otras perfil',
        // url: 'https://app.declarando.es/contrata/plan/2',
    },
    contratacion_plan_avanzado: {
        titulo: 'Contratación plan Avanzado',
        tipo: 'Formulario',
        seccion: 'Otras perfil',
        // url: 'https://app.declarando.es/contrata/plan/3',
    },
    contratacion_plan_estelar: {
        titulo: 'Contratación plan Estelar',
        tipo: 'Formulario',
        seccion: 'Otras perfil',
        // url: 'https://app.declarando.es/contrata/plan/4',
    },
    invita_a_un_amigo: {
        titulo: 'Invita a un amigo',
        tipo: 'Formulario',
        seccion: 'Otras perfil',
        // url: 'https://app.declarando.es/invita-amigo',
    },
    informe_renta: {
        titulo: 'Informe renta',
        tipo: 'Informe',
        seccion: 'Otras perfil',
        // url: 'https://app.declarando.es/impuestos/informe-renta',
    },
} as const;
