import { Box, Grid } from '@declarando/design_system';
import { Form } from 'shared/UI/components/Form/components/Form';
import { useCustomForm } from 'shared/UI/components/Form/hooks/useCustomForm';
import { resolver } from 'shared/UI/components/Form/resolver/resolver';
import { DefaultValues, Path, UseFormReturn } from 'react-hook-form';
import { Notifier } from 'shared/infra/utils/Notifier';
import { IngresoConFacturaClientRulesService } from '../../../IngresoConFactura/services/IngresoConFacturaRulesService';
import {
    useVIESCheck,
    VIESProvider,
} from './SalesDocumentFormContent/components/ClientDetails/components/VIESChecker/hooks/useVIESCheck';
import { SalesDocumentFormContent } from './SalesDocumentFormContent/SalesDocumentFormContent';
import { IngresoConFacturaResumen } from './SalesDocumentSummary/SalesDocumentSummary';
import {
    FormBlocked,
    SalesDocumentFormMode,
    SalesDocumentFormProvider,
    SalesDocumentType,
} from './context/SalesDocumentFormProvider';
import { HandleSubmitArgs } from '../hooks/useSalesDocumentMutation';
import { ObjectSchema } from 'yup';
import { BaseSalesDocument } from '../../domain/SalesDocument';

type SalesDocumentFormProps<T extends BaseSalesDocument = BaseSalesDocument> = {
    form: UseFormReturn<T>;
    handleSubmit: (args: HandleSubmitArgs) => void;
    isMutationPending: boolean;
    formBlocked: FormBlocked;
};

type SalesDocumentLayoutProps<TDocument extends BaseSalesDocument = BaseSalesDocument> = {
    defaultValues: TDocument;
    salesDocumentMode: SalesDocumentFormMode;
    salesDocumentType: SalesDocumentType;
    handleSubmit: SalesDocumentFormProps<TDocument>['handleSubmit'];
    isMutationPending: SalesDocumentFormProps<TDocument>['isMutationPending'];
    formBlocked: SalesDocumentFormProps<TDocument>['formBlocked'];
    salesDocumentSchema: ObjectSchema<any>;
};

const SalesDocumentForm = <TDocument extends BaseSalesDocument = BaseSalesDocument>({
    form,
    handleSubmit,
    isMutationPending,
}: SalesDocumentFormProps<TDocument>) => {
    const { triggerVIESError } = useVIESCheck();

    const handleSubmitValidationAfterSubmit = (documentData: TDocument) => {
        const hasConsistentVAT = IngresoConFacturaClientRulesService.hasConsistentVAT({
            client: documentData.client,
            lines: documentData.lines,
        });

        if (!hasConsistentVAT) {
            const formValues = form.getValues();
            formValues.lines.forEach((_line: TDocument['lines'][number], index: number) => {
                const fieldPath = `lines.${index}.vat` as const;
                form.setError(
                    fieldPath as Path<TDocument>,
                    {
                        message: 'El IVA de las líneas no es consistente con el IVA del cliente',
                    },
                    { shouldFocus: index === 1 },
                );
            });
            return Notifier.error({
                title: 'No ha sido posible crear el ingreso',
                message: (
                    <>
                        Debes seleccionar el mismo tipo de IVA para todas las líneas de concepto:
                        <div>• No aplica IVA español → 0%</div>
                        <div>• Sí aplica IVA español → 4%, 10% o 21%</div>
                    </>
                ),
                link: {
                    href: 'https://ayuda.declarando.es/es/articles/10038609-error-al-crear-factura-incompatibilidad-de-tipos-de-iva#h_7d67042ed1',
                    label: 'Saber más',
                    shouldOpenInNewTab: true,
                },
            });
        }

        handleSubmit({ data: documentData, triggerVIESError });
    };

    return (
        <Form<TDocument>
            form={form}
            onSubmit={(data) => handleSubmitValidationAfterSubmit(data)}
            onInvalid={(errors: any) => console.log('error', errors)}
        >
            <Grid
                gridTemplateColumns={{ _: '1fr', mediumScreen: '1fr 360px' }}
                gridTemplateRows={{ _: 'auto auto', mediumScreen: '1fr' }}
                gridTemplateAreas={{
                    _: `
            "content"
            "resumen"
        `,
                    mediumScreen: `"content resumen"`,
                }}
                gridGap="sm"
                width="100%"
            >
                <Box gridArea="content">
                    <SalesDocumentFormContent />
                </Box>
                <Box gridArea="resumen">
                    <IngresoConFacturaResumen isSubmitButtonDisabled={isMutationPending} />
                </Box>
            </Grid>
        </Form>
    );
};

export const SalesDocumentFormLayout = <TDocument extends BaseSalesDocument>({
    salesDocumentMode,
    salesDocumentType,
    defaultValues,
    handleSubmit,
    isMutationPending,
    formBlocked,
    salesDocumentSchema,
}: SalesDocumentLayoutProps<TDocument>) => {
    const form = useCustomForm<TDocument>({
        mode: 'onChange',
        defaultValues: defaultValues as DefaultValues<TDocument>,
        resolver: resolver(salesDocumentSchema),
    });

    return (
        <Box m={{ _: 'sm', desktop: 'lg' }} display="flex" justifyContent="center">
            <Box width="100%" maxWidth="1440px">
                <SalesDocumentFormProvider
                    defaultValues={defaultValues}
                    salesDocumentMode={salesDocumentMode}
                    salesDocumentType={salesDocumentType}
                    formBlocked={formBlocked}
                >
                    <VIESProvider>
                        <SalesDocumentForm<TDocument>
                            form={form}
                            handleSubmit={handleSubmit}
                            isMutationPending={isMutationPending}
                            formBlocked={formBlocked}
                        />
                    </VIESProvider>
                </SalesDocumentFormProvider>
            </Box>
        </Box>
    );
};
