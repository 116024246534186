import {
    addStateNameToClient,
    addCountryNameToClient,
} from 'features/Ingresos/Clientes/services/AssignLocationNameToClient';
import { useGetClientsList } from 'features/Ingresos/Clientes/UI/hooks/useGetClientsList';
import { useGetFrequentInvoiceClients } from 'features/Ingresos/Clientes/UI/hooks/useGetFrequentInvoiceClients';
import { useGetCountriesList, useGetStatesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { useState } from 'react';
import { Notifier } from 'shared/infra/utils/Notifier';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useVIESCheck } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/VIESChecker/hooks/useVIESCheck';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useAssignZeroVATByDefault } from '../../../../Lines/hooks/useAssignZeroVATByDefault';

export const useClientSelection = (args: { debouncedSearchValue: string; handleCloseAction: () => void }) => {
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const { countries } = useGetCountriesList();
    const { states } = useGetStatesList();
    const { setValue, getValues } = useCustomFormContext<InvoiceForm>();
    const { frequentInvoiceClients, isLoading: isLoadingFrequentInvoiceClients } = useGetFrequentInvoiceClients();
    const {
        clientsList,
        isLoading: isLoadingClientsList,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useGetClientsList({ filter: args.debouncedSearchValue });
    const { resetCheckVIES } = useVIESCheck();
    const { assignVATZeroByDefault } = useAssignZeroVATByDefault();

    const handleSelectClient = (selectedIds: string[]) => {
        let selectedClient;

        if (selectedIds[0].includes('-')) {
            //NOTE: selectedIds[0] can be a string like '{id}-{exponentialNumber}' where the first number is the client id and the second number is an exponential number to avoid duplicates
            // representing a frequent client
            const cleanupID = selectedIds[0].split('-')[0];
            selectedClient = frequentInvoiceClients.find((client) => client.id === cleanupID);
        } else {
            selectedClient = clientsList.find((client) => client.id === selectedIds[0]);
        }

        if (!selectedClient) return;

        const clientWithStateName = addStateNameToClient(selectedClient, states);
        const clientWithCountryName = addCountryNameToClient(clientWithStateName, countries);

        if (clientWithCountryName) {
            const previousClient = getValues('client');

            setValue('client', {
                ...clientWithCountryName,
                //NOTE: The following properties can be manipulated in the invoice form for EU, company/freelancer clients
                fiscalId: clientWithCountryName.fiscalId ?? '',
                address: clientWithCountryName.address ?? '',
            });
            setValue('client.hasEUVatNumber', clientWithCountryName.hasEUVatNumber ?? undefined);
            setValue('withEquivalencySurcharge', null);
            setValue('activity.withImageRights', undefined as unknown as boolean);
            setValue('activity.forFattening', undefined as unknown as boolean);
            resetCheckVIES();
            setSelectedIds([]);

            if (previousClient?.id) {
                Notifier.notifyFeedBack({ title: 'Los datos del receptor se han actualizado' });
            }

            assignVATZeroByDefault({ client: clientWithCountryName });

            args.handleCloseAction();
        }
    };

    return {
        selectedIds,
        setSelectedIds,
        handleSelectClient,
        frequentInvoiceClients,
        isLoadingFrequentInvoiceClients,
        clientsList,
        isLoadingClientsList,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    };
};
