import { IApp } from 'shared/domain/interfaces/App';
import { IAEType } from './IAEType';

type IaesHelperProps = {
    state: IApp;
    setState: (data: any) => any;
    iaes: any[];
};

export class IaesHelper {
    constructor(private app?: IaesHelperProps) {}

    get iaes() {
        if (!this.app) return [];
        return this.app.state.userStatus.iaes;
    }

    findIae = (id: string) => this.iaes.find((iae: any) => +iae.id === +id) ?? null;

    hasIaesChanged = (bool: boolean) => this.app?.setState({ iaesChanged: bool });

    isRegimenGeneral(iae: IAEType) {
        return !iae.equivalenceSurcharge && !iae.ivaExempt;
    }
}
