import { SalesDocumentNextNumberAutomaticRepository } from '../../shared/domain/SalesDocumentNextNumberAutomaticRepository/SalesDocumentNextNumberAutomaticRepository';
import { SalesDocumentNextNumberAutomaticService } from '../../shared/services/SalesDocumentNumberService';

class QuoteNumberService extends SalesDocumentNextNumberAutomaticService {
    constructor(repository: SalesDocumentNextNumberAutomaticRepository) {
        super(repository);
    }
}

export const createQuoteNextNumberService = (
    repository: SalesDocumentNextNumberAutomaticRepository,
): QuoteNumberService => {
    return new QuoteNumberService(repository);
};
