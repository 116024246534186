import { createContext, useEffect } from 'react';
import { ContentLoader, Grid, NavigationSteps } from '@declarando/design_system';
import { useOnboarding } from './hooks/useOnboarding';
import { OnboardingMapper } from './OnboardingMapper';
import { OnboardingUrlPathMapper } from '../../../routes/setupNoAFFA';
import { useHistory } from 'react-router-dom';

export const OnboardingContext = createContext({} as ReturnType<typeof useOnboarding>);
const Onboarding = () => {
    const onboardingContext = useOnboarding();

    const { push } = useHistory();
    useEffect(() => {
        change();
    }, [onboardingContext.currentStep]);

    const change = () => {
        const uri = OnboardingUrlPathMapper[onboardingContext.currentStep];
        const url = document.location.pathname.match(uri);
        console.log('currentStep', onboardingContext.currentStep);
        if (!url) {
            push(uri);
        }
    };

    return (
        <OnboardingContext.Provider value={onboardingContext}>
            {onboardingContext.currentStep === 'BIENVENIDA' || onboardingContext.currentStep === 'FINALIZADO' ? (
                <>
                    {onboardingContext.busy ? (
                        <ContentLoader height={600} text={'Cargando...'} />
                    ) : (
                        OnboardingMapper[onboardingContext.currentStep]
                    )}
                </>
            ) : (
                <>
                    <Grid
                        maxWidth={1440}
                        margin="auto"
                        minHeight="600px"
                        bg="white"
                        borderRadius="md"
                        gridTemplateColumns={{ _: '1fr', tablet: '40% 60%', desktop: '380px 1fr' }}
                    >
                        <NavigationSteps list={Object.values(onboardingContext.steps)}></NavigationSteps>
                        {onboardingContext.busy ? (
                            <ContentLoader height={600} text={'Cargando...'} />
                        ) : (
                            OnboardingMapper[onboardingContext.currentStep]
                        )}
                    </Grid>
                </>
            )}
        </OnboardingContext.Provider>
    );
};

export default Onboarding;
