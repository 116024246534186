import { ReactNode, useEffect, useRef } from 'react';
import './expandable-form.css';

type Props = {
    children: ReactNode;
    shouldExpand: boolean;
};

export const ExpandableContent = ({ children, shouldExpand }: Props) => {
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            const focusableElements = contentRef.current.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
            );

            focusableElements.forEach((el) => {
                if (shouldExpand) {
                    (el as HTMLElement).removeAttribute('tabindex');
                } else {
                    (el as HTMLElement).setAttribute('tabindex', '-1');
                }
            });
        }
    }, [shouldExpand]);

    return (
        <div className={`expandable ${shouldExpand ? 'open' : ''}`} hidden={!shouldExpand} aria-hidden={!shouldExpand}>
            <div className="expandable-content" ref={contentRef}>
                {children}
            </div>
        </div>
    );
};
