import * as React from 'react';
import { createContext } from 'react';
import { useStep2 } from './useStep2';

export const Step2Context = createContext({} as ReturnType<typeof useStep2>);

export const Step2ContextProvider = ({ children }: { children: React.ReactNode }) => {
    const step2 = useStep2();

    return <Step2Context.Provider value={step2}>{children}</Step2Context.Provider>;
};
