import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { invoicePaymentMethodService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaPaymentOptionService';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { useState, useEffect } from 'react';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';

export const useCashMethod = () => {
    const { formBlocked } = useSalesDocumentFormContext();
    const [shouldBeDisabled, setShouldBeDisabled] = useState(false);

    const { control, setValue } = useCustomFormContext<InvoiceForm>();

    const invoiceLines = useCustomWatch({ control, name: 'lines' });
    const withEquivalencySurcharge = useCustomWatch({ control, name: 'withEquivalencySurcharge' });
    const incomeTaxPercentage = useCustomWatch({ control, name: 'incomeTax' });

    useEffect(() => {
        const isCashPaymentValid = invoicePaymentMethodService.isCashPaymentAmountValid({
            invoiceLines,
            withEquivalencySurcharge,
            incomeTax: incomeTaxPercentage,
        });

        if (!isCashPaymentValid) {
            setValue('paymentOptions.cash.isChecked', false);
            setShouldBeDisabled(true);
        } else {
            setShouldBeDisabled(false);
        }
    }, [incomeTaxPercentage, invoiceLines, setValue, withEquivalencySurcharge]);

    return { shouldBeDisabled: shouldBeDisabled || formBlocked.isFormBlocked, control };
};
