class IvaExempt {
    static NINGUNO = 0;
    static ALGUNOS = 1;
    static TODOS = 2;

    static getQuantity(iaes) {
        let equivalence = IvaExempt.NINGUNO;
        let some = false;
        let all = true;

        iaes.forEach((iae) => {
            iae.ivaExempt ? (some = true) : (all = false);
        });

        if (all) {
            equivalence = IvaExempt.TODOS;
        } else if (some) {
            equivalence = IvaExempt.ALGUNOS;
        }

        return equivalence;
    }
}

export default IvaExempt;
