import { Grid, Box } from '@declarando/design_system';
import { SkeletonInput } from 'shared/UI/components/Skeletons/Input/SkeletonInput';

export const LinesSkeletons = () => {
    return (
        <Grid
            gridTemplateColumns={{ _: '1fr', mediumScreen: '1fr' }}
            gridTemplateRows={{ _: 'auto', mediumScreen: 'auto auto' }}
            gridColumnGap="sm"
            gridRowGap="lg"
            pb="md"
        >
            <Grid gridTemplateColumns={{ _: '1fr', mediumScreen: '5fr 2fr' }} gridColumnGap="sm" gridRowGap="sm">
                <Box>
                    <SkeletonInput labelWidth="100%" />
                </Box>
                <Box>
                    <SkeletonInput labelWidth="100%" />
                </Box>
            </Grid>
            <Grid
                gridTemplateColumns={{ _: '1fr', mediumScreen: '1fr 5fr 2fr 2fr 5fr' }}
                gridColumnGap="sm"
                gridRowGap="sm"
            >
                <SkeletonInput labelWidth="100%" />
                <SkeletonInput labelWidth="100%" />
                <SkeletonInput labelWidth="100%" />
                <SkeletonInput labelWidth="100%" />
                <SkeletonInput labelWidth="100%" />
            </Grid>
        </Grid>
    );
};
