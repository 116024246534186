import { ContentLoader } from '@declarando/design_system';
import { ReactNode, useContext } from 'react';

import { Step2Initial } from './Step2Initial';
import { Step2Context, Step2ContextProvider } from './Step2Context';
import { Step2Bankflip } from './Step2Bankflip';

export const Step2Mapper = () => {
    const { state, StateKey } = useContext(Step2Context);

    const StatesMapper: { [keys: string]: ReactNode } = {
        [StateKey.INITIAL]: <Step2Initial />,
        [StateKey.LOADING]: <ContentLoader text={'Cargando...'} />,
        [StateKey.BANKFLIP]: <Step2Bankflip />,
    };

    return <>{StatesMapper[state] ?? StatesMapper[StateKey.INITIAL]}</>;
};

export const Step2 = () => {
    return (
        <Step2ContextProvider>
            <Step2Mapper />
        </Step2ContextProvider>
    );
};
