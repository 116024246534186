import { useContext, useState } from 'react';
import { OnboardingContext } from '../../Onboarding';
import { OnboardingSteps } from '../../../domain/OnboardingSteps';
import { useSolicitudPagoContabilidad } from '../../../services/useSolicitudPagoContabilidad';
import { Notifier } from 'shared/infra/utils/Notifier';
import { useImportarContabilidad } from '../../../services/useImportarContabilidad';

export const useStep3 = () => {
    enum StateKey {
        LOADING = 'loading',
        INITIAL = 'INITIAL',
    }
    const [state, setState] = useState(StateKey.INITIAL);

    const { currentStep, getOnboardingState } = useContext(OnboardingContext);
    const isInicio = currentStep === OnboardingSteps.CONTABILIDAD_INICIO;
    const isPagada = currentStep === OnboardingSteps.CONTABILIDAD_PAGADA;
    const [tab, setTab] = useState(isInicio ? 0 : 1);
    const { mutate: mutateSolicitudPagoContabilidad } = useSolicitudPagoContabilidad();

    const [file, setSelectedFile] = useState<File | null>(null);
    const [errorFile, setFileError] = useState<string>('');

    const { mutate: mutateImportarContabilidad } = useImportarContabilidad();

    const importarContabilidad = () => {
        if (!file) {
            setFileError('Debes seleccionar un archivo');
            return;
        }
        setState(StateKey.LOADING);
        setFileError('');
        const formData = new FormData();
        formData.append('file', file);
        mutateImportarContabilidad(formData, {
            onSuccess: async ({ data, message }) => {
                if (data) {
                    if (data.success) {
                        return getOnboardingState();
                    }
                }
                Notifier.notifyError(message);
                setState(StateKey.INITIAL);
            },
            onError: (error) => {
                Notifier.notifyError(error.message);
                setState(StateKey.INITIAL);
            },
        });
    };

    const solicitudPagoContabilidad = () => {
        setState(StateKey.LOADING);
        mutateSolicitudPagoContabilidad(null, {
            onSuccess: ({ data, message }) => {
                if (data?.url) {
                    return (window.location.href = data.url);
                }
                Notifier.notifyError(message);
                setState(StateKey.INITIAL);
            },
            onError: (error) => {
                Notifier.notifyError(error.message);
                setState(StateKey.INITIAL);
            },
        });
    };

    return {
        StateKey,
        state,
        tab,
        setTab,
        isInicio,
        isPagada,
        file,
        setSelectedFile,
        importarContabilidad,
        errorFile,
        solicitudPagoContabilidad,
    };
};
