import { Box, ContentLoader } from '@declarando/design_system';

export const MainLoaderSalesDocument = () => {
    return (
        <Box m={{ _: 'sm', desktop: 'lg' }} display="flex" justifyContent="center">
            <Box width="100%" maxWidth="1440px">
                <ContentLoader text="Cargando contenido" />
            </Box>
        </Box>
    );
};
