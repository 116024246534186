/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { AdviceBar, AdviceCard, Box, P } from '@declarando/design_system';
import { EmptyAdvices } from './EmptyAdvices';
import { AdviceState } from 'shared/UI/components/App/AdvicesBar/AdviceState';
import { LoadingAdvices } from './LoadingAdvices';

export const Advices = ({
    title,
    description,
    setDisplay,
    display,
    state,
    setState,
    advices,
    onRemember,
    onDelete,
    tasksList,
    advicesList,
    tasks,
    loading,
}) => {
    useEffect(() => {
        if (tasks.length > 0) {
            setDisplay(true);
        }
        setState(2);
    }, [tasks]);

    useEffect(() => {
        setTimeout(() => {
            // advicesList.addAjusteContabilidad(() => alert('Acción'))
        }, 5000);
        return () => {
            tasksList.reset();
            advicesList.reset();
        };
    }, []);

    const customAdviceCardsMapped = advicesList.state.advices.map((advice, id) => (
        <div onClick={() => setTimeout(() => advicesList.select(advice), 100)}>
            <AdviceCard
                direction="left"
                selected={advicesList.state.selected?.id === advice.id}
                key={'custom' + id}
                {...advice}
            />
        </div>
    ));

    const defaultAdviceCardsMapped = advices?.map((advice) => (
        <AdviceCard
            key={'default' + advice.id}
            {...advice}
            direction="left"
            onRemember={(date_option) => onRemember(advice, AdviceState, date_option)}
            onDelete={async (score) => onDelete(score, advice, AdviceState)}
            actionInteractionEvent={() => null}
            options={[
                {
                    icon: 'trash',
                    content: 'Borrar consejo',
                    action: 'delete',
                },
                {
                    icon: 'clock',
                    content: 'Añadir recordatorio',
                    action: 'remember',
                },
            ]}
        />
    ));

    let customAdvices = [];
    let defaultAdvices = [];
    let adviceCardsList = null;
    if ((advices && advices.length > 0) || advicesList.state.advices.length > 0) {
        customAdvices = customAdviceCardsMapped || [];
        defaultAdvices = defaultAdviceCardsMapped || [];
        adviceCardsList = customAdvices.concat(defaultAdvices);
    }

    const list = {
        [1]:
            customAdvices.length || defaultAdvices.length ? (
                adviceCardsList
            ) : loading ? (
                <LoadingAdvices id="loadingAdvices" />
            ) : (
                <EmptyAdvices id="emptyAdvices" />
            ),
        [2]:
            tasks.length > 0 ? (
                tasks.map((advice, id) => (
                    <div key={'tasks' + id} onClick={() => setTimeout(() => tasksList.select(advice), 100)}>
                        <AdviceCard
                            direction="left"
                            selected={tasksList.state.selected?.id === advice.id}
                            key={id}
                            {...advice}
                        />
                    </div>
                ))
            ) : (
                <EmptyAdvices id="emptyAdvices" />
            ),
    };

    const tabs =
        tasksList.state.tasks.length > 0
            ? [
                  <P
                      small
                      key="advicestab"
                      className={`tabAdvices ${state === 1 ? 'active' : ''}`}
                      onClick={() => setState(1)}
                  >
                      Consejos{' '}
                  </P>,
                  <Box key="taskstab">
                      <P small className={`tabAdvices ${state === 2 ? 'active' : ''}`} onClick={() => setState(2)}>
                          Tareas{' '}
                          <span
                              style={{
                                  width: '10px',
                                  height: '10px',
                                  maxHeight: '10px',
                                  maxWidth: '10px',
                                  background: 'red',
                                  display: 'inline-block',
                                  marginBottom: '6px',
                                  borderRadius: '50%',
                                  marginLeft: '2px',
                              }}
                          />
                      </P>
                  </Box>,
              ]
            : [];
    const currentTab = tasks.length > 0 ? state : 1;

    return (
        <div onClick={() => tasksList.select(null)}>
            <AdviceBar title={title} description={description} display={display} tabs={tabs}>
                {list[currentTab]}
            </AdviceBar>
        </div>
    );
};
