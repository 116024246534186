import ClientesRepository from '../../../shared/infra/repositories/TableRepositories/ClientesRepository';
import { ClienteDTO } from '../domain/Cliente';
import { TipoPais } from '../domain/TipoPais';
import { ClienteTypes } from '../../../shared/domain/clientes/ClienteTypes';
import { ClienteResponse } from '../../../shared/domain/types/api/ClienteResponse';
import { DeclarandoApiService } from '../../../shared/services/DeclarandoApiService';
import ClientsTableV2Repository from '../../../shared/infra/repositories/TableRepositories/ClientsTableV2Repository';

export class ClienteService extends DeclarandoApiService {
    private repository = new ClientesRepository();

    async getCliente(clienteId: string) {
        return this.execute(() => this.repository.getCliente(clienteId));
    }

    async guardarCliente(formData: ClienteDTO, tipoPais: TipoPais) {
        let comprobarPrimerEuParticularResponse: any = {};
        if (tipoPais === TipoPais.EUR && formData.type === ClienteTypes.Particular) {
            comprobarPrimerEuParticularResponse = await this.comprobarPrimerEuParticular();
            if (comprobarPrimerEuParticularResponse.error) {
                return comprobarPrimerEuParticularResponse;
            }
        }
        const response = await this.execute<{ clientId: number }>(() => this.repository.guardarCliente(formData));
        return response;
    }

    private async comprobarPrimerEuParticular() {
        return this.execute<ClienteResponse.ComprobarPrimerEuParticular>(() =>
            this.repository.comprobarPrimerEuParticular(),
        );
    }

    async checkExitCif(cif: string) {
        return this.execute(() => new ClientsTableV2Repository().checkClientCif(cif));
    }
}
