import { TResumenAnualResponse } from 'features/Impuestos/Infra/TResumenAnualResponse';
import V2Repository from 'shared/infra/repositories/V2Repository';
import { Env } from '../../../config/Env';
import { TModeloCompletado, TModeloLinks, TModeloPendiente } from '../domain/TModeloPendiente';
import { TTipoDeclaracion } from '../domain/TTipoDeclaracion';

export class ImpuestosV2Repository extends V2Repository {
    async getPendingModels() {
        return this.get('/modelos/pendientes');
    }

    async getYearlyModelResume(): Promise<TResumenAnualResponse> {
        return this.get('/modelos/presentados/resumen');
    }

    getCalctrim() {
        return this.get('/contabilidad/anual');
    }

    updateCalctrimIngresos(year: any, ingresos: any) {
        return this.post(
            '/contabilidad/ingresos/anual/store',
            JSON.stringify({
                year,
                ingresos,
            }),
        );
    }

    updateCalctrimGastos(year: any, gastos: any) {
        return this.post(
            '/contabilidad/gastos/anual/store',
            JSON.stringify({
                year,
                gastos,
            }),
        );
    }

    downloadModelTrimestral(modelNumber: any, year: any, period: any, paymentMethod: string | null = null) {
        let uri = '/v2/fichero/modelo/' + modelNumber + '/trimestral/' + year + '/' + period;
        if (paymentMethod) {
            uri += '/' + paymentMethod;
        }
        return this.downloadFile(import.meta.env.VITE_API_URL + uri);
    }

    downloadModelAnual(modelNumber: any, year: any) {
        const uri = '/v2/fichero/modelo/' + modelNumber + '/anual/' + year;
        return this.checkAndDownloadFile(import.meta.env.VITE_API_URL + uri);
    }

    downloadJustificante(modelNumber: any, year: any, quarter: string) {
        //http://localhost/v2/fichero/justificante/100/2025/1T
        const uri = `/v2/fichero/justificante/${modelNumber}/${year}/${quarter}`;
        return this.checkAndDownloadFile(import.meta.env.VITE_API_URL + uri);
    }

    checkValidDataModel347(year: string) {
        return this.get('/modelo/347/' + year + '/datos-obligatorios/check');
    }

    checkValidDataModel190(year: string) {
        return this.get('/modelo/190/' + year + '/datos-obligatorios/check');
    }

    checkValidDataModel390(year: string) {
        return this.get('/modelo/390/' + year + '/datos-obligatorios/check');
    }

    checkValidDataModel180(year: string) {
        return this.get('/modelo/180/' + year + '/datos-obligatorios/check');
    }

    sendDataModel347(clients: any, providers: any) {
        return this.post(
            '/modelo/347/datos-obligatorios',
            JSON.stringify({
                clients: clients,
                providers: providers,
            }),
        );
    }

    sendDataModel190(providers: any) {
        return this.post(
            '/modelo/190/datos-obligatorios',
            JSON.stringify({
                providers: providers,
            }),
        );
    }

    sendDataModel390(user390IaeCnaes: any) {
        return this.post(
            '/modelo/390/datos-obligatorios',
            JSON.stringify({
                userIaeCnaes: user390IaeCnaes,
            }),
        );
    }

    sendDataModel180(provider: any, property: any) {
        return this.post(
            '/modelo/180/datos-obligatorios',
            JSON.stringify({
                provider: provider,
                property: property,
            }),
        );
    }

    checkValidJustification(year: string, period: string) {
        return this.get('/modelo/303/' + year + '/' + period + '/datos-obligatorios/check');
    }

    sendValidDataJustification(year: any, period: any, justification: any) {
        return this.post(
            '/modelo/303/datos-obligatorios',
            JSON.stringify({
                year: year,
                period: period,
                justification: justification,
            }),
        );
    }

    getCompensation(year: string | number) {
        return this.get('/modelos/compensacion/' + year + '/check');
    }

    updateCompensation(year: string, compensation: any, ahora_no = 0) {
        return this.post(
            '/modelos/compensacion/' + year,
            JSON.stringify({
                value: compensation,
                ahora_no,
            }),
        );
    }

    updatePaymentType(modelNumber: string, paymentType: string, year: number | string, period: string) {
        return this.post('/modelo/' + modelNumber + '/' + year + '/' + period + '/metodo-pago/' + paymentType);
    }

    /*Nuevo listado modelos*/
    async getModelosPorPresentar(): Promise<{ resources: TModeloPendiente[] }> {
        if (Env.get('VITE_DEVELOPER') === 'adrianM') {
            const data = await import('../domain/mocks/modelos_pendientes');
            return data.default;
        }
        return this.get('/modelos/pendientes-new');
    }

    async getModelosProximos(): Promise<{ resources: TModeloPendiente[] }> {
        if (Env.get('VITE_DEVELOPER') === 'adrianM') {
            const data = await import('../domain/mocks/modelos_proximos');
            return data.default;
        }
        return this.get('/modelos/proximos');
    }

    async getModelosCompletados(): Promise<{ resources: TModeloCompletado[] }> {
        if (Env.get('VITE_DEVELOPER') === 'adrianM') {
            const data = await import('../domain/mocks/modelos_completados');
            return data.default;
        }
        return this.get('/modelos/completados');
    }

    async getTiposDeclaracion({ presentacion, nombre }: TModeloPendiente): Promise<TTipoDeclaracion> {
        if (Env.isLocal('adrianM')) {
            return {
                tiposDeclaracion: [
                    {
                        codigo: 'CASH',
                        isActiva: true,
                        fechaLimite: '10-10-2024',
                    },
                    {
                        codigo: 'DIRECT',
                        isActiva: true,
                        fechaLimite: '10-10-2025',
                    },
                    {
                        codigo: 'NRC',
                        isActiva: true,
                        fechaLimite: '10-10-2024',
                    },
                    {
                        codigo: 'APLAZAMIENTO',
                        isActiva: true,
                        fechaLimite: '10-10-2024',
                    },
                ],
                tipoDeclaracionSeleccionada: 'DIRECT',
            };
        }

        return this.get(
            `/modelos/${nombre}/tipos-declaracion?trimestre=${presentacion.trimestre}&year=${presentacion.year}`,
        );
    }

    async getLinksModelo({ nombre, presentacion }: TModeloPendiente): Promise<TModeloLinks> {
        if (Env.isLocal('adrianM')) {
            return {
                PER: 'https://www.google.com?per=PER',
                CERT: 'https://www.google.com?cert=CERT',
                PIN: 'https://www.google.com?pin=PIN',
            };
        }
        return this.get(`/modelos/${nombre}/links/${presentacion.year}`);
    }
}
