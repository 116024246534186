import SecureRepository from '../SecureRepository';

export default class IncomesTableRepository extends SecureRepository {
    getData(page, rows, filters, orders) {
        return this.post(
            '/incomes',
            JSON.stringify({
                page,
                rows,
                filters,
                orders,
            }),
        );
    }
}
