import Validators from '../infra/utils/Validators';

export enum Errores {
    required = 'Este campo es obligatorio',
    invalidFormat = 'Formato no valido',
    noErrors = '',
}

export const ValidationService = {
    requiredValidation: (value: any, required: boolean) => (!value && required ? Errores.required : Errores.noErrors),
    phone: (phone: { prefix: unknown; number: unknown }, required: boolean) => {
        if (!Boolean(phone.number) && !required) return Errores.noErrors;
        if (!Boolean(phone.number) && required) return Errores.required;
        if (!Validators.isValidPrefix(phone.prefix)) {
            return 'El prefijo no es válido';
        }
        if (!Validators.isValidPhone(phone.prefix, phone.number)) return 'El teléfono no es válido';
        return Errores.noErrors;
    },
    email: (value: string, required: boolean) => {
        if (!Boolean(value) && !required) return Errores.noErrors;
        if (!Boolean(value) && required) return Errores.required;
        return Validators.isValidEmail(value) ? Errores.noErrors : 'El email no es válido';
    },
};
