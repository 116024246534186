import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { ExpandableContent } from '../../../../shared/components/ExpandableContent';
import { Box, Grid, Input } from '@declarando/design_system';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useVIESCheck } from '../VIESChecker/hooks/useVIESCheck';
import { VIESChecker } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/VIESChecker/VIESChecker';
import { NotificationVIES } from './NotificationVIES';

// import { useInvoiceFormContext } from 'features/Ingresos/IngresoConFactura/UI/IngresoConFacturaFormView/context/InvoiceFormProvider';

const FiscalIDInput = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    // const { formBlocked } = useInvoiceFormContext();
    const { isVIESValid, error, resetCheckVIES } = useVIESCheck();

    return (
        <div>
            <ControlledFormField
                control={control}
                name="client.fiscalId"
                Component={({ field, fieldState: { error } }) => (
                    <Input
                        // NOTE: Commented until client edition from new invoice form is implemented */
                        // readOnly={formBlocked.isFormBlocked}
                        readOnly
                        success={!!isVIESValid}
                        error={!!error?.message || isVIESValid === false}
                        helperText={error?.message}
                        required
                        label="NIF IVA"
                        {...field}
                        onChange={(e) => {
                            field.onChange(e);
                            resetCheckVIES();
                        }}
                    />
                )}
            />
            <NotificationVIES isValid={isVIESValid} error={error} />
            {/* NOTE: Commented until client edition from new invoice form is implemented */}
            {/* <Box marginTop="sm"> */}
            {/*     <ControlledFormField */}
            {/*         control={control} */}
            {/*         name="client.address" */}
            {/*         Component={({ field, fieldState: { error } }) => ( */}
            {/*             <Input */}
            {/*                 readOnly={formBlocked.isFormBlocked} */}
            {/*                 error={!!error?.message} */}
            {/*                 helperText={error?.message} */}
            {/*                 required */}
            {/*                 label="Dirección" */}
            {/*                 {...field} */}
            {/*             /> */}
            {/*         )} */}
            {/*     /> */}
            {/* </Box> */}
        </div>
    );
};

export const FiscalID = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const isNIFIVAChecked = useCustomWatch({ control, name: 'client.hasEUVatNumber' });

    return (
        <ExpandableContent shouldExpand={!!isNIFIVAChecked}>
            <Grid marginTop="md" gridTemplateColumns={{ _: '1fr', tablet: '2fr 1fr' }}>
                <Grid
                    gridTemplateColumns={{ _: '1fr', tablet: '2fr 1fr' }}
                    gridRowGap="xs"
                    gridColumnGap="xs"
                    alignItems="flex-start"
                >
                    <FiscalIDInput />
                    <Box display="flex" alignItems="flex-start" marginTop={{ _: '0px', tablet: '26px' }}>
                        <VIESChecker />
                    </Box>
                </Grid>
            </Grid>
        </ExpandableContent>
    );
};
