import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import OldBrowserNotification from './oldBrowserNotification';

import 'assets/sass/skin.scss';
import 'assets/fonts.css';
import 'assets/date-selector.css';
import '@declarando/design_system/dist/index.css';

import indexRoutes from './routes/index';
import LoggerV2Repository from './shared/infra/repositories/LoggerV2Repository';
import { MainLoader } from '@declarando/design_system';
import { RootProvidersComponent } from './RootProvidersComponent';
import 'tippy.js/dist/tippy.css';
import './tooltip.css';
import './app.css';
import { dateClock } from './shared/infra/utils/DateClock';
import { QueryClient, QueryClientProvider } from '@declarando/query-hooks';
import { Env } from 'config/Env';
import { ErrorReporting } from 'shared/infra/error-reporter/ErrorReporting';
import { SentryReporter } from 'shared/infra/error-reporter/SentryReporter';

export const errorReporting = ErrorReporting.getInstance(new SentryReporter());
errorReporting.init();

Env.envQueryParams();
dateClock.check();

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 2 } } });

const myApp = (
    <RootProvidersComponent>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Switch>
                    <Redirect from="/informe-renta" to="/impuestos/informe-renta" />
                    <Redirect from="/impuestos/informe-renta/cuenta-propia" to="/impuestos/informe-renta/" />
                    {indexRoutes.map((prop, key) => {
                        return (
                            <Route
                                path={prop.path}
                                render={(props) => (
                                    <Suspense fallback={<MainLoader label="" />}>
                                        <prop.component
                                            wide={prop.wide}
                                            registerLanding={prop.registerLanding}
                                            routes={prop.routes}
                                            {...props}
                                        />
                                    </Suspense>
                                )}
                                key={key}
                            />
                        );
                    })}
                </Switch>
            </BrowserRouter>
        </QueryClientProvider>
    </RootProvidersComponent>
);

const browser_is_ie_11_or_older = window.navigator.userAgent.match(/(MSIE|Trident)/);

try {
    if (browser_is_ie_11_or_older) {
        //IE STUFF
        document.body.innerHTML = OldBrowserNotification.element;
    } else {
        // const demo = (<RootProviderComponent>{(changeLanguage) => <AdvicesMockComponent />}</RootProviderComponent>)
        ReactDOM.render(myApp, document.getElementById('root'));
        /**
TODO:
Solucionar problemas con los estados de clase antes de migrar ReactDOM.render a ReactDOM.createRoot
Ejemplo:
========
state = {
 data: {
   prop1: "a",
   prop2: "b"
 }
}
Mal: setState({data: {prop2: value}})
Bien: setState({data: {...this.state.data, prop2: value}})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(myApp);
*/
    }
} catch (e) {
    new LoggerV2Repository().logEvent(e.message, e.stack);
}

// ServiceWorker.register();
