import { useFetch } from 'shared/UI/hooks/QueryHooks';
import { createInvoicingActivitiesRepository } from '../../infra/InvoicingActivitiesRepository';
import { createInvoicingActivityService } from '../../services/InvoicingActivityService';
import { InvoicingActivity } from '../../domain/InvoicingActivity';
import { InvoicingActivityDTO } from '../../adapters/InvoicingActivityDTO';
import { toInvoicingActivity } from '../../adapters/InvoicingActivityAdapter';

export const useGetInvoicingActivities = () => {
    const service = createInvoicingActivityService(createInvoicingActivitiesRepository());

    const context = useFetch<InvoicingActivityDTO[], InvoicingActivity[]>({
        queryKey: ['invoicingActivities'],
        fetchFn: () => service.getInvoicingActivities(),
        config: {
            select: (data) => ({
                ...data,
                data: data?.data ? data?.data.map((activity) => toInvoicingActivity(activity)) : [],
            }),
        },
    });

    return {
        invoicingActivities: context.data?.data || [],
        isLoading: context.isLoading,
        isFetching: context.isFetching,
    };
};
