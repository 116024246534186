import { SelectorGroup } from '@declarando/design_system';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useGetUpdateFunctionEquivalencySurchargeInSalesDocumentLines } from './hooks/useGetUpdateFunctionEquivalencySurchargeInInvoiceLines';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

export const EquivalencySurchargeInput = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const { formBlocked } = useSalesDocumentFormContext();
    const { updateEquivalencySurchargeSalesDocumentLines } =
        useGetUpdateFunctionEquivalencySurchargeInSalesDocumentLines();

    return (
        <ControlledFormField
            control={control}
            name="withEquivalencySurcharge"
            Component={({ field, formState: { errors } }) => (
                <SelectorGroup
                    required
                    id="withEquivalencySurcharge"
                    label="¿Tu cliente está en recargo de equivalencia?"
                    tooltipOptions={{
                        title: '¿Qué es el recargo de equivalencia?',
                        text: 'Es un sistema especial de IVA que aplican obligatoriamente los autónomos que tienen la condición de comerciantes minoristas. Solo aplica a los bienes destinados a la reventa.',
                        link: {
                            href: 'https://ayuda.declarando.es/es/articles/1029283-que-es-y-como-funciona-el-recargo-de-equivalencia',
                            text: 'Saber más',
                        },
                    }}
                    variant="radio"
                    helperText={errors?.withEquivalencySurcharge?.message}
                    error={!!errors?.withEquivalencySurcharge?.message}
                    orientation="horizontal"
                    selectors={[
                        { id: 'si', label: 'Sí', checked: field.value === true, disabled: formBlocked.isFormBlocked },
                        { id: 'no', label: 'No', checked: field.value === false, disabled: formBlocked.isFormBlocked },
                    ]}
                    onChange={(selectedIds) => {
                        updateEquivalencySurchargeSalesDocumentLines({
                            withEquivalencySurcharge: selectedIds[0] === 'si',
                        });
                        field.onChange(selectedIds[0] === 'si');
                    }}
                    ref={field.ref}
                />
            )}
        />
    );
};
