import moment, { DurationInputArg1 } from 'moment/moment';
import Validators from './Validators';
import FiscalDates from './FiscalDates';
import {
    format,
    parse,
    isToday,
    isYesterday,
    isAfter as isAfterThirdParty,
    parseISO,
    isBefore as isBeforeThirdParty,
    isEqual,
    getYear,
    endOfYear,
    startOfYear,
    addDays as addDaysThirdParty,
    subDays as subDaysThirdParty,
} from 'date-fns';
import { es } from 'date-fns/locale/es';
import 'moment/locale/es';
import { dateClock } from './DateClock';

const VISUAL_FORMAT = 'DD-MM-YYYY' as const;
const FULL_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss' as const;
const YEAR_FORMAT = 'YYYY' as const;

/**@deprecated use date-fns instead */
export type DateInput = moment.MomentInput;
/**@deprecated use date-fns instead */
export type DateType = moment.Moment;
/**@deprecated use date-fns instead */
type FormatDateTypes =
    | typeof VISUAL_FORMAT
    | typeof FULL_TIME_FORMAT
    | typeof YEAR_FORMAT
    | 'YYYY-MM-DD'
    | 'DD-MM-YYYY HH:mm:ss'
    | 'HH:mm:ss'
    | 'HH:mm'
    | 'D MMM YYYY'
    | 'D MMMM YYYY'
    | 'D [de] MMMM'
    | undefined;
/**@deprecated use date-fns instead */
type UnitTime = moment.unitOfTime.DurationConstructor;
/**@deprecated use date-fns instead */
type DurationInput = DurationInputArg1;

moment.locale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
});

class DateWrapper {
    static visualFormat = VISUAL_FORMAT;
    static fullTimeFormat = FULL_TIME_FORMAT;
    static yearFormat = YEAR_FORMAT;

    // DEPRECATED: Use instead parseNativeDate
    static parseDate(date: DateInput) {
        return moment(date);
    }

    static parseNativeDate(date: DateInput) {
        return moment(date);
    }

    /**
     *  Method for format native date to string with date-fns
     * @param date
     * @param formatType
     */
    static formatNativeDateToString(date: Date | string, formatType = 'dd-MM-yyyy') {
        return format(date, formatType, { locale: es });
    }

    /**
     *  Method for parse string to native date with date-fns
     * @param date
     * @param formatType
     */
    static formatStringToNativeDate(value: string, formatType = 'dd-MM-yyyy') {
        return parse(value, formatType, new Date());
    }

    /**
     * Converts a date string from one format to another. Util to use in legacy code where the format used its dd-MM-yyyy instead of yyyy-MM-dd
     *
     * @param {string} date - The date string to convert.
     * @param {Object} [args] - The format options.
     * @param {string} [args.formatFrom="dd-MM-yyyy"] - The format of the input date string.
     * @param {string} [args.formatTo="yyyy-MM-dd"] - The desired format for the output date string.
     *
     * @returns {string} The date string in the new format.
     *
     * @example
     *  Convert from default format (dd-MM-yyyy) to default format (yyyy-MM-dd)
     * DateUtils.convertDateFormatType("28-08-2024")
     *  Returns: "2024-08-28"
     *
     * @example
     *  Convert from MM/dd/yyyy to yyyy-MM-dd
     * DateUtils.convertDateFormatType("08/28/2024", { formatFrom: "MM/dd/yyyy", formatTo: "yyyy-MM-dd" })
     *  Returns: "2024-08-28"
     *
     * @example
     *  Convert from yyyy.MM.dd to dd/MM/yyyy
     * DateUtils.convertDateFormatType("2024.08.28", { formatFrom: "yyyy.MM.dd", formatTo: "dd/MM/yyyy" })
     *  Returns: "28/08/2024"
     */
    static convertDateFormatType(
        date: string,
        args: { formatFrom: string; formatTo: string } = { formatFrom: 'dd-MM-yyyy', formatTo: 'yyyy-MM-dd' },
    ): string {
        const parsedDate = parse(date, args.formatFrom, new Date());

        return format(parsedDate, args.formatTo);
    }

    static isToday(date: Date) {
        return isToday(date);
    }

    static isYesterday(date: Date) {
        return isYesterday(date);
    }

    static getFullYear(date: Date | string) {
        return getYear(date);
    }

    static getEndOfYear(date: Date | string) {
        return endOfYear(date);
    }

    static getStartOfYear(date: Date | string) {
        return startOfYear(date);
    }

    static addDays(date: Date | string, days: number) {
        return addDaysThirdParty(date, days);
    }

    static subDays(date: Date | string, days: number) {
        return subDaysThirdParty(date, days);
    }

    static isDateBetweenProvidedDates = (args: { date: string; from: string; to: string }) => {
        const dateParsed = parseISO(args.date);
        const fromParsed = parseISO(args.from);
        const toParsed = parseISO(args.to);

        return (
            (isAfterThirdParty(dateParsed, fromParsed) || isEqual(dateParsed, fromParsed)) &&
            (isBeforeThirdParty(dateParsed, toParsed) || isEqual(dateParsed, toParsed))
        );
    };

    static parseStringToNativeDate(date: DateInput, format: FormatDateTypes = DateWrapper.visualFormat) {
        return this.formatStringToDate(date, format).toDate();
    }

    static parseNativeDateToString(date: DateInput) {
        return moment(date).format(DateWrapper.visualFormat);
    }

    static formatDateToString(date: DateInput, format: FormatDateTypes = DateWrapper.visualFormat) {
        return date && date instanceof moment
            ? (
                  date as {
                      format: any;
                  }
              ).format(format)
            : '';
    }

    static formatStringToDate(date: DateInput, format: FormatDateTypes = DateWrapper.visualFormat) {
        return date ? moment(date, format) : this.getNow();
    }

    static subtractFromDate(
        date: DateInput,
        amount: DurationInput,
        format: FormatDateTypes = DateWrapper.visualFormat,
    ) {
        return moment(date, format).subtract(amount);
    }

    static addToDate(date: DateInput, amount: DurationInput, format: FormatDateTypes = DateWrapper.visualFormat) {
        return moment(date, format).add(amount);
    }

    static formatFullTimeStringtoDate(string: DateInput) {
        return moment(string, this.fullTimeFormat);
    }

    static formatStringFormatToDate(string: string, format: FormatDateTypes) {
        return moment(string, format);
    }

    static formatNowToString() {
        return this.formatDateToString(this.getNow());
    }

    static getNow() {
        const date = dateClock.getDate();
        const ahora = moment();
        if (date) {
            const fecha = moment(date, DateWrapper.visualFormat);
            fecha.hour(ahora.hour());
            fecha.minute(ahora.minute());
            fecha.second(ahora.second());
            fecha.millisecond(ahora.millisecond());

            return fecha;
        }
        return ahora;
    }

    static getNativeDateNow() {
        return this.getNow().toDate();
    }

    static isValid(date: DateInput) {
        if (typeof date === 'string') {
            return Validators.isValidDate(date);
        }

        const string = this.formatDateToString(date);
        return Validators.isValidDate(string);
    }

    static isFromOlderTrimester(date: DateInput, now = this.getNow()) {
        const actualYear = now.year();
        const actualQuarter = now.quarter();

        const isTaxesPeriod = FiscalDates.isTaxesPeriod(now.toDate());

        const dates = [
            `${actualYear - 1}-10-01`,
            `${actualYear}-01-01`,
            `${actualYear}-04-01`,
            `${actualYear}-07-01`,
            `${actualYear}-10-01`,
        ];

        const quartersLess = isTaxesPeriod ? 1 : 0;

        if (date !== undefined && date !== null) {
            return date < moment(dates[actualQuarter - quartersLess]);
        }
    }

    /**@deprecated en favor a isBeforeCustom*/
    static isBefore(dateFrom: DateType, dateTo: DateType) {
        return dateFrom.isBefore(dateTo);
    }

    /**@deprecated en favor a isAfterCustom*/
    static isAfter(dateFrom: DateType, dateTo: DateType) {
        return dateFrom.isAfter(dateTo);
    }

    static isDateSameOrAfter = (date: string, dateToCompare: string) => {
        const parsedDate = date;
        const parsedDateToCompare = dateToCompare;

        return isAfterThirdParty(parsedDate, parsedDateToCompare) || isEqual(parsedDate, parsedDateToCompare);
    };

    static isValidOrNull(date: null | DateType) {
        const string = this.formatDateToString(date);
        return string === '' || Validators.isValidDate(string);
    }

    static stringMonth(date: DateInput) {
        return moment(date).format('MMM');
    }

    /**@deprecated en favor a getFullYear */
    static getYear(date?: DateInput) {
        return moment(date).format('YYYY');
    }

    static generatePreviusArrayMonths(date: DateInput, lenght: number) {
        const months = [];
        if (!date) {
            months.push(moment(date).format('YYYY-MM-01'));
        }
        for (let i = 1; i <= lenght; i++) {
            const year = moment(date).subtract(i, 'months').format('YYYY');
            const currentYear = DateWrapper.getNow().subtract(1, 'years').format('YYYY');
            if (Number(year) >= Number(currentYear)) {
                const month = moment(date).subtract(i, 'months').format('YYYY-MM-01');
                months.push(month);
            }
        }
        return months;
    }

    static generateNextArrayMonths(date: DateInput, lenght: number) {
        const months = [];
        for (let i = 1; i <= lenght; i++) {
            const year = moment(date).add(i, 'months').format('YYYY');
            const currentYear = DateWrapper.getNow().add(1, 'years').format('YYYY');
            if (Number(year) <= Number(currentYear)) {
                const month = moment(date).add(i, 'months').format('YYYY-MM-01');
                months.push(month);
            }
        }
        return months;
    }

    static isOlderThan(date: DateInput, amount: number, unitTime: UnitTime) {
        const now = DateWrapper.getNow();
        const dateA = moment(date);
        const dateB = DateWrapper.getNow().subtract(amount, unitTime);
        return now.diff(dateA) >= now.diff(dateB);
    }

    static diffDaysFromNow(day: DateInput = '01-08-2021', format = 'DD-MM-YYYY') {
        return moment(day, format).diff(DateWrapper.getNow(), 'days');
    }

    static diffDays(dateA: moment.Moment, dateB: moment.Moment) {
        return moment(dateA.format('YYYY-MM-DD')).diff(moment(dateB.format('YYYY-MM-DD')), 'days');
    }

    static diffSeconds(dateA: moment.Moment, dateB: moment.Moment) {
        return dateA.diff(dateB, 'seconds');
    }

    static formatFullDateToFriendlyOutput(fecha: Date | string): string {
        return format(fecha, "EEEE, d 'de' MMMM yyyy", { locale: es });
    }

    static getHourFromDate(fecha: Date | string): string {
        const date = new Date(fecha);
        const now_utc = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
        );
        const dateTimeZone = new Date(
            new Date(now_utc).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }),
        );

        return format(dateTimeZone, 'HH:mm', { locale: es });
    }

    static getQuarter() {
        return this.getNow().quarter();
    }
}

export default DateWrapper;
