import { css, styled } from '@declarando/design_system';

export const AppLayout = styled('div')(
    css({
        display: 'grid',
        gap: '1px',
        gridTemplateColumns: ['1fr', '1fr auto'],
        gridTemplateRows: 'auto 1fr',
        height: '100vh',
        '.main-panel': {
            marginTop: 0,
            overflow: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        '#Navbar': {
            gridColumnStart: 1,
            gridColumnEnd: -1,
        },
        '.mainContent': {
            overflow: 'scroll',
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
        },
        '.mainContent::-webkit-scrollbar': {
            display: 'none',
        },
        '.adviceBar:has(.hideHeader)': { boxShadow: 'none', borderLeft: 'none' },
        '.adviceBar': {
            zIndex: 998,
            position: 'absolute',
            top: 0,
            right: 0,
            background: 'white',
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
            minWidth: ['fit-content', 'max-content'],
            boxShadow: '-8px 8px 24px -8px rgba(0, 0, 0, 0.25)',
        },
        '.adviceBar::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-width: 1023px)': {
            '.adviceBar': {
                boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
                position: 'fixed',
                top: 'unset',
                right: 'unset',
                bottom: 0,
                zIndex: 2,
                width: '100vw',
                maxHeight: '-webkit-fill-available',
                overflow: 'scroll',
            },
        },
    }),
);
