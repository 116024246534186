import { Box, Skeleton } from '@declarando/design_system';

type Props = {
    inputHeight?: string;
    labelWidth?: string;
    labelHeight?: string;
};

export const SkeletonInput = ({ inputHeight = '50px', labelHeight = '17px', labelWidth = '225px' }: Props = {}) => {
    return (
        <Box display="flex" flexDirection="column" gap="4px">
            <Skeleton width={labelWidth} height={labelHeight} />
            <Skeleton height={inputHeight} />
        </Box>
    );
};
