import { DeclarandoApiResponse } from '../domain/http/DeclarandoApiResponse';
import { HttpTypes } from '../domain/http/HttpTypes';

type Args = {
    bodyAsData?: boolean;
    /**
     * If true, throws an error instead of returning it in the response object.
     * Useful for integration with error-catching mechanisms like React Query.
     */
    shouldThrowError?: boolean;
};

export class DeclarandoApiService {
    protected async execute<T>(
        callback: () => Promise<T>,
        args: Args = { bodyAsData: false, shouldThrowError: false },
    ) {
        const response = new DeclarandoApiResponse<T>();
        try {
            const body = (await callback()) as any;
            if (args?.bodyAsData) {
                response.setData(body);
            } else {
                typeof body === 'object' && (body.data || body.data === false)
                    ? response.setData(body.data)
                    : response.setData(body);
            }
        } catch (e: HttpTypes.ApiDeclarandoException) {
            response.setErrorMessage(e.message, e.code ?? e.status);
            if (e.body) response.body = e.body;

            if (args?.shouldThrowError) {
                throw response;
            }
        }
        return response;
    }
}
