import { Notification, Grid, FormControlContainer, Box, P } from '@declarando/design_system';
import { Client } from 'features/Ingresos/Clientes/domain';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useGetCitiesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { ComponentProps, useEffect, useState } from 'react';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { InputIRPF } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/InputIRPF/InputIRPF';
import { EditClientModal } from '../EditClientModal/EditClientModal';

const NotificationClientInfo = ({
    clientInfo,
    actionLinkOptions,
}: {
    clientInfo: Client;
    actionLinkOptions?: ComponentProps<typeof Notification>['actionLinkOptions'];
}) => {
    return (
        <Notification
            title={clientInfo.name}
            tone="neutral"
            customIcon="user-circle"
            message={
                <Box display="flex" flexDirection="column">
                    <P small>{clientInfo?.fiscalId}</P>
                    <P small>{clientInfo?.address}</P>
                    <P small>
                        {clientInfo?.postalCode} {clientInfo?.city?.name} {clientInfo?.state?.name}{' '}
                        {clientInfo?.country?.name.charAt(0).toUpperCase() +
                            clientInfo?.country?.name.slice(1).toLowerCase()}
                    </P>
                    <P small>
                        {clientInfo?.phone?.prefix} {clientInfo?.phone?.number}
                    </P>
                    <P small>{clientInfo?.email}</P>
                </Box>
            }
            actionLinkOptions={actionLinkOptions}
            full
        />
    );
};

export const CardDatosReceptor = ({ clientInfo }: { clientInfo: Client }) => {
    const { formBlocked } = useSalesDocumentFormContext();
    const { setValue } = useCustomFormContext<InvoiceForm>();
    const { cities } = useGetCitiesList(clientInfo?.state?.id ?? null);
    const [editClientModalIsOpen, setEditClientModalIsOpen] = useState(false);

    //NOTE: Necessary to update the city name because the API returns the id of the city and not the name
    useEffect(() => {
        const currentCityName = clientInfo?.city?.name;
        const cityName = cities.find((city) => city.id === clientInfo?.city?.id)?.name;

        if (cityName && cityName !== currentCityName) {
            setValue('client.city.name', cityName ?? '', { shouldDirty: false });
        }
    }, [cities, clientInfo, setValue]);

    return (
        <div>
            <Grid className="fade-in" gridTemplateColumns="9fr 2fr 2fr" gridColumnGap="sm">
                <FormControlContainer label="Receptor" required>
                    {formBlocked.isFormBlocked ? (
                        <NotificationClientInfo clientInfo={clientInfo} />
                    ) : (
                        <NotificationClientInfo
                            clientInfo={clientInfo}
                            //NOTE: Temporary comment out until the edit client modal is implemented
                            // actionLinkOptions={{
                            //     label: 'Editar',
                            //     icon: 'edit',
                            //     onClick: () => setEditClientModalIsOpen(true),
                            // }}
                        />
                    )}
                </FormControlContainer>
                <InputIRPF />
            </Grid>
            <EditClientModal isOpen={editClientModalIsOpen} onClose={() => setEditClientModalIsOpen(false)} />
        </div>
    );
};
