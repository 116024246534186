import { Box, Header, P } from '@declarando/design_system';
import Loader from './assets/Loader.svg';
import './assets/animation.css';
import { useEffect } from 'react';
import UserV2Repository from 'shared/infra/repositories/UserV2Repository';

export const CertificadoProcesandoPago = () => {
    useEffect(() => {
        checkStatus();
    }, []);

    const checkStatus = () => {
        setInterval(() => {
            new UserV2Repository().getRecurrenteData().then((data) => {
                if (data.estado === 'PAGADO') {
                    window.location.href = '/inicio?modal-reactivacion-cuenta=success';
                }
            });
        }, 3000);
    };

    return (
        <Box
            className={'ReloadContainer'}
            alignCenter
            p={'xxl'}
            bg={'white'}
            height={'100vh'}
            width={'100vw'}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                background: 'white !important',
            }}
        >
            <Box p="xxl">
                <img className={'reloadImg'} src={Loader} alt="Procesando pago" />
                <Header as={'h2'} mt={'sm'} mb={'xs'}>
                    Estamos procesando tu pago...
                </Header>
                <P>El proceso puede llevar unos segundos, por favor, no cierres esta ventana.</P>
            </Box>
        </Box>
    );
};
