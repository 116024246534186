import { Invoice } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { InvoiceDTO } from 'features/Ingresos/SalesDocument/IngresoConFactura/adapters/InvoiceDTO';
import { toInvoiceDTO } from 'features/Ingresos/SalesDocument/IngresoConFactura/adapters/InvoiceAdapter';
import {
    GetInvoicesListParams,
    IngresoConFacturaRepository,
} from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/IngresoConFacturaRepository';
import V2Repository from 'shared/infra/repositories/V2Repository';
import { InvoicesListDTO } from '../adapters/InvoicesListDTO';

class IngresoConFacturaApiRepository extends V2Repository implements IngresoConFacturaRepository {
    create(invoice: Invoice): Promise<string> {
        const dto = toInvoiceDTO(invoice);
        return this.post('/invoices', JSON.stringify(dto));
    }

    edit(invoice: Invoice): Promise<string> {
        const dto = toInvoiceDTO(invoice);
        return this.put(`/invoices/${invoice.id}`, JSON.stringify(dto));
    }

    deleteInvoice(id: string): Promise<void> {
        return this.delete(`/invoices/${id}`);
    }

    getByUUID(id: string): Promise<InvoiceDTO> {
        return this.get(`/invoices/${id}`);
    }

    getList(params?: GetInvoicesListParams): Promise<InvoicesListDTO> {
        const queryParams = new URLSearchParams();

        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                if (value !== undefined && value !== null && value !== '') {
                    queryParams.append(key, value.toString());
                }
            });
        }

        const queryString = queryParams.toString();
        return this.get(`/invoices${queryString ? `?${queryString}` : ''}`);
    }
}

export const createIngresoConFacturaApiRepository = (): IngresoConFacturaRepository => {
    return new IngresoConFacturaApiRepository();
};
