import { Client } from 'features/Ingresos/Clientes/domain';
import {
    ActivityDTO,
    ClientDTO,
    InvoiceDTO,
    InvoiceLineDTO,
} from 'features/Ingresos/SalesDocument/IngresoConFactura/adapters/InvoiceDTO';
import { Invoice, InvoiceLine } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import DateWrapper from 'shared/infra/utils/Date';
import { InvoiceActivity } from '../domain/InvoiceActivity';
import { invoiceLineCalculationsService } from '../services/IngresoConFacturaCalculationService';

export function toInvoice(dto: InvoiceDTO): Invoice {
    return {
        id: dto.id,
        documentNumber: dto.documentNumber,
        issueDate: dto.issueDate,
        dueDate: dto.dueDate ?? '',
        incomeTax: dto.incomeTax,
        client: toClient(dto.client),
        activity: toActivity(dto.activity),
        withEquivalencySurcharge: dto.withEquivalencySurcharge,
        lines: dto.lines.map((line) => toInvoiceLine(line, dto.withEquivalencySurcharge)),
        paymentOptions: toPaymentOptions(dto.paymentOptions),
        documentNote: dto.notes ?? '',
    };
}

function toClient(dto: ClientDTO): Client {
    return {
        id: dto.id,
        clientType: dto.clientType,
        fiscalId: dto.fiscalId,
        name: dto.name,
        country: {
            code: dto.country.code,
            name: dto.country.name,
            isEuropean: dto.country.isEuropean,
        },
        address: dto.address,
        state: dto.state,
        city: dto.city,
        postalCode: dto.postalCode,
        phone: dto.phone,
        email: dto.email,
        hasEUVatNumber: dto.hasEUVatNumber ?? undefined,
    };
}

function toActivity(dto: ActivityDTO): InvoiceActivity {
    return {
        id: dto.id,
        section: dto.section,
        epigraph: dto.epigraph,
        name: dto.name,
        isVatExempt: dto.isVatExempt,
        registrationDate: dto.registrationDate,
        withImageRights: dto.withImageRights,
        forFattening: dto.forFattening,
        activityType: dto.activityType,
    };
}

function toInvoiceLine(dto: InvoiceLineDTO, withEquivalencySurcharge: boolean | null): InvoiceLine {
    const lineAmounts = invoiceLineCalculationsService.calculateInvoiceLine({
        fromField: 'price',
        line: dto,
        withEquivalencySurcharge: withEquivalencySurcharge ?? false,
    });

    return {
        ...dto,
        totalLine: lineAmounts.totalLine,
    };
}

function toPaymentOptions(dtoOptions: { option: 'CASH' | 'BANK TRANSFER' | 'OTHER'; value: string | null }[]) {
    const paymentOptions = {
        cash: { isChecked: false },
        bankTransfer: { isChecked: false, details: '' },
        other: { isChecked: false, details: '' },
    };

    dtoOptions.forEach((option) => {
        switch (option.option) {
            case 'CASH':
                paymentOptions.cash.isChecked = true;
                break;
            case 'BANK TRANSFER':
                paymentOptions.bankTransfer.isChecked = true;
                paymentOptions.bankTransfer.details = option.value ?? '';
                break;
            case 'OTHER':
                paymentOptions.other.isChecked = true;
                paymentOptions.other.details = option.value ?? '';
                break;
        }
    });

    return paymentOptions;
}

//TODO: Improve transformation
export const toInvoiceDTO = (invoice: Invoice): InvoiceDTO => {
    console.log('Invoice before transforming to DTO', invoice);
    return {
        ...invoice,
        withEquivalencySurcharge: invoice.withEquivalencySurcharge ?? false,
        activity: {
            ...invoice.activity,
            withImageRights: invoice.activity.withImageRights ?? false,
            forFattening: invoice.activity.forFattening ?? false,
        },
        client: {
            ...invoice.client,
            fiscalId: invoice.client.fiscalId || null,
            address: invoice.client.address || null,
            postalCode: invoice.client.postalCode || null,
            hasEUVatNumber: invoice.client.hasEUVatNumber ?? false,
            phone: invoice.client.phone
                ? { prefix: invoice.client.phone.prefix, number: invoice.client.phone.number }
                : null,
            state: invoice.client.state ? { id: invoice.client.state.id, name: invoice.client.state.name } : null,
            city: invoice.client.city ? { id: invoice.client.city.id, name: invoice.client.city.name } : null,
            email: invoice.client.email || null,
        },
        issueDate: DateWrapper.formatNativeDateToString(invoice.issueDate, 'yyyy-MM-dd'),
        dueDate: invoice.dueDate ? DateWrapper.formatNativeDateToString(invoice.dueDate, 'yyyy-MM-dd') : null,
        lines: invoice.lines.map((line) => ({
            ...line,
            equivalencySurcharge:
                invoice.withEquivalencySurcharge && line.productType === 'GOODS' ? line.equivalencySurcharge : 0,
            price: line.price ?? 0,
            discount: line.discount ?? 0,
        })),
        paymentOptions: [
            ...(invoice.paymentOptions.cash.isChecked ? [{ option: 'CASH' as const, value: null }] : []),
            ...(invoice.paymentOptions.bankTransfer.isChecked && invoice.paymentOptions.bankTransfer.details
                ? [{ option: 'BANK TRANSFER' as const, value: invoice.paymentOptions.bankTransfer.details }]
                : []),
            ...(invoice.paymentOptions.other.isChecked && invoice.paymentOptions.other.details
                ? [{ option: 'OTHER' as const, value: invoice.paymentOptions.other.details }]
                : []),
        ],
        notes: invoice.documentNote || null,
    };
};
