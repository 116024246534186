import AccountNumber from './AccountNumber';
import { PrefixesCountries } from '@declarando/design_system';

class Validators {
    static isRadioGroupValid(options, value) {
        let found = false;

        options.forEach((option) => {
            if (option === value) {
                found = true;
            }
        });
        return found;
    }

    static isValidEmail(email) {
        return email.match(/^[a-z0-9][-a-z0-9._]+@[-a-z0-9]+\.[a-z]+/g);
    }

    // Deprecated
    static isValidPhoneNumber(phoneNumber) {
        const match1 = String(phoneNumber).match(/^[67]\d{8}$/);
        const match2 = String(phoneNumber).match(/^[0-9]{1,14}$/);

        return (match1 && match1.length > 0) || (match2 && match2.length > 0);
    }

    static isValidPhone(prefix, phoneNumber, telefonosFijos = false) {
        if (!prefix) return false;
        if (+prefix === 34) {
            const regEx = telefonosFijos ? /^[679]\d{8}$/ : /^[67]\d{8}$/;
            const match1 = String(phoneNumber).match(regEx);
            return match1 && match1.length > 0;
        }
        const match2 = String(phoneNumber).match(/^[0-9]{4,14}$/);
        return match2 && match2.length > 0;
    }

    static isValidIban(iban) {
        return (
            iban && iban.length === 24 && AccountNumber.validateAccountDC(AccountNumber.calculateAccountFromIban(iban))
        );
    }

    static isValidPostalCode = (cp) => {
        return cp && cp.length === 5 && !isNaN(cp);
    };

    static isValidDate(string) {
        const isValid = string.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
        if (isValid === null) {
            return false;
        }
        return isValid;
    }

    static isValidInteger(number) {
        return !isNaN(number);
    }

    static isValidNumber(number) {
        return !isNaN(number);
    }

    static isValidPositiveInteger(number) {
        return !isNaN(number) && number > 0;
    }

    static isValidQuantity(number) {
        return !isNaN(number) && number > 0;
    }

    static isValidString(string = '') {
        return string !== '' && string !== null;
    }

    static requiredHasData(data) {
        return data !== null && data !== '';
    }

    static requiredAmountHasData(data) {
        return !isNaN(data) && (data > 0 || data < 0);
    }

    static isDoublePattern(value) {
        const pattern = /^-?(|(?:\d+)(?:(\.|,)(\d+|))?)$/;
        return pattern.test(value);
    }

    static isValidRefCatastral(referenciaCatastral) {
        var pesoPosicion = [13, 15, 12, 5, 4, 17, 9, 21, 3, 7, 1];
        var letraDc = 'MQWERTYUIOPASDFGHJKLBZX';

        if (referenciaCatastral === null || referenciaCatastral.length !== 20) {
            return false;
        }
        referenciaCatastral = referenciaCatastral.toUpperCase();

        //Para calcular cada dígito de control se utilizan siguientes subcadenas
        var cadenaPrimerDC = (
            referenciaCatastral.substring(0, 7) + referenciaCatastral.substring(14, 18)
        ).toUpperCase();
        var cadenaSegundoDC = (
            referenciaCatastral.substring(7, 14) + referenciaCatastral.substring(14, 18)
        ).toUpperCase();

        var cadenasDC = [cadenaPrimerDC, cadenaSegundoDC];
        var dcCalculado = '';

        cadenasDC.forEach(function (cadena) {
            var sumaDigitos = 0;
            cadena.split('').forEach(function (caracter, posicion) {
                var valorCaracter = caracter;

                if (caracter >= 'A' && caracter <= 'N') {
                    valorCaracter = caracter.charCodeAt() - 64;
                } else if (caracter === 'Ñ') {
                    valorCaracter = 15;
                } else if (caracter > 'N') {
                    valorCaracter = caracter.charCodeAt() - 63;
                }

                sumaDigitos = (sumaDigitos + valorCaracter * pesoPosicion[posicion]) % 23;
            });
            dcCalculado += letraDc.charAt(sumaDigitos);
        });

        if (dcCalculado !== referenciaCatastral.substring(18, 20)) {
            return false;
        }
        return true;
    }

    static isValidPrefix(prefix) {
        const existInList = PrefixesCountries.find((item) => +item.dialCode === +prefix);
        return Boolean(existInList);
    }
}

export default Validators;
