const Country = {
    id: {
        SPAIN: 69,
    },
    iso: {
        SPAIN: 'ES',
    },
};

export default Country;
