export const initialOnboardingNavigationList = {
    1: {
        label: 'Certificado digital',
        check: false,
        active: false,
        infoTag: '',
    },
    2: {
        label: 'Importar documentación',
        check: false,
        active: false,
        infoTag: '',
    },
    3: {
        label: 'Revisión de tu situación',
        check: false,
        active: false,
        infoTag: '',
    },
    4: {
        label: 'Importar contabilidad',
        check: false,
        active: false,
        infoTag: '',
    },
};
