import { useFetch } from 'shared/UI/hooks/QueryHooks';
import { QuoteDTO } from '../../adapters/QuoteDTO';
import { Quote } from '../../domain/Quote';
import { toQuote } from '../../adapters/QuoteAdapter';
import { createQuoteService } from '../../services/QuoteService';
import { createQuoteRepository } from '../../infra/QuoteRepository';

export const useGetQuote = (args: { quoteUUID: string }) => {
    const service = createQuoteService(createQuoteRepository());

    const context = useFetch<QuoteDTO | null, Quote | null>({
        queryKey: ['quotes', 'quote', args.quoteUUID],
        fetchFn: () => service.getQuoteByUUID(args.quoteUUID),
        config: {
            enabled: !!args.quoteUUID,
            select: (data) => ({ ...data, data: data?.data ? toQuote(data?.data) : null }),
        },
    });

    return { quoteData: context.data?.data, isLoading: context.isLoading, isError: context.error };
};
