import { useHistory } from 'react-router-dom';
import { Notifier } from 'shared/infra/utils/Notifier';
import { invoiceDocumentNumberUnique } from '../../../IngresoConFactura/validations/InvoiceDocumentNumberUnique';
import { TDeclarandoApiResponse } from 'shared/domain/http/DeclarandoApiResponse';
import { UseMutationResult } from '@declarando/query-hooks';
import { Invoice } from '../../../IngresoConFactura/domain/Invoice';
import { VIESContextType } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/VIESChecker/hooks/useVIESCheck';

export type SalesDocumentMutationConfig<TData = unknown, TError = Error, TVariables = unknown, TContext = unknown> = {
    mutation: UseMutationResult<TData, TError, TVariables, TContext>;
    trackingEvent: () => void;
    successMessage: {
        title: string;
        message: string;
        link?: { label: string; href: string; shouldOpenInNewTab?: boolean };
    };
    errorMessage: {
        title: string;
        message: string;
    };
    getSuccessRedirectPath: (responseData: any) => string;
};

export type HandleSubmitArgs = {
    data: Invoice;
    triggerVIESError: VIESContextType['triggerVIESError'];
};

export const useSalesDocumentMutation = <
    TData extends TDeclarandoApiResponse<string>,
    TError = Error,
    TVariables = unknown,
    TContext = unknown,
>(
    config: SalesDocumentMutationConfig<TData, TError, TVariables, TContext>,
) => {
    const history = useHistory();

    const handleSubmit = (args: HandleSubmitArgs) => {
        config.trackingEvent();

        config.mutation.mutate(args.data as TVariables, {
            onError: (error: any) => {
                //NOTE: Improve error handling, this is temporal for tickets created in helpdesk
                if (error.body.code === 'DEK_INV.INVALID_CLIENT' && error.body.message.includes('VIES')) {
                    return args.triggerVIESError({ typeError: 'not-valid' });
                }

                if (error.body.code === 'DEK.NUMBER_EXISTS') {
                    return Notifier.error({
                        title: 'No ha sido posible crear el ingreso',
                        message:
                            'El número de factura ya se ha utilizado en un año anterior. Cámbialo para continuar y garantizar una gestión clara. Recuerda que la numeración debe ser correlativa.',
                    });
                }

                if (
                    error.body.code === 'DEK_INV.PERSON_USER_NOT_EXIST' &&
                    error.body.message.includes('with no working address')
                ) {
                    return Notifier.error({
                        message:
                            'No ha sido posible generar el ingreso con factura porque tienes que rellenar la dirección de trabajo en tu perfil',
                        link: { href: '/user/profile/vivienda', label: 'Ir a mi perfil' },
                    });
                }

                if (error.body.code === 'DEK.VIES') {
                    return args.triggerVIESError({ typeError: 'service-down' });
                }

                Notifier.error({
                    message: config.errorMessage.message,
                    title: config.errorMessage.title,
                });
            },
            onSuccess: (response) => {
                Notifier.success({
                    title: config.successMessage.title,
                    message: config.successMessage.message,
                    link: config.successMessage.link,
                });

                history.push(config.getSuccessRedirectPath(response.data));

                invoiceDocumentNumberUnique.resetValidation();
            },
        });
    };

    return { handleSubmit, isMutationPending: config.mutation.isPending };
};
