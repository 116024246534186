import React from 'react';
import { css, Grid, styled } from '@declarando/design_system';
import AdvicesLoaderSvg from '../../../../../assets/img/loading-state-advices.svg';

const LoadingContainer = styled(Grid)(
    css({
        width: ['100%', '328px'],
        justifyItems: 'center',
        height: 'calc(100vh - (56px + 72px))',
        gap: 'sm',
        backgroundColor: 'white',
        '#EmptyAdvices': {
            alignSelf: 'center',
        },
    }),
);

export const LoadingAdvices = () => {
    return (
        <LoadingContainer>
            <object id="EmptyAdvices" data={AdvicesLoaderSvg} type="image/svg+xml" width="140">
                <img src={AdvicesLoaderSvg} alt="Cargando" />
            </object>
        </LoadingContainer>
    );
};
