import V2Repository from 'shared/infra/repositories/V2Repository';
import { ClientRepository } from '../domain/ClientRepository';
import { LegacyClientDTO } from '../adapters/LegacyClientDTO';
import { Client } from '../domain';
import { ClientListDTO, ClientListParams } from '../adapters/ClientListDTO';
import { transformClientToClientDTO } from '../adapters/ClientAdapter';

export default class ClientsRepository extends V2Repository implements ClientRepository {
    create(client: Client): Promise<string> {
        const transformedClient = transformClientToClientDTO(client);
        return this.post('/clients', JSON.stringify(transformedClient));
    }

    getById(id: string): Promise<LegacyClientDTO> {
        return this.get(`/cliente/${id}`);
    }

    getList(params: ClientListParams): Promise<ClientListDTO> {
        const queryParams = new URLSearchParams();

        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                queryParams.append(key, value.toString());
            }
        });

        const url = `/clients?${queryParams.toString()}`;

        return this.get(url);
    }
}

export const createClientsRepository = (): ClientRepository => {
    return new ClientsRepository();
};
