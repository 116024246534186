import {
    useFetch as useFetchAbstraction,
    useGenericMutation,
    useLoadMore,
    useFetchMultiple as useFetchMultipleAbstraction,
} from '@declarando/query-hooks';
import type {
    GenericMutationParams,
    UseFetchParams,
    UseQueriesParams,
    UseLoadMoreParams,
} from '@declarando/query-hooks';
import type { TDeclarandoApiResponse } from 'shared/domain/http/DeclarandoApiResponse';

export const useFetch = <T, S = T>(args: UseFetchParams<TDeclarandoApiResponse<T>, TDeclarandoApiResponse<S>>) => {
    return useFetchAbstraction<TDeclarandoApiResponse<T>, TDeclarandoApiResponse<S>>(args);
};

export const useFetchMultiple = <T, S = T>(
    args: UseQueriesParams<TDeclarandoApiResponse<T>, TDeclarandoApiResponse<S>>,
) => {
    return useFetchMultipleAbstraction<TDeclarandoApiResponse<T>, TDeclarandoApiResponse<S>>(args);
};

export const usePost = <T, S = T, U = TDeclarandoApiResponse<T>, R = T>(
    args: GenericMutationParams<T, TDeclarandoApiResponse<S>, TDeclarandoApiResponse<U>, R>,
) => {
    return useGenericMutation<T, TDeclarandoApiResponse<S>, TDeclarandoApiResponse<U>, R>(args);
};

export const useDelete = <T, S = T, U = TDeclarandoApiResponse<T>, R = T>(
    args: GenericMutationParams<T, TDeclarandoApiResponse<S>, TDeclarandoApiResponse<U>, R>,
) => {
    return useGenericMutation<T, TDeclarandoApiResponse<S>, TDeclarandoApiResponse<U>, R>(args);
};

export const useUpdate = <T, S = T, U = TDeclarandoApiResponse<T>, R = T>(
    args: GenericMutationParams<T, TDeclarandoApiResponse<S>, TDeclarandoApiResponse<U>, R>,
) => {
    return useGenericMutation<T, TDeclarandoApiResponse<S>, TDeclarandoApiResponse<U>, R>(args);
};

export const useInfiniteFetch = <T, S = T>(
    args: UseLoadMoreParams<TDeclarandoApiResponse<T>, TDeclarandoApiResponse<S>>,
) => {
    return useLoadMore<TDeclarandoApiResponse<T>, TDeclarandoApiResponse<S>>(args);
};
