import { useState } from 'react';

export const useContentScroll = () => {
    const [blocked, setBlocked] = useState(false);

    const block = () => {
        setBlocked(true);
    };

    const unBlock = () => {
        setBlocked(false);
    };

    return {
        blocked,
        block,
        unBlock,
    };
};
