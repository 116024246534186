export abstract class BrowserStorage {
    constructor(private storage: Storage) {}

    createStorageAccessor(key: string) {
        return {
            get: () => this.get(key),
            set: (value: string) => this.set(key, value),
            remove: () => this.remove(key),
        };
    }

    set(key: string, value: string) {
        this.storage.setItem(key, value);
    }

    get(key: string) {
        return this.storage.getItem(key) || '';
    }

    remove(key: string) {
        this.storage.removeItem(key);
    }
}
