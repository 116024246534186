import { Box, Icon, Caption, Skeleton, P } from '@declarando/design_system';
import { Currency } from 'shared/infra/utils/Currency';
import { BaseSalesDocument } from '../../../domain/SalesDocument';

type SalesDocumentDetailProps = {
    label: string;
    value: React.ReactNode;
    icon: string;
};

export const SalesDocumentDetail: React.FC<SalesDocumentDetailProps> = ({ label, value, icon }) => (
    <Box display="inline-flex" paddingX="xs">
        <Box as="dt" display="flex" gap="4px" alignItems="baseline" flex={1}>
            <Icon color="semantic.text.general.text1" fontSize="16px" name={icon} />
            <Caption fontSize="xs" color="semantic.text.general.text1">
                {label}
            </Caption>
        </Box>
        <Box as="dd" flex={1} display="flex" alignItems="flex-end">
            {value}
        </Box>
    </Box>
);

type SalesDocumentDetailsProps = {
    salesDocumentData: BaseSalesDocument | undefined;
    isLoading: boolean;
    totalPayable: number | null;
};

export const SalesDocumentDetails: React.FC<SalesDocumentDetailsProps> = ({
    salesDocumentData,
    isLoading,
    totalPayable,
}) => (
    <Box as="dl" display="flex" flexDirection="column" gap="20px" margin={0}>
        <SalesDocumentDetail
            label="Fecha emisión"
            icon="calender"
            value={
                isLoading ? (
                    <Skeleton width="100%" height="20px" />
                ) : (
                    <P as="span" small fontWeight="bold">
                        {salesDocumentData?.issueDate as string}
                    </P>
                )
            }
        />
        <SalesDocumentDetail
            label="Cliente"
            icon="user"
            value={
                isLoading ? (
                    <Skeleton width="100%" height="20px" />
                ) : (
                    <P as="span" small fontWeight="bold">
                        {salesDocumentData?.client.name}
                    </P>
                )
            }
        />
        <SalesDocumentDetail
            label="Total"
            icon="invoice"
            value={
                isLoading ? (
                    <Skeleton width="100%" height="20px" />
                ) : (
                    <P as="span" small fontWeight="bold">
                        {Currency(totalPayable ?? 0)}
                    </P>
                )
            }
        />
    </Box>
);
