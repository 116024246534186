import React from 'react';

function GTMData(props) {
    return (
        <span style={{ display: 'none' }} className={'userId'}>
            {props.userInfo.id}
        </span>
    );
}

export default GTMData;
