import { InvoiceTaxes, ProductTaxes, TaxExemption } from '../../domain/InvoiceTaxes';
import { InvoiceTaxesDTO } from './InvoiceTaxesDTO';

const transformSingleTax = (tax: InvoiceTaxesDTO['taxes'][0]): ProductTaxes => ({
    vat: tax.vat,
    equivalencySurcharge: tax.equivalencySurcharge,
});

export const toInvoiceTaxes = (dto: InvoiceTaxesDTO): InvoiceTaxes => {
    const goodsTaxes = new Set<ProductTaxes>();
    const servicesTaxes = new Set<ProductTaxes>();

    const servicesTaxExemptions = new Set<TaxExemption>();
    const goodsTaxExemptions = new Set<TaxExemption>();

    dto.taxes.forEach((tax) => {
        const transformedTax = transformSingleTax(tax);

        if (tax.productType === 'ALL' || tax.productType === 'GOODS') {
            goodsTaxes.add(transformedTax);
        }

        if (tax.productType === 'ALL' || tax.productType === 'SERVICES') {
            servicesTaxes.add(transformedTax);
        }
    });

    const sortedGoodsTaxes = Array.from(goodsTaxes).sort((a, b) => a.vat - b.vat);
    const sortedServicesTaxes = Array.from(servicesTaxes).sort((a, b) => a.vat - b.vat);

    dto.taxExemptions.forEach((taxExemption) => {
        const transformedTaxExemption = {
            code: taxExemption.exemption.code,
            description: taxExemption.exemption.description,
        };

        if (taxExemption.productType === 'ALL' || taxExemption.productType === 'GOODS') {
            goodsTaxExemptions.add(transformedTaxExemption);
        }

        if (taxExemption.productType === 'ALL' || taxExemption.productType === 'SERVICES') {
            servicesTaxExemptions.add(transformedTaxExemption);
        }
    });

    return {
        canApplyEquivalencySurcharge: dto.canApplyEquivalencySurcharge,
        incomeTax: dto.incomeTax,
        taxes: {
            goods: sortedGoodsTaxes,
            services: sortedServicesTaxes,
        },
        taxExemptions: {
            services: Array.from(servicesTaxExemptions),
            goods: Array.from(goodsTaxExemptions),
        },
    };
};
