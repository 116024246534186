import { UrlParser } from './UrlParser';
import DateWrapper from './Date';
import { Env } from '../../../config/Env';

class DateClock {
    private readonly key_clockoffset = 'clockoffset';
    private readonly key_date_clock = 'date_clock';

    private saveSession(date: string, offset: number) {
        sessionStorage.setItem(this.key_clockoffset, `${offset}`);
        sessionStorage.setItem(this.key_date_clock, date);
    }

    private cleanSession() {
        sessionStorage.removeItem(this.key_clockoffset);
        sessionStorage.removeItem(this.key_date_clock);
    }

    getOffset() {
        return sessionStorage.getItem(this.key_clockoffset);
    }

    getDate() {
        return sessionStorage.getItem(this.key_date_clock);
    }

    check() {
        if (Env.isProd()) {
            return;
        }
        let date_clock = UrlParser.getQueryParams().date_clock; // 'DD-MM-YYYY'
        if (date_clock) {
            this.cleanSession();
            const actualDate = DateWrapper.getNow();
            if (!date_clock.includes(':')) {
                date_clock = date_clock + ' ' + DateWrapper.formatDateToString(actualDate, 'HH:mm:ss');
            }
            const date = DateWrapper.formatStringToDate(date_clock, 'DD-MM-YYYY HH:mm:ss');
            const clockOffset = DateWrapper.diffSeconds(date, actualDate);
            this.saveSession(date_clock, clockOffset);
        }
    }
}

export const dateClock = new DateClock();
