import { validateSpanishIban } from 'features/Ingresos/shared/utils/validate-spanish-iban';

export type IngresoConFacturaPaymentOptionsRules = typeof ingresoConFacturaPaymentOptionsRules;

export const MAX_CASH_PAYMENT_AMOUNT = 1000;

export const ingresoConFacturaPaymentOptionsRules = {
    cash: {
        isLessOrEqualThanMaximumAllowed: (amount: number) => {
            return amount <= MAX_CASH_PAYMENT_AMOUNT;
        },
    },

    bankAccount: {
        isSpanishBankAccountCorrect: (iban: string) => {
            return validateSpanishIban(iban);
        },
    },
};
