import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { SalesDocumentPDFRepository } from '../domain/SalesDocumentPDF';

export abstract class SalesDocumentPDFService extends DeclarandoApiService {
    constructor(private repository: SalesDocumentPDFRepository) {
        super();
    }

    getSalesDocumentPDF(id: string): Promise<any> {
        return this.execute(() => this.repository.getDocumentPDF(id), {
            shouldThrowError: true,
        });
    }
}
