import V2Repository from 'shared/infra/repositories/V2Repository';
import { VIESRepositoryInterface } from '../domain/VIES/VIESRepositoryInterface';

type VIESResponse = {
    isValid: boolean;
};

class VIESRepository extends V2Repository implements VIESRepositoryInterface {
    checkVAT(args: { countryCode: string; VAT: string }): Promise<VIESResponse> {
        return this.get(`/vies/check/${args.countryCode}/vat/${args.VAT}`);
    }
}

export const createVIESRepository = (): VIESRepositoryInterface => {
    return new VIESRepository();
};
