import { UrlParser } from 'shared/infra/utils/UrlParser';
import Cookies from 'js-cookie';
import { FeatureFlagsService } from 'shared/infra/repositories/FeatureFlagsRepository';
import { Env } from './Env';

type Developers = 'adrianM';

export const Flags = {
    cambioPassword: 'cambio-password',
    periodoPrueba: 'periodo-prueba',
    limiteGastos: 'limite-gastos',
    flujoRenta: 'flujo-renta',
};

export class FeatureFlags {
    static cache = {};

    static serverFlags: string[] = [];

    static getDeveloperFlags() {
        if (!Env.isProd()) {
            const queryFlags = UrlParser.getQueryParams().flags;
            if (queryFlags) {
                return queryFlags.split(',');
            }
            return [];
        }
        return [];
    }

    static checkFlag(flag: string): boolean {
        return FeatureFlags.serverFlags.includes(flag) || FeatureFlags.getDeveloperFlags().includes(flag);
    }

    static async loadFlags() {
        const { data } = await new FeatureFlagsService().getFlags();
        FeatureFlags.serverFlags = data ?? [];
    }

    // @deprecated use Env.isLocal
    static isLocal(developer?: Developers) {
        if (developer && developer !== import.meta.env.VITE_DEVELOPER) {
            return false;
        }
        return import.meta.env.VITE_ENV === 'local';
    }

    static forceMockValueInLocalEnv(currentValue: any, mockValue: any) {
        if (import.meta.env.VITE_ENV === 'local') return mockValue;
        return currentValue;
    }

    static localMocksTransferencia() {
        return (
            Cookies.get('CY_TEST') !== '1' &&
            (import.meta.env.VITE_DEVELOPER === 'adrian' || UrlParser.getQueryParams().simulate === 'transferencia')
        );
    }

    static mockFechaBajaAutonomo() {
        return Cookies.get('CY_TEST') !== '1' && UrlParser.getQueryParams().simulate === 'fechaBajaAutonomo';
    }

    static hasCookieCYTest() {
        return Cookies.get('CY_TEST') === '1';
    }

    static cypressTest() {
        return import.meta.env.VITE_ENV === 'local' && Cookies.get('CY_TEST') === '1';
    }

    static mockGastoTipoAlquiler() {
        return UrlParser.getQueryParams().tipoAlquiler === '1';
    }

    static accesoInformeRenta() {
        return UrlParser.getQueryParams().informeRenta === '1';
    }

    static enableTopBannerPeriodoPrueba() {
        //return false;
        return FeatureFlags.checkFlag(Flags.periodoPrueba);
    }

    static enabledProductosRenta() {
        return false;
    }

    static enabledRentalPresentation() {
        return Env.isLocal() || Env.isTest() || FeatureFlags.checkFlag(Flags.flujoRenta);
    }

    static enabledCambioPassword() {
        return FeatureFlags.checkFlag(Flags.cambioPassword);
    }

    static enableNuevaSeccionImpuestos() {
        return true;
        //FeatureFlags.checkFlag(Flags.nuevaSeccionImpuestos);
    }

    static enabledQueryFlaguserFreeBloqued() {
        if (!FeatureFlags.enableTopBannerPeriodoPrueba()) {
            return false;
        }
        if (UrlParser.getQueryParams().userFreeBloqued === '0') {
            sessionStorage.removeItem('userFreeBloqued');
            return false;
        } else if (
            sessionStorage.getItem('userFreeBloqued') === '1' ||
            UrlParser.getQueryParams().userFreeBloqued === '1'
        ) {
            sessionStorage.setItem('userFreeBloqued', '1');
            return true;
        }
        return false;
    }

    static enabledFlujoRenta() {
        return FeatureFlags.checkFlag(Flags.flujoRenta);
    }

    static shouldNewInvoiceFormBeEnabled() {
        const checker = () => {
            return true;
        };

        const getInvoiceFormURL = () => `/ingreso/factura`;
        const getInvoiceEditionFormURL = (invoiceID: string) => `/ingreso/factura-editar/${invoiceID}`;
        const getInvoicePreviewURL = (invoiceID: string) => `/ingreso/factura-previsualizar/${invoiceID}`;

        return {
            checker,
            getInvoicePreviewURL,
            getInvoiceFormURL,
            getInvoiceEditionFormURL,
        };
    }

    static enabledLimiteGastos = () => {
        return FeatureFlags.checkFlag(Flags.limiteGastos);
    };
}
