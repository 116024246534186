import V2Repository from 'shared/infra/repositories/V2Repository';
import { TNotificationRepository, UpdateReadStatusParams, UpdateStateParams } from '../domain/NotificationRepository';
import { TNotification } from '../domain/models/Notification';

const ENDPOINT_NAME = '/notificaciones';

export class NotificationsRepository extends V2Repository implements TNotificationRepository {
    async fetchNotifications(): Promise<TNotification[]> {
        return this.get(ENDPOINT_NAME);
    }

    async updateReadStatus({ notificationId, leida }: UpdateReadStatusParams) {
        return this.put(`${ENDPOINT_NAME}/${notificationId}/leida`, JSON.stringify({ leida }));
    }

    async updateState({ notificationId, estado }: UpdateStateParams) {
        return this.put(`${ENDPOINT_NAME}/${notificationId}/estado`, JSON.stringify({ estado }));
    }
}
