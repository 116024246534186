import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { TNotificationRepository, UpdateReadStatusParams, UpdateStateParams } from '../domain/NotificationRepository';

export class NotificationsService extends DeclarandoApiService {
    private repository: TNotificationRepository;

    constructor(repository: TNotificationRepository) {
        super();
        this.repository = repository;
    }

    async getNotifications() {
        return this.execute(() => this.repository.fetchNotifications());
    }

    async updateReadStatus({ notificationId, leida }: UpdateReadStatusParams) {
        return this.execute(() => this.repository.updateReadStatus({ notificationId, leida }));
    }

    async updateState({ notificationId, estado }: UpdateStateParams) {
        return this.execute(() => this.repository.updateState({ notificationId, estado }));
    }
}
