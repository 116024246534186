import { useEffect } from 'react';
import { ClienteForm } from './hooks/useFormularioClienteReducer';
import { SelectCountry, SelectorGroup } from '@declarando/design_system';
import { FormularioClienteHook } from './hooks/useFormularioCliente';
import useWindowSize from 'shared/UI/hooks/useWindowSize';
import { TipoCliente } from 'features/Incomes/domain/TipoCliente';

export const DatosFiscalesCliente = ({
    form,
    updateInput,
    formularioCliente,
}: {
    form: ClienteForm;
    updateInput: (key: keyof ClienteForm, value: string | boolean | null) => void;
    formularioCliente: FormularioClienteHook;
}) => {
    const { width } = useWindowSize();
    const isMobile = width && width < 768;
    const { iniInput } = formularioCliente;
    const { esSubvencion } = formularioCliente;
    const onChangeTipo = (tipo: string) => {
        updateInput('type', tipo);
    };

    useEffect(() => {
        if (esSubvencion) {
            updateInput('type', TipoCliente.EMPRESA);
        }
    }, [esSubvencion]);

    return (
        <>
            <SelectCountry
                label={form.pais.label}
                required
                id={'nationality'}
                value={form.pais.value}
                onChange={(nationality: string) => {
                    updateInput('pais', nationality);
                    updateInput('cif', '');
                    iniInput('cp', '');
                    iniInput('provincia', '');
                    iniInput('poblacion', '');
                    iniInput('direccion', '');
                    updateInput('isCanario', false);
                }}
                error={Boolean(form.pais.error)}
                message={form.pais.error}
            />
            <SelectorGroup
                id="client-type"
                selectors={[
                    {
                        label: TipoCliente.PARTICULAR,
                        id: TipoCliente.PARTICULAR,
                        checked: form.type.value === TipoCliente.PARTICULAR,
                        disabled: !!esSubvencion,
                    },
                    {
                        label: TipoCliente.EMPRESA,
                        id: TipoCliente.EMPRESA,
                        checked: form.type.value === TipoCliente.EMPRESA,
                        disabled: !!esSubvencion,
                    },
                    {
                        label: TipoCliente.AUTONOMO,
                        id: TipoCliente.AUTONOMO,
                        checked: form.type.value === TipoCliente.AUTONOMO,
                        disabled: !!esSubvencion,
                    },
                ]}
                onChange={(selectedIds) => onChangeTipo(selectedIds[0])}
                variant="radio"
                required
                label={form.type.label}
                error={Boolean(form.type.error)}
                helperText={form.type.error ? form.type.error : ''}
                orientation={isMobile ? 'vertical' : 'horizontal'}
                tooltipOptions={{
                    text: esSubvencion
                        ? 'Como estás informando un ingreso de subvención, el cliente que aplica es de tipo empresa'
                        : '',
                }}
            />
        </>
    );
};
