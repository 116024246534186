import React from 'react';
import DateWrapper from '../../../infra/utils/Date';

const t_trimestre = <>Todos los trimestres</>;
const t_t1 = <>1º</>;
const t_t2 = <>2º</>;
const t_t3 = <>3º</>;
const t_t4 = <>4º</>;

export default class Quarters {
    static all = 'all';
    static first = '1T';
    static second = '2T';
    static third = '3T';
    static fourth = '4T';

    static getOptionsList() {
        return [
            { value: Quarters.all, label: t_trimestre },
            { value: Quarters.first, label: t_t1 },
            { value: Quarters.second, label: t_t2 },
            { value: Quarters.third, label: t_t3 },
            { value: Quarters.fourth, label: t_t4 },
        ];
    }

    /** @Param quarter 1|2|3|4|5 */
    static getQuarterDate(quarter) {
        const year = DateWrapper.getYear();
        const dates = {
            1: `01-01-${year}`, // 1er trimestre empieza el 1 de enero
            2: `01-04-${year}`, // 2do trimestre empieza el 1 de abril
            3: `01-07-${year}`, // 3er trimestre empieza el 1 de julio
            4: `01-10-${year}`, // 4to trimestre empieza el 1 de octubre
            5: `01-01-${+year + 1}`, // 1er trimestre empieza el 1 de enero siguiente año
        };

        return dates[quarter];
    }
}
