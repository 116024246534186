import { Grid } from '@declarando/design_system';
import { DocumentNote } from './components/DocumentNote';

export const AdvancedOptions = () => {
    return (
        <Grid gridTemplateColumns={{ _: '1fr' }} gridColumnGap="md" gridRowGap="md">
            {/* <IngresoConFacturaFormOpcionesAvanzadasGastosSuplidos /> */}
            <DocumentNote />
        </Grid>
    );
};
