import { Box, Icon, Header, P, Button } from '@declarando/design_system';

export const NoSearchResults: React.FC<{ onResetSearch: () => void }> = ({ onResetSearch }) => (
    <Box marginBottom="120px" alignItems="center" display="flex" flexDirection="column" gap="16px" padding="sm">
        <Icon fontSize="64px" name="user-circle" color="semantic.text.status.emptyDisabled" />
        <div style={{ textAlign: 'center' }}>
            <Header as="h3" textColor="semantic.text.general.text2">
                Sin resultados de búsqueda
            </Header>
            <P style={{ lineHeight: '24px' }} small color="semantic.text.general.text2">
                No existen clientes que coincidan con tus criterios de búsqueda
            </P>
        </div>
        <div>
            <Button size="M" onClick={onResetSearch} label="Reiniciar búsqueda" variant="secondary" />
        </div>
    </Box>
);
