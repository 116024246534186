import Repository from './Repository';

class V2Repository extends Repository {
    constructor() {
        super();
        this.apiPath = this.apiPath + '/v2';
    }

    login(user, pass) {
        return this.post(
            '/public/login',
            JSON.stringify({
                user,
                pass,
            }),
        );
    }

    goToClientOfExpert(id) {
        return this.post('/expert/enter/client/' + id);
    }

    async getUserRoles() {
        const roles = localStorage.getItem('roles');
        if (roles && process.env.NODE_ENV === 'development') {
            return JSON.parse(roles);
        }
        return this.post('/user/roles');
    }

    getLastNumbersCreditCard() {
        return this.get('/purchases/visa/actual');
    }
}

export default V2Repository;
