import React, { useContext, useEffect, useState } from 'react';
import { Box, ButtonSolid, ButtonText, Grid, ModalBase, P, Section } from '@declarando/design_system';
import Date from 'shared/infra/utils/Date';
import { AppRouter } from '../../../../routes/AppRouter';
import { RootContext } from 'shared/UI/context/RootContext/RootContext';

type Props = {
    fechaRecurrenciaProgramada: string;
    regularizarTarjetaConCoF: boolean;
};

export const Modalregularizaciontarjeta = ({ fechaRecurrenciaProgramada, regularizarTarjetaConCoF }: Props) => {
    const blockDays = 90;

    const [show, setShow] = useState(false);
    const [days, setDays] = useState<number | null>(null);
    const { regularizacionTarjeta } = useContext(RootContext);

    useEffect(() => {
        if (fechaRecurrenciaProgramada) {
            setShow(regularizarTarjetaConCoF);
            setDays(Date.diffDaysFromNow(fechaRecurrenciaProgramada));
        } else {
            setShow(regularizarTarjetaConCoF);
        }
    }, [regularizarTarjetaConCoF]);

    const closeModal = () => {
        setShow(false);
    };

    const redirecAction = async () => {
        closeModal();
        regularizacionTarjeta.displayModalVisaChangeHandler();
        AppRouter.goToValidate3dsModal();
    };

    const showCloseModalButton = !fechaRecurrenciaProgramada || (days !== null && days > blockDays);

    const msgBloqueo =
        days !== null &&
        (days > blockDays ? (
            <P mb="lg">{`Te quedan X días para realizar la verificación.`.replace('X', String(days))}</P>
        ) : (
            <P color="accent.Main" mb="lg">
                El uso de la plataforma está bloqueado hasta que verifiques la tarjeta.
            </P>
        ));

    return fechaRecurrenciaProgramada || regularizarTarjetaConCoF ? (
        <ModalBase isOpen={show} title={(<>Acción obligatoria en tu cuenta</>) as any} busy={false}>
            <Section mt="lg">
                <P>
                    Tenemos que <b>verificar tus datos de pago</b> para asegurarnos que estás usando el último sistema
                    de seguridad. Para ello tendrás que{' '}
                    <b>cambiar la tarjeta desde la sección de facturación de Declarando.</b>
                </P>
                <P>
                    Pasaremos un <b>pago de 0 euros</b> para confirmar la tarjeta y con esta acción tus{' '}
                    <b>pagos serán más seguros.</b>
                </P>
                <P>
                    Debes verificar tu tarjeta para continuar usando Declarando. Si no verificas tu tarjeta antes del
                    tiempo sugerido, se bloqueará el acceso al servicio Declarando hasta que la verifiques.
                </P>

                {msgBloqueo}
            </Section>

            <Grid pt={'md'} gridTemplateColumns={showCloseModalButton ? '1fr 1fr' : '1fr'}>
                {showCloseModalButton && (
                    <Box display="flex" alignItems="center">
                        <ButtonText actionLabel="Cerrar" onClick={closeModal} />
                    </Box>
                )}
                <Box style={{ textAlign: showCloseModalButton ? 'end' : 'center' }}>
                    <ButtonSolid onClick={redirecAction}>Continuar</ButtonSolid>
                </Box>
            </Grid>
        </ModalBase>
    ) : null;
};
