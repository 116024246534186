import { SalesDocumentPDFRepository } from '../../shared/domain/SalesDocumentPDF';
import { SalesDocumentPDFService } from '../../shared/services/SalesDocumentPDFService';

class QuotePDFService extends SalesDocumentPDFService {
    constructor(repository: SalesDocumentPDFRepository) {
        super(repository);
    }
}

export const createQuotePDFService = (repository: SalesDocumentPDFRepository): QuotePDFService => {
    return new QuotePDFService(repository);
};
