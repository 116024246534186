import React from 'react';

import { Box, ModalChoice } from '@declarando/design_system';
import { ShareScreen } from 'shared/infra/utils/ShareScreen';
import { useModal } from '../../hooks/useModal';

export class ContrataModalController {
    static modal;
}

const ContrataModal = () => {
    const contrataModalController = useModal();
    ContrataModalController.modal = contrataModalController;

    const goRecruit = () => {
        window.open('/invita-amigo', '_blank');
    };

    return contrataModalController ? (
        <ModalChoice
            id={'contrataModal'}
            title={<>Este servicio no está incluído en tu plan actual</>}
            isOpen={contrataModalController.show}
            labelAction={<>Solicitar llamada de asesoramiento</>}
            labelCancel={<>No gracias</>}
            handleAction={ShareScreen.openCrankwheelForEmail}
            handleCancel={contrataModalController.closeModal}
            handleClose={contrataModalController.closeModal}
        >
            <Box pr="15px" pl="15px">
                <p>
                    <>
                        Puedes solicitar una{' '}
                        <span
                            className={'cursor-pointer text-info text-underline'}
                            onClick={ShareScreen.openCrankwheelForEmail}
                        >
                            llamada gratuita de asesoramiento
                        </span>{' '}
                        y te explicaremos los beneficios que te tiene para ti esta funcionalidad.
                    </>
                </p>
                <p>
                    <>
                        Si lo prefieres, puedes{' '}
                        <span className={'cursor-pointer text-info text-underline'} onClick={goRecruit}>
                            invitar a 3 amigos
                        </span>{' '}
                        a unirse a Declarando y podrás usar todos los módulos durante 15 días.
                    </>
                </p>
            </Box>
        </ModalChoice>
    ) : null;
};

export default ContrataModal;
