//@ts-nocheck
import V2Repository from '../V2Repository';
import Date from '../../utils/Date';

/**
 * @class IngresosTableV2Repository
 * @extends V2Repository
 * @description Incomes repository extended from V2Repository"
 */
export default class IncomesTableV2Repository extends V2Repository {
    recurrence(id, objectInitDate, objectEndDate, frequency, sendInvoice, clientId, clientEmail) {
        let initDate = Date.formatDateToString(objectInitDate);
        let endDate = Date.formatDateToString(objectEndDate);

        return this.post(
            `/ingresos/${id}/recurrente`,
            JSON.stringify({
                initDate,
                endDate,
                sendInvoice,
                frequency,
                clientId,
                clientEmail,
            }),
        );
    }

    duplicate(id, objDate, sendInvoice, clientId, clientEmail) {
        let date = Date.formatDateToString(objDate);

        return this.post(
            `/ingresos/${id}/duplicar`,
            JSON.stringify({
                date,
                sendInvoice,
                clientId,
                clientEmail,
            }),
        );
    }

    deleteRecurrence(id: string) {
        return this.delete(`/ingresos/${id}/recurrente`);
    }
}
