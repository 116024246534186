export type InvoicingActivityType =
    | 'RANCHER_NOT_FATTENING'
    | 'RANCHER_FOR_FATTENING'
    | 'BUSINESS'
    | 'PROFESSIONAL'
    | 'ARTISTIC'
    | 'RENT';

export const invoicingActivityTypes: InvoicingActivityType[] = [
    'RANCHER_NOT_FATTENING',
    'RANCHER_FOR_FATTENING',
    'BUSINESS',
    'PROFESSIONAL',
    'ARTISTIC',
    'RENT',
];

export type InvoicingActivity = {
    id: string;
    section: string;
    epigraph: string;
    name: string;
    isVatExempt: boolean;
    registrationDate: string;
    isEquivalencySurcharge: boolean;
    activityType: InvoicingActivityType;
};
