import { SalesDocumentType, useSalesDocumentFormContext } from '../../../../context/SalesDocumentFormProvider';
import { DueDateInvoice } from 'features/Ingresos/SalesDocument/IngresoConFactura/UI/IngresoConFacturaFormView/IngresoConFacturaFormContent/components/IngresoConFacturaDetails/components/DueDateInvoice';
import { DueDateQuote } from 'features/Ingresos/SalesDocument/Quote/UI/QuoteFormView/QuoteFormContent/components/QuoteDetails/components/DueDateQuote';

const MapperDueDate: Record<SalesDocumentType, () => JSX.Element> = {
    invoice: () => <DueDateInvoice />,
    quote: () => <DueDateQuote />,
};

export const DueDate = () => {
    const { salesDocumentType } = useSalesDocumentFormContext();

    const Component = MapperDueDate[salesDocumentType];

    return <Component />;
};
