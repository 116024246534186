interface Articulo {
    id: string;
    url: string;
}
export const Articulos: Record<string, Articulo> = {
    '130': { id: '9404105', url: 'https://ayuda.declarando.es/es/articles/9404105-modelo-130' },
    '303': { id: '9389208', url: 'https://ayuda.declarando.es/es/articles/9389208-modelo-303' },
    '309': { id: '9389221', url: 'https://ayuda.declarando.es/es/articles/9389221-modelo-309' },
    '349': { id: '9389227', url: 'https://ayuda.declarando.es/es/articles/9389227-modelo-349' },
    '111': { id: '9398671', url: 'https://ayuda.declarando.es/es/articles/9398671-modelo-111' },
    '115': { id: '9404080', url: 'https://ayuda.declarando.es/es/articles/9404080-modelo-115' },
    '190': { id: '9460273', url: 'https://ayuda.declarando.es/es/articles/9460273-modelo-190' },
    '180': { id: '9460264', url: 'https://ayuda.declarando.es/es/articles/9460264-modelo-180' },
    '347': { id: '9478106', url: 'https://ayuda.declarando.es/es/articles/9478106-modelo-347' },
    '390': { id: '9478101', url: 'https://ayuda.declarando.es/es/articles/9478101-modelo-390' },
    '303C': { id: '9470553', url: 'https://ayuda.declarando.es/es/articles/9470553-modelo-303-complementario' },
    main: {
        id: '3828129',
        url: 'https://ayuda.declarando.es/es/articles/3828129-todo-lo-que-tienes-que-saber-sobre-los-impuestos',
    },
    invoicing: {
        id: '10259888',
        url: 'https://ayuda.declarando.es/es/articles/10259888-facturar-en-declarando-a-partir-de-2025',
    },
} as const;
