import { ReactNode, useContext } from 'react';
import { ContentLoader } from '@declarando/design_system';
import { Step3Initial } from './Step3Initial';
import { Step3Context, Step3ContextProvider } from './Step3Context';

export const Step3Mapper = () => {
    const { state, StateKey } = useContext(Step3Context);

    const StatesMapper: { [keys: string]: ReactNode } = {
        [StateKey.INITIAL]: <Step3Initial />,
        [StateKey.LOADING]: <ContentLoader text={'Cargando...'} />,
    };

    return <>{StatesMapper[state] ?? StatesMapper[StateKey.INITIAL]}</>;
};

export const Step3 = () => {
    return (
        <Step3ContextProvider>
            <Step3Mapper />
        </Step3ContextProvider>
    );
};
