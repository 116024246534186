import { useContext, useEffect } from 'react';
import { Box, Header, Icon, Link, P, theme, Section, Grid, ActionLink } from '@declarando/design_system';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import imageStep from '../assets/Modo_de_aislamiento.svg';
import { OnboardingContext } from '../../Onboarding';

export const StepWaitingRoom = () => {
    const { setInfoTagStep } = useContext(OnboardingContext);

    useEffect(() => {
        setInfoTagStep('En revisión');
        return () => setInfoTagStep('');
    }, []);

    const iconColor = theme.colors.semantic.text.mainStatus.default;
    const backgroundColorBox1 = theme.colors.semantic.background.mainStatus.light;
    const backgroundColorBox2 = theme.colors.semantic.background.secondaryStatusLight.hover;

    const mentorInfoItems = [
        'Te acompañará y resolverá tus dudas sobre la plataforma.',
        'Te dará respuesta a las cuestiones fiscales que necesites.',
        'Te dará las herramientas para llevar el control de tu negocio.',
    ];

    const actionLinks = [
        {
            icon: 'question-circle',
            label: 'Centro de ayuda',
            href: 'https://ayuda.declarando.es/es/',
            event_label: 'Ir al Centro de ayuda',
        },
        {
            icon: 'graduation-cap',
            label: 'Mentorías online',
            href: 'https://unmarred-mushroom-c93.notion.site/Declarando-Mentor-as-online-55e21cf8969648c384eb7ccf75edf4bd',
            event_label: 'Ir a Mentorías online',
        },
        {
            icon: 'youtube',
            label: 'Píldoras en Youtube',
            href: 'https://www.youtube.com/@DeclarandoEs/videos',
            event_label: 'Ir a Youtube',
        },
        {
            icon: 'books',
            label: 'Biblioteca de recursos',
            href: 'https://declarando.es/recursos-para-autonomos',
            event_label: 'Ir a Biblioteca de recursos',
        },
        {
            icon: 'newspaper',
            label: 'Noticias para autónomos',
            href: 'https://declarando.es/observatorio-juridico',
            event_label: 'Ir a Observatorio jurídico',
        },
    ];

    return (
        <Box px={'sm'} py="xxl" alignItems="center" alignSelf="stretch" maxWidth={800} margin="auto">
            <Box alignCenter mb="lg">
                <Box mb="sm" alignCenter>
                    <img src={imageStep} alt="Mentor" />
                </Box>
                <Header as="h2">Estamos analizando tu situación particular...</Header>
                <P mt="xs">Nuestro equipo está evaluando tu caso para ofrecerte una experiencia personalizada.</P>
            </Box>

            <Grid gridGap={10} gridTemplateColumns={{ _: '1fr', desktop: '1fr max-content' }}>
                <Box padding="md" gap="sm" backgroundColor={backgroundColorBox1} borderRadius="md">
                    <Section>
                        <P bold>
                            Si tienes{' '}
                            <Link
                                styles={{ fontWeight: 700 }}
                                href="https://declarando.es/planes-y-precios-servicios-autonomos"
                            >
                                plan Esencial, Avanzado o Estelar
                            </Link>
                            , te asignaremos un mentor:
                        </P>
                        {mentorInfoItems.map((text, index) => (
                            <Box key={index} display="flex" gridGap="xxs">
                                <Icon name="check" color={iconColor} />
                                <P>{text}</P>
                            </Box>
                        ))}
                    </Section>
                </Box>

                <Box padding="md" gap="sm" backgroundColor={backgroundColorBox2} borderRadius="md">
                    <Section>
                        {actionLinks.map(({ icon, label, href, event_label }, index) => (
                            <Box key={index} display="flex" gridGap="xxs">
                                <ActionLink
                                    size="S"
                                    icon={icon}
                                    label={label}
                                    title={label}
                                    onClick={() => {
                                        TrackingEvents.clickElement({ event_label });
                                        location.href = href;
                                    }}
                                />
                            </Box>
                        ))}
                    </Section>
                </Box>
            </Grid>
        </Box>
    );
};
