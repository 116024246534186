import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { IssueDateInput } from './components/IssueDateInput';
import { IssueDateManualNumbering } from './components/IssueDateManualNumbering/IssueDateManualNumbering';
import { IssueDateAutomaticNumbering } from './components/IssueDateAutomaticNumbering/IssueDateAutomaticNumbering';
import { useGetContabilidadPresentedOrBlocked } from 'features/Ingresos/Contabilidad/UI/hooks/useGetContabilidadPresentedOrBlocked';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import DateWrapper from 'shared/infra/utils/Date';
import { useEffect } from 'react';

const useAssignDinamicallyContabilidadPresentedOrBlocked = () => {
    const { control, setValue, trigger, getFieldState } = useCustomFormContext<InvoiceForm>();
    const { formBlocked } = useSalesDocumentFormContext();
    const watchIssueDate = useCustomWatch({ control, name: 'issueDate' });
    const parsedIssueDate = DateWrapper.formatNativeDateToString(
        watchIssueDate ? new Date(watchIssueDate) : new Date(),
        'yyyy-MM-dd',
    );
    const { contabilidadPresentedOrBlocked, isLoading } = useGetContabilidadPresentedOrBlocked(parsedIssueDate, {
        shouldTriggerApiCall: !!parsedIssueDate && !formBlocked.isFormBlocked,
    });

    useEffect(() => {
        if (isLoading) return;
        setValue('hasContabilidadPresentedOrBlocked', contabilidadPresentedOrBlocked?.hasContabilidadBlocked);

        const issueDateState = getFieldState('issueDate');
        if (issueDateState.isDirty) {
            trigger('issueDate');
        }
    }, [contabilidadPresentedOrBlocked?.hasContabilidadBlocked, getFieldState, isLoading, setValue, trigger]);
};

export const IssueDateSelector = () => {
    const { userStatus } = useRootContext();
    useAssignDinamicallyContabilidadPresentedOrBlocked();

    return userStatus?.autoNumInvoice ? <IssueDateAutomaticNumbering /> : <IssueDateManualNumbering />;
};

export const IssueDate = () => {
    const { formBlocked } = useSalesDocumentFormContext();

    if (formBlocked.isFormBlocked) return <IssueDateInput readOnly />;

    return <IssueDateSelector />;
};
