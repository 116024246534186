import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { InvoiceTaxesDTO } from 'features/Ingresos/SalesDocument/IngresoConFactura/adapters/InvoiceTaxesDTO';
import { GetTaxesArgs } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/IngresoConFacturaRulesRepository';
import { createIngresoConFacturaRulesApiRepository } from 'features/Ingresos/SalesDocument/IngresoConFactura/infra/IngresoConFacturaRulesRepository';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { useFetch } from 'shared/UI/hooks/QueryHooks';
import DateWrapper from 'shared/infra/utils/Date';
import { InvoiceTaxes } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/InvoiceTaxes';
import { toInvoiceTaxes } from 'features/Ingresos/SalesDocument/IngresoConFactura/adapters/InvoiceTaxesDTO/InvoiceTaxesAdapter';
import { longLivedQueryConfig } from 'shared/UI/config/react-query-config';
import { useRef, useEffect } from 'react';
import { Notifier } from 'shared/infra/utils/Notifier';
import { createIngresoConFacturaRepositoryRulesService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaRulesService';
import { useNIFIVAProgressionAndApiTrigger } from '../../../hooks/use-nif-iva-progression-api-call';
import { useImageRightsProgressionAndApiTrigger } from '../../../hooks/use-image-rights-progession-api-call';
import { useRancherForFatteningProgressionAndApiTrigger } from '../../../hooks/use-rancher-fattening-progression-api-call';

function isDeepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;
    for (const key of keys1) {
        if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) return false;
    }
    return true;
}

export const useGetRequiredArgsForTaxes = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const watchIssueDate = useCustomWatch({ control, name: 'issueDate' });
    const watchClient = useCustomWatch({ control, name: 'client' });
    const watchActivity = useCustomWatch({ control, name: 'activity' });

    return {
        issueDate: watchIssueDate ? DateWrapper.formatNativeDateToString(new Date(watchIssueDate), 'yyyy-MM-dd') : null,
        client: watchClient,
        activity: watchActivity,
    };
};

export const useGetAvailableTaxes = (args: GetTaxesArgs) => {
    const service = createIngresoConFacturaRepositoryRulesService(createIngresoConFacturaRulesApiRepository());

    const shouldTriggerCallWithNIFIVA = useNIFIVAProgressionAndApiTrigger({ client: args.client });
    const shouldTriggerCallWithImageRights = useImageRightsProgressionAndApiTrigger({
        client: args.client,
        activity: args.activity,
    });
    const shouldTriggerCallWithRancherForFattening = useRancherForFatteningProgressionAndApiTrigger({
        client: args.client,
        activity: args.activity,
    });

    const shouldTriggerCall = !!(
        args.issueDate &&
        args.client.id &&
        args.activity.id &&
        shouldTriggerCallWithNIFIVA &&
        shouldTriggerCallWithImageRights &&
        shouldTriggerCallWithRancherForFattening
    );

    return useFetch<InvoiceTaxesDTO, InvoiceTaxes | null>({
        queryKey: [
            'invoice-taxes',
            args.client.clientType,
            args.client.country.code,
            args.client?.state?.id ?? '',
            `${args.client.hasEUVatNumber}`,
            `${args.activity.withImageRights}`,
            `${args.activity.forFattening}`,
            args.activity.id,
            args.issueDate,
        ],
        fetchFn: () => service.getAvailableTaxes(args),
        config: {
            select: (data) => ({ ...data, data: data.data ? toInvoiceTaxes(data.data) : null }),
            enabled: shouldTriggerCall,
            ...longLivedQueryConfig,
        },
    });
};

//TODO: Return taxes directly instead of an object with data.data
export const useGetTaxes = () => {
    const args = useGetRequiredArgsForTaxes();
    const { data, ...rest } = useGetAvailableTaxes(args as GetTaxesArgs);
    const previousTaxesRef = useRef<InvoiceTaxes | null>(null);

    useEffect(() => {
        if (data?.data) {
            if (previousTaxesRef.current && !isDeepEqual(data.data, previousTaxesRef.current)) {
                Notifier.notifyFeedBack({
                    title: 'El cambio ha actualizado las reglas fiscales',
                    message: 'Se han recalculado automáticamente considerando el cambio que has aplicado.',
                    options: { toastId: 'invoice-taxes' },
                });
            }
            previousTaxesRef.current = data.data;
        }
    }, [data]);

    return { data, ...rest };
};
