import { Box } from '@declarando/design_system';

type SalesDocumentPDFViewerProps = {
    salesDocumentPDFUrl: string;
};

export const SalesDocumentPDFViewer: React.FC<SalesDocumentPDFViewerProps> = ({ salesDocumentPDFUrl }) => (
    <Box flex={1}>
        <object data={`${salesDocumentPDFUrl}#zoom=79#view=FitV`} type="application/pdf" width="100%" height="100%">
            <p>
                Tu navegador no soporta la visualización de PDF's.
                <a href={salesDocumentPDFUrl}>Clicka para descargar el PDF</a>.
            </p>
        </object>
    </Box>
);
