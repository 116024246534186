import { Select } from '@declarando/design_system';
import { Invoice, InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useGetInvoicingActivities } from 'features/Ingresos/InvoicingActivities/UI/hooks/useGetInvoicingActivities';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { SkeletonInput } from 'shared/UI/components/Skeletons/Input/SkeletonInput';
import { useGetNextSalesDocumentNumber } from '../hooks/useGetNextSalesDocumentNumber';
import { SalesDocumentType, useSalesDocumentFormContext } from '../../../../context/SalesDocumentFormProvider';

const getLabel = (args: { salesDocumentType: SalesDocumentType }) => {
    const MAPPER_TITLE: Record<SalesDocumentType, string> = {
        invoice: 'Actividad asociada a la factura',
        quote: 'Actividad asociada al presupuesto',
    };

    return MAPPER_TITLE[args.salesDocumentType];
};

export const SalesDocumentActivityDetails = () => {
    const { control, setValue, trigger } = useCustomFormContext<InvoiceForm>();
    const { formBlocked, salesDocumentType } = useSalesDocumentFormContext();
    const { invoicingActivities } = useGetInvoicingActivities();
    const { isLoading } = useGetNextSalesDocumentNumber();

    const handleChange = (value: string) => {
        const filteredActivity = invoicingActivities?.find((activity) => activity.id === value);

        if (!filteredActivity) throw new Error('Actividad no encontrada');

        setValue('activity', {
            ...filteredActivity,
            withImageRights: undefined as unknown as Invoice['activity']['withImageRights'],
            forFattening: undefined as unknown as Invoice['activity']['forFattening'],
        });

        setValue('activity.withImageRights', undefined as unknown as boolean);
        setValue('activity.forFattening', undefined as unknown as boolean);

        //NOTE: Trigger manual de validacion para que se actualice el error
        trigger('activity.id');
    };

    if (isLoading) return <SkeletonInput labelHeight="22px" />;

    return (
        <ControlledFormField
            name="activity.id"
            control={control}
            Component={({ field, formState: { errors } }) => (
                <Select
                    readOnly={formBlocked.isFormBlocked}
                    error={!!errors?.activity?.id?.message}
                    helperText={errors?.activity?.id?.message}
                    id="invoice-activity"
                    value={field.value}
                    options={invoicingActivities?.map((activity) => ({ value: activity.id, label: activity.name }))}
                    onChange={handleChange}
                    label={getLabel({ salesDocumentType })}
                    required
                    placeholder="Selecciona una actividad"
                />
            )}
        />
    );
};
