import { Box, ButtonSolid, FileuploaderSection, Link, P, Section } from '@declarando/design_system';
import { useContext } from 'react';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { Step3Context } from './Step3Context';
import {
    publishCargarPlantillaContabilidadEvent,
    publishDescargarPlantillaContabilidadEvent,
    publishSubirContabilidadPlantillaEvent,
} from '../../../services/events';

export const Step3TabPlantillaDeclarando = () => {
    const { errorFile, importarContabilidad, setSelectedFile, file } = useContext(Step3Context);

    const handleUploadClick = () => {
        importarContabilidad();
        publishSubirContabilidadPlantillaEvent();
    };

    const handleFileSelection = (file: File | null) => {
        setSelectedFile(file);
        publishCargarPlantillaContabilidadEvent();
    };

    return (
        <>
            <Section mt="sm">
                <Box display="flex" flexDirection="column">
                    {[
                        'Prepara un excel separando tus ingresos y gastos por pestañas.',
                        'Asegúrate de que tu contabilidad esté al día, no podrás editarla tras la importación.',
                        <>
                            Si lo prefieres,{' '}
                            <Link
                                target="_self"
                                href="/downloads/Plantilla_Ingresos_y_Gastos.xlsx"
                                onClick={publishDescargarPlantillaContabilidadEvent}
                            >
                                descarga nuestra plantilla
                            </Link>{' '}
                            y rellénala con la ayuda de este{' '}
                            <Link
                                href="https://www.youtube.com/watch?v=GnDqP3b_uCQ"
                                onClick={() => {
                                    TrackingEvents.clickElement({ event_label: 'Ve tutorial subida contabilidad' });
                                }}
                            >
                                vídeo tutorial
                            </Link>
                            .
                        </>,
                    ].map((text, index) => (
                        <Box key={index} display="flex" gap="7px">
                            <P>{index + 1}.</P>
                            <P>{text}</P>
                        </Box>
                    ))}
                </Box>

                <FileuploaderSection
                    uploading={false}
                    icon="upload"
                    actionLabel="Selecciona el archivo o arrástralo aquí"
                    filecopy="El archivo debe ser en formato excel (.xlsx) y no exceder los 5MB"
                    formats={['xlsx', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                    loaderLabel="Subiendo xlsx..."
                    maxMb={5}
                    onChangeFile={handleFileSelection}
                    errorMessage={errorFile}
                />
            </Section>

            <Box
                mt="lg"
                display={{ _: 'grid', desktop: 'flex' }}
                gridTemplateColumns={{ _: '1fr', tablet: 'auto' }}
                justifyContent="center"
                gridGap="xs"
            >
                <ButtonSolid disabled={!file} onClick={handleUploadClick}>
                    Subir mi contabilidad
                </ButtonSolid>
            </Box>
        </>
    );
};
