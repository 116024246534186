import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';

export const useShouldAppearClientInfo = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const watchClient = useCustomWatch({ control, name: 'client' });

    return { shouldAppearClientInfo: !!watchClient?.id, clientInfo: watchClient };
};
