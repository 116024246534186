import { Client } from 'features/Ingresos/Clientes/domain';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { IngresoConFacturaClientRulesService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaRulesService';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';

export const useNIFIVAProgressionAndApiTrigger = (args: { client: Client }) => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const watchHasNIFIVA = useCustomWatch({ control, name: 'client.hasEUVatNumber' });
    const isNIFIVARequired = IngresoConFacturaClientRulesService.isNIFIVARequired({
        country: args.client.country,
        clientType: args.client.clientType,
    });

    if (!isNIFIVARequired) return true;

    if (isNIFIVARequired && watchHasNIFIVA !== undefined) return true;

    return false;
};
