import {
    IsManagedByUserArgs,
    PresentationOfRentaRepository,
} from 'features/CalculadoraRenta/PresentationOfRenta/infra/PresentationOfRentaRepository';
import DateWrapper from 'shared/infra/utils/Date';
import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';

const yearRent = DateWrapper.getFullYear(new Date()) - 1;

export class PresentationOfRentaService extends DeclarandoApiService {
    constructor(private readonly repository = new PresentationOfRentaRepository()) {
        super();
    }

    getStatus() {
        return this.execute(() => this.repository.getStatus());
    }

    goToNextStep() {
        return this.execute(() => this.repository.goToNextStep());
    }

    isManagedByUser(args: IsManagedByUserArgs) {
        return this.execute(() => this.repository.isManagedByUser(args));
    }

    uploadProof(formData: FormData) {
        return this.execute(() => this.repository.uploadProof(formData, yearRent));
    }
}

export const useRentalPresentationService = () => new PresentationOfRentaService();
