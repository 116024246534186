import { useFetch } from 'shared/UI/hooks/QueryHooks';
import { createIngresoConFacturaApiRepository } from '../../infra/IngresoConFacturaRepository';
import { createIngresoConFacturaService } from '../../services/IngresoConFacturaService';
import { InvoiceDTO } from '../../adapters/InvoiceDTO';
import { Invoice } from '../../domain/Invoice';
import { toInvoice } from '../../adapters/InvoiceAdapter';

export const useGetIngresoConFactura = (args: { invoiceUUID: string }) => {
    const service = createIngresoConFacturaService(createIngresoConFacturaApiRepository());

    const context = useFetch<InvoiceDTO | null, Invoice | null>({
        queryKey: ['invoices', 'invoice', args.invoiceUUID],
        fetchFn: () => service.getInvoiceByUUID(args.invoiceUUID),
        config: {
            enabled: !!args.invoiceUUID,
            select: (data) => ({ ...data, data: data?.data ? toInvoice(data?.data) : null }),
        },
    });

    return { invoiceData: context.data?.data, isLoading: context.isLoading, isError: context.error };
};
