import { Box, Header, P, Button, Icon, Grid } from '@declarando/design_system';
import Image from '../assets/inicio.svg';
import { useContext } from 'react';
import { OnboardingContext } from '../../Onboarding';
import { publishConfigurarMiCuentaEvent } from '../../../services/events/publishConfigurarMiCuentaEvent';

export const SetupBienvenida = () => {
    const { siguintePaso } = useContext(OnboardingContext);

    const empezarClick = () => {
        siguintePaso();
        publishConfigurarMiCuentaEvent();
    };

    const iconColor = '#007AFF';
    const benefits = [
        {
            icon: 'usd-circle',
            text: 'Alcanza tu máximo potencial de ahorro anual',
        },
        {
            icon: 'percentage',
            text: 'Calcula automáticamente la deducibilidad de tus gastos',
        },
        {
            icon: 'file-check',
            text: 'Crea facturas perfectas con cálculo automático de IVA e IRPF',
        },
        {
            icon: 'file-check-alt',
            text: 'Anticípate a la facturación electrónica (¡pronto será obligatoria!)',
        },
        {
            icon: 'chart-line',
            text: 'Descubre el impacto de cada factura en tu renta',
        },
        {
            icon: 'university',
            text: 'Evita errores y sanciones de Hacienda',
        },
    ];

    return (
        <Box
            p={{ _: 'md', tablet: 'xl' }}
            py={'xl'}
            mx="auto"
            alignSelf="stretch"
            mt="xxl"
            backgroundColor="white"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            borderRadius={8}
            minHeight={'600px'}
        >
            <Header as="h1" textAlign="center">
                Te damos la bienvenida a Declarando
            </Header>
            <P mt="xs" textAlign="center">
                Configura tu cuenta e inicia tu camino hacia la tranquilidad fiscal.
            </P>
            <Box my="lg">
                <Button
                    icon={'arrow-right'}
                    label={'Empezar la configuración'}
                    iconPosition={'right'}
                    onClick={empezarClick}
                />
            </Box>
            <Box
                backgroundColor="#EBF4FF"
                borderRadius="16px"
                display="flex"
                alignItems="center"
                px={{ _: 'md', tablet: 'xl' }}
                py="md"
                maxWidth="800px"
                justifyContent="center"
                mx="auto"
            >
                <Box
                    display={{ _: 'none', desktop: 'flex' }}
                    justifyContent="center"
                    alignItems="center"
                    mr="md"
                    width="200px"
                    height="200px"
                    flexShrink={0}
                >
                    <img src={Image} alt="Bienvenida" style={{ width: '100%', height: '100%', display: 'block' }} />
                </Box>
                <Grid gridGap="16px" gridTemplateColumns={{ _: '1f', desktop: '1fr 1fr' }}>
                    {benefits.map((benefit, index) => (
                        <Box key={index} display="flex" alignItems="center" gap="4px">
                            <Icon name={benefit.icon} fontSize="32px" color={iconColor} />
                            <P fontSize="14px" textAlign="left" weight="700" lineHeight="20px">
                                {benefit.text}
                            </P>
                        </Box>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};
