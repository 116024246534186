import React from 'react';
import LogoBlue from 'assets/img/declarando-blue.svg';
import { Flex } from '@declarando/design_system';
import { AppRouter } from 'routes/AppRouter';

export const Logo = () => (
    <Flex height="lg" width="lg" style={{ cursor: 'pointer' }}>
        <img onClick={() => AppRouter.goHome()} src={LogoBlue} alt="Declarando logo" height="32px" width="32px" />
    </Flex>
);
