import {
    Controller,
    FieldValues,
    Path,
    Control,
    RegisterOptions,
    ControllerRenderProps,
    ControllerFieldState,
    UseFormStateReturn,
    PathValue,
} from 'react-hook-form';

type ControlledFormFieldProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> = {
    name: TName;
    control?: Control<TFieldValues>;
    defaultValue?: PathValue<TFieldValues, TName>;
    rules?: Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    Component: (props: {
        field: ControllerRenderProps<TFieldValues, TName>;
        fieldState: ControllerFieldState;
        formState: UseFormStateReturn<TFieldValues>;
    }) => React.ReactElement;
};

export function ControlledFormField<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({
    name,
    control,
    rules,
    defaultValue,
    Component,
}: ControlledFormFieldProps<TFieldValues, TName>) {
    return (
        <Controller<TFieldValues, TName>
            name={name}
            defaultValue={defaultValue}
            control={control}
            rules={rules}
            render={(props) => Component(props)}
        />
    );
}
