import { ActionLink, Box, Divider, TooltipOptions, Tag } from '@declarando/design_system';
import { Client } from 'features/Ingresos/Clientes/domain';
import { useState, useMemo, useCallback, ComponentProps } from 'react';
import { ClientSelectionContent } from './components/ClientSelectionContent';
import { Modal } from 'shared/UI/components/Modal/Modal';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { IngresoConFacturaClientRulesService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaRulesService';
import { ClientSearchSection } from './components/ClientSearchSection';
import { useClientSearch } from './hooks/useClientSearch';
import { useClientSelection } from './hooks/useClientSelection';
import { CreateClientModal } from '../CreateClientModal/CreateClientModal';
import { SalesDocumentType, useSalesDocumentFormContext } from '../../../../../context/SalesDocumentFormProvider';

type TagOptions = ComponentProps<typeof Tag>;

export type ClientOption = {
    id: string;
    label: string;
    helperText: string;
    checked: boolean;
    disabled?: boolean;
    tagOptions?: TagOptions & { text: string };
    tooltipOptions?: TooltipOptions;
};

const clientToOption = (client: Client, selectedIds: string[]): ClientOption => {
    const shouldShowAddressIsMissing = IngresoConFacturaClientRulesService.hasClientIncompleteProfile({ client });

    return {
        id: client.id,
        label: client.name,
        helperText: client.fiscalId ?? '',
        checked: selectedIds.includes(client.id),
        ...(shouldShowAddressIsMissing
            ? {
                  disabled: true,
                  tagOptions: { text: 'Datos incompletos', variant: 'notification' },
                  tooltipOptions: {
                      title: 'Faltan datos obligatorios en la ficha del cliente',
                      text: 'Para poder emitirle un ingreso con factura, primero debes completar todos los datos obligatorios en su ficha de cliente.',
                      link: { text: 'Ir a mis clientes', href: '/ingresos/clientes', openInNewTab: false },
                  },
              }
            : {}),
    };
};

const getButtonLabel = (args: { salesDocumentType: SalesDocumentType }) => {
    const MAPPER_TITLE: Record<SalesDocumentType, string> = {
        invoice: 'Añadir datos a la factura',
        quote: 'Añadir datos al presupuesto',
    };

    return MAPPER_TITLE[args.salesDocumentType];
};

export const ClientSelection: React.FC = () => {
    const [isModalSelectClientOpen, setIsModalSelectClientOpen] = useState(false);
    const [isModalNewClientOpen, setIsModalNewClientOpen] = useState(false);
    const { salesDocumentType } = useSalesDocumentFormContext();

    const { searchValue, setSearchValue, debouncedSearchValue, handleResetSearch } = useClientSearch();

    const {
        selectedIds,
        setSelectedIds,
        handleSelectClient,
        frequentInvoiceClients,
        isLoadingFrequentInvoiceClients,
        clientsList,
        isLoadingClientsList,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useClientSelection({ debouncedSearchValue, handleCloseAction: () => setIsModalSelectClientOpen(false) });

    const handleClickAddNewClient = () => {
        setIsModalSelectClientOpen(false);
        setIsModalNewClientOpen(true);
    };

    const infiniteScrollTriggerRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (isFetchingNextPage || !node) return;

            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    fetchNextPage();
                }
            });

            observer.observe(node);

            return () => observer.disconnect();
        },
        [isFetchingNextPage, hasNextPage, fetchNextPage],
    );

    const clientOptions = useMemo(
        () => clientsList.map((client) => clientToOption(client, selectedIds)),
        [clientsList, selectedIds],
    );
    const frequentClientOptions = useMemo(
        () =>
            frequentInvoiceClients.map((client, index) =>
                clientToOption({ ...client, id: `${client.id}-${index}` }, selectedIds),
            ),
        [frequentInvoiceClients, selectedIds],
    );

    return (
        <div>
            <ActionLink
                label="Rellenar desde uno de mis clientes"
                icon="users-alt"
                size="L"
                onClick={() => {
                    setIsModalSelectClientOpen(true);
                    TrackingEvents.clickElement({ event_label: 'Rellena desde cliente existente' });
                }}
            />
            <CreateClientModal isOpen={isModalNewClientOpen} onClose={() => setIsModalNewClientOpen(false)} />
            <Modal
                onAction={() => handleSelectClient(selectedIds)}
                actionLabel={getButtonLabel({ salesDocumentType })}
                variant="small"
                isOpen={isModalSelectClientOpen}
                onClose={() => setIsModalSelectClientOpen(false)}
                title="Seleccionar uno de mis clientes"
                isActionDisabled={selectedIds.length === 0}
            >
                <Box height="616px">
                    <ClientSearchSection
                        setSelectedIds={setSelectedIds}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        onAddNewClient={handleClickAddNewClient}
                    />
                    <Box marginY="sm">
                        <Divider />
                    </Box>
                    <ClientSelectionContent
                        isLoading={isLoadingClientsList || isLoadingFrequentInvoiceClients}
                        searchValue={searchValue}
                        frequentClientOptions={frequentClientOptions}
                        clientOptions={clientOptions}
                        setSelectedIds={setSelectedIds}
                        onResetSearch={handleResetSearch}
                        infiniteScrollTriggerRef={infiniteScrollTriggerRef}
                    />
                </Box>
            </Modal>
        </div>
    );
};
