// @ts-nocheck

import { TrackPage } from 'shared/infra/utils/user_events/TrackPage';
import { ContentScrollSection } from './ContentScrollSection';
import { Redirect, Switch } from 'react-router-dom';
import AuthenticatedRoute from '../Auth/AuthenticatedRoute';
import JWT from 'shared/infra/utils/JWT';
import React from 'react';
import { userFreeRoutes } from '../../../../routes/userFreeRoutes';
import { useRootContext } from '../../context/RootContext/RootContext';

import { ModalPeriodoPruebaFinalizado } from './modals/ModalPeriodoPruebaFinalizado/ModalPeriodoPruebaFinalizado';

export const MainContentSection = ({ userInfo, routes, wide, loadedUserInfo, userStatus, spreads, roles }) => {
    const { userFreeNotPeriodoDemo, recurrenteData } = useRootContext();
    const onScrollMain = () => {
        const mainContent = document.getElementById('MainContent');
        if (document.getElementsByClassName('adviceBar').length > 0 && window.innerWidth < 1024) {
            mainContent.style.marginBottom = '72px';
        } else {
            mainContent.style.marginBottom = '';
        }
    };

    const isImpagadoFinalPuedeRenovar =
        recurrenteData?.estado === 'IMPAGADO_FINAL' && recurrenteData?.renovacion.puedeRenovar;

    const isFreeNoDemoRoutes =
        userFreeNotPeriodoDemo || recurrenteData?.estado === 'IMPAGADO_FINAL' ? userFreeRoutes : routes;

    return (
        <div id="MainContent" className="mainContent" onScroll={onScrollMain}>
            {userFreeNotPeriodoDemo && !isImpagadoFinalPuedeRenovar ? <ModalPeriodoPruebaFinalizado /> : null}
            <ContentScrollSection userInfo={userInfo} wide={wide} loadedUserInfo={loadedUserInfo} routes={routes}>
                <Switch>
                    {isFreeNoDemoRoutes.map((prop, key) => {
                        if (prop.isPaylandsOk === true) {
                            return (
                                <AuthenticatedRoute
                                    getToken={JWT.getToken}
                                    path={prop.path}
                                    key={key}
                                    render={(props) => {
                                        return (
                                            <TrackPage {...prop.track_params}>
                                                <prop.component
                                                    {...spreads}
                                                    isPaylandsOk={true}
                                                    {...props}
                                                    currentRoute={prop}
                                                />
                                            </TrackPage>
                                        );
                                    }}
                                />
                            );
                        }
                        if (prop.isPaylandsOk === false) {
                            return (
                                <AuthenticatedRoute
                                    getToken={JWT.getToken}
                                    path={prop.path}
                                    key={key}
                                    render={(props) => (
                                        <TrackPage {...prop.track_params}>
                                            <prop.component
                                                {...this.spreads}
                                                isPaylandsOk={false}
                                                {...props}
                                                currentRoute={prop}
                                            />
                                        </TrackPage>
                                    )}
                                />
                            );
                        }
                        if (prop.role && !roles.includes(prop.role)) {
                            return null;
                        }
                        if (prop.redirect) {
                            return <Redirect from={prop.path} to={prop.to} key={key} />;
                        } else if (!prop.directLink)
                            return (
                                <AuthenticatedRoute
                                    getToken={JWT.getToken}
                                    path={prop.path}
                                    key={key}
                                    render={(props) => {
                                        return (
                                            <TrackPage {...prop.track_params}>
                                                <prop.component {...spreads} {...props} currentRoute={prop} />
                                            </TrackPage>
                                        );
                                    }}
                                />
                            );
                        return null;
                    })}
                </Switch>
            </ContentScrollSection>
        </div>
    );
};
