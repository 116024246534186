import { Route, Switch } from 'react-router-dom';
import React from 'react';

type CallbackProps = {
    routes: any[];
    spreads: any;
};

export const Callback = (props: CallbackProps) => {
    return (
        <Switch>
            {props.routes.map((prop, key) => {
                if (!prop.directLink)
                    return <Route path={prop.path} key={key} render={(props) => <prop.component {...props} />} />;
                return null;
            })}
        </Switch>
    );
};
