import { TipoPais } from 'features/Incomes/domain/TipoPais';
import { ModalCliente } from 'features/Ingresos/Clientes/UI/ModalCliente/ModalCliente';
import { useCreateClient } from 'features/Ingresos/Clientes/UI/hooks';
import { transformLegacyClientDTOToClient } from 'features/Ingresos/Clientes/adapters/ClientAdapter';
import { LegacyClientDTO } from 'features/Ingresos/Clientes/adapters/LegacyClientDTO';
import {
    addStateNameToClient,
    addCountryNameToClient,
} from 'features/Ingresos/Clientes/services/AssignLocationNameToClient';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useGetStatesList, useGetCountriesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { Notifier } from 'shared/infra/utils/Notifier';
import { useVIESCheck } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/VIESChecker/hooks/useVIESCheck';
import { useAssignZeroVATByDefault } from '../../../Lines/hooks/useAssignZeroVATByDefault';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

export const CreateClientModal: React.FC<Props> = ({ isOpen, onClose }) => {
    const { setValue, getValues } = useCustomFormContext<InvoiceForm>();
    const { states } = useGetStatesList();
    const { countries } = useGetCountriesList();
    const { createClient } = useCreateClient();
    const { resetCheckVIES } = useVIESCheck();
    const { assignVATZeroByDefault } = useAssignZeroVATByDefault();

    function handleSubmit(args: { formData: LegacyClientDTO; tipoPais: TipoPais }) {
        console.log('data', args.formData, args.tipoPais);

        const clientTransformed = transformLegacyClientDTOToClient(args.formData);
        const clientWithState = addStateNameToClient(clientTransformed, states);
        const clientWithCountry = addCountryNameToClient(clientWithState, countries);

        if (!clientWithCountry) throw new Error('Client couldnt be transformed');

        if (clientWithCountry) {
            createClient.mutate(clientWithCountry, {
                onError: () => {
                    Notifier.error({
                        message: 'Inténtalo de nuevo más tarde.',
                        title: 'No ha sido posible crear el cliente',
                    });
                },
                onSuccess: (response) => {
                    if (!response.data) throw new Error('Client id not found');

                    const previousClient = getValues('client');

                    if (previousClient?.id) {
                        Notifier.notifyFeedBack({ title: 'Los datos del receptor se han actualizado' });
                    } else {
                        Notifier.success({ title: '', message: 'Cliente creado correctamente' });
                    }

                    setValue('client', {
                        ...clientWithCountry,
                        id: response.data,
                        fiscalId: clientWithCountry.fiscalId,
                    });
                    setValue('client.hasEUVatNumber', clientWithCountry.hasEUVatNumber ?? undefined);
                    setValue('withEquivalencySurcharge', null);
                    setValue('activity.withImageRights', undefined as unknown as boolean);
                    setValue('activity.forFattening', undefined as unknown as boolean);
                    resetCheckVIES();

                    assignVATZeroByDefault({ client: clientWithCountry });
                    onClose();
                },
            });
        }
    }

    return (
        <ModalCliente
            labelAction="Guardar cliente"
            formMode="create"
            isLoading={createClient.isPending}
            isOpen={isOpen}
            cliente={{} as any}
            closeModal={onClose}
            onSubmit={handleSubmit}
        />
    );
};
