import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { VIESResponseDTO } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/VIES/VIESRepositoryInterface';
import { createVIESRepository } from 'features/Ingresos/SalesDocument/IngresoConFactura/infra/VIESRepository';
import { createVIESService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/VIESService';

export type VIESContextType = {
    isLoading: boolean;
    error: Error | null;
    isVIESValid: boolean | null;
    checkVIES: (args: { countryCode: string; fiscalId: string | null }) => Promise<void>;
    triggerVIESError: (args: { typeError: 'not-valid' | 'service-down' }) => void;
    resetCheckVIES: () => void;
};

const VIESContext = createContext<VIESContextType | undefined>(undefined);

export const VIESProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<VIESResponseDTO | null>(null);

    const service = useMemo(() => createVIESService(createVIESRepository()), []);

    const checkVIES = useCallback(
        async (args: { countryCode: string; fiscalId: string | null }) => {
            if (!args.countryCode || !args.fiscalId) {
                setError(new Error('Country code and VAT number are required'));
                return;
            }

            setIsLoading(true);
            setError(null);

            try {
                const result = await service.checkVAT({ countryCode: args.countryCode, VAT: args.fiscalId });
                setData(result.data ?? null);
            } catch (err) {
                setError(err instanceof Error ? err : new Error('An unknown error occurred'));
            } finally {
                setIsLoading(false);
            }
        },
        [service],
    );

    const triggerVIESError: VIESContextType['triggerVIESError'] = useCallback((args) => {
        if (args.typeError === 'not-valid') {
            setData({ isValid: false });
        } else {
            setError(new Error('VIES service down'));
        }
    }, []);

    const resetCheckVIES = useCallback(() => {
        setIsLoading(false);
        setError(null);
        setData(null);
    }, []);

    const value = useMemo(
        () => ({
            isLoading,
            error,
            triggerVIESError,
            isVIESValid: data?.isValid ?? null,
            checkVIES,
            resetCheckVIES,
        }),
        [isLoading, error, triggerVIESError, data?.isValid, checkVIES, resetCheckVIES],
    );

    return <VIESContext.Provider value={value}> {children} </VIESContext.Provider>;
};

export const useVIESCheck = () => {
    const context = useContext(VIESContext);
    if (context === undefined) {
        throw new Error('useVIESCheck must be used within a VIESProvider');
    }
    return context;
};
