import { useState } from 'react';

export const useModal = () => {
    const [show, setShow] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [busy, setBusy] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const dispatchAction = async (action: () => Promise<any>) => {
        setBusy(true);
        await action();
        setBusy(false);
    };

    const closeModal = () => {
        setShow(false);
    };

    const showModal = () => {
        setShow(true);
    };

    const disableAction = () => {
        setDisabled(true);
    };

    const enableAction = () => {
        setDisabled(false);
    };

    return {
        show,
        showModal,
        closeModal,
        busy,
        setBusy,
        dispatchAction,
        isDisabled,
        disableAction,
        enableAction,
        loadingData,
        setLoadingData: (isLoading: boolean) => {
            setLoadingData(isLoading);
            setBusy(isLoading);
        },
    };
};

export type TUseModal = ReturnType<typeof useModal>;
