import DocumentValidator from 'shared/services/DocumentValidator';

export default class ClienteValidationErrors {
    static getCountryRequired(): string {
        return 'Debes seleccionar un país';
    }

    static getTypeRequired(): string {
        return 'Debes seleccionar un tipo de cliente';
    }

    static getNifIvaRequired(): string {
        return 'Debes seleccionar una opción';
    }

    static getCifRequired(): string {
        return 'Debes introducir el identificador fiscal de tu cliente';
    }

    static getDefaultMessageRequired(): string {
        return 'Este campo es obligatorio';
    }

    static getCifFormatES(type: string): string {
        if (type === 'Particular') return 'El NIF/NIE tiene un formato erróneo';
        if (type === 'Autónomo') return 'El NIF/NIE tiene un formato erróneo';
        if (type === 'Empresa') return 'El NIF empresa tiene un formato erróneo';
        return 'El identificador fiscal tiene un formato erróneo';
    }

    static getCifIncorrectEUPrefix(country: string): string {
        return (
            'El NIF IVA tiene que empezar con el ISO del país ' +
            DocumentValidator.getCifCountryCode(country) +
            'XXXXXXX...'
        );
    }

    static getCifFormatEU(type: string): string {
        if (type === 'Empresa') return 'El NIF IVA tiene un formato erróneo';
        if (type === 'Autónomo') return 'El NIF IVA tiene un formato erróneo';
        return 'El identificador fiscal tiene un formato erróneo';
    }

    static getNameRequired() {
        return 'Debes introducir el nombre o razón social de tu cliente';
    }

    static getPhoneRequired() {
        return 'Debes introducir el teléfono de tu cliente';
    }
}
