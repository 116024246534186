import { Box, Header, P } from '@declarando/design_system';
import Img from './assets/exclamation-circle.svg';
import { useEffect } from 'react';
export const CertificadoPagoNoOK = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = '/';
        }, 3000);
    }, []);

    return (
        <Box
            alignCenter
            p={'xxl'}
            bg={'white'}
            height={'100vh'}
            style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh' }}
        >
            <Box p="xxl">
                <img src={Img} alt="Procesando pago" />
                {/* <Icon name="exclamation-circle" textColor="#FF0000" /> */}
                <Header as={'h2'} mt={'sm'} mb={'xs'}>
                    Ha ocurrido un problema con el pago.
                </Header>
                <P>Revisa la validez y los fondos de tu tarjeta y vuelve a intentarlo más tarde.</P>
            </Box>
        </Box>
    );
};
