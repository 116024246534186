import { Invoice, InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { IngresoConFacturaClientRulesService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaRulesService';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';

export const useImageRightsProgressionAndApiTrigger = (args: {
    client: Invoice['client'];
    activity: Invoice['activity'];
}) => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const watchImageRights = useCustomWatch({ control, name: 'activity.withImageRights' });

    const isImageRightsRequired = IngresoConFacturaClientRulesService.isImageRightsRequired({
        country: args.client.country,
        clientType: args.client.clientType,
        activityType: args.activity.activityType,
    });

    if (!isImageRightsRequired) return true;

    if (isImageRightsRequired && watchImageRights !== undefined) return true;

    return false;
};
