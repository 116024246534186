import { useFetch } from 'shared/UI/hooks/QueryHooks';
import { createLocationService } from '../../services/LocationService';
import { createLocationsRepository } from '../../infra/LocationsRepository';
import { longLivedQueryConfig } from 'shared/UI/config/react-query-config';
import { transformStatesDTO } from '../../adapters/LocationsAdapter';

export const useGetStatesList = () => {
    const service = createLocationService(createLocationsRepository());

    const context = useFetch({
        queryKey: ['states'],
        fetchFn: () => service.getStates(),
        config: {
            ...longLivedQueryConfig,
            select: (data) => ({ ...data, data: data?.data ? transformStatesDTO(data.data) : [] }),
        },
    });

    return { states: context.data?.data || [], isLoading: context.isLoading };
};
