import { usePost } from 'shared/UI/hooks/QueryHooks';
import { SalesDocumentType } from '../SalesDocumentFormView/context/SalesDocumentFormProvider';
import { SalesDocumentSendEmailServiceFactory } from '../../services/SalesDocumentSendEmailServiceFactory';

const QueryKeyMapper: Record<SalesDocumentType, string> = {
    invoice: 'invoice-email',
    quote: 'quote-email',
};

export const useSendEmailWithSalesDocument = (args: { salesDocumentType: SalesDocumentType }) => {
    const service = SalesDocumentSendEmailServiceFactory.create(args.salesDocumentType);

    const sendEmailWithSalesDocument = usePost<{ salesDocumentId: string; email: string }, void>({
        queryKey: [QueryKeyMapper[args.salesDocumentType]],
        mutationFn: ({ salesDocumentId, email }) => service.sendEmail({ email, documentId: salesDocumentId }),
    });

    return {
        sendEmailWithSalesDocument,
    };
};
