import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useEffect } from 'react';
import DateWrapper from 'shared/infra/utils/Date';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { useInvoiceDateValidation } from '../../hooks/useInvoiceDateValidation';
import { IssueDateInput } from '../IssueDateInput';

export const IssueDateAutomaticNumberingCreationMode = () => {
    const { control, setValue, trigger, formState } = useCustomFormContext<InvoiceForm>();

    const watchIssueDate = useCustomWatch({ control, name: 'issueDate' });
    const formattedDate = watchIssueDate ? DateWrapper.formatNativeDateToString(watchIssueDate, 'yyyy-MM-dd') : null;

    const { ingresosConFacturaList, isLoading } = useInvoiceDateValidation({
        formattedDate,
    });

    useEffect(() => {
        if (isLoading || !ingresosConFacturaList) return;

        const lastInvoice = ingresosConFacturaList[0];
        setValue('minValidIssueDate', lastInvoice?.issueDate ?? null);

        if (formState.dirtyFields?.issueDate) {
            trigger('issueDate');
        }
    }, [formState.dirtyFields?.issueDate, setValue, ingresosConFacturaList, isLoading, trigger]);

    return <IssueDateInput />;
};
