import { Country } from 'features/Ingresos/Clientes/domain/Client';
import { addCountryNameToClient } from 'features/Ingresos/Clientes/services/AssignLocationNameToClient';
import { Invoice } from '../../domain/Invoice';
import { useCreateIngreso } from '../IngresoConFacturaCreationView/hooks/useCreateIngreso';
import { IngresoConFacturaBaseView } from '../IngresoConFacturaCommonView/IngresoConFacturaCommonView';

export const IngresoConFacturaDuplicationView = () => {
    const { handleSubmit, isMutationPending } = useCreateIngreso();

    const customizeDefaultValues = (invoiceData: Invoice, countries: Country[], userStatus: any) => ({
        ...invoiceData,
        //NOTE: Passing the documentNumber to avoid bug in the form which doesn't expand in the first painting if the documentNumber is empty
        documentNumber: userStatus?.autoNumInvoice ? invoiceData.documentNumber : '',
        id: '',
        client: addCountryNameToClient(invoiceData.client, countries),
    });

    return (
        <IngresoConFacturaBaseView
            mode="create"
            title="Nuevo ingreso con factura"
            handleSubmit={handleSubmit}
            isMutationPending={isMutationPending}
            customizeDefaultValues={customizeDefaultValues}
        />
    );
};
