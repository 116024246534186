import GastosConFacturaTypes from '../../../shared/domain/contabilidad/GastosConFacturaTypes';
import { IAEType } from '../../../shared/domain/fiscalStatus/iae/IAEType';
import { ReglasDeducibilidadMapper, TReglasDeducibilidad } from './ReglasDeducibilidad';
import { TTipado } from '../../../shared/TipadoType';

export const reglasVehiculoPropio = {
    isVehiculoPropio(tipado: TTipado): boolean {
        return tipado === GastosConFacturaTypes.SPANISH_VEHICULO_PROPIO;
    },

    getDeducibilidad(iae: IAEType): TReglasDeducibilidad {
        if (iae?.iaeDeducible100) {
            return {
                mapper: ReglasDeducibilidadMapper.vehiculoPropioFixed,
                type: 'fixed',
                value: 100,
                info: 'Como tu actividad requiere de un vehículo para su desarrollo, el gasto es deducible al 100%',
            };
        }
        return {
            mapper: ReglasDeducibilidadMapper.vehiculoPropioEditable,
            type: 'editable',
            min: 50,
            value: 50,
            hideQuestion: true,
            info: 'Como tu actividad no requiere de un vehículo para su desarrollo, puedes deducirte un 50% ampliable hasta un 100% de manera voluntaria',
            infoLink: { label: 'Quiero analizar mi gasto', link: '/gasto/asistente/' },
            selectReason: true,
        };
    },
};
