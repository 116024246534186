import V2Repository from 'shared/infra/repositories/V2Repository';
import { SalesDocumentPDFRepository } from '../../shared/domain/SalesDocumentPDF';

class InvoicePDFRepository extends V2Repository implements SalesDocumentPDFRepository {
    getDocumentPDF(id: string): Promise<any> {
        return this.getBlobResponse(`/invoices/${id}/document`);
    }
}

export const createInvoicePDFRepository = (): SalesDocumentPDFRepository => {
    return new InvoicePDFRepository();
};
