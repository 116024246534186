import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { createContext, useContext } from 'react';

export type SalesDocumentFormMode = 'create' | 'edit';

export type SalesDocumentType = 'quote' | 'invoice';

export type FormBlocked = {
    isFormBlocked: boolean;
    blockedReason?: 'contabilidad-bloqueada' | 'quote-converted-to-invoice';
};

export type SalesDocumentFormContextValue = {
    salesDocumentMode: SalesDocumentFormMode;
    salesDocumentType: SalesDocumentType;
    defaultValues?: InvoiceForm;
    formBlocked: FormBlocked;
};

type SalesDocumentFormProviderProps = {
    children: React.ReactNode;
} & SalesDocumentFormContextValue;

const SalesDocumentFormContext = createContext<SalesDocumentFormContextValue | undefined>(undefined);

export const useSalesDocumentFormContext = () => {
    const context = useContext(SalesDocumentFormContext);
    if (!context) {
        throw new Error('useSalesDocumentFormContext must be used within an SalesDocumentFormProvider');
    }
    return context;
};

export const SalesDocumentFormProvider = ({
    children,
    salesDocumentMode,
    salesDocumentType,
    defaultValues,
    formBlocked,
}: SalesDocumentFormProviderProps) => {
    const value = {
        salesDocumentMode,
        salesDocumentType,
        defaultValues,
        formBlocked,
    };

    return <SalesDocumentFormContext.Provider value={value}>{children}</SalesDocumentFormContext.Provider>;
};
