import React, { useReducer } from 'react';
import FiscalDates from '../infra/utils/FiscalDates';

const TASK_ID = {
    ADD_CUOTA: 'ADD_CUOTA',
    PRESENT_303C: 'PRESENT_303C',
    LAND_REGISTRY_RENT_LOCAL_180: 'LAND_REGISTRY_RENT_LOCAL_180',
    AJUSTE_CONTABILIDAD_390: 'AJUSTE_CONTABILIDAD_390',
    CHEKC_C604: 'CHECK_604',
    PRESENTACION_RENTA: 'PRESENTACION_RENTA',
};

export const useTasksListStore = () => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            return { ...state, ...action };
        },
        {
            tasks: [],
            selected: null,
            selectedTaskModelColor: 'white',
        },
    );

    const setSelected = (selected) =>
        dispatch({ selected, selectedTaskModelColor: getColor(selected ? selected.type : null) });
    const setTasks = (tasks) => dispatch({ tasks });

    const addTask = (stickyData) => {
        if (!stickyData || +stickyData.sticky === 0 || state.tasks.find((item) => item.id === stickyData.id)) {
            return;
        }
        const [text, description, ...notes] = stickyData.advice;
        const { message, ...data } = stickyData;
        const stickData = {
            text,
            description,
            notes,
            ...data,
        };
        setTasks([...state.tasks, stickData]);
    };

    const reset = () => {
        setSelected(null);
        setTasks([]);
    };

    const removeTask = (value, key = 'id') => {
        setTasks(state.tasks.filter((item) => item[key] !== value));
    };

    const addCuota = (_data, action) => {
        const data = {
            modelo: '303',
            id: TASK_ID.ADD_CUOTA,
            iconAction: 'edit',
            actionLabel: <>Añadir cuota a compensar</>,
            action,
            ..._data,
        };
        addTask(data);
    };

    const removeCouta = () => {
        removeTask(TASK_ID.ADD_CUOTA);
    };

    const addComplementaria = (_data, action) => {
        const data = {
            id: TASK_ID.PRESENT_303C,
            modelo: '303',
            iconAction: 'edit',
            actionLabel: <></>,
            ..._data,
            action,
        };
        addTask(data);
    };

    const addCheckC604 = (action) => {
        const data = {
            sticky: '1',
            type: 'info',
            action_type: 'action',
            advice: [
                'Modelo 100',
                'Recuerda guardar el valor de la casilla 604 con el valor que aparece en Hacienda. Así evitarás posibles problemas en la presentación de la Renta',
            ],
            id: TASK_ID.CHEKC_C604,
            modelo: '100',
            iconAction: 'edit',
            action,
            actionLabel: <>Revisar valor casilla 604</>,
        };
        addTask(data);
    };

    const addRentaPresentada = (action) => {
        const data = {
            sticky: '1',
            type: 'info',
            action_type: 'action',
            advice: [
                'Debes marcar como presentada tu declaración de la renta ' + (FiscalDates.getFiscalYear() - 1),
                'De esta manera sabremos que has completado correctamente la presentación del modelo 100',
            ],
            id: TASK_ID.PRESENTACION_RENTA,
            iconAction: 'check',
            action,
            actionLabel: <>Marcar renta como presentada</>,
        };
        addTask(data);
    };

    const addMissingDataForRentLocal = (_data, action) => {
        const data = {
            id: TASK_ID.LAND_REGISTRY_RENT_LOCAL_180,
            modelo: '180',
            sticky: '1',
            type: 'info',
            advice: [
                'Modelo 180',
                'Antes de presentar el modelo 180 revisa que los datos del proveedor sean correctos.',
            ],
            iconAction: 'edit',
            actionLabel: <>Revisar datos modelo 180</>,
            ..._data,
            action,
        };
        addTask(data);
    };

    const addAjusteContabilidad = (_data, action) => {
        const data = {
            id: TASK_ID.AJUSTE_CONTABILIDAD_390,
            type: 'info',
            advice: [
                'Se ha realizado un ajuste contable para presentar tus impuestos',
                'Tu contabilidad no coincidia con tus impuestos presentados y se ha añadido un gasto para solucionarlo',
            ],
            iconAction: 'folder-plus',
            actionLabel: <>Ver gasto</>,
            ..._data,
            action,
        };
        addTask(data);
    };

    const removeAjusteContabilidad = () => {
        removeTask(TASK_ID.AJUSTE_CONTABILIDAD_390);
    };

    const removeMissingDataForRentLocal = () => {
        removeTask(TASK_ID.LAND_REGISTRY_RENT_LOCAL_180);
    };

    const removeCheckC604 = () => {
        removeTask(TASK_ID.CHEKC_C604);
    };

    const removeRentaPresentada = () => {
        removeTask(TASK_ID.PRESENTACION_RENTA);
    };

    const select = (_select) => {
        setSelected(_select);
    };

    const unselect = () => {
        setSelected(null);
    };

    const getColor = (type) => {
        switch (type) {
            case 'info':
                return 'primary.Light10';
            case 'warning':
                return 'yellow.100';
            case 'error':
                return 'accent.Light10';
            default:
                return 'white';
        }
    };

    return {
        state,
        reset,
        removeTask,
        addCuota,
        removeCouta,
        addComplementaria,
        addCheckC604,
        addMissingDataForRentLocal,
        addAjusteContabilidad,
        removeAjusteContabilidad,
        removeMissingDataForRentLocal,
        removeCheckC604,
        select,
        unselect,
        addRentaPresentada,
        removeRentaPresentada,
    };
};
