import React, { useEffect } from 'react';
import { trackPageView } from 'shared/infra/utils/user_events/index';
type Props = { children: React.ReactNode; titulo?: string; tipo?: string; seccion?: string };
export const TrackPage = ({ children, titulo, tipo, seccion }: Props) => {
    useEffect(() => {
        if (!titulo || !tipo || !seccion) {
            return;
        }
        trackPageView({
            titulo,
            tipo,
            seccion,
        });
    }, [titulo]);
    return <>{children}</>;
};
