import { DeclarandoApiService } from '../../../shared/services/DeclarandoApiService';
import { OnboardingRepository } from './OnboardingRepository';

export class PedidoPagosService extends DeclarandoApiService {
    repository = new OnboardingRepository();

    async solicitudPagoCertificado() {
        return this.execute(() => this.repository.solicitudPagoCertificado());
    }

    async solicitudPagoContabilidad() {
        return this.execute(() => this.repository.solicitudPagoContabilidad());
    }
}
