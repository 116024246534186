import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import {
    GetNextNumberArgs,
    SalesDocumentNextNumberAutomaticRepository,
} from '../domain/SalesDocumentNextNumberAutomaticRepository/SalesDocumentNextNumberAutomaticRepository';

export abstract class SalesDocumentNextNumberAutomaticService extends DeclarandoApiService {
    constructor(private repository: SalesDocumentNextNumberAutomaticRepository) {
        super();
    }

    getNextNumber(args: GetNextNumberArgs) {
        return this.execute(() => this.repository.getNextNumber(args), { bodyAsData: true, shouldThrowError: true });
    }
}
