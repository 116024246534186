import { BaseNotification, NotificationSystem as NotificationSystemUI } from '@declarando/design_system';
import {
    useGetNotifications,
    useUpdateReadStatusNotification,
    useUpdateStatusNotification,
} from './hooks/notifications';
import { TNotification } from '../domain/models';
import { formatNotificationDate, getOriginName } from './utils';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';

const MAPPER_STATE: Record<TNotification['estado'], BaseNotification['state']> = {
    ANULADA: 'cancelled',
    CADUCADA: 'expired',
    IGNORADO: 'ignored',
    PENDIENTE: 'pending',
    COMPLETADO: 'completed',
};

const MAPPER_TYPE: Record<TNotification['tipo'], BaseNotification['type']> = {
    ALERTA: 'alert',
    CONSEJO: 'advice',
};

const transformNotificacionDTO = (notificaciones: TNotification[]): BaseNotification[] => {
    if (!notificaciones) return [];

    return notificaciones.map((notificacion) => ({
        id: notificacion.notificacionId,
        origin: {
            url: notificacion.origenUrl ?? '',
            name: getOriginName(notificacion.origenTexto) ?? '',
        },
        state: MAPPER_STATE[notificacion.estado],
        initDate: formatNotificationDate(new Date(notificacion.fechaInicio)),
        type: MAPPER_TYPE[notificacion.tipo],
        title: notificacion.titulo,
        description: notificacion.descripcion,
        isRead: notificacion.leida,
    }));
};

export const NotificationSystem = ({ popoverMobileTopPosition }: { popoverMobileTopPosition?: number }) => {
    useEffect(() => {
        const notificationTabEntrada = document.querySelector('.notificationSystemTab1');
        const notificationTabIgnoradas = document.querySelector('.notificationSystemTab2');

        const handleClickEntrada = () => {
            TrackingEvents.tab({ tab_label: 'Accede bandeja entrada notificaciones' });
        };
        const handleClickIgnoradas = () => {
            TrackingEvents.tab({ tab_label: 'Accede notificaciones ignoradas' });
        };

        notificationTabEntrada?.addEventListener('click', handleClickEntrada);
        notificationTabIgnoradas?.addEventListener('click', handleClickIgnoradas);

        return () => {
            notificationTabEntrada?.removeEventListener('click', handleClickEntrada);
            notificationTabIgnoradas?.removeEventListener('click', handleClickIgnoradas);
        };
    }, []);

    const { data, isLoading } = useGetNotifications();
    const { mutate: mutateStatus, isPending: isPendingMutationStatus } = useUpdateStatusNotification(
        (prevData, newData) => {
            const updatedData = prevData?.data?.map((notification) => {
                if (notification.notificacionId === newData.id) {
                    return { ...notification, estado: newData.estado };
                }
                return notification;
            });

            return { ...prevData, data: updatedData };
        },
    );

    const { mutate: mutateReadStatus, isPending: isPendingMutationReadStatus } = useUpdateReadStatusNotification(
        (prevData, newData) => {
            const updatedData = prevData?.data?.map((notification) => {
                if (notification.notificacionId === newData.id) {
                    return { ...notification, leida: newData.leida };
                }
                return notification;
            });

            return { ...prevData, data: updatedData };
        },
    );
    const history = useHistory();

    const handleClickIgnoreNotification = ({ id, isIgnored }: { id: string; isIgnored: boolean }) => {
        TrackingEvents.clickElement({
            event_label: isIgnored ? 'Recupera notificación' : 'Ignora notificación',
        });
        mutateStatus({ id, estado: isIgnored ? 'PENDIENTE' : 'IGNORADO' });
    };

    const handleClickToggleReadStatus = ({ id, isRead }: { id: string; isRead: boolean }) => {
        TrackingEvents.clickElement({
            event_label: isRead ? 'Marca notificación como no leída' : 'Marca notificación como leída',
        });
        mutateReadStatus({ id, leida: !isRead });
    };

    const handleClickOrigin = (origin: BaseNotification['origin']) => {
        TrackingEvents.clickElement({ event_label: 'Accede origen notificación' });
        history.push(origin.url);
    };

    const handleClickCardBodyLink = () =>
        TrackingEvents.clickElement({ event_label: 'Accede enlace texto notificacion' });

    return (
        <NotificationSystemUI
            handleClickCardBodyLink={handleClickCardBodyLink}
            isLoading={isLoading || isPendingMutationStatus || isPendingMutationReadStatus}
            notifications={transformNotificacionDTO(data?.data ?? [])}
            handleClickOrigin={handleClickOrigin}
            handleIgnoreNotification={handleClickIgnoreNotification}
            handleToggleReadStatus={handleClickToggleReadStatus}
            popoverMobileTopPosition={popoverMobileTopPosition}
        />
    );
};
