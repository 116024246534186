import { SelectorGroup } from '@declarando/design_system';
import { ClientOption } from '../ClientSelection';

export const ClientList: React.FC<{
    clientOptions: ClientOption[];
    setSelectedIds: (ids: string[]) => void;
    infiniteScrollTriggerRef: (node: HTMLDivElement | null) => void;
}> = ({ clientOptions, setSelectedIds, infiniteScrollTriggerRef }) => (
    <>
        {clientOptions.length > 0 && (
            <SelectorGroup
                label="Todos mis clientes"
                selectors={clientOptions}
                orientation="vertical"
                onChange={setSelectedIds}
                infiniteScrollTriggerRef={infiniteScrollTriggerRef}
            />
        )}
    </>
);
