import { createContext } from 'react';
import { useStep3 } from './useStep3';

export const Step3Context = createContext({} as ReturnType<typeof useStep3>);

export const Step3ContextProvider = ({ children }: { children: React.ReactNode }) => {
    const step3 = useStep3();

    return <Step3Context.Provider value={step3}>{children}</Step3Context.Provider>;
};
