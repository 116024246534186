import { useContext, useEffect, useState } from 'react';
import { OnboardingSteps, ValueOfOnboardingSteps } from '../../domain/OnboardingSteps';
import { RootContext } from 'shared/UI/context/RootContext/RootContext';
import { TSiguentePasoArgs } from '../../services/OnboardingRepository';
import { initialOnboardingNavigationList } from './initialOnboardingNavigationList';
import { StepNavigationMapper } from '../StepNavigationMapper';
import { Notifier } from 'shared/infra/utils/Notifier';
import { useRetrocederPaso } from '../../services/useRetrocederPaso';
import { useSiguientePaso } from '../../services/useSiguientePaso';
import { OnboardingService } from '../../services/OnboardingService';

export type TNoAFFANavigationKey = keyof typeof initialOnboardingNavigationList | 0;

export const useOnboarding = () => {
    const { onboarding, setOnboarding } = useContext(RootContext);
    const [busy, setBusy] = useState(false);
    const [currentStep, setCurrentStep] = useState<ValueOfOnboardingSteps>(onboarding.step);
    const [steps, setSteps] = useState({ ...initialOnboardingNavigationList });

    const reload = () => (window.location.href = '/');

    useEffect(() => {
        if (!onboarding.isActive) {
            reload();
        }
        const step = StepNavigationMapper[currentStep];
        setStepActive(step);
        // changeUrl();
    }, [currentStep]);

    const setInfoTagStep = (infoTag: string) => {
        const stepKey = StepNavigationMapper[currentStep];
        if (stepKey === 0) {
            return;
        }
        const updatedSteps = { ...steps };
        updatedSteps[stepKey].infoTag = infoTag;
        setSteps(updatedSteps);
    };

    const setStepActive = (stepKey: TNoAFFANavigationKey) => {
        const updatedSteps = { ...steps };
        Object.keys(updatedSteps).forEach((key) => {
            const keyNumber = +key as TNoAFFANavigationKey;
            if (keyNumber !== stepKey && stepKey !== 0) {
                updatedSteps[stepKey].active = false;
                if (keyNumber && keyNumber < stepKey) {
                    updatedSteps[keyNumber].check = true;
                }
            }
        });
        if (currentStep !== OnboardingSteps.CONTABILIDAD_REVISADA && currentStep !== OnboardingSteps.FINALIZADO) {
            if (stepKey !== 0) updatedSteps[stepKey].active = true;
        }

        setSteps(updatedSteps);
    };

    const setStepCheck = (stepKey: TNoAFFANavigationKey) => {
        const updatedSteps = { ...steps };
        if (stepKey === 0) return;
        updatedSteps[stepKey].active = false;
        updatedSteps[stepKey].check = true;

        setSteps(updatedSteps);
    };

    const setCheckCurrentStep = () => {
        const step = StepNavigationMapper[currentStep];
        setStepCheck(step);
    };

    const { mutate: siguientePaso } = useSiguientePaso();
    const { mutate: retrocederpaso } = useRetrocederPaso();

    const getOnboardingState = async () => {
        const { data } = await new OnboardingService().getOnboarding();
        if (!data) {
            return Notifier.notifyError('No se pudo obtener el estado');
        }
        if (data?.isActive === false) {
            return (window.location.href = '/');
        } else {
            return setCurrentStep(data.step);
        }
    };

    return {
        siguintePaso: (arg?: TSiguentePasoArgs) => {
            setBusy(true);
            siguientePaso(arg as TSiguentePasoArgs, {
                onSuccess: ({ data, message }) => {
                    if (data) {
                        if (data.isActive === false) {
                            reload();
                        } else {
                            setCurrentStep(data.step);
                            setOnboarding({ isActive: true, step: data.step });
                        }
                    } else {
                        Notifier.notifyError(message ?? null);
                    }
                    setBusy(false);
                },
                onError: () => {
                    Notifier.notifyError();
                    setBusy(false);
                },
            });
        },
        retrocederPaso: () => {
            setBusy(true);
            retrocederpaso(null, {
                onSuccess: ({ data, message }) => {
                    if (data) {
                        if (data.isActive === false) {
                            reload();
                        } else {
                            setCurrentStep(data.step);
                            setOnboarding({ isActive: true, step: data.step });
                        }
                    } else {
                        Notifier.notifyError(message ?? null);
                    }
                    setBusy(false);
                },
                onError: () => {
                    Notifier.notifyError();
                    setBusy(false);
                },
            });
        },
        getOnboardingState,
        steps,
        busy,
        setBusy,
        currentStep,
        setCurrentStep,
        setCheckCurrentStep,
        setInfoTagStep,
    };
};
