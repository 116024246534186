import { RolesHelper } from 'shared/stores/RolesHelper';

export const InitialAppState = {
    hasAdminRole: false,
    verificationPhoneWeek: null,
    isPhoneVerified: false,
    isVerificationPhoneModalOpen: false,
    showMainLoader: true,
    loadingData: true,
    regularizarTarjeta: false,
    fechaRecurrenciaProgramada: null,
    userInfo: {
        id: '0',
        name: '',
        isAgent: 0,
        plan: {},
    },
    phoneValidated: {
        validado: true,
    },
    userIdentity: {
        email: '',
        firstName: '',
        iban: '',
    },
    userStatus: {
        iaes: [],
        digitalSign: false,
        prorrataPercent: 0,
        autoNumInvoice: false,
        maxIrpf: false,
    },
    userAddress: {},
    userWorkAddress: {
        sameAddress: '1',
    },
    popupGoProfile: false,
    popupPermissionsOpen: false,
    popupNotEnabledOpen: false,
    ahorro: 0,
    beneficio: 0,
    iaesChanged: false,
    roles: RolesHelper.retriveTmpRoles(),
    rememberedConfirmPhone: true,
    loadedUserInfo: false,
    onboarding: {
        isActive: false,
    },
};
