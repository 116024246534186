import { ContentContainer, Grid, Header, Section } from '@declarando/design_system';

export const Versiones = () => {
    return (
        <Grid
            margin="md"
            justifyContent="space-between"
            p="lg"
            pb={0}
            bg="white"
            gridTemplateColumns={['1fr', 'max-content max-content']}
        >
            <ContentContainer>
                <Section>
                    <Header as="h3">Releases</Header>
                    <ul>
                        <li>
                            <b>3.22</b> Jan 13, 2025 - Mejora en ingresos con factura y gastos del dashboard
                        </li>
                        <li>
                            <b>3.21</b> Oct 16, 2024 - Mejora en la sincronización de facturas y en el registro de logs
                        </li>
                        <li>
                            <b>3.18.0</b> June 27, 2024 - Actualización y mejora de impuestos
                        </li>
                        <li>
                            <b>3.17.0</b> June 2, 2024 - Nuevo proceso de setup desde la plataforma
                        </li>
                        <li>
                            <b>3.16.5</b> May 8, 2024 - El usuario podrá indicar si es autónomo o no y un código
                            promocional en la contratación
                        </li>
                        <li>
                            <b>3.16.4</b> April 24, 2024 - El usuario ya se puede deducir los gastos de casa, impuestos,
                            tasas y comunidad de propietarios
                        </li>
                        <li>
                            <b>3.16.3</b> April 9, 2024 - El usuario ya puede ver sus facturas de Declarando
                        </li>
                        <li>
                            <b>3.16.2</b> March 25, 2024 - El usuario ya se puede deducir sus gastos de vehículo
                        </li>
                        <li>
                            <b>3.16.1</b> February 21, 2024 - El usuario ya puede ver consejos en el sistema de
                            notificaciones
                        </li>
                        <li>
                            <b>3.16.0 </b> February 7, 2024 - Actualización de versiones de la plataforma{' '}
                        </li>
                        <li>
                            <b>3.15.3 </b> January 31, 2024 - La usuaria tendrá clasificados sus gastos de Declarando en
                            formas de pago y en su listado de gastos{' '}
                        </li>
                        <li>
                            <b>3.15.2 </b> January 30, 2024 - El usuario ya puede informar y deducirse correctamente sus
                            gastos de dietas comidas{' '}
                        </li>
                        <li>
                            <b>3.15.1 </b> January 9, 2024 - El usuario se deducirá al 100% sus Gastos de Dietas
                            (subtipos Comidas con clientes y Hoteles y otros alojamientos)
                        </li>
                        <li>
                            <b>3.15.0 </b> January 2, 2024 - La usuaria podrá ver su objetivo anual de ahorro y sus 5
                            gastos más frecuentes según su profesión y lugar de trabajo (nuevo dashboard)
                        </li>
                        <li>
                            <b>3.14.7 </b> December 28, 2023 - La usuaria podrá informar su gasto del servicio de
                            Declarando
                        </li>
                        <li>
                            <b>3.14.6 </b> December 27, 2023 - El usuario NO podrá editar ni borrar el Proveedor
                            Declarando Asesores
                        </li>
                        <li>
                            <b>3.14.5 </b> December 26, 2023 - El usuario podrá registrar gastos con el mismo número de
                            factura pero distinto proveedor
                        </li>
                        <li>
                            <b>3.14.4 </b> December 19, 2023 - La usuaria podrá identificar gastos de Dietas con los
                            subtipos: hoteles y otros alojamientos, comidas y comidas con clientes para proveedores de
                            cualquier nacionalidad
                        </li>
                        <li>
                            <b>3.14.3 </b> December 12, 2023 - El usuario NO podrá pagar en efectivo su modelo 303
                        </li>
                        <li>
                            <b>3.14.2 </b> December 4, 2023 - El usuario ya sabrá cuando presenta el 303 y es negativo
                            que tiene que compensar o pedir la devolución antes de generar el TXT en el 4T
                        </li>
                        <li>
                            <b>3.14.1 </b> November 28, 2023 - El usuario ya no podrá presentar modelos anuales antes de
                            presentar sus modelos del 4T
                        </li>
                        <li>
                            <b>3.14.0 </b> Nov 27, 2023 - El usuario podrá validar el NIF IVA de su cliente/proveedor
                            europeo
                        </li>
                        <li>
                            <b>3.13.0 </b> Nov 6, 2023 - Mejora de la UX/UI de las tablas de movimientos y actualización
                            del sistema de diseño
                        </li>
                        <li>
                            <b>3.12.1 </b> Oct 3, 2023 - El usuario tendrá que informar obligatoriamente la dirección en
                            Clientes ESP, Clientes Canarios y Clientes EU con NIF IVA
                        </li>
                        <li>
                            <b>3.12.0 </b> Sep 26, 2023 - El usuario tendrá que informar obligatoriamente el NIF de
                            Clientes Particulares ESP y canarios desde el formulario de Clientes
                        </li>
                        <li>
                            <b>3.11.0 </b> Sep 6, 2023 - El usuario podrá deducirse correctamente los gastos de casa,
                            impuestos y tasas y comunidad de propietarios teniendo en cuenta dónde trabaja
                        </li>
                        <li>
                            <b>3.10.3 </b> Ago 30, 2023 - La administradora podrá desactivar / activar las reglas del
                            bloqueo de contabilidad de un trimestre concreto
                        </li>
                        <li>
                            <b>3.10.2 </b> Ago 21, 2023 - El usuario podrá hacer la simulación de su renta informando un
                            Plan de pensiones individual o colectivo
                        </li>
                        <li>
                            <b>3.10.1 </b> Ago 3, 2023 - La administradora podrá ver en Salesforce si el usuario ha
                            subido su primer ticket a la APP
                        </li>
                        <li>
                            <b>3.10.0 </b> Jul 20, 2023 - El usuario podrá financiar su suscripción con Declarando de
                            forma automatizada con FLOA
                        </li>
                        <li>
                            <b>3.9.3 </b> Jul 3, 2023 - El usuario puede identificar mejor las acciones del desplegable
                            de su perfil y ver sus datos personales más relevantes
                        </li>
                        <li>
                            <b>3.9.2 </b> Jun 5, 2023 - El usuario sabrá que va a informar un ingreso con factura
                            simplificada desde la acción principal y secundaria
                        </li>
                        <li>
                            <b>3.9.1 </b> May 24, 2023 - El usuario podrá marcar y subir los justificantes de los
                            modelos que ha presentado a Hacienda desde Impuestos
                        </li>
                        <li>
                            <b>3.9.0 </b> Abr 11, 2023 - El usuario podrá informar clientes particulares y autónomos
                            españoles con identificadores fiscales que empiecen por M
                        </li>
                        <li>
                            <b>3.8.1 </b> Mar 29, 2023 - El usuario podrá registrar informar gastos del tipo IVA de
                            Importación
                        </li>
                        <li>
                            <b>3.8.0 </b> Mar 7, 2023 - El sistema rellenará automáticamente el IRPF en función de la
                            Actividad y del Cliente seleccionados
                        </li>
                        <li>
                            <b>3.7.2 </b> Feb 13, 2023 - El usuario no podrá borrar sus actividades si tienen
                            contabilidad asociada
                        </li>
                        <li>
                            <b>3.7.1 </b> Ene 18, 2023 - Las administradoras de Declarando pueden descargar contabilidad
                            automáticamente
                        </li>
                        <li>
                            <b>3.7.0 </b> Dec 27, 2022 - El usuario que se da de baja podrá presentar sus últimos
                            modelos en Declarando
                        </li>
                        <li>
                            <b>3.6.0 </b> Dec 9, 2022 - El usuario puede recuperar el IVA de los tickets y convertirlos
                            en gastos
                        </li>
                        <li>
                            <b>3.5.1 </b> Dec 7, 2022 - El usuario puede añadir Ingresos de Particulares EU
                        </li>
                        <li>
                            <b>3.5.0 </b> Dec 7, 2022 - El usuario puede añadir Presupuestos de Particulares EU
                        </li>
                        <li>
                            <b>3.4.0 </b> Nov 29, 2022 - El usuario puede guardar sus Ingresos simplificados de menos de
                            400 euros de Particulares ESP
                        </li>
                        <li>
                            <b>3.3.0 </b> Nov 21, 2022 - Las administradoras de Declarando pueden subir contabilidad
                            automáticamente
                        </li>
                        <li>
                            <b>3.2.0 </b> Nov 20, 2022 - El usuario podrá presentar el modelo 390 aunque no pueda
                            generarlo
                        </li>
                        <li>
                            <b>3.1.1 </b> Nov 17, 2022 - El usuario “políticamente expuesto”que quiere la tarjeta
                            Declarando debe informar país y cargo
                        </li>
                        <li>
                            <b>3.1.0 </b> Nov 1, 2022 - El usuario puede tener una tarjeta Declarando
                        </li>
                        <li>
                            <b>3.0.0 </b> Nov 1, 2022 - El usuario puede guardar el tipo y la nacionalidad del cliente
                            por separado
                        </li>
                        <li>
                            <b>2.49.1 </b> Oct 20, 2022 - El usuario podrá aplicar un 5% en el IVA del gas
                        </li>
                        <li>
                            <b>2.49. </b> Oct 17, 2022 - El usuario podrá aplicar un 5% en el IVA de la luz
                        </li>
                    </ul>
                </Section>
            </ContentContainer>
        </Grid>
    );
};
