import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { OnboardingRepository, TImportarContabildadArgs, TSiguentePasoArgs } from './OnboardingRepository';
import { PedidoPagosService } from './PedidoPagosService';

export class OnboardingService extends DeclarandoApiService {
    repository = new OnboardingRepository();

    async siguientePaso(data?: TSiguentePasoArgs) {
        return this.execute(() => this.repository.siguientePaso(data));
    }

    async retrocederPaso() {
        return this.execute(() => this.repository.retrocederPaso());
    }

    async getOnboarding() {
        return this.execute(() => this.repository.getOnboarding());
    }

    async solicitudPagoCertificado() {
        return new PedidoPagosService().solicitudPagoCertificado();
    }

    async solicitudPagoContabilidad() {
        return new PedidoPagosService().solicitudPagoContabilidad();
    }

    async importarContabilidad(formData: TImportarContabildadArgs) {
        return this.execute(() => this.repository.importarContabilidad(formData));
    }
}
