import { Grid, Box } from '@declarando/design_system';
import { useGetNextSalesDocumentNumber } from './hooks/useGetNextSalesDocumentNumber';
import { SkeletonInput } from 'shared/UI/components/Skeletons/Input/SkeletonInput';
import { DueDate } from './components/DueDate';
import { SalesDocumentActivityDetails } from './components/SalesDocumentActivityDetails';
import { SalesDocumentNumber } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/SalesDocumentDetails/components/SalesDocumentNumber';
import { IssueDate } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/SalesDocumentDetails/components/IssueDate/IssueDate';

export const SalesDocumentDetails = () => {
    const { isLoading } = useGetNextSalesDocumentNumber();

    return (
        <Grid gridTemplateColumns={{ _: '1fr', mediumScreen: '1fr 1fr 1fr' }} gridRowGap="sm" gridColumnGap="sm">
            <SalesDocumentNumber />
            {isLoading ? <SkeletonInput labelHeight="22px" /> : <IssueDate />}
            {isLoading ? <SkeletonInput labelHeight="22px" /> : <DueDate />}
            <Box gridColumn={{ _: '', mediumScreen: '1 / span 2' }}>
                <SalesDocumentActivityDetails />
            </Box>
        </Grid>
    );
};
