import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useGetIngresosConFacturaList } from 'features/Ingresos/shared/UI/hooks/useGetIngresosConFacturaList';
import { useEffect } from 'react';
import DateWrapper from 'shared/infra/utils/Date';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { IssueDateInput } from '../IssueDateInput';

export const IssueDateAutomaticNumberingEditionMode = () => {
    const { control, setValue, trigger, getValues } = useCustomFormContext<InvoiceForm>();

    const watchIssueDate = useCustomWatch({ control, name: 'issueDate' });
    const formattedDate = DateWrapper.formatNativeDateToString(watchIssueDate, 'yyyy-MM-dd');

    const endOfYear = DateWrapper.formatNativeDateToString(DateWrapper.getEndOfYear(new Date()), 'yyyy-MM-dd');

    const startOfYear = DateWrapper.formatNativeDateToString(DateWrapper.getStartOfYear(new Date()), 'yyyy-MM-dd');

    const isIssueDateInTheSameYear = DateWrapper.isDateBetweenProvidedDates({
        date: formattedDate,
        from: startOfYear,
        to: endOfYear,
    });

    const {
        ingresosConFacturaList: ingresosConFacturaAfterLastIngreso,
        isLoading: isLoadingIngresosConFacturaAfterLastIngreso,
    } = useGetIngresosConFacturaList(
        {
            issueDateTo: endOfYear,
            documentNumberFrom: getValues('documentNumber'),
            series: 'default',
            sort: '+series',
            offset: 0,
            limit: 2,
        },
        { shouldTriggerCall: !!formattedDate && isIssueDateInTheSameYear },
    );

    const {
        ingresosConFacturaList: ingresosconFacturaUntilLastIngreso,
        isLoading: isLoadingIngresosConFacturaUntilLastIngreso,
    } = useGetIngresosConFacturaList(
        {
            issueDateFrom: startOfYear,
            documentNumberTo: getValues('documentNumber'),
            series: 'default',
            sort: '-series',
            offset: 0,
            limit: 2,
        },
        { shouldTriggerCall: !!formattedDate && isIssueDateInTheSameYear },
    );

    useEffect(() => {
        if (
            isLoadingIngresosConFacturaAfterLastIngreso ||
            isLoadingIngresosConFacturaUntilLastIngreso ||
            !ingresosConFacturaAfterLastIngreso ||
            !ingresosconFacturaUntilLastIngreso
        )
            return;

        const nextInvoice = ingresosConFacturaAfterLastIngreso[1];
        const lastInvoice = ingresosconFacturaUntilLastIngreso[1];
        setValue('minValidIssueDate', lastInvoice?.issueDate ?? startOfYear);
        setValue('maxValidIssueDate', nextInvoice?.issueDate ?? endOfYear);
        trigger('issueDate');
    }, [
        setValue,
        ingresosConFacturaAfterLastIngreso,
        isLoadingIngresosConFacturaAfterLastIngreso,
        trigger,
        endOfYear,
        startOfYear,
        ingresosconFacturaUntilLastIngreso,
        isLoadingIngresosConFacturaUntilLastIngreso,
    ]);

    return <IssueDateInput />;
};
