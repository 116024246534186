import { useSalesDocumentMutation } from 'features/Ingresos/SalesDocument/shared/UI/hooks/useSalesDocumentMutation';
import { useParams } from 'react-router-dom';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { useQuoteMutation } from './useQuoteMutation';

export const useEditQuote = () => {
    const { quoteUUID } = useParams<{ quoteUUID: string }>();
    const { editQuote } = useQuoteMutation();

    return useSalesDocumentMutation({
        mutation: editQuote,
        trackingEvent: () => TrackingEvents.clickElement({ event_label: 'Guarda presupuesto editado' }),
        successMessage: {
            title: 'Presupuesto actualizado correctamente',
            message: 'Puedes consultarlo en el listado de presupuestos.',
            link: { label: 'Ir al listado de presupuestos', href: '/ingresos/presupuestos' },
        },
        errorMessage: {
            title: 'No ha sido posible actualizar el presupuesto',
            message: 'Por favor, vuelve a intentarlo.',
        },
        getSuccessRedirectPath: () => `/ingreso/presupuesto-previsualizar/${quoteUUID}`,
    });
};
