import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useNIFIVAProgressionAndApiTrigger } from './use-nif-iva-progression-api-call';
import { useImageRightsProgressionAndApiTrigger } from './use-image-rights-progession-api-call';
import { useRancherForFatteningProgressionAndApiTrigger } from './use-rancher-fattening-progression-api-call';

/**
 * Determines whether to show the rest of the fields in a wizard-style form.
 * The form requires specific properties to be filled out first before displaying additional fields.
 *
 */
export const useShouldExpandForm = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const [documentNumber, issueDate, client, activity] = useCustomWatch({
        control,
        name: ['documentNumber', 'issueDate', 'client', 'activity'],
    });

    const requirementCheckNIFIVA = useNIFIVAProgressionAndApiTrigger({ client });
    const requirementCheckImageRights = useImageRightsProgressionAndApiTrigger({ client, activity });
    const requirementCheckRancherForFattening = useRancherForFatteningProgressionAndApiTrigger({ client, activity });

    const shouldShowRestOfFields = !!(
        documentNumber &&
        issueDate &&
        client.id &&
        activity.id &&
        requirementCheckNIFIVA &&
        requirementCheckImageRights &&
        requirementCheckRancherForFattening
    );

    return { shouldShowRestOfFields };
};
