import { InvoiceSummary } from '../domain/InvoiceSummary';
import { InvoiceSummaryDTO } from './InvoiceSummaryDTO';

export const toInvoiceSummary = (invoiceSummary: InvoiceSummaryDTO): InvoiceSummary => {
    return {
        documentNumber: invoiceSummary.documentNumber,
        issueDate: invoiceSummary.issueDate,
        taxBase: invoiceSummary.taxBase,
        total: invoiceSummary.total,
        invoiceId: invoiceSummary.invoiceId,
    };
};
