import DocumentValidator from 'shared/services/DocumentValidator';
import { defaultInputSettings } from 'shared/services/formulario/Formulario';
import { Errores, ValidationService } from 'shared/services/ValidationService';
import ClienteValidationErrors from '../../UI/ModalCliente/form/ClienteValidationErrors';
import Validators from '../../../../shared/infra/utils/Validators';

let currentCountry: string;
let currentType: string;

const validateCif = (
    currentType: string,
    currentCountry: string,
    nif: string,
    required: boolean,
    invalidValues?: string[],
) => {
    if (nif && invalidValues?.includes(nif))
        return 'Debes informar un identificador fiscal distinto al que aparece en tu perfil';
    if (!nif && !required) return Errores.noErrors;
    if (!Boolean(nif)) return ClienteValidationErrors.getCifRequired();
    if (currentCountry === 'ES') {
        if (
            (currentType !== 'Empresa' && !DocumentValidator.isValidDNI(nif)) ||
            (currentType === 'Empresa' && !DocumentValidator.isValidCIF(nif))
        ) {
            return ClienteValidationErrors.getCifFormatES(currentType);
        }
        return '';
    } else if (currentCountry !== 'ES' && nif.substring(0, 2) !== DocumentValidator.getCifCountryCode(currentCountry)) {
        return ClienteValidationErrors.getCifIncorrectEUPrefix(currentCountry);
    } else if (currentCountry !== 'ES' && !DocumentValidator.isValidEuroCIF(nif)) {
        // TODO function that returns if is European country
        return ClienteValidationErrors.getCifFormatEU(currentType);
    }
    return Errores.noErrors;
};

export const ClienteSchema = {
    pais: {
        ...defaultInputSettings(),
        validate: (pais: string, required: boolean) => {
            currentCountry = pais;
            if (!pais && !required) return Errores.noErrors;
            if (!Boolean(pais)) return ClienteValidationErrors.getCountryRequired();
            return Errores.noErrors;
        },
        label: 'País en el que opera tu cliente',
    },
    type: {
        ...defaultInputSettings(),
        validate: (type: string, required: boolean) => {
            currentType = type;
            if (!type && !required) return Errores.noErrors;
            if (!Boolean(type)) return ClienteValidationErrors.getTypeRequired();
            return Errores.noErrors;
        },
        label: 'Tipo de cliente',
    },
    nifIva: {
        ...defaultInputSettings(),
        validate: (nifIva: string, required: boolean) => {
            if (!nifIva && !required) return Errores.noErrors;
            if (!Boolean(nifIva)) return ClienteValidationErrors.getNifIvaRequired();
            return Errores.noErrors;
        },
        label: '¿Tu cliente tiene NIF IVA?',
    },
    cif: {
        ...defaultInputSettings(),
        invalidValues: [],
        validate: (nif: string, required: boolean, invalidValues?: string[]) =>
            validateCif(currentType, currentCountry, nif, required, invalidValues),
        label: 'Identificador fiscal del cliente',
    },
    nombre: {
        ...defaultInputSettings(),
        validate: (nombre: string, required: boolean) => {
            if (!nombre && !required) return Errores.noErrors;
            if (!Boolean(nombre)) return ClienteValidationErrors.getNameRequired();
            return Errores.noErrors;
        },
        label: 'Nombre o razón social',
    },
    phone: {
        invalidValues: [],
        label: 'Teléfono',
        required: true,
        visible: true,
        value: { number: null, prefix: 34 },
        error: Errores.noErrors,
        validate: (phone: { prefix: unknown; number: unknown }, required: boolean) => {
            if (!Boolean(phone.number) && !required) return Errores.noErrors;
            if (!Boolean(phone.number) && required) return ClienteValidationErrors.getPhoneRequired();
            if (!Validators.isValidPrefix(phone.prefix)) {
                return 'El prefijo no es válido';
            }
            if (!Validators.isValidPhone(phone.prefix, phone.number, true)) return 'El teléfono no es válido';
            return Errores.noErrors;
        },
    },
    email: { ...defaultInputSettings(false), label: 'Email', validate: ValidationService.email },
    provincia: {
        ...defaultInputSettings(false),
        label: 'Provincia',
        validate: (value: string, required: boolean) => {
            if (!value && required) {
                return 'Campo provincia obligatorio';
            } else {
                return Errores.noErrors;
            }
        },
    },
    poblacion: {
        ...defaultInputSettings(false),
        label: 'Población',
        validate: (value: string, required: boolean) => {
            if (!value && required) {
                return 'Campo población obligatorio';
            } else {
                return Errores.noErrors;
            }
        },
    },
    cp: {
        ...defaultInputSettings(false),
        label: 'Código postal',
        validate: (cp: string, required: boolean) => {
            if (!cp && required) {
                return 'Campo código postal obligatorio';
            } else {
                return Errores.noErrors;
            }
        },
    },
    direccion: {
        ...defaultInputSettings(false),
        label: 'Dirección',
        validate: (direccion: string, required: boolean) => {
            if ((!direccion || direccion.trim() === '') && required) {
                return 'Campo dirección obligatorio';
            } else {
                return Errores.noErrors;
            }
        },
    },
    isCanario: {
        ...defaultInputSettings(false),
        validate: (value: boolean, required: boolean) => {
            if (![true, false].includes(value) && !required) return Errores.noErrors;
            if (![true, false].includes(value)) return ClienteValidationErrors.getDefaultMessageRequired();
            return Errores.noErrors;
        },
        label: 'Este cliente opera en Canarias',
    },
};
