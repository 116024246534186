import Date from '../../../infra/utils/Date';
import DateWrapper from '../../../infra/utils/Date';
import { IAEType } from '../../fiscalStatus/iae/IAEType';
import { IAEChecker } from '../../fiscalStatus/iae/IAEChecker';
import { InvoiceDataIngreso } from '../../../../features/Incomes/domain/InvoiceDataIngreso';

export type TIRPF_VALUES = (typeof IRPF_TYPES)[keyof typeof IRPF_TYPES];

export const IRPF_TYPES = {
    MAXIMO: 19,
    GENERAL: 15,
    REDUCIDO: 7,
    MINIMO: 2,
    NONE: 0,
    SUBSET_NONE: 0,
    SUBSET_NORMAL: 1,
    SUBSET_ALQUILER: 2,
    ALQUILER_LOCAL: 19,
    DERECHOS_IMAGEN: 24,
    GANADERIA_ENGORDE: 1,
} as const;

export enum TiposIAE {
    alquiler = 'alquiler',
    ganadero = 'ganadero', // DEPRECATED
    empresarial = 'empresarial',
    artistico = 'artistico',
    profesional = 'profesional',
    ganaderoEngorde = 'ganaderoEngorde',
    ganaderoNoEngorde = 'ganaderoNoEngorde',
}

class IrpfTypes {
    // Deprecated: Usar IRPF_VALUES en lugar de los static
    static MAXIMO = IRPF_TYPES.MAXIMO;
    static GENERAL = IRPF_TYPES.GENERAL;
    static REDUCIDO = IRPF_TYPES.REDUCIDO;
    static MINIMO = IRPF_TYPES.MINIMO;
    static NONE = IRPF_TYPES.NONE;
    static SUBSET_NONE = IRPF_TYPES.SUBSET_NONE;
    static SUBSET_NORMAL = IRPF_TYPES.SUBSET_NORMAL;
    static SUBSET_ALQUILER = IRPF_TYPES.SUBSET_ALQUILER;
    static ALQUILER_LOCAL = IRPF_TYPES.ALQUILER_LOCAL;
    static DERECHOS_IMAGEN = IRPF_TYPES.DERECHOS_IMAGEN;
    static GANADERIA_ENGORDE = IRPF_TYPES.GANADERIA_ENGORDE;

    static toIntIrpf(irpf: string | number) {
        return +irpf as TIRPF_VALUES;
    }

    constructor(
        private altaDate: Date,
        private wantsGeneral: boolean,
        private forcedIrpf: number | null,
    ) {}

    getAutoIrpf(
        invoiceData: InvoiceDataIngreso,
        iae: IAEType,
        iaes: IAEType[] = [],
    ): { irpf: number; irpfMessage?: string; iaeSubgrupoNombre: TiposIAE | string; reducido: boolean } {
        if (this.forcedIrpf) {
            return { irpf: this.forcedIrpf, iaeSubgrupoNombre: '', irpfMessage: '', reducido: false };
        }
        const iaeChecker = new IAEChecker(iae);

        if (iaeChecker.isAlquilerDeViviendas()) {
            return {
                irpf: iaeChecker.getIrpfAlquilerDeViviendas(),
                iaeSubgrupoNombre: '',
                irpfMessage: '',
                reducido: false,
            };
        }

        const iaeSubgrupoNombre = iae.subgrupoNombre;
        const LIMITE_ANYOS = 3;
        let iaeGroup = iaes.filter((i) => [TiposIAE.artistico, TiposIAE.profesional].includes(i.subgrupoNombre));
        if (!iaeGroup.length) {
            iaeGroup = iaes.filter((i) => iae.subgrupoNombre === i.subgrupoNombre);
        }
        iaeGroup.sort((a, b) => {
            const fechaA = DateWrapper.parseStringToNativeDate(a.fechaAlta) as any;
            const fechaB = DateWrapper.parseStringToNativeDate(b.fechaAlta) as any;
            return fechaA - fechaB;
        });

        const firstOldIaeGroup = iaeGroup[0];
        const isReducido = this.getPlazo(invoiceData.date, firstOldIaeGroup.fechaAlta) < LIMITE_ANYOS;

        const typeResolver = () => {
            const isReducido = this.getPlazo(invoiceData.date, firstOldIaeGroup.fechaAlta) < LIMITE_ANYOS;
            return !isReducido || this.wantsGeneral ? IrpfTypes.GENERAL : IrpfTypes.REDUCIDO;
        };
        const artisticoImagenResolver = (esDerechoImagen: boolean) =>
            esDerechoImagen ? IrpfTypes.DERECHOS_IMAGEN : typeResolver();
        const ganaderoEngorderResolver = (engorde: boolean) =>
            engorde ? IrpfTypes.GANADERIA_ENGORDE : IrpfTypes.MINIMO;

        const resolverFunctions = {
            [TiposIAE.alquiler]: () => IrpfTypes.MAXIMO,
            [TiposIAE.ganadero]: () => IrpfTypes.MINIMO, // DEPRECATED
            [TiposIAE.ganaderoNoEngorde]: () => IrpfTypes.MINIMO,
            [TiposIAE.empresarial]: () => IrpfTypes.NONE,
            [TiposIAE.artistico]: () => artisticoImagenResolver(!!invoiceData?.esDerechoImagen),
            [TiposIAE.profesional]: typeResolver,
            [TiposIAE.ganaderoEngorde]: () => ganaderoEngorderResolver(!!invoiceData?.esEngorde),
        };

        const irpf = resolverFunctions[iaeSubgrupoNombre]();
        const reducido =
            [TiposIAE.artistico, TiposIAE.profesional, TiposIAE.ganaderoEngorde].includes(iaeSubgrupoNombre) &&
            !invoiceData?.esDerechoImagen &&
            !invoiceData?.esEngorde
                ? isReducido
                : false;
        return {
            irpf,
            iaeSubgrupoNombre,
            reducido,
        };
    }

    getPlazo(invoiceDate: string | Date, altaDate: string): number {
        const dateIni = Date.formatStringToDate(altaDate);
        const initYear = Date.getYear(dateIni) as any;
        let dateEnd = invoiceDate;
        if (typeof invoiceDate === 'string') {
            dateEnd = Date.formatStringToDate(invoiceDate);
        }
        const endYear = Date.getYear(dateEnd) as any;

        return endYear - initYear;
    }
}

export default IrpfTypes;
