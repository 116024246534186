import { Button } from '@declarando/design_system';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { useVIESCheck } from './hooks/useVIESCheck';
import { useSalesDocumentFormContext } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';

const useShouldBeButtonDisabled = () => {
    const { control, formState } = useCustomFormContext<InvoiceForm>();
    const { formBlocked } = useSalesDocumentFormContext();
    const fiscalId = useCustomWatch({ control, name: 'client.fiscalId' });

    const shouldBeButtonDisabled =
        !fiscalId || !!formState.errors?.client?.fiscalId?.message || formBlocked.isFormBlocked;

    return { shouldBeButtonDisabled };
};

export const VIESChecker = () => {
    const { shouldBeButtonDisabled } = useShouldBeButtonDisabled();
    const { control } = useCustomFormContext<InvoiceForm>();
    const [fiscalId, countryCode] = useCustomWatch({ control, name: ['client.fiscalId', 'client.country.code'] });
    const { checkVIES, isLoading } = useVIESCheck();

    return (
        <Button
            type="button"
            fill
            wrapLabel={false}
            isDisabled={shouldBeButtonDisabled}
            isLoading={isLoading}
            variant="secondary"
            loadingLabel="Comprobando..."
            label="Comprobar en el VIES"
            onClick={() => checkVIES({ countryCode, fiscalId })}
        />
    );
};
