export default class ExpensesLocationRepository {
    getExpensesUrl() {
        return '/gastos/gastos';
    }

    gotoModifyExpense(idfac, type) {
        switch (type) {
            case 'Sí':
                return '/gasto/con-factura/' + idfac;
            case 'No':
                return '/gasto/sin-factura/' + idfac;
            default:
                return false;
        }
    }

    goToNewExpensesAssistant(type = '') {
        return '/gasto/asistente/' + type;
    }

    getNewGastoUrl() {
        return '/gasto/nuevo';
    }

    getUrlPrintList(listType, year, period, client, invoiced, filterIae, filter) {
        let url =
            import.meta.env.VITE_API_URL +
            '/v2/files/listadoGastos/' +
            listType +
            '/' +
            year +
            '/' +
            period +
            '/' +
            client +
            '/' +
            invoiced +
            '/' +
            filterIae;
        if (filter) {
            url += '/' + filter;
        }
        return url;
    }

    getUrlPrintListFacturas(listType) {
        return import.meta.env.VITE_API_URL + '/v2/fichero/reporte/facturas/' + listType;
    }
}
