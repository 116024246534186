import { ComponentProps } from 'react';
import DateWrapper from './Date';
import { Tag } from '@declarando/design_system';

interface TagOptions {
    context?: 'navbar' | 'dashboard';
}

export const handleTagDependingOnDate = ({ context = 'navbar' }: TagOptions = {}): {
    children: string;
    variant: ComponentProps<typeof Tag>['variant'];
    size?: string;
} => {
    const now = DateWrapper.getNow();

    const month = now.month();
    const day = now.date();
    const year = now.year();
    const dateStr = `${year}-${month}-${day}`;

    let children: string;
    const size = context === 'dashboard' ? 'medium' : 'small';

    if (dateStr < '2025-3-22') {
        children = context === 'dashboard' ? '🗓 Inicio 22 abril' : 'Inicio 22 abril';
        return { children, variant: 'neutral', size };
    } else if (dateStr >= '2025-3-22' && dateStr <= '2025-5-20') {
        children = context === 'dashboard' ? '✏️ En periodo' : 'En periodo';
        return { children, variant: 'success', size };
    } else if (dateStr >= '2025-5-21' && dateStr <= '2025-5-30') {
        children = context === 'dashboard' ? '😱️️ Últimos días' : 'Últimos días';
        return { children, variant: 'notification', size };
    }
    return { children: '', variant: '', size };
};
