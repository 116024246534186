import React from 'react';
import { Box, MainLoader, Toast } from '@declarando/design_system';
import App from './App';
import { RootContextProvider } from 'shared/UI/context/RootContext/RootContext';
import { RolesHelper } from '../../stores/RolesHelper';
import LogoDeclarando from 'assets/img/logo_declarando_azul.svg';
import AuthenticatedRoute from '../components/Auth/AuthenticatedRoute';
import JWT from '../../infra/utils/JWT';
import { Switch } from 'react-router-dom';
import Intercom from 'shared/UI/components/External/Intercom';

class SetupNoAFFALayout extends App {
    get layout() {
        return 'SetupNoAFFALayout';
    }
    render() {
        this.spreads = {
            ...this.props,
        };

        const contextProperties = {
            ...this.spreads,
            userIdentity: this.state.userIdentity,
            userInfo: this.state.userInfo,
            userStatus: this.state.userStatus,
            userAddress: this.state.userAddress,
            userWorkAddress: this.state.userWorkAddress,
            storeUserIdentity: this.storeUserIdentity,
            storeUserStatus: this.storeUserStatus,
            storeUserAddress: this.storeUserAddress,
            storeUserWorkAddress: this.storeUserWorkAddress,
            rolesHelper: new RolesHelper(this),
            planHelper: this.planHelper,
            onboarding: this.state.onboarding,
            setOnboarding: this.setOnboarding,
        };
        return (
            <RootContextProvider value={contextProperties}>
                {this.state.showMainLoader ? <MainLoader /> : null}
                {this.state.loadedUserInfo && (
                    <Box style={{ maxWidth: 1440, margin: 'auto' }} p={{ _: 'sm', tablet: 'xl' }} pt={{ tablet: 24 }}>
                        <Box
                            display={{ _: 'flex', tablet: 'block' }}
                            justifyContent={'center'}
                            mb={{ _: 'sm', tablet: 'xl' }}
                        >
                            <img src={LogoDeclarando} alt="Logo declarando" width={200} />
                        </Box>
                        <Switch>
                            {this.props.routes.map((prop, key) => {
                                return (
                                    <AuthenticatedRoute
                                        getToken={JWT.getToken}
                                        path={prop.path}
                                        key={key}
                                        render={(props) => (
                                            <prop.component {...this.spreads} {...props} currentRoute={prop} />
                                        )}
                                    />
                                );
                            })}
                        </Switch>
                    </Box>
                )}
                <Toast ref="notifSystem" />

                {!this.state.loadingData && this.planHelper.isPaid() && (
                    <Intercom
                        userInfo={this.state.userInfo}
                        userIdentity={this.state.userIdentity}
                        userStatus={this.state.userStatus}
                        ahorro={this.state.ahorro}
                        beneficio={this.state.beneficio}
                    />
                )}
            </RootContextProvider>
        );
    }
}

export default SetupNoAFFALayout;
