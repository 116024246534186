import * as React from 'react';
import { useContext } from 'react';
import { AlertMessage, Box, ButtonSolid, Header, Link, Modal, P } from '@declarando/design_system';
import imageStep from '../assets/certificado-inicio.svg';
import { OnboardingContext } from '../../Onboarding';
import { Step1Context } from './Step1Context';
import {
    publishNoTengoCertificadoEvent,
    publishObtenerCertificadoConDeclarandoEvent,
    publishObtenerCertificadoPorMiCuentaEvent,
} from '../../../services/events';
import { publishTengoCertificadoEvent } from '../../../services/events/publishTengoCertificadoEvent';

export const Step1Initial = () => {
    const { solicitarPagoCertificadoAction, modal } = useContext(Step1Context);
    const { siguintePaso } = useContext(OnboardingContext);

    const noTengoNadaClick = () => {
        modal.showModal();
        publishNoTengoCertificadoEvent();
    };

    const tengoCertificadoClick = () => {
        siguintePaso();
        publishTengoCertificadoEvent();
    };

    const modalAction = () => {
        solicitarPagoCertificadoAction();
        publishObtenerCertificadoConDeclarandoEvent();
    };

    const modalClose = () => {
        modal.closeModal();
        publishObtenerCertificadoPorMiCuentaEvent();
    };

    return (
        <>
            <Box px={'sm'} mx={'auto'} py={'xxl'}>
                <Box alignCenter>
                    <Box mb="sm">
                        <img src={imageStep} alt={'Certificado'} />
                    </Box>
                    <Header as={'h2'}>¿Tienes certificado digital o Cl@ve PIN?</Header>
                    <P mt={'xs'}>
                        Para utilizar todas las funcionalidades de Declarando necesitas contar con un certificado
                        digital válido.
                    </P>
                </Box>
                <Box my={'lg'} mx={'auto'} paddingX={'xxs'} display={'flex'} justifyContent={'center'}>
                    <AlertMessage
                        shrink={false}
                        title={'¿Para qué necesito el certificado digital?'}
                        message={[
                            '1. Para importar tu documentación desde Hacienda / Seguridad Social y así configurar tu perfil.',
                            '2. Para presentar en Hacienda los modelos que solo permiten presentación online: 303, 349, 390, 347, 190, 180.',
                        ].map((reason, index) => (
                            <div key={index}>{reason}</div>
                        ))}
                    />
                </Box>
                <Box
                    display={{ _: 'grid', desktop: 'flex' }}
                    gridTemplateColumns={{ _: '1fr', tablet: 'auto' }}
                    justifyContent={'center'}
                    gridGap={'xs'}
                >
                    <Box order={{ _: 2, desktop: 0 }}>
                        <ButtonSolid
                            style={{ width: '100%', borderColor: '#007AFF' }}
                            variant={'secondary'}
                            onClick={noTengoNadaClick}
                        >
                            No tengo nada
                        </ButtonSolid>
                    </Box>
                    <ButtonSolid icon={'arrow-right'} iconDirection={'right'} onClick={tengoCertificadoClick}>
                        Tengo certificado digital o Cl@ve PIN
                    </ButtonSolid>
                </Box>
            </Box>
            <Modal
                title={'Certificado digital o Cl@ve PIN'}
                isOpen={modal.show}
                onClose={modalClose}
                onAction={modalAction}
                variant={'small'}
                actionLabel={'Tramitar con Declarando'}
                closeLabel={'Lo haré por mi cuenta'}
            >
                <P marginBottom={'md'}>
                    El certificado digital o Cl@ve PIN son dos tipos de firma electrónica que permiten identificarte
                    para realizar gestiones por internet con la Seguridad Social y/o con Hacienda.
                </P>
                <AlertMessage
                    full
                    tone={'info'}
                    title={'Tienes las siguientes opciones para obtenerlos:'}
                    message={
                        <Box display={'flex'} flexDirection={'column'}>
                            <Box display={'flex'} gap={'7px'}>
                                <P paddingLeft={'4px'} fontSize={'14px'}>
                                    1.
                                </P>
                                <P fontSize={'14px'}>Tramitar la</P>
                                <Link
                                    underline
                                    href="https://ayuda.declarando.es/es/articles/1003567-como-puedo-obtener-la-cl-ve-pin"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Cl@ve PIN por tu cuenta
                                </Link>
                            </Box>

                            <Box display={'flex'} gap={'3px'}>
                                <P paddingLeft={'4px'} fontSize={'14px'}>
                                    2.
                                </P>
                                <P fontSize={'14px'}>Tramitar el </P>
                                <Link
                                    underline
                                    href="https://ayuda.declarando.es/es/articles/1003600-como-puedo-obtener-el-certificado-digital"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    certificado digital por tu cuenta
                                </Link>
                            </Box>

                            <Box display={'flex'} gap={'3px'}>
                                <P paddingLeft={'4px'} small>
                                    3.
                                </P>
                                <P small>Tramitar el certificado digital con Declarando</P>
                            </Box>
                            <Box display={'flex'} gap={'3px'} alignItems={'center'}>
                                <P bold paddingLeft={'16px'} small>
                                    ·
                                </P>
                                <P small>Coste: 14,56€ (IVA incl.)</P>
                            </Box>
                            <Box display={'flex'} gap={'3px'} alignItems={'center'}>
                                <P bold paddingLeft={'16px'} small>
                                    ·
                                </P>
                                <P small>Duración: 1 año</P>
                            </Box>

                            {/*-------------*/}
                        </Box>
                    }
                />
            </Modal>
        </>
    );
};
