import DateWrapper from 'shared/infra/utils/Date';
import { Invoice } from '../Invoice';
import { ClientTypeChecks } from 'features/Ingresos/Clientes/domain/ClientType';
import { isClientCanario } from 'features/Ingresos/Clientes/domain/Canario';
import { isClientCeutaMelilla } from 'features/Ingresos/Clientes/domain/Ceuta-Melilla';
import { Client } from 'features/Ingresos/Clientes/domain';
import { InvoicingActivity } from 'features/Ingresos/InvoicingActivities/domain/InvoicingActivity';
import { InvoicingActivitiesTypeChecker } from 'features/Ingresos/InvoicingActivities/domain/InvoicingActivityChecker';
import { isCountryEU, isCountrySpain } from 'features/Ingresos/Parameters/Locations/services/CountryPredicate';

export type IngresoConFacturaRulesClient = {
    isDueDateLaterThanExpeditionDate: (args: { dueDate: string; expeditionDate: string }) => boolean;
    isExpeditionDateLaterThanActivityRegistrationDate: (args: {
        expeditionDate: string;
        activityRegistrationDate: string;
    }) => boolean;
    isExpeditionDateAfterLastInvoiceDate: (args: { currentDate: string; lastInvoiceIssueDate: string }) => boolean;
    isTotalAmountDifferentFromZero: (total: number) => boolean;
    isTotalAmountLessOrEqualThanMaximalAmount: (total: number) => boolean;
    isNIFIVARequired: (args: { country: Client['country']; clientType: Client['clientType'] }) => boolean;
    isImageRightsRequired: (args: {
        activityType: InvoicingActivity['activityType'];
        clientType: Client['clientType'];
        country: Client['country'];
    }) => boolean;
    isForFatteningRequired: (args: {
        activityType: InvoicingActivity['activityType'];
        clientType: Client['clientType'];
        country: Client['country'];
    }) => boolean;
    hasConsistentVAT: (args: { client: Invoice['client']; lines: Invoice['lines'] }) => boolean;
    isExpeditionDateBetweenValidRangeWhenEditingAutomaticNumbering: (args: {
        expeditionDate: string;
        minValidDate: string;
        maxValidDate: string;
    }) => boolean;
    hasClientIncompleteProfile: (client: Client) => boolean;
    shouldApplyZeroVATByDefault: (args: { client: Client; isFieldVATDirty: boolean }) => boolean;
    MAX_CHARACTERS_PER_CONCEPT_LINE: number;
    MAX_CHARACTERS_NOTES: number;
    MAX_CHARACTERS_PAYMENT_TYPE_OTHERS: number;
    MAX_DECIMAL_PLACES_PER_UNIT_PRICE: number;
};

const MAXIMAL_AMOUNT = 1000000;

export const ingresoConFacturaRulesClient: IngresoConFacturaRulesClient = {
    isDueDateLaterThanExpeditionDate: ({ dueDate, expeditionDate }) => {
        return DateWrapper.isDateSameOrAfter(dueDate, expeditionDate);
    },

    isExpeditionDateLaterThanActivityRegistrationDate: ({ expeditionDate, activityRegistrationDate }) => {
        return DateWrapper.isDateSameOrAfter(expeditionDate, activityRegistrationDate);
    },

    isExpeditionDateAfterLastInvoiceDate: ({ currentDate, lastInvoiceIssueDate }) => {
        return DateWrapper.isDateSameOrAfter(currentDate, lastInvoiceIssueDate);
    },

    isTotalAmountDifferentFromZero: (total: number) => {
        return total !== 0;
    },

    isTotalAmountLessOrEqualThanMaximalAmount: (total: number) => {
        return total <= MAXIMAL_AMOUNT;
    },

    isNIFIVARequired: (args) => {
        const isEuropeanCountry = isCountryEU(args.country);
        const isSpainCountry = isCountrySpain(args.country);
        const isCompanyOrFreelancer = ClientTypeChecks.isCompanyOrFreelancer(args.clientType);

        return isEuropeanCountry && !isSpainCountry && isCompanyOrFreelancer;
    },

    isImageRightsRequired: (args) => {
        const isSpainCountry = isCountrySpain(args.country);
        const isCompanyOrFreelancer = ClientTypeChecks.isCompanyOrFreelancer(args.clientType);
        const isArtisticActivity = InvoicingActivitiesTypeChecker.isArtistic(args.activityType);

        return isArtisticActivity && isCompanyOrFreelancer && isSpainCountry;
    },

    isForFatteningRequired: (args) => {
        const isSpainCountry = isCountrySpain(args.country);
        const isCompanyOrFreelancer = ClientTypeChecks.isCompanyOrFreelancer(args.clientType);
        const isRancherForFattening = InvoicingActivitiesTypeChecker.isRancherForFattening(args.activityType);

        return isSpainCountry && isCompanyOrFreelancer && isRancherForFattening;
    },

    hasConsistentVAT: ({ client, lines }) => {
        const clientStateId = client?.state?.id;
        const clientType = client.clientType;

        const isClientIndividual = ClientTypeChecks.isIndividual(clientType);

        const isClientCanary = isClientCanario(clientStateId);
        const isClientCeutaOrMelilla = isClientCeutaMelilla(clientStateId);
        const isClientNotEuropean = !client.country.isEuropean;

        const requiresSpecialHandling =
            (isClientCanary || isClientCeutaOrMelilla || isClientNotEuropean) && isClientIndividual;

        if (!requiresSpecialHandling || lines.length === 1) return true;

        const firstLineVAT = lines[0]?.vat;
        const hasInconsistentVAT = lines.some((line) => (line.vat === 0) !== (firstLineVAT === 0));

        return !hasInconsistentVAT;
    },

    isExpeditionDateBetweenValidRangeWhenEditingAutomaticNumbering: ({
        expeditionDate,
        minValidDate,
        maxValidDate,
    }) => {
        return DateWrapper.isDateBetweenProvidedDates({ date: expeditionDate, from: minValidDate, to: maxValidDate });
    },

    hasClientIncompleteProfile: (client: Client) => {
        if (
            isCountrySpain(client.country) &&
            (!client.address || !client?.city?.id || !client?.state?.id || !client.postalCode)
        ) {
            return true;
        }

        if (
            isCountryEU(client.country) &&
            !isCountrySpain(client.country) &&
            client.hasEUVatNumber &&
            (!client.address || !client?.fiscalId)
        ) {
            return true;
        }

        if (!client.name || !client.country.code) return true;

        if (!client.clientType) return true;

        return false;
    },

    shouldApplyZeroVATByDefault: ({ client, isFieldVATDirty }) => {
        if (isFieldVATDirty) return false;

        const isClientCanary = isClientCanario(client.state?.id);
        const isParticular = ClientTypeChecks.isIndividual(client.clientType);
        const canaryValidation = isClientCanary && isParticular;

        const isClientCeutaOrMelilla = isClientCeutaMelilla(client.state?.id);
        const isClientNotEuropean = !isCountryEU(client.country);

        return canaryValidation || isClientCeutaOrMelilla || isClientNotEuropean;
    },
    MAX_CHARACTERS_PER_CONCEPT_LINE: 10000,
    MAX_CHARACTERS_NOTES: 10000,
    MAX_CHARACTERS_PAYMENT_TYPE_OTHERS: 1000,
    MAX_DECIMAL_PLACES_PER_UNIT_PRICE: 5,
};
