import { useContext, useEffect, useState } from 'react';
import { OnboardingContext } from '../../Onboarding';
import { publishDocumentosBankflipRevisadosEvent } from '../../../services/events';

export const useStep2 = () => {
    enum StateKey {
        LOADING = 'LOADING',
        INITIAL = 'INITIAL',
        BANKFLIP = 'BANKFLIP',
    }
    const { siguintePaso } = useContext(OnboardingContext);
    const [state, setState] = useState(StateKey.LOADING);

    useEffect(() => {
        setState(StateKey.INITIAL);
        window.onmessage = (e: { data: { name: string } }) => {
            if (e.data.name === 'user_requested_closure') {
                siguintePaso();
                publishDocumentosBankflipRevisadosEvent();
            }
        };
    }, []);

    const importarDocumentacionAction = () => {
        setState(StateKey.BANKFLIP);
    };

    return {
        StateKey,
        state,
        importarDocumentacionAction,
    };
};
