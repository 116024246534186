import JWT from 'shared/infra/utils/JWT';
import { addTimeOffsetToQueryParam } from '../utils/GetOffset';

export default class Repository {
    constructor() {
        this.apiPath = import.meta.env.VITE_API_URL;
    }

    get(url) {
        return this.call('GET', url);
    }

    put(url, data) {
        return this.call('PUT', url, data);
    }

    patch(url, data) {
        return this.call('PATCH', url, data);
    }

    post(url, data) {
        return this.call('POST', url, data);
    }

    delete(url, data) {
        return this.call('DELETE', url, data);
    }

    call(method, url, data) {
        let queryParam = addTimeOffsetToQueryParam(url);
        const endpoint = this.apiPath + url + queryParam;

        let options = {
            method: method,
            credentials: 'include',
        };

        if (typeof data !== 'undefined') {
            options = Object.assign(options, {
                body: data,
            });
        }

        const request = new Request(endpoint, options);

        return fetch(request)
            .then(async (response) => {
                if (response.status === 401) {
                    JWT.removeToken();
                }

                if (response.status === 204) {
                    return;
                }

                if (response.ok) return response.json();
                // Aqui mostrariamos el notifyError() si lo quisieramos hacer de forma general.
                // Quiza entonces no querríamos devolver el mismo mensaje de error, si acaso ninguno

                let error = new Error('Unknown Error');
                const body = await response.json();
                error.body = body;
                error.status = response.status;
                if (response.message) {
                    error.message = response.message;
                } else {
                    try {
                        if (body.message) {
                            error.message = body.message;
                        } else {
                            error.message = body.error ? body.error : 'Unknown Error';
                        }
                    } catch (e) {
                        error.message = e;
                    }
                }

                return Promise.reject(error);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async downloadFile(url) {
        let options = { method: 'GET', credentials: 'include' };
        const request = new Request(url, options);
        const response = await fetch(request);
        if (response.status === 200) {
            window.location.href = url;
            return true;
        } else {
            throw new Error('No se ha podido descargar el archivo');
        }
    }

    async checkAndDownloadFile(url) {
        let options = { method: 'GET', credentials: 'include' };
        const request = new Request(url, options);
        const response = await fetch(request);
        let json = null;
        try {
            json = await response.json();
        } catch (e) {
            window.location.href = url;
            return true;
        }

        const error = new Error('No se ha podido descargar el archivo');
        error.body = json;
        throw error;
    }

    async getBlobResponse(url, customOptions) {
        const endpoint = this.apiPath + url;

        let options = {
            method: 'GET',
            credentials: 'include',
            ...customOptions,
        };

        const request = new Request(endpoint, options);

        try {
            const response = await fetch(request);

            if (response.status === 401) {
                JWT.removeToken();
                throw new Error('Unauthorized');
            }

            if (!response.ok) {
                let error = new Error('HTTP error! status: ' + response.status);
                error.status = response.status;
                throw error;
            }

            const blob = await response.blob();
            return blob;
        } catch (error) {
            console.error('Error fetching blob:', error);
            throw error;
        }
    }
}
