import { City, Country, State } from '../domain';
import { CitiesDTO } from './CityDTO';
import { CountryDTO } from './CountryDTO';
import { StatesDTO } from './StateDTO';

export function transformCitiesDTO(cities: CitiesDTO): City[] {
    return cities.data.map((city) => ({
        id: city.value,
        name: city.label,
        postalCodes: city.codigos_postales,
    }));
}

export function transformStatesDTO(states: StatesDTO): State[] {
    return states.data.map((state) => ({
        id: state.value,
        name: state.label,
    }));
}

export function transformCountryDTO(countries: CountryDTO[]): Country[] {
    return countries.map((country) => ({
        id: country.id,
        code: country.code,
        name: country.name,
        isEuropean: country.isEuropean,
    }));
}
