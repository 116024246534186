import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useEffect } from 'react';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { EquivalencySurchargeInput } from './EquivalencySurchargeInput';
import { useGetUpdateFunctionEquivalencySurchargeInSalesDocumentLines } from './hooks/useGetUpdateFunctionEquivalencySurchargeInInvoiceLines';
import { useGetTaxes } from '../../hooks/get-available-taxes';
import { ExpandableContent } from '../../../../shared/components/ExpandableContent';
import { Box } from '@declarando/design_system';

export const EquivalencySurchargeQuestion = () => {
    const { setValue } = useCustomFormContext<InvoiceForm>();
    const { data } = useGetTaxes();
    const { updateEquivalencySurchargeSalesDocumentLines } =
        useGetUpdateFunctionEquivalencySurchargeInSalesDocumentLines();

    useEffect(() => {
        if (data?.data?.canApplyEquivalencySurcharge === false) {
            setValue('canApplyEquivalencySurcharge', false);
            updateEquivalencySurchargeSalesDocumentLines({ withEquivalencySurcharge: false });
        } else {
            setValue('canApplyEquivalencySurcharge', true);
        }
    }, [data?.data?.canApplyEquivalencySurcharge, setValue, updateEquivalencySurchargeSalesDocumentLines]);

    return (
        <ExpandableContent shouldExpand={!!data?.data?.canApplyEquivalencySurcharge}>
            <Box marginTop="md">
                <EquivalencySurchargeInput />
            </Box>
        </ExpandableContent>
    );
};
