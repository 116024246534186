import { ActionLink } from '@declarando/design_system';
import { useState } from 'react';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { CreateClientModal } from '../CreateClientModal/CreateClientModal';

export const ClientCreation = () => {
    const [isModalNewClientOpen, setIsModalNewClientOpen] = useState(false);

    return (
        <div>
            <ActionLink
                label="Rellenar creando un nuevo cliente"
                icon="plus"
                size="L"
                onClick={() => {
                    setIsModalNewClientOpen(true);
                    TrackingEvents.clickElement({ event_label: 'Rellena creando un nuevo cliente' });
                }}
            />
            <CreateClientModal isOpen={isModalNewClientOpen} onClose={() => setIsModalNewClientOpen(false)} />
        </div>
    );
};
