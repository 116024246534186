import { IObjectSchema } from 'shared/validation/types';
import { SalesDocumentFormMode } from '../../shared/UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { createIssueDateSchema } from '../../shared/validations/IssueDateSchema';
import { createSalesDocumentLineSchema } from '../../shared/validations/LinesSchema';
import {
    createBaseSalesDocumentSchema,
    createDocumentNumberSchema,
} from '../../shared/validations/SalesDocumentSchema';
import { QuoteForm, Quote } from '../domain/Quote';
import { QuoteClientRulesService } from '../services/QuoteClientRulesService';
import { Schema } from 'shared/validation/Schema';

export const getQuoteSchema: IObjectSchema<QuoteForm> = (args: {
    salesDocumentMode: SalesDocumentFormMode;
    defaultValues: QuoteForm;
}) => {
    const baseSchema = createBaseSalesDocumentSchema();

    const documentNumberSchema = createDocumentNumberSchema({ errorRequired: 'Debes introducir un nº de presupuesto' });
    const issueDateSchema = createIssueDateSchema<QuoteForm>({
        numerationType: 'automatic',
        salesDocumentMode: args.salesDocumentMode,
    });

    const linesSchema = createSalesDocumentLineSchema({
        validations: {
            totalLine: {
                additionalTests: [
                    {
                        name: 'total-lines-should-be-greater-than-0',
                        message: 'El total del presupuesto debe ser mayor que 0',
                        test: (_, ctx) => {
                            const { from } = ctx;
                            if (!from || from.length < 2) return true;
                            const quoteDocument = from[1]?.value as Quote;

                            return QuoteClientRulesService.isTotalAmountDifferentFromZero(quoteDocument);
                        },
                    },
                ],
            },
        },
    });

    return Schema.object({
        ...baseSchema,
        documentNumber: documentNumberSchema,
        issueDate: issueDateSchema,
        lines: Schema.array().of(linesSchema).required(),
    });
};
