import { Link, Notification, P } from '@declarando/design_system';
import React from 'react';

const VIES_LINK = 'https://ayuda.declarando.es/es/articles/8370269-compras-a-proveedor-europeo';

export const ViesNotification = ({
    isValid,
    exception,
    type = 'proveedor',
}: {
    isValid: boolean | null;
    exception: boolean;
    type?: 'cliente' | 'proveedor';
}) => {
    if (exception) {
        return (
            <Notification
                full
                shrink={false}
                tone={'error'}
                title={
                    <>
                        Debido a un error de servicio{' '}
                        <a
                            target="_blank"
                            style={{ textDecoration: 'underline', color: 'inherit', fontWeight: 'inherit' }}
                            href={'https://ec.europa.eu/taxation_customs/vies/#/vat-validation'}
                        >
                            con el VIES
                        </a>{' '}
                        no ha sido posible validar el NIF IVA
                    </>
                }
                message={
                    <>
                        <P small>Te recomendamos volver a intentarlo pasados unos minutos</P>
                    </>
                }
            />
        );
    }

    if (isValid === null) return null;
    return isValid ? (
        <Notification
            full
            shrink={false}
            tone={'success'}
            title={
                <>
                    El NIF IVA coincide con un número válido{' '}
                    <a
                        target="_blank"
                        style={{ textDecoration: 'underline', color: 'inherit', fontWeight: 'inherit' }}
                        href={VIES_LINK}
                    >
                        según el VIES
                    </a>
                </>
            }
            message={
                <>
                    Puedes operar con él con las ventajas propias de los operadores intracomunitarios
                    <P small mt="xxs">
                        <Link underline href={VIES_LINK}>
                            Saber más
                        </Link>{' '}
                    </P>
                </>
            }
        />
    ) : (
        <Notification
            full
            shrink={false}
            tone={'error'}
            title={
                <>
                    El NIF IVA no coincide con un número válido{' '}
                    <a
                        target="_blank"
                        style={{ textDecoration: 'underline', color: 'inherit', fontWeight: 'inherit' }}
                        href={VIES_LINK}
                    >
                        según el VIES
                    </a>
                </>
            }
            message={
                <>
                    <P small>No puedes operar con él con las ventajas propias de los operadores intracomunitarios</P>
                    <P small mt={'sm'}>
                        1. Comprueba si el número que has introducido es el correcto
                    </P>
                    <P small>2. Asegúrate de que tu {type} cuente con un NIF IVA válido</P>
                    <P small>
                        3. En caso contrario, selecciona NO en la pregunta <i>¿Tu {type} tiene NIF IVA?</i>
                    </P>
                    <P small mt="xxs">
                        <Link underline href={VIES_LINK}>
                            Saber más
                        </Link>{' '}
                    </P>
                </>
            }
        />
    );
};
