import V2Repository from 'shared/infra/repositories/V2Repository';
import {
    GetPrintedQuotesListParams,
    GetQuotesListParams,
    QuoteRepository as Interface,
} from '../domain/QuoteRepository';
import { Quote } from '../domain/Quote';
import { toQuoteDTO } from '../adapters/QuoteAdapter';
import { QuoteDTO } from '../adapters/QuoteDTO';
import { QuoteListDTO } from '../adapters/QuoteListDTO';

function createQueryString(params?: Record<string, any>) {
    if (!params) return '';

    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
            queryParams.append(key, value.toString());
        }
    });

    const queryString = queryParams.toString();
    return queryString ? `?${queryString}` : '';
}

class QuoteRepository extends V2Repository implements Interface {
    create(quote: Quote) {
        const dto = toQuoteDTO(quote);
        return this.post('/quotes', JSON.stringify(dto));
    }

    edit(quote: Quote) {
        const dto = toQuoteDTO(quote);
        return this.put(`/quotes/${quote.id}`, JSON.stringify(dto));
    }

    deleteQuote(id: string): Promise<void> {
        return this.delete(`/quotes/${id}`);
    }

    getByUUID(id: string): Promise<QuoteDTO> {
        return this.get(`/quotes/${id}`);
    }

    getQuotesList(params?: GetQuotesListParams): Promise<QuoteListDTO[] | null> {
        const queryString = createQueryString(params);

        return this.get(`/quotes${queryString ? `?${queryString}` : ''}`);
    }

    getPrintedList(params: GetPrintedQuotesListParams): Promise<Blob> {
        const queryString = createQueryString(params);
        const MapperHeaderAccept: Record<GetPrintedQuotesListParams['printType'], string> = {
            CSV: 'text/csv',
            PDF: 'application/pdf',
        };

        return this.getBlobResponse(`/quotes${queryString ? `?${queryString}` : ''}`, {
            headers: { accept: MapperHeaderAccept[params.printType] },
        });
    }

    convertToInvoice(args: { invoiceId: string; quoteId: string }): Promise<string> {
        return this.put(`/quotes/${args.quoteId}/invoice`, JSON.stringify({ invoiceId: args.invoiceId }));
    }
}

export const createQuoteRepository = (): Interface => {
    return new QuoteRepository();
};
