import { useHistory, useParams } from 'react-router-dom';
import { useGetIngresoConFactura } from '../hooks/useGetIngresoConFactura';
import { Invoice } from '../../domain/Invoice';
import { FeatureFlags } from 'config/FeatureFlags';
import { SalesDocumentPreviewView } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentPreviewView/SalesDocumentPreviewView';
import { useGetPDFSalesDocument } from 'features/Ingresos/SalesDocument/shared/UI/hooks/useGetPDFSalesDocument';

export const IngresoConFacturaPreviewView = () => {
    const { invoiceUUID } = useParams<{ invoiceUUID: string }>();
    const history = useHistory();

    const { invoiceData, isLoading } = useGetIngresoConFactura({ invoiceUUID });
    const { pdfUrl } = useGetPDFSalesDocument({ salesDocumentUUID: invoiceUUID, salesDocumentType: 'invoice' });

    if (!invoiceUUID || !FeatureFlags.shouldNewInvoiceFormBeEnabled().checker()) {
        history.push('/ingresos/ingresos');
        return null;
    }

    return (
        <SalesDocumentPreviewView
            salesDocumentType="invoice"
            salesDocumentPDFUrl={pdfUrl as string}
            salesDocumentData={invoiceData as Invoice}
            isLoading={isLoading}
        />
    );
};
