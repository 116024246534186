import { ClientOption } from '../ClientSelection';
import { ClientList } from './ClientList';
import { FrequentClientList } from './FrequentClientList';
import { LoadingSkeleton } from './LoadingSkeleton';
import { NoSearchResults } from './NoSearchResults';

export const ClientSelectionContent: React.FC<{
    isLoading: boolean;
    searchValue: string;
    frequentClientOptions: ClientOption[];
    clientOptions: ClientOption[];
    setSelectedIds: (ids: string[]) => void;
    onResetSearch: () => void;
    infiniteScrollTriggerRef: (node: HTMLDivElement | null) => void;
}> = ({
    isLoading,
    searchValue,
    frequentClientOptions,
    clientOptions,
    setSelectedIds,
    onResetSearch,
    infiniteScrollTriggerRef,
}) => {
    if (isLoading) return <LoadingSkeleton />;
    if (searchValue && clientOptions.length === 0) return <NoSearchResults onResetSearch={onResetSearch} />;

    return (
        <>
            <FrequentClientList
                frequentClientOptions={frequentClientOptions}
                setSelectedIds={setSelectedIds}
                showDivider={!searchValue && clientOptions.length > 0}
            />
            <ClientList
                clientOptions={clientOptions}
                setSelectedIds={setSelectedIds}
                infiniteScrollTriggerRef={infiniteScrollTriggerRef}
            />
        </>
    );
};
