import type { PresentationOfRentaDTO } from 'features/CalculadoraRenta/PresentationOfRenta/adapters/PresentationOfRentaDTO';
import {
    type TPresentationOfRenta,
    toRentalPresentation,
} from 'features/CalculadoraRenta/PresentationOfRenta/adapters/PresentationOfRentaAdapter';
import { useRentalPresentationService } from 'features/CalculadoraRenta/PresentationOfRenta/services/PresentationOfRentaService';
import { useFetch } from 'shared/UI/hooks/QueryHooks';

export const useGetPresentationOfRentaStep = () => {
    const service = useRentalPresentationService();
    const context = useFetch<PresentationOfRentaDTO | null, TPresentationOfRenta | null>({
        queryKey: ['rentalPresentation'],
        fetchFn: () => service.getStatus(),
        config: {
            select: (data) => ({ ...data, data: data?.data ? toRentalPresentation(data?.data) : null }),
            //NOTE: Important for specific tasks where the user opens typeform or other external links and then returns to the page. This way we receive the updated step's status
            refetchOnWindowFocus: 'always',
        },
    });
    return {
        isError: context?.isError,
        loading: context?.isLoading,
        isRefetching: context?.isRefetching,
        data: context?.data,
        refetch: context?.refetch,
    };
};
