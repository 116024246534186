import { FormTypes } from '../../../shared/domain/types/FormTypes';
import { DeclarandoApiService } from '../../../shared/services/DeclarandoApiService';
import GastosRepository from '../../../shared/infra/repositories/GastosRepository';
import GastosConFacturaTypes, {
    SubTiposGastosConFacturaTypes,
} from '../../../shared/domain/contabilidad/GastosConFacturaTypes';
import GastosSinFacturaTypes, {
    SubTiposGastosSinFacturaTypes,
} from '../../../shared/domain/contabilidad/GastosSinFacturaTypes';

export class GastosService extends DeclarandoApiService {
    private readonly repository = new GastosRepository();

    async downloadGastoPdf(id: number) {
        return this.execute(() => this.repository.downloadFacturaDiva(id));
    }

    async getTipoIvasHabilitados(
        date: string,
        tipado: string | GastosConFacturaTypes | GastosSinFacturaTypes,
        subtipado: null | string | SubTiposGastosConFacturaTypes | SubTiposGastosSinFacturaTypes,
    ) {
        return this.execute<FormTypes.OptionList>(() =>
            this.repository.getTipoIvasHabilitados({ date, tipado, subtipado }),
        );
    }

    async getReglasAlquilerLocal(data: { total: number; year: number; gastoId?: string }) {
        return this.execute<{ alquiler_irpf_forzado: number | null }>(() => this.repository.getReglasAlquiler(data));
    }

    async gastoDeducibilidadDiaria(data: {
        isSpain: boolean;
        fecha: string;
        isPernocta: boolean;
        gastoUuid: string | null;
        isRectificativo: boolean;
    }) {
        return this.execute<{
            limite: number;
            consumido: number;
            isSpain: boolean;
            isPernocta: boolean;
        }>(() => this.repository.gastoDeducibilidadDiaria(data));
    }

    getListaMotivosDeducibilidadVehiculo() {
        return this.execute(() => this.repository.getListaMotivosDeducibilidadVehiculo());
    }
}
