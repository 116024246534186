import { usePost, useUpdate } from 'shared/UI/hooks/QueryHooks';
import { createIngresoConFacturaApiRepository } from 'features/Ingresos/SalesDocument/IngresoConFactura/infra/IngresoConFacturaRepository';
import { createIngresoConFacturaService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaService';
import { Invoice } from '../../domain/Invoice';

export const useIngresoConFactura = () => {
    const service = createIngresoConFacturaService(createIngresoConFacturaApiRepository());

    const createInvoice = usePost<Invoice, string>({
        queryKey: ['invoices'],
        mutationFn: (invoice) => service.createInvoice(invoice),
    });

    const editInvoice = useUpdate<Invoice, string>({
        queryKey: ['invoices'],
        mutationFn: (invoice) => service.editInvoice(invoice),
    });

    return {
        createInvoice,
        editInvoice,
    };
};
