import React from 'react';
import { FeatureFlags } from '../../../../../config/FeatureFlags';
import { BannerPeriodoPrueba } from './BannerPeriodoPrueba';
import { BannerImpagado } from './BannerImpagado';
import { BannerImpagadoFinal } from './BannerImpagadoFinal';
//import { ModalPendientePago } from '../../../../../features/Impuestos/UI/_legacy/Modals/ModalPlanPendientePago/ModalPlanPendientePago';

export const Banners = () => {
    return (
        <>
            {FeatureFlags.enableTopBannerPeriodoPrueba() ? <BannerPeriodoPrueba /> : null}
            {FeatureFlags.enableTopBannerPeriodoPrueba() ? <BannerImpagado /> : null}
            {FeatureFlags.enableTopBannerPeriodoPrueba() ? <BannerImpagadoFinal /> : null}
        </>
    );
};
