import React, { ChangeEvent, ReactNode, useContext, useEffect, useState } from 'react';
import {
    Box,
    Caption,
    ContentLoader,
    FormControlContainer,
    Link,
    Section,
    Select,
    Slider,
    TextArea,
} from '@declarando/design_system';

import { TInvoiceDataGasto } from '../GastoCockpit/types/TInvoiceDataGasto';
import {
    ReglasDeducibilidad,
    ReglasDeducibilidadMapper,
    TReglasDeducibilidadMapper,
} from '../services/ReglasDeducibilidad';
import { TTipado } from 'shared/TipadoType';
import { GastoContext } from '../GastoContext';
import { OptionList } from 'shared/domain/types/FormTypes';
import { GastosService } from '../services/GastosService';
import { Notifier } from '../../../shared/infra/utils/Notifier';

type DeducibilidadGastoProps = {
    invoiceData: TInvoiceDataGasto;
    updateDeductiblePercent: (value: string | number) => any;
    is100Deductible: boolean;
    deducibilidad: ReturnType<typeof ReglasDeducibilidad.prototype.calcularDeducibilidad>;
    defaultValue: number;
    children: ReactNode;
    isBlocked: boolean;
};

export const MotivoDeducibilidadOtros = 'GMD09';
export const DeducibilidadGasto = ({
    invoiceData,
    updateDeductiblePercent,
    is100Deductible,
    deducibilidad,
    defaultValue,
    isBlocked,
    children,
}: DeducibilidadGastoProps) => {
    const { min, info, value, type, hideQuestion, infoLink, mapper, selectReason } = deducibilidad ?? {};

    const [val, setVal] = useState(value ?? defaultValue);

    useEffect(() => {
        setVal(defaultValue);
        updateDeductiblePercent(defaultValue as any);
    }, [defaultValue]);

    useEffect(() => {
        if (is100Deductible && type === 'editable') {
            updateDeductiblePercent('100');
        }
    }, [type, is100Deductible]);

    useEffect(() => {
        if (!deducibilidad) {
            return;
        }
        const { type, value, info } = deducibilidad;
        if (type === 'fixed' || val < (min ?? 0) || value === 0) {
            updateDeductiblePercent(value);
            setVal(value);
        }
    }, [min, info, value, type, hideQuestion]);

    const onChange = (e: string) => {
        let _value = Number(e);
        if (min && min > _value) {
            _value = min;
        }
        setVal(_value);
        updateDeductiblePercent(String(_value));
    };

    if (type === 'editable' && !hideQuestion && !isBlocked) {
        return <>{children}</>;
    }

    const tooltip = <>{info ?? null}</>;

    return (
        <>
            <Box>
                <FormControlContainer
                    required
                    label={'Este es el porcentaje del gasto que te puedes deducir'}
                    info={tooltip}
                >
                    <Slider
                        value={val}
                        onChange={(e: any) => onChange(e)}
                        id={'deducibilidadPC'}
                        minimumValue={min}
                        disabled={type === 'fixed' || isBlocked}
                    />
                </FormControlContainer>
            </Box>
            {selectReason ? (
                <RazonSelecionDeducibilidad mapper={mapper} tipado={invoiceData.tipado} deducibilidadPC={+val} />
            ) : null}
        </>
    );
};

type RazonSelecionDeducibilidadProps = {
    tipado: TTipado | null;
    mapper?: TReglasDeducibilidadMapper;
    deducibilidadPC: number;
};
export const RazonSelecionDeducibilidad = ({ tipado, mapper, deducibilidadPC }: RazonSelecionDeducibilidadProps) => {
    const [options, setOptions] = useState<OptionList>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const { updateInvoiceData, gasto, gastoState, updateGastoState } = useContext(GastoContext);

    const reset = () => {
        updateInvoiceData('motivoDeducibilidad', null);
        updateInvoiceData('notasDeducibilidad', null);
    };

    useEffect(() => {
        if (tipado && mapper === ReglasDeducibilidadMapper.vehiculoPropioEditable) {
            getOptions();
        } else {
            reset();
            setOptions([]);
        }
    }, [tipado]);

    useEffect(() => {
        if (mapper === ReglasDeducibilidadMapper.vehiculoPropioEditable && deducibilidadPC === 50) {
            reset();
        }
    }, [deducibilidadPC]);

    const onChangeMotivo = (value: TInvoiceDataGasto['motivoDeducibilidad']) => {
        updateInvoiceData('motivoDeducibilidad', value);
        updateGastoState({ errorMotivoDeducibilidad: '' });
    };

    const onChangeNotas = (value: TInvoiceDataGasto['notasDeducibilidad']) => {
        updateInvoiceData('notasDeducibilidad', value);
        updateGastoState({ errorNotasDeducibilidad: '' });
    };

    const getOptions = async () => {
        setLoading(true);
        const { data, error } = await new GastosService().getListaMotivosDeducibilidadVehiculo();
        setLoading(false);
        if (error || !data) {
            return Notifier.notifyError();
        }
        setOptions(data);
    };

    if (+deducibilidadPC <= 50 || mapper !== ReglasDeducibilidadMapper.vehiculoPropioEditable) {
        return null;
    }

    if (loading)
        return (
            <Box>
                <ContentLoader text={'Cargando...'} />
            </Box>
        );

    return (
        <Section>
            <Select
                id={'motivoDeducibilidad'}
                placeholder={'Selecciona la opción que mejor defina tu situación...'}
                label={'Motivo del porcentaje escogido'}
                required
                value={gasto.invoiceData['motivoDeducibilidad']}
                onChange={onChangeMotivo}
                options={options}
                info={
                    <>
                        Descubre los motivos por los que puedes deducirte más de un 50% e indícalo aquí para recordarlo
                        en caso de que Hacienda te solicite información adicional en el futuro.
                        <Caption mt={'xxs'}>
                            <Link underline styles={{ color: 'white' }} href={'/gasto/asistente/'} target={'_blank'}>
                                Quiero analizar mi gasto
                            </Link>
                        </Caption>
                    </>
                }
                error={!!gastoState.errorMotivoDeducibilidad}
                message={gastoState.errorMotivoDeducibilidad}
            />
            <TextArea
                id={'notaDeducibilidad'}
                placeholder={'Escribe cualquier detalle que te ayude a recordar el porcentaje escogido...'}
                required={gasto.invoiceData['motivoDeducibilidad'] === MotivoDeducibilidadOtros}
                label={'Nota adicional'}
                value={gasto.invoiceData['notasDeducibilidad'] as string}
                onChange={(e: ChangeEvent<HTMLTextAreaElement> | any) => onChangeNotas(e.currentTarget.value)}
                error={!!gastoState.errorNotasDeducibilidad}
                message={gastoState.errorNotasDeducibilidad}
                maxLength={255}
            />
        </Section>
    );
};
