import { Box, Button, ButtonSolid, Header, P } from '@declarando/design_system';
import imageStep from '../assets/finalizado.svg';
import { useContext, useEffect } from 'react';
import { OnboardingContext } from '../../Onboarding';
import { publishFinalizarConfiguracionEvent } from '../../../services/events';

export const StepRevisada = () => {
    const { setCheckCurrentStep, siguintePaso } = useContext(OnboardingContext);
    useEffect(() => {
        setCheckCurrentStep();
    }, []);

    const finalizarConfiguracionClick = () => {
        siguintePaso();
        publishFinalizarConfiguracionEvent();
    };

    return (
        <>
            <Box px={'sm'} mx={'auto'} py={'xxl'} backgroundColor={'white'} borderRadius={'md'} minHeight={600}>
                <Box alignCenter>
                    <Box mb="sm">
                        <img src={imageStep} alt={'Finalizado'} />
                    </Box>
                    <Header as={'h2'}>Tu perfil se ha configurado correctamente</Header>
                    <P mt={'xs'}>Tu mentor te ha enviado un correo electrónico con los detalles de la configuración.</P>

                    <Box maxWidth={570} mx={'auto'} mt={'lg'}>
                        <Button
                            icon={'arrow-right'}
                            iconPosition={'right'}
                            onClick={finalizarConfiguracionClick}
                            label={'Finalizar configuración'}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};
