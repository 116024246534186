import { InvoicingActivity } from '../domain/InvoicingActivity';
import { InvoicingActivityDTO } from './InvoicingActivityDTO';

export function toInvoicingActivity(dto: InvoicingActivityDTO): InvoicingActivity {
    return {
        id: dto.id,
        section: dto.section,
        epigraph: dto.epigraph,
        name: dto.name,
        isVatExempt: dto.isVatExempt,
        registrationDate: dto.registrationDate,
        isEquivalencySurcharge: dto.isEquivalencySurcharge,
        activityType: dto.activityType,
    };
}
