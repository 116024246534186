import { invoiceCalculationsService } from '../../IngresoConFactura/services/IngresoConFacturaCalculationService';
import { quoteRulesClient } from '../domain/QuoteClientRules';
import { Quote } from '../domain/Quote';

export const QuoteClientRulesService = {
    isTotalAmountDifferentFromZero(quote: Quote) {
        const totalAmounts = invoiceCalculationsService.calculateInvoiceTotals({
            invoiceLines: quote.lines,
            withEquivalencySurcharge: quote.withEquivalencySurcharge,
            incomeTax: quote.incomeTax,
        });

        return quoteRulesClient.isTotalAmountGreaterThanZero(totalAmounts.totalPayable);
    },
};
