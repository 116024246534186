import V2Repository from 'shared/infra/repositories/V2Repository';
import { LocationRepository } from '../domain/LocationRepository';
import { CitiesDTO } from '../adapters/CityDTO';
import { StatesDTO } from '../adapters/StateDTO';
import { CountryDTO } from '../adapters/CountryDTO';

class LocationsRepository extends V2Repository implements LocationRepository {
    getCountries(): Promise<CountryDTO[]> {
        return this.get('/countries');
    }

    getStates(): Promise<StatesDTO> {
        return this.get('/provincias/1');
    }

    getCitiesByState(stateId: number): Promise<CitiesDTO> {
        return this.get(`/poblaciones/${stateId}`);
    }
}

export const createLocationsRepository = (): LocationRepository => {
    return new LocationsRepository();
};
