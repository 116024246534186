import { Invoice } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { GetInvoicesListParams, IngresoConFacturaRepository } from '../domain/IngresoConFacturaRepository';
import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';

class IngresoConFacturaService extends DeclarandoApiService {
    private repository: IngresoConFacturaRepository;

    constructor(repository: IngresoConFacturaRepository) {
        super();
        this.repository = repository;
    }

    createInvoice(invoice: Invoice) {
        return this.execute(() => this.repository.create(invoice), { shouldThrowError: true });
    }

    editInvoice(invoice: Invoice) {
        return this.execute(() => this.repository.edit(invoice), { shouldThrowError: true });
    }

    deleteInvoice(id: string) {
        return this.execute(() => this.repository.deleteInvoice(id), { shouldThrowError: true });
    }

    getInvoiceByUUID(id: string) {
        return this.execute(() => this.repository.getByUUID(id), { shouldThrowError: true });
    }

    getInvoicesList(params?: GetInvoicesListParams) {
        return this.execute(() => this.repository.getList(params), { bodyAsData: true, shouldThrowError: true });
    }
}

export const createIngresoConFacturaService = (repository: IngresoConFacturaRepository): IngresoConFacturaService => {
    return new IngresoConFacturaService(repository);
};
