import DateWrapper from 'shared/infra/utils/Date';

export function formatNotificationDate(date: Date) {
    if (DateWrapper.isToday(date)) {
        return `Hoy a las ${DateWrapper.getHourFromDate(date)}`;
    }
    if (DateWrapper.isYesterday(date)) {
        return `Ayer a las ${DateWrapper.getHourFromDate(date)}`;
    }
    return DateWrapper.formatNativeDateToString(date, 'PPP');
}
