import { ClientType } from './Client';

export const ClientTypeChecks = {
    isIndividual: (clientType: ClientType) => clientType === 'INDIVIDUAL',

    isCompany: (clientType: ClientType) => clientType === 'COMPANY',

    isFreelancer: (clientType: ClientType) => clientType === 'FREELANCE',

    isCompanyOrFreelancer: (clientType: ClientType) => clientType === 'COMPANY' || clientType === 'FREELANCE',
};
