import { useMemo } from 'react';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { invoiceCalculationsService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaCalculationService';

export const useGetSalesDocumentTotals = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const watchLines = useCustomWatch({ control, name: 'lines' });
    const watchIncomeTax = useCustomWatch({ control, name: 'incomeTax' });
    const watchWithEquivalencySurcharge = useCustomWatch({ control, name: 'withEquivalencySurcharge' });

    const detailedSums = useMemo(
        () =>
            invoiceCalculationsService.calculateInvoiceTotals({
                invoiceLines: watchLines,
                withEquivalencySurcharge: watchWithEquivalencySurcharge,
                incomeTax: watchIncomeTax,
            }),
        [watchIncomeTax, watchLines, watchWithEquivalencySurcharge],
    );

    return {
        detailedSums,
        withEquivalenceSurcharge: watchWithEquivalencySurcharge,
        incomeTaxPercentage: watchIncomeTax,
        incomeTaxAmount: detailedSums.totalIRPF,
        totalWithIncomeTax: detailedSums.totalPayable,
    };
};
