import { createInvoiceSendEmailRepository } from '../../IngresoConFactura/infra/InvoiceSendEmailRepository';
import { createInvoiceSendEmailService } from '../../IngresoConFactura/services/IngresoConFacturaSendEmailService';
import { createQuoteSendEmailRepository } from '../../Quote/domain/QuoteSendEmailRepository';
import { createQuoteSendEmailService } from '../../Quote/services/QuoteSendEmailService';
import { SalesDocumentType } from '../UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { SalesDocumentSendEmailService } from './SalesDocumentSendEmailService';

export class SalesDocumentSendEmailServiceFactory {
    static create(documentType: SalesDocumentType): SalesDocumentSendEmailService {
        switch (documentType) {
            case 'invoice':
                return createInvoiceSendEmailService(createInvoiceSendEmailRepository());
            case 'quote':
                return createQuoteSendEmailService(createQuoteSendEmailRepository());
            default:
                throw new Error(`Unsupported document type: ${documentType}`);
        }
    }
}
