import { SalesDocumentNextNumberAutomaticRepository } from '../../shared/domain/SalesDocumentNextNumberAutomaticRepository/SalesDocumentNextNumberAutomaticRepository';
import { SalesDocumentNextNumberAutomaticService } from '../../shared/services/SalesDocumentNumberService';

class InvoiceNextNumberService extends SalesDocumentNextNumberAutomaticService {
    constructor(repository: SalesDocumentNextNumberAutomaticRepository) {
        super(repository);
    }
}

export const createInvoiceNextNumberService = (
    repository: SalesDocumentNextNumberAutomaticRepository,
): InvoiceNextNumberService => {
    return new InvoiceNextNumberService(repository);
};
