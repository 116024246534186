import V2Repository from 'shared/infra/repositories/V2Repository';
import { ContabilidadPresentedOrBlockedRepository as Interface } from '../domain/ContabilidadPresentedOrBlocked/ContabilidadPresentedOrBlockedRepository';

class ContabilidadPresentedOrBlockedRepository extends V2Repository implements Interface {
    getContabilidadPresentedOrBlocked(seletedDate: string) {
        return this.get(`/contabilidad/presentadabloqueada/${seletedDate}`);
    }
}

export const createContabilidadPresentedOrBlockedRepository = () => {
    return new ContabilidadPresentedOrBlockedRepository();
};
