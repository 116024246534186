import { Invoice } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useIngresoConFactura } from '../../hooks/useIngresoConFactura';
import { VIESContextType } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentFormView/SalesDocumentFormContent/components/ClientDetails/components/VIESChecker/hooks/useVIESCheck';
import { useSalesDocumentMutation } from '../../../../shared/UI/hooks/useSalesDocumentMutation';
import { useParams } from 'react-router-dom';
import { FeatureFlags } from 'config/FeatureFlags';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';

export type CreateIngresoArgs = {
    data: Invoice;
    triggerVIESError: VIESContextType['triggerVIESError'];
};

export const useEditIngreso = () => {
    const { invoiceUUID } = useParams<{ invoiceUUID: string }>();
    const { editInvoice } = useIngresoConFactura();

    return useSalesDocumentMutation({
        mutation: editInvoice,
        trackingEvent: () => TrackingEvents.clickElement({ event_label: 'Guarda ingreso con factura editado' }),
        successMessage: {
            title: 'Ingreso actualizado correctamente',
            message: 'Puedes consultarlo en el listado de ingresos.',
            link: { label: 'Ir al listado de ingresos', href: '/ingresos/ingresos' },
        },
        errorMessage: {
            title: 'No ha sido posible actualizar el ingreso',
            message: 'Por favor, vuelve a intentarlo.',
        },
        getSuccessRedirectPath: () =>
            invoiceUUID
                ? FeatureFlags.shouldNewInvoiceFormBeEnabled().getInvoicePreviewURL(invoiceUUID)
                : '/ingresos/ingresos',
    });
};
