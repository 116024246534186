import { Client } from 'features/Ingresos/Clientes/domain';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { IngresoConFacturaClientRulesService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaRulesService';
import { SalesDocumentLine } from 'features/Ingresos/SalesDocument/shared/domain/SalesDocument';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useSalesDocumentLineCalculations } from '../../../hooks/useSalesDocumentCalculations';
import { useGetTaxes } from '../../ClientDetails/hooks/get-available-taxes';

export const useAssignZeroVATByDefault = () => {
    const { data } = useGetTaxes();
    const { handleLineChange } = useSalesDocumentLineCalculations(data?.data?.taxes);
    const {
        getValues,
        formState: { dirtyFields },
    } = useCustomFormContext<InvoiceForm>();

    const shouldApplyZeroVATByDefault = ({
        client,
        ignoreFieldVATDirty,
    }: {
        client: Client;
        ignoreFieldVATDirty?: boolean;
    }) => {
        const isFieldVATDirty = dirtyFields?.lines?.some((field) => field?.vat) ?? false;
        const shouldApplyZeroVATByDefault = IngresoConFacturaClientRulesService.shouldApplyZeroVATByDefault({
            client: client,
            isFieldVATDirty: ignoreFieldVATDirty ? false : isFieldVATDirty,
        });

        return shouldApplyZeroVATByDefault;
    };

    const assignVATZeroByDefault = ({ client }: { client: Client }) => {
        if (shouldApplyZeroVATByDefault({ client })) {
            const lines = getValues('lines');
            lines.forEach((_: SalesDocumentLine, index: number) => {
                handleLineChange(index, 'vat', 0, { shouldTriggerValidation: false });
            });
        }
    };

    return { assignVATZeroByDefault, shouldApplyZeroVATByDefault };
};
