import { useEffect } from 'react';
import { IAEType } from 'shared/domain/fiscalStatus/iae/IAEType';
import { localSotrageRepository } from 'shared/infra/repositories/BrowserStorageRepository/LocalSotrageRepository';
import LocationsRepository from 'shared/infra/repositories/LocationsRepository';
import MixPanel from 'shared/infra/utils/user_events/MixPanel';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';

export const MixPanelProfileData = () => {
    useTransformProfileDataToMixPanel();
    return null;
};

export const useTransformProfileDataToMixPanel = () => {
    const {
        userInfo: { id, isAgent, registerDate, plan },
        userStatus: { iaes },
        userWorkAddress: { poblacion_id, provincia_id },
        userIdentity: { email, digitalSign, firstName, lastName, birthDate },
    } = useRootContext();

    useEffect(() => {
        if (+id) {
            MixPanel.identify(id);
            MixPanel.unregister('plan');
            MixPanel.unregister('autonomo');

            getNombreProvinciaYPoblacion(poblacion_id, provincia_id);
            transformData();
        }
    }, [id]);

    const getNombreProvinciaYPoblacion = async (poblacion_id: number, provincia_id: number) => {
        const locationsRepository = new LocationsRepository();
        const provinciaNombreStorage = localSotrageRepository.provinciaNombre(provincia_id);
        const provincia = provinciaNombreStorage.get();
        if (!provincia && provincia_id) {
            const { data: provincias } = await locationsRepository.getProvinces(true);
            const provincia = provincias.find((option) => +option.value === +provincia_id);
            if (provincia?.label) {
                MixPanel.setProfileData({ 'provincia trabajo': provincia.label });
                provinciaNombreStorage.set(provincia.label);
            }
        }
        const poblacionNombreStorage = localSotrageRepository.poblacionNombre(poblacion_id);
        const poblacion = poblacionNombreStorage.get();
        if (provincia_id && !poblacion && poblacion_id) {
            const { data: poblaciones } = await locationsRepository.getTowns(provincia_id);
            const poblacion = poblaciones.find((option) => +option.value === +poblacion_id);
            if (poblacion?.label) {
                MixPanel.setProfileData({ 'poblacion trabajo': poblacion.label });
                poblacionNombreStorage.set(poblacion.label);
            }
        }
    };

    const transformData = () => {
        let total_iaes = 0;
        let iae_principal = {};
        if (iaes && iaes.length) {
            total_iaes = iaes.length;
            const iae = iaes.find((iae: IAEType) => iae.main) || ({} as IAEType);
            iae_principal = {
                'IAE fecha alta': iae.fechaAlta,
                'IAE denominacion': iae.denominacion,
                'IAE exento de IVA': iae.ivaExempt ? 'Sí' : 'No',
                'IAE comercio mayor': +iae.isComercioMayor === 1 ? 'Sí' : 'No',
                'IAE regimen general': +iae.isRegimenGeneral === 1 ? 'Sí' : 'No',
                'IAE subgrupo': iae.subgrupoNombre,
                'IAE deducible 100%': +iae.iaeDeducible100 === 1 ? 'Sí' : 'No',
                'IAE recargo de equivalencia': +iae.equivalenceSurcharge === 1 ? 'Sí' : 'No',
            };
        }

        const planMapper: Record<string, string> = {
            pro: 'Esencial',
            plus: 'Avanzado',
            total: 'Estelar',
        };

        const planLabel = plan.label.toLocaleLowerCase();

        const profileData: Record<string, number | string> = {
            id,
            plan: planMapper[planLabel] ?? planLabel,
            email,
            nombre: firstName,
            apellidos: lastName,
            'fecha registro': registerDate,
            'fecha nacimiento': birthDate,
            'es asesor externo': +isAgent === 1 ? 'Sí' : 'No',
            'certificado digital': +digitalSign === 1 ? 'Sí' : 'No',
            ...iae_principal,
            'total IAEs': total_iaes,
        };
        MixPanel.setProfileData(profileData);
    };
};
