import { Box, Header, Divider, ContentContainer, Notification } from '@declarando/design_system';
import { ReactNode } from 'react';
import { useShouldExpandForm } from './hooks/use-should-expand-form';
import { ExpandableContent } from './shared/components/ExpandableContent';
import { SalesDocumentType, useSalesDocumentFormContext } from '../context/SalesDocumentFormProvider';
import { FeatureFlags } from 'config/FeatureFlags';
import { ClientDetails } from './components/ClientDetails/ClientDetails';
import { AdvancedOptions } from './components/AdvancedOptions/AdvancedOptions';
import { PaymentOptions } from './components/PaymentOptions/PaymentOptions';
import { Lines } from './components/Lines/Lines';
import { SalesDocumentDetails } from './components/SalesDocumentDetails/SalesDocumentDetails';

const FormSectionWrapper = ({
    children,
    title,
    hasPaddingBottom = true,
    showDivider = true,
}: {
    children: ReactNode;
    title?: string;
    showDivider?: boolean;
    hasPaddingBottom?: boolean;
}) => {
    return (
        //TODO: instead of px with gap, transform the key breakpoint to its corresponding value from Box component
        <Box>
            <Box px="md" pt="md" pb={hasPaddingBottom ? 'xl' : null} display="flex" flexDirection="column" gap="24px">
                {title ? <Header as="h3">{title}</Header> : null}
                {children}
            </Box>
            {showDivider ? <Divider /> : null}
        </Box>
    );
};

const getTitleSectionSalesDocumentDetails = (args: { salesDocumentType: SalesDocumentType }) => {
    const MAPPER_TITLE: Record<SalesDocumentType, string> = {
        invoice: 'Datos de la factura',
        quote: 'Datos del presupuesto',
    };

    return MAPPER_TITLE[args.salesDocumentType];
};

export const SalesDocumentFormContent = () => {
    const { shouldShowRestOfFields } = useShouldExpandForm();
    const { formBlocked } = useSalesDocumentFormContext();
    const { salesDocumentType } = useSalesDocumentFormContext();

    return (
        <ContentContainer padding="none">
            {formBlocked.isFormBlocked && formBlocked.blockedReason === 'contabilidad-bloqueada' ? (
                <FormSectionWrapper showDivider={false} hasPaddingBottom={false}>
                    <Notification
                        full
                        title="No puedes modificar un ingreso de un trimestre presentado a Hacienda"
                        tone="warning"
                        message="Si necesitas hacer ajustes en trimestres anteriores, te recomendamos contactar con tu mentor."
                    />
                </FormSectionWrapper>
            ) : null}
            {formBlocked.isFormBlocked && formBlocked.blockedReason === 'quote-converted-to-invoice' ? (
                <FormSectionWrapper showDivider={false} hasPaddingBottom={false}>
                    <Notification
                        full
                        title="No es posible editar un presupuesto convertido a ingreso"
                        tone="warning"
                        message="Conservar el detalle del presupuesto inicial te ayudará a tener una referencia de la cotización aprobada por tu cliente."
                    />
                </FormSectionWrapper>
            ) : null}
            {FeatureFlags.shouldNewInvoiceFormBeEnabled().checker() && salesDocumentType === 'invoice' ? (
                <FormSectionWrapper showDivider={false} hasPaddingBottom={false}>
                    <Notification
                        full
                        title="Avanzamos contigo: registra ingresos con factura desde el 2025 🚀"
                        tone="info"
                        message="Para registrar un ingreso anterior a 2025, añade un nuevo ingreso con factura, elige una fecha de 2024 y el sistema te llevará al proceso anterior."
                    />
                </FormSectionWrapper>
            ) : null}
            <FormSectionWrapper title={getTitleSectionSalesDocumentDetails({ salesDocumentType })}>
                <SalesDocumentDetails />
            </FormSectionWrapper>
            <FormSectionWrapper title="Datos del receptor" showDivider={shouldShowRestOfFields}>
                <ClientDetails />
            </FormSectionWrapper>
            <ExpandableContent shouldExpand={shouldShowRestOfFields}>
                <FormSectionWrapper title="Conceptos">
                    <Lines />
                </FormSectionWrapper>
                <FormSectionWrapper title="Opciones avanzadas">
                    <AdvancedOptions />
                </FormSectionWrapper>
                <FormSectionWrapper title="Opciones de pago" showDivider={false}>
                    <PaymentOptions />
                </FormSectionWrapper>
            </ExpandableContent>
        </ContentContainer>
    );
};
