import PublicV2Repository from './PublicV2Repository';

export default class QuotationByTokenV2Repository extends PublicV2Repository {
    quotationByToken(data) {
        return this.get('/quotation/token/' + data);
    }

    quotationPdfByToken(data) {
        return import.meta.env.VITE_API_URL + '/v2/public/file/quotation/pdf/' + data;
    }
}
