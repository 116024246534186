class IncomesTypes {
    static EMPRESA = 'I001';
    static PARTICULAR = 'I003';
    static EURO = 'I002';
    static NOEURO = 'I004';
    static SINFACTURA = 'IREN';
    static SUBVENCION = 'I006';
}

export default IncomesTypes;
