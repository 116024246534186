import Repository from './Repository';

class SecureRepository extends Repository {
    constructor() {
        super();
        this.apiPath = this.apiPath + '/secure';
    }
}

export default SecureRepository;
