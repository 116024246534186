import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { LocationRepository } from '../domain/LocationRepository';

class LocationService extends DeclarandoApiService {
    private repository: LocationRepository;

    constructor(repository: LocationRepository) {
        super();
        this.repository = repository;
    }

    getCountries() {
        return this.execute(() => this.repository.getCountries(), { shouldThrowError: true, bodyAsData: true });
    }

    getStates() {
        return this.execute(() => this.repository.getStates(), { shouldThrowError: true, bodyAsData: true });
    }

    getCitiesByState(provinceId: number) {
        return this.execute(() => this.repository.getCitiesByState(provinceId), {
            shouldThrowError: true,
            bodyAsData: true,
        });
    }
}

export const createLocationService = (repository: LocationRepository): LocationService => {
    return new LocationService(repository);
};
