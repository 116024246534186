// @ts-nocheck
import V2Repository from './V2Repository';
import Date from 'shared/infra/utils/Date';
import { FeatureFlags } from '../../../config/FeatureFlags';
import { UrlParser } from '../utils/UrlParser';
import { MotivoDeducibilidadOtros } from '../../../features/Expenses/UI/DeducibilidadGasto';
import { Env } from '../../../config/Env';

class GastosRepository extends V2Repository {
    getTipadoGastosConFactura(isSpanish) {
        const countrtySpanish = isSpanish ? '1' : '0';
        return this.get('/gastos/confactura/tipoenum/' + countrtySpanish);
    }

    getSubtipadoGastosConFactura(tipoGasto, date) {
        return this.get('/gastos/confactura/subtipoenum/' + tipoGasto + '?date=' + Date.formatDateToString(date));
    }

    getTipadoGastosSinFactura() {
        return this.get('/gastos/sinfactura/tipoenum');
    }

    getSubtipadoGastosSinFactura(tipoGasto) {
        return this.get('/gastos/sinfactura/subtipoenum/' + tipoGasto);
    }

    postGastoConFactura = (gasto) => {
        const data = {
            ...gasto,
            totalAmount: Number(gasto.totalAmount),
            providerType: Number(gasto.providerType),
            deductiblePercent: String(gasto.deductiblePercent),
            date: Date.formatDateToString(gasto.date),
        };

        return this.post('/gastos/gastoConFactura', JSON.stringify(data));
    };

    postGastoSinFactura = (gasto) => {
        const data = {
            ...gasto,
            providerType: Number(gasto.providerType),
            deductiblePercent: String(gasto.deductiblePercent),
            date: Date.formatDateToString(gasto.date),
        };

        return this.post('/gastos/gastoSinFactura', JSON.stringify(data));
    };

    async getProveedoresEnum() {
        return this.get('/proveedores/enum');
    }

    async getGastoConFactura(gastoUUID) {
        return this.get('/gastos/gastoConFactura/' + gastoUUID);
    }

    getFromTransaccion(trasactionId) {
        return this.get('/gastos/transaction/' + trasactionId);
    }

    getProveedoresGastosAlquiler() {
        return this.get('/proveedores/enum/gastos/alquiler');
    }

    getRules() {
        return this.get('/gastos/reglas');
    }

    setExpenseReconciled(transactionId, expenseId) {
        const data = {
            transactionId,
            expenseId,
        };

        return this.post('/gastos/contabilize', JSON.stringify(data));
    }

    deleteRule(rule) {
        return this.delete('/gastos/regla', JSON.stringify(rule));
    }

    createRule(rule, isConFactura) {
        if (isConFactura) {
            return this.post('/gastos/confactura/regla', JSON.stringify(rule));
        } else {
            return this.post('/gastos/sinfactura/regla', JSON.stringify(rule));
        }
    }

    getListado(page, rows, filters, orders) {
        return this.post(
            '/gastos/listado',
            JSON.stringify({
                page,
                rows,
                filters,
                orders,
            }),
        );
    }

    async getGastoIvaImportacion(uuid) {
        return this.get(`/gastos/gasto-con-factura/${uuid}/datos-importacion`);
    }

    editarGastoTrimestreCerrado(data) {
        return this.post('/gastos/gasto-con-factura/trimestre-cerrado', JSON.stringify(data));
    }

    downloadFacturaDiva(id) {
        return this.downloadFile(this.apiPath + '/gastos/ticket/pdf/' + id);
    }

    async getTipoIvasHabilitados(props) {
        return this.post('/gastos/gastoConFactura/tipoIvasHabilitados', JSON.stringify(props));
    }

    async getReglasAlquiler(data) {
        if (FeatureFlags.mockGastoTipoAlquiler()) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        alquiler_irpf_forzado: Math.random() * 10 > 5 ? 19 : null,
                    });
                }, 2000);
            });
        }

        if (data.gastoId === '') {
            delete data.gastoId;
        }

        const queryString = UrlParser.setQueryParams(data);
        return this.get('/gastos/confactura/reglas-alquiler?' + queryString);
    }

    async deleteExpense(conFactura, uuid) {
        const tipo = conFactura ? 'gastoConFactura' : 'gastoSinFactura';
        return this.delete(`/gastos/${tipo}/${uuid}`);
    }

    getProviderDataByIdOrUuId(id) {
        return this.get('/proveedor/' + id + '/find');
    }

    getUninvoicedExpense = (number) => {
        return this.get('/gasto/sin-factura/' + number);
    };

    async invoiceNumberExist({ number, providerId, year, gastoId }) {
        const params = {
            number,
            providerId,
            year,
        };
        if (gastoId) {
            params.gastoId = gastoId;
        }
        const queryParams = new URLSearchParams(params);

        return this.get(`/gasto/con-factura/existe?${queryParams}`);
    }

    uninvoiceNumberExist(number) {
        return this.post(
            '/gasto/sin-factura/existe',
            JSON.stringify({
                number,
            }),
        );
    }

    async gastoDeducibilidadDiaria(data: {
        isSpain: boolean;
        fecha: string;
        isPernocta: boolean;
        gastoUuid: string | null;
    }): Promise<{
        limite: number;
        consumido: number;
        isSpain: boolean;
        isPernocta: boolean;
    }> {
        return this.post('/gasto/deducibilidad/diaria', JSON.stringify(data));
    }

    getImpactInvoicedRentaHelp(formData) {
        const data = {
            ...formData,
            date: Date.formatDateToString(formData.date),
        };
        return this.post(
            '/mensaje-ayuda/renta/gasto/con-factura',
            JSON.stringify({
                data,
            }),
        );
    }

    getImpactUninvoicedRentaHelp(formData) {
        const data = {
            ...formData,
            date: Date.formatDateToString(formData.date),
        };
        return this.post(
            '/mensaje-ayuda/renta/gasto/sin-factura',
            JSON.stringify({
                data,
            }),
        );
    }

    async getListaMotivosDeducibilidadVehiculo() {
        if (Env.isLocal('adrianM')) {
            return [
                { label: 'Algo', value: 'algo' },
                { label: 'otros', value: MotivoDeducibilidadOtros },
            ];
        }
        return this.get('/gastos/gasto-con-factura/vehiculo/motivos');
    }
}

export default GastosRepository;
