import { createInvoiceNextNumberAutomaticRepository } from '../../IngresoConFactura/infra/InvoiceNextNumberAutomaticRepository';
import { createInvoiceNextNumberService } from '../../IngresoConFactura/services/InvoiceNextNumberService';
import { createQuoteNextNumberAutomaticRepository } from '../../Quote/infra/QuoteNextNumberAutomaticRepository';
import { createQuoteNextNumberService } from '../../Quote/services/QuoteNextNumberService';
import { SalesDocumentType } from '../UI/SalesDocumentFormView/context/SalesDocumentFormProvider';
import { SalesDocumentNextNumberAutomaticService } from './SalesDocumentNumberService';

export class SalesDocumentNumberServiceFactory {
    static create(documentType: SalesDocumentType): SalesDocumentNextNumberAutomaticService {
        switch (documentType) {
            case 'invoice':
                return createInvoiceNextNumberService(createInvoiceNextNumberAutomaticRepository());
            case 'quote':
                return createQuoteNextNumberService(createQuoteNextNumberAutomaticRepository());
            default:
                throw new Error(`Unsupported document type: ${documentType}`);
        }
    }
}
