import { useContext, useRef } from 'react';
import IframeResizer from 'iframe-resizer-react';
import { Box, ButtonText } from '@declarando/design_system';
import { OnboardingContext } from '../../Onboarding';
import { Env } from 'config/Env';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';

export function Step2Bankflip() {
    const iframeRef = useRef(null);
    const { retrocederPaso } = useContext(OnboardingContext);

    const retroceder = () => {
        TrackingEvents.clickElement({ event_label: 'Vuelve atrás desde documentacion-inicio' });
        retrocederPaso();
    };

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'sm'} mt={'xxl'} mb={'xxl'}>
            <Box bg={'white'} mx={'auto'} display={'flex'} justifyContent={'center'}>
                <ButtonText
                    icon={'arrow-left'}
                    onClick={retroceder}
                    actionLabel={'Todavía no tengo mi certificado digital'}
                />
            </Box>
            <Box
                width={'100%'}
                maxWidth={800}
                height={'1400px'}
                pt={'0'}
                mt={'0'}
                display={'flex'}
                justifyContent={'center'}
            >
                <IframeResizer
                    forwardRef={iframeRef}
                    heightCalculationMethod="lowestElement"
                    inPageLinks
                    log
                    checkOrigin={
                        Env.isProd()
                            ? ['https://bkfp.io', 'https://widget.bankflip.io']
                            : ['https://s.bkfp.io', 'https://widget.sandbox.bankflip.io']
                    }
                    src={decodeURIComponent(Env.get('VITE_BANKFLIP_URL'))}
                    style={{
                        width: '100%',
                        minWidth: '100%',
                        minHeight: '100vh',
                        border: 'none',
                        marginTop: 0,
                        padding: 0,
                        overflow: 'scroll',
                    }}
                />
            </Box>
        </Box>
    );
}
