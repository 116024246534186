import { SalesDocumentSendEmailRepository } from '../../shared/domain/SalesDocumentSendEmailWithDocument';
import { SalesDocumentSendEmailService } from '../../shared/services/SalesDocumentSendEmailService';

class InvoiceSendEmailService extends SalesDocumentSendEmailService {
    constructor(repository: SalesDocumentSendEmailRepository) {
        super(repository);
    }
}

export const createInvoiceSendEmailService = (
    repository: SalesDocumentSendEmailRepository,
): InvoiceSendEmailService => {
    return new InvoiceSendEmailService(repository);
};
