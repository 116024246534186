import { Invoice } from '../domain/Invoice';

export const invoiceDefaultValues: Invoice = {
    id: '',
    documentNumber: '',
    issueDate: undefined as unknown as string,
    dueDate: '',
    incomeTax: 0,
    client: {
        id: '',
        clientType: '' as Invoice['client']['clientType'],
        fiscalId: '',
        hasEUVatNumber: undefined as unknown as Invoice['client']['hasEUVatNumber'],
        name: '',
        country: {
            id: '',
            code: '',
            name: '',
            isEuropean: '' as unknown as Invoice['client']['country']['isEuropean'],
        },
        address: '',
        state: {
            id: '',
            name: '',
        },
        city: {
            id: '',
            name: '',
        },
        postalCode: '',
        phone: {
            prefix: '',
            number: '',
        },
        email: '',
    },
    activity: {
        id: '',
        section: '',
        epigraph: '',
        name: '',
        isVatExempt: false,
        registrationDate: '',
        withImageRights: undefined as unknown as Invoice['activity']['withImageRights'],
        forFattening: undefined as unknown as Invoice['activity']['forFattening'],
        activityType: undefined as unknown as Invoice['activity']['activityType'],
    },
    withEquivalencySurcharge: null,
    lines: [
        {
            quantity: 1,
            price: 0,
            totalLine: 0,
            discount: undefined,
            vat: 21,
            equivalencySurcharge: 0,
            description: '',
            productType: 'SERVICES',
        },
    ],
    paymentOptions: {
        cash: { isChecked: false },
        other: { isChecked: false, details: '' },
        bankTransfer: { isChecked: false, details: '' },
    },
    documentNote: '',
};
