import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { IngresoConFacturaClientRulesService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaRulesService';
import { useState, useEffect } from 'react';
import DateWrapper from 'shared/infra/utils/Date';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { IssueDateInput } from '../IssueDateInput';
import { Modal, Box, Button, P } from '@declarando/design_system';
import image from './Imagen-aclaratoria.png';
import { useInvoiceDateValidation } from '../../hooks/useInvoiceDateValidation';

type IssueDateValidationModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const IssueDateValidationModal = ({ isOpen, onClose }: IssueDateValidationModalProps) => (
    <Modal
        title="Existen facturas con una fecha posterior"
        isOpen={isOpen}
        onClose={onClose}
        variant="small"
        onAction={onClose}
        customFooter={
            <Box display="flex" justifyContent="center" flex={1}>
                <Button label="Entendido" type="button" onClick={onClose} />
            </Box>
        }
    >
        <P>Tienes facturas creadas con una fecha posterior a la seleccionada.</P>{' '}
        <P>
            Comprueba que tus facturas sean correlativas en fecha y número para evitar problemas con Hacienda como en el
            siguiente ejemplo
        </P>
        <Box marginTop="md">
            <img
                src={image}
                alt="explicación de facturas correlativas con ejemplos"
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                }}
            />
        </Box>
    </Modal>
);

export const IssueDateManualNumbering = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const watchIssueDate = useCustomWatch({ control, name: 'issueDate' });
    const formattedDate = watchIssueDate ? DateWrapper.formatNativeDateToString(watchIssueDate, 'yyyy-MM-dd') : null;

    const { ingresosConFacturaList, isLoading } = useInvoiceDateValidation({
        formattedDate,
    });

    useEffect(() => {
        if (isLoading || !ingresosConFacturaList?.length || !formattedDate) return;

        const isIssueDateValid = IngresoConFacturaClientRulesService.isExpeditionDateAfterLastInvoiceDate({
            currentDate: formattedDate,
            lastInvoiceIssueDate: ingresosConFacturaList[0].issueDate,
        });

        if (!isIssueDateValid) {
            setIsModalOpen(true);
        }
    }, [ingresosConFacturaList, isLoading, formattedDate]);

    return (
        <div>
            <IssueDateValidationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <IssueDateInput />
        </div>
    );
};
