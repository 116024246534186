//@ts-nocheck

//TODO: Delete ts-nocheck and write types

class DocumentValidator {
    static NIF_TYPE = 'NIF';
    static NIE_TYPE = 'NIE';
    static CIF_TYPE = 'CIF';

    static NIF_REGEX = /^(\d{8})([A-Z])$/;
    static NIE_REGEX = /^[MXYZ]\d{7}[A-Z]$/;
    static CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    static ES_PROVIDER_REGEX = /^ES[A-Za-z0-9]+$|N[A-Za-z0-9]+$/;

    static getIdType(str) {
        if (str.match(this.NIF_REGEX)) {
            return this.NIF_TYPE;
        }
        if (str.match(this.NIE_REGEX)) {
            return this.NIE_TYPE;
        }
        if (str.match(this.CIF_REGEX)) {
            return this.CIF_TYPE;
        }
    }

    static isValidSpanishId(str) {
        let valid = false;
        if (str) {
            str = this.cleanNationalPrefix(str);
            let type = this.getIdType(str);

            switch (type) {
                case this.NIF_TYPE:
                case this.NIE_TYPE:
                    valid = this.isValidDNI(str);
                    break;
                case this.CIF_TYPE:
                    valid = this.isValidCIF(str);
                    break;
            }
        }
        return valid;
    }

    static isValidSpanishProviderCIF(str) {
        return str.match(this.ES_PROVIDER_REGEX) !== null;
    }

    static isValidDNI(str) {
        str = this.cleanNationalPrefix(str);
        const primera = str.substr(0, 1);
        const dni = str.toUpperCase();
        let DNI_REGEX = '';

        if (['M', 'X', 'Y', 'Z'].includes(primera)) {
            DNI_REGEX = this.NIE_REGEX;
        } else {
            DNI_REGEX = this.NIF_REGEX;
        }

        if (DNI_REGEX.test(dni) === true) {
            if (primera === 'M' && dni.match(DNI_REGEX)) {
                return true;
            }
            const conjunto_letras = 'TRWAGMYFPDXBNJZSQVHLCKET';
            const letraFinal = dni.substr(dni.length - 1, 1);
            let numero = dni.substr(0, dni.length - 1);
            numero = numero.replace('X', 0);
            numero = numero.replace('Y', 1);
            numero = numero.replace('Z', 2);
            numero = numero % 23;
            const letra = conjunto_letras.substring(numero, numero + 1);

            return letra === letraFinal;
        } else {
            return false;
        }
    }

    static isValidCIF(str) {
        if (!str.match(this.CIF_REGEX)) {
            return false;
        }
        str = this.cleanNationalPrefix(str);
        const match = str.match(this.CIF_REGEX);
        const letter = match[1],
            number = match[2],
            control = match[3];
        let even_sum = 0,
            odd_sum = 0;

        for (let i = 0; i < number.length; i++) {
            let n = parseInt(number[i], 10);
            // Odd positions (Even index equals to odd position. i=0 equals first position)
            if (i % 2 === 0) {
                // Odd positions are multiplied first.
                n *= 2;
                // If the multiplication is bigger than 10 we need to adjust
                odd_sum += n < 10 ? n : n - 9;
                // Even positions
                // Just sum them
            } else {
                even_sum += n;
            }
        }

        const lastDigit = (even_sum + odd_sum).toString().substr(-1);
        let control_digit = 0;

        if (String(lastDigit) !== String(0)) {
            control_digit = 10 - lastDigit;
        }

        const conjunto_letras = 'JABCDEFGHI';
        let control_letter = conjunto_letras.substr(control_digit, 1);

        // Control must be a digit
        if (letter.match(/[ABEH]/)) {
            return String(control) === String(control_digit);

            // Control must be a letter
        } else if (letter.match(/[PQSW]/)) {
            return String(control) === String(control_letter);

            // Can be either
        } else {
            return String(control) === String(control_digit) || String(control) === String(control_letter);
        }
    }

    static getCifCountryCode(countryISO) {
        if (countryISO === 'GR') return 'EL';
        return countryISO;
    }

    static isValidEuroCIF(str) {
        const NIF_EUR_REGEX = /^[A-Za-z]{2}[A-Za-z0-9]{2,12}$/;
        if (str.match(NIF_EUR_REGEX) && str.length <= 14) {
            const paisCIF = str.substr(0, 2);
            const RegexNifEuMap = {
                DE: /^DE[0-9]{9}$/i,
                EE: /^EE[0-9]{9}$/i,
                EL: /^EL[0-9]{9}$/i,
                PT: /^PT[0-9]{9}$/i,
                AT: /^ATU[0-9]{8}$/i,
                CY: /^CY[0-9a-z]{9}$/i,
                BG: /^BG[0-9]{9,10}$/i,
                DK: /^DK[0-9]{8}$/i,
                SI: /^SI[0-9]{8}$/i,
                FI: /^FI[0-9]{8}$/i,
                HU: /^HU[0-9]{8}$/i,
                LU: /^LU[0-9]{8}$/i,
                MT: /^MT[0-9]{8}$/i,
                FR: /^FR[0-9a-z]{2}[0-9]{9}$/i,
                GB: /^GB[0-9]{9}$/i,
                NL: /^NL[0-9b]{12}$/i,
                IT: /^IT[0-9]{11}$/i,
                LV: /^LV[0-9]{11}$/i,
                HR: /^HR[0-9]{11}$/i,
                IE: /^(IE[0-9]{7,8}[a-z]{1,2}|IEX[0-9]{7}[a-z]{0,1})$/i,
                BE: /^BE0[0-9]{9}$/i,
                PL: /^PL[0-9]{10}$/i,
                SK: /^SK[0-9]{10}$/i,
                CZ: /^CZ[0-9]{8,10}$/i,
                RO: /^RO[0-9]{2,10}$/i,
                SE: /^SE[0-9]{12}$/i,
                LT: /^LT([0-9]{9}|[0-9]{12})$/i,
            };

            if (!RegexNifEuMap[paisCIF]) {
                return false;
            }
            return Boolean(str.match(RegexNifEuMap[paisCIF]));
        } else {
            return false;
        }
    }

    static isComunidadBienes(nif) {
        const COM_REGEX = /^([E])(\d{7})([0-9A-J])$/;
        return nif.match(COM_REGEX);
    }

    static isDeclarandoCif(cif) {
        return cif === 'B12936720';
    }

    static isNifMaestroProveedor(nif) {
        return nif === 'B00000000';
    }

    /** DEPRECATED isProviderMaestro to nifMaestroProveedor  **/
    static isProviderMaestro(nif) {
        return [
            'A00000000',
            'B00000000',
            'C00000000',
            'D00000000',
            'E00000000',
            'F00000000',
            'G00000000',
            'H00000000',
            'J00000000',
            'R00000000',
            'U00000000',
            'V00000000',
            '00000000X',
        ].includes(nif);
    }

    static isClientMaestro(nif) {
        return nif === '00000000L';
    }

    static isValidEuroNIF(nif) {
        const CIF_EU = /^EU[0-9]{9}$/;
        const CIF_UE = /^UE[0-9]{9}$/;
        const validEU = nif.toUpperCase().match(CIF_EU) !== null;
        const validUE = nif.toUpperCase().match(CIF_UE) !== null;

        return validEU || validUE;
    }

    static isValidWNif(nif) {
        const W_NIF = /^W[0-9]{7}[A-J]$/;
        return nif.toUpperCase().match(W_NIF);
    }

    static isValidNNif(nif) {
        const N_NIF = /^N[0-9]{7}[A-J]$/;
        return nif.toUpperCase().match(N_NIF);
    }

    static seemsEuroNif(nif) {
        if (nif) {
            return this.isValidEuroNIF(nif) || this.isValidWNif(nif) || this.isValidNNif(nif);
        }
        return false;
    }

    static isValidId(nif) {
        return this.isValidSpanishId(nif) || this.isValidEuroCIF(nif) || this.isValidEuroNIF(nif);
    }

    static isSpecialCIF(cif) {
        const regex = /^(K|L|M|X|Y|Z)/gm;
        const n = regex.exec(cif);

        return n != null;
    }

    static cleanNationalPrefix(str) {
        str = str.toUpperCase();
        let extrajero = str.substr(0, 2);
        if (extrajero === 'ES') {
            // NIF extranjero se corta para factura española
            str = str.substr(2, str.length - 2);
        }
        return str;
    }
}

export default DocumentValidator;
