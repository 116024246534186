import { Box, CheckBox, TextArea } from '@declarando/design_system';
import { ExpandableContent } from '../../../shared/components/ExpandableContent';
import { useState } from 'react';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { SalesDocumentType, useSalesDocumentFormContext } from '../../../../context/SalesDocumentFormProvider';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { BaseSalesDocumentForm } from 'features/Ingresos/SalesDocument/shared/domain/SalesDocument';
import { ingresoConFacturaRulesClient } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/IngresoConFacturaRulesClient';

const LabelMapper: Record<SalesDocumentType, string> = {
    invoice: 'Añadir nota en el documento de la factura',
    quote: 'Añadir nota en el documento del presupuesto',
};

const EventTrackMapper: Record<SalesDocumentType, { item_id: string; event_label: string }> = {
    invoice: { item_id: 'Nota factura', event_label: 'Añade nota a la factura' },
    quote: { item_id: 'Nota presupuesto', event_label: 'Añade nota al presupuesto' },
};

export const DocumentNote = () => {
    const { control, setValue } = useCustomFormContext<BaseSalesDocumentForm>();
    const watchDocumentNote = useCustomWatch({ control, name: 'documentNote' });
    const [isNotaDocumentoEnabled, setIsNotaDocumentoEnabled] = useState(!!watchDocumentNote);
    const { formBlocked, salesDocumentType } = useSalesDocumentFormContext();

    return (
        <Box display="flex" flexDirection="column" gap="8px">
            <CheckBox
                disabled={formBlocked.isFormBlocked}
                id="checker-sales-document-note"
                label={LabelMapper[salesDocumentType]}
                checked={isNotaDocumentoEnabled}
                onChange={() => {
                    setIsNotaDocumentoEnabled(!isNotaDocumentoEnabled);
                    setValue('documentNote', '');
                    if (!isNotaDocumentoEnabled) {
                        TrackingEvents.fillField(EventTrackMapper[salesDocumentType]);
                    }
                }}
            />
            <ExpandableContent shouldExpand={isNotaDocumentoEnabled}>
                <ControlledFormField
                    control={control}
                    name="documentNote"
                    Component={({ field }) => (
                        <TextArea
                            {...field}
                            readOnly={formBlocked.isFormBlocked}
                            id="sales-document-note"
                            showClearButton={false}
                            minHeight="48px"
                            rows={1}
                            maxLength={ingresoConFacturaRulesClient.MAX_CHARACTERS_NOTES}
                            shouldHideMaxCharacters
                            resize="vertical"
                        />
                    )}
                />
            </ExpandableContent>
        </Box>
    );
};
