import React, { Component } from 'react';
import { Box, Grid, Input, Select } from '@declarando/design_system';
import Validators from 'shared/infra/utils/Validators';
import PropTypes from 'prop-types';

class NeighborhoodForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorCPostal: '',
            errorPoblacion: '',
            errorProvincia: '',
            codigosPostales: [],
        };
    }

    getCodigosPostales(poblacionId) {
        if (!poblacionId || !this.state.towns) {
            return;
        }

        this.state.towns.forEach((town) => {
            if (+town.value !== +poblacionId) {
                return;
            }
            const codigosPostales = [];
            town.codigos_postales.forEach((cp) => {
                codigosPostales.push({ label: cp, value: cp });
            });
            let cp = this.props.cp;
            if (town.codigos_postales.length === 1) {
                cp = town.codigos_postales[0].trim();
            } else if (!town.codigos_postales.includes(this.props.cp)) {
                cp = null;
            }
            this.props.handleCpChange(cp);
            this.setState(
                {
                    codigosPostales,
                },
                () => this.props.handlePoblacionChange(poblacionId),
            );
        });
    }

    componentDidMount() {
        this.loadProvinces();

        if (this.props.province > 0) {
            this.loadTowns(this.props.province);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.province !== prevProps.province) {
            this.loadTowns(this.props.province);
        }
    }

    loadProvinces() {
        this.props.getProvinces().then((response) => {
            this.setState({
                provinces: response.data,
            });
        });
    }

    loadTowns(province) {
        if (province) {
            this.props.getTowns(province).then((response) => {
                this.setState({
                    towns: response.data,
                });
                this.getCodigosPostales(this.props.town);
            });
        }
    }

    filtrarProvincias(provincias, isCanario) {
        if (!provincias) return [];
        return (
            provincias?.filter((item) => {
                if (isCanario) {
                    return [38, 35].includes(+item.value);
                } else if (isCanario === false) {
                    return ![38, 35].includes(+item.value);
                }
                return item;
            }) ?? []
        );
    }

    updateProvincia(provincia_id) {
        this.props.handleProvinciaChange(provincia_id, () => {
            this.props.handlePoblacionChange(0);
            this.loadTowns(provincia_id);
            if (!this.state.poblacionId) {
                this.setState({
                    codigosPostales: [],
                });
            }
        });
    }

    isValidated() {
        const validation = this._validateData();
        const errors = this._validationErrors(validation);

        this.setState({
            errorPoblacion: errors.poblacion,
            errorProvincia: errors.provincia,
            errorCPostal: errors.cpostal,
        });

        return validation.provincia && validation.poblacion && validation.cpostal;
    }

    _validateData() {
        return {
            provincia: !this.props.required || this.props.province > 0,
            poblacion: !this.props.required || this.props.town > 0,
            cpostal: this._isValidCp(),
        };
    }

    _isValidCp() {
        let validCp = false;
        if (this.props.required) {
            validCp = this.props.cp ? Validators.isValidPostalCode(this.props.cp) : false;
        } else {
            validCp = this.props.cp ? Validators.isValidPostalCode(this.props.cp) : true;
        }

        return validCp;
    }

    _validationErrors(obj) {
        return {
            poblacion: obj.poblacion ? '' : this.props.labelErrorTown,
            provincia: obj.provincia ? '' : this.props.labelErrorProvince,
            cpostal: obj.cpostal ? '' : this.props.labelErrorCP,
        };
    }

    render() {
        return (
            <>
                <Grid gridTemplateColumns={['1fr', '1fr 1fr 1fr']} gridRowGap="md" gridColumnGap="sm">
                    <Box>
                        <Select
                            label={this.props.labelProvince}
                            required={this.props.required}
                            name="provincia"
                            id={'select-provincia-' + this.props.formName}
                            value={this.props.province ?? ''}
                            options={this.filtrarProvincias(this.state.provinces, this.props.isCanario)}
                            clearable={false}
                            placeholder={this.props.labelPlaceholderFilter}
                            onChange={(value) => {
                                this.setState({ errorProvincia: '' });
                                this.updateProvincia(value);
                            }}
                            error={this.state.errorProvincia}
                            message={this.state.errorProvincia}
                        />
                    </Box>
                    <Box>
                        <Select
                            label={this.props.labelTown}
                            required={this.props.required}
                            name="poblacion"
                            id={'select-poblacion-' + this.props.formName}
                            value={this.props.town ?? ''}
                            disabled={this.props.province === null || this.props.province === 0}
                            options={this.state.towns}
                            placeholder={this.props.labelPlaceholderFilter}
                            onChange={(value) => {
                                this.setState({ errorPoblacion: '', errorCPostal: '' });
                                this.props.handlePoblacionChange(value);
                                this.getCodigosPostales(value);
                            }}
                            error={this.state.errorPoblacion}
                            message={this.state.errorPoblacion}
                        />
                    </Box>
                    <Box>
                        {this.state.codigosPostales.length > 1 && (
                            <Select
                                label={this.props.labelPostalCode}
                                required={this.props.required}
                                name="poblacion"
                                id={'cp-' + this.props.formName}
                                value={this.props.cp ?? ''}
                                onChange={(value) => {
                                    this.setState({ errorCPostal: '' });
                                    this.props.handleCpChange(value);
                                }}
                                options={this.state.codigosPostales}
                                placeholder={'Selecciona CP'}
                                error={this.state.errorCPostal}
                                message={this.state.errorCPostal}
                            />
                        )}
                        {this.state.codigosPostales.length === 1 && (
                            <Input
                                required={this.props.required}
                                label={this.props.labelPostalCode}
                                id={'cp-' + this.props.formName}
                                value={this.props.cp ?? ''}
                                maxLength={5}
                                inform
                            />
                        )}
                    </Box>
                </Grid>
            </>
        );
    }
}

NeighborhoodForm.propTypes = {
    /** Postal code select onChange handler */
    required: PropTypes.bool,
    /** Id used as part of inputs Ids*/
    formName: PropTypes.string,
    /**  Test to be shown when town input has an error */
    labelErrorTown: PropTypes.string,
    /**  Test to be shown when Province input has an error */
    labelErrorProvince: PropTypes.string,
    /**  Test to be shown when CP input has an error */
    labelErrorCP: PropTypes.string,
    /** Label of Province Input Label */
    labelProvince: PropTypes.string,
    /** Label of Province Input Label */
    labelTown: PropTypes.string,
    /** Label of Province Input Label */
    labelPostalCode: PropTypes.string,
    /** Placeholder for selects  */
    labelPlaceholderFilter: PropTypes.string,
    /**  Funtion must provide promise with response options for Provinces select */
    getProvinces: PropTypes.func.isRequired,
    /**  Funtion must provide promise with response options for Towns select */
    getTowns: PropTypes.func.isRequired,
    /**  Province select value */
    province: PropTypes.string,
    /**  Town select value */
    town: PropTypes.string,
    /**  Postal Code value */
    cp: PropTypes.string,
    /** Province select onChange handler */
    handleProvinciaChange: PropTypes.func,
    /** Town select onChange handler */
    handlePoblacionChange: PropTypes.func,
    /** Postal code select onChange handler */
    handleCpChange: PropTypes.func,

    isCanario: PropTypes.bool,
};

NeighborhoodForm.defaultProps = {
    formName: 'neighborhoodForm',
    required: false,
};

export default NeighborhoodForm;
