import { ClienteSchema } from 'features/Incomes/services/schemas/ClienteSchema';
import { LegacyClient } from 'features/Ingresos/Clientes/adapters/LegacyClient';
import { useReducer } from 'react';
import { InputModel } from 'shared/domain/types/form/InputModel';
import { AccionesFormulario, InputValue, useFormulario } from 'shared/services/formulario/Formulario';
import { Errores } from 'shared/services/ValidationService';
import { LegacyClientSchema } from '../schema/LegacyClientSchema';

export type ClienteForm = { [Prop in keyof Omit<LegacyClient, 'id'>]: InputModel };
export type ClienteFormKey = keyof ClienteForm;

export const useFormularioClienteReducer = () => {
    const [form, dispatch] = useReducer(
        (
            form: ClienteForm,
            {
                inputKey,
                phonePart,
                prop,
                value,
                action,
                validate = true,
            }: {
                inputKey?: ClienteFormKey;
                value?: InputValue;
                phonePart?: 'number' | 'prefix';
                prop?: keyof InputModel;
                action: any;
                validate?: boolean;
            },
        ) => {
            if (action === AccionesFormulario.cleanForm) {
                return ClienteSchema;
            }

            if (!inputKey) return form;

            const input = form[inputKey];
            if (action === AccionesFormulario.error) {
                return { ...form, [inputKey]: { ...form[inputKey], error: value } };
            }
            if (action === AccionesFormulario.update) {
                return {
                    ...form,
                    [inputKey]: {
                        ...input,
                        value,
                        error: validate ? input.validate(value, input.required, input.invalidValues) : '',
                    },
                };
            } else if (action === AccionesFormulario.updatePhone) {
                const phone = { ...form.phone.value };
                phone[phonePart as string] = value;
                return {
                    ...form,
                    [inputKey]: {
                        ...input,
                        value: phone,
                        error: validate ? input.validate(phone, input.required) : '',
                    },
                };
            } else if (action === AccionesFormulario.updateProp) {
                return {
                    ...form,
                    [inputKey]: { ...input, [prop as string]: value, error: Errores.noErrors },
                };
            } else if (action === AccionesFormulario.init) {
                return { ...form, [inputKey]: { ...form[inputKey], value: value } };
            }

            return form;
        },
        LegacyClientSchema,
    );

    return useFormulario(form, dispatch);
};
