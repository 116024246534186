import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { useGetCountriesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { IngresoConFacturaClientRulesService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaRulesService';

export const useShouldShowNIFIVAQuestion = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const client = useCustomWatch({ control, name: 'client' });
    const { countries, isLoading } = useGetCountriesList();

    if (isLoading || !client.country.code) return { shouldShowNIFIVAQuestion: false };

    const filteredCountry = countries?.find((country) => country.code === client.country.code);

    if (!filteredCountry) throw new Error('Country not found');

    const isNIFIVARequired = IngresoConFacturaClientRulesService.isNIFIVARequired({
        country: filteredCountry,
        clientType: client.clientType,
    });

    return { shouldShowNIFIVAQuestion: isNIFIVARequired };
};
