import { SubHeader } from 'shared/UI/components/SubHeader/SubHeader';
import { SalesDocumentFormLayout } from '../../../shared/UI/SalesDocumentFormView/SalesDocumentFormLayout';
import { invoiceDefaultValues } from 'features/Ingresos/SalesDocument/IngresoConFactura/UI/invoice-default-values';
import { useCreateQuote } from './hooks/useCreateQuote';
import { getQuoteSchema } from '../../validations/QuoteSchema';

export const QuoteCreationView = () => {
    const { handleSubmit, isMutationPending } = useCreateQuote();

    const quoteDefaultValues = {
        ...invoiceDefaultValues,
        issueDate: new Date(),
    };

    return (
        <div style={{ lineHeight: '24px' }}>
            <SubHeader title="Nuevo presupuesto" contextPath="/ingresos/presupuestos" />
            <SalesDocumentFormLayout
                salesDocumentSchema={getQuoteSchema({ salesDocumentMode: 'create', defaultValues: quoteDefaultValues })}
                salesDocumentType="quote"
                salesDocumentMode="create"
                handleSubmit={handleSubmit}
                isMutationPending={isMutationPending}
                defaultValues={quoteDefaultValues}
                formBlocked={{ isFormBlocked: false }}
            />
        </div>
    );
};
