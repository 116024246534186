import { DeclarandoApiService } from 'shared/services/DeclarandoApiService';
import { ClientRepository } from '../domain/ClientRepository';
import { ClientListParams } from '../adapters/ClientListDTO';
import { Client } from '../domain';

export class ClientsService extends DeclarandoApiService {
    private repository: ClientRepository;

    constructor(repository: ClientRepository) {
        super();
        this.repository = repository;
    }

    createClient(client: Client) {
        return this.execute(() => this.repository.create(client), { shouldThrowError: true });
    }

    getClient(clienteId: string) {
        return this.execute(() => this.repository.getById(clienteId), { shouldThrowError: true });
    }

    getClientsList(params: ClientListParams) {
        return this.execute(() => this.repository.getList(params), { shouldThrowError: true, bodyAsData: true });
    }
}

export const createClientsService = (repository: ClientRepository): ClientsService => {
    return new ClientsService(repository);
};
