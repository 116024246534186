import { Box, Grid, TextArea, Input, AmountInput, ActionLink, Divider } from '@declarando/design_system';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useCallback, useMemo } from 'react';
import { useCustomFieldArray } from 'shared/UI/components/Form/hooks/useCustomFieldArray';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useSalesDocumentLineCalculations } from '../../hooks/useSalesDocumentCalculations';
import { useGetTaxes } from '../ClientDetails/hooks/get-available-taxes';
import { invoiceDefaultValues } from '../../../../../../IngresoConFactura/UI/invoice-default-values';
import { useSalesDocumentFormContext } from '../../../context/SalesDocumentFormProvider';
import { LinesSkeletons } from './components/LinesSkeletons/LinesSkeletons';
import { LineProductType } from './components/LineProductType/LineProductType';
import { LineDiscount } from './components/LineDiscount/LineDiscount';
import { LineVAT } from './components/LineVAT/LineVAT';
import { LineTotal } from './components/LineTotal/LineTotal';
import { LineTaxExemption } from './components/LineTaxExemption/LineTaxExemption';
import { SalesDocumentLine } from 'features/Ingresos/SalesDocument/shared/domain/SalesDocument';
import { useAssignZeroVATByDefault } from './hooks/useAssignZeroVATByDefault';
import { ingresoConFacturaRulesClient } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/IngresoConFacturaRulesClient';

export type LinesProps = {
    index: number;
    handleLineChange: (
        index: number,
        field: keyof SalesDocumentLine,
        value: any,
        options?: { shouldTriggerValidation?: boolean; shouldDirty?: boolean },
    ) => void;
};

export const Lines = () => {
    const { formBlocked } = useSalesDocumentFormContext();
    const { control, getValues } = useCustomFormContext<InvoiceForm>();
    const { fields, append, remove } = useCustomFieldArray({ control, name: 'lines' });
    const { data, isLoading } = useGetTaxes();
    const { handleLineChange } = useSalesDocumentLineCalculations(
        useMemo(() => data?.data?.taxes, [data?.data?.taxes]),
    );
    const { shouldApplyZeroVATByDefault } = useAssignZeroVATByDefault();

    const memoizedHandleLineChange = useCallback(
        (index: number, field: keyof SalesDocumentLine, value: any) => {
            handleLineChange(index, field, value, { shouldTriggerValidation: false });
        },
        [handleLineChange],
    );

    const isLastItem = (index: number) => index === fields.length - 1;

    if (isLoading) return <LinesSkeletons />;

    return (
        <>
            {fields.map((field, index) => {
                return (
                    <Box key={field.id} aria-label={`Linea concepto ${index}`}>
                        <Grid
                            gridTemplateColumns={{ _: '1fr', mediumScreen: '1fr' }}
                            gridTemplateRows={{ _: 'auto', mediumScreen: '1fr' }}
                            gridColumnGap="sm"
                            gridRowGap="lg"
                            pb="md"
                        >
                            <Grid
                                gridTemplateColumns={{ _: '1fr', mediumScreen: '1fr min-content' }}
                                gridColumnGap="sm"
                                gridRowGap="sm"
                            >
                                <Box>
                                    <ControlledFormField
                                        control={control}
                                        name={`lines.${index}.description`}
                                        Component={({ field, formState: { errors } }) => (
                                            <TextArea
                                                readOnly={formBlocked.isFormBlocked}
                                                placeholder="Introduce una descripción"
                                                id={`sales-document-concept-description-${index}`}
                                                error={!!errors?.lines?.[index]?.description?.message}
                                                helperText={errors?.lines?.[index]?.description?.message}
                                                maxLength={ingresoConFacturaRulesClient.MAX_CHARACTERS_PER_CONCEPT_LINE}
                                                shouldHideMaxCharacters
                                                label="Descripción"
                                                required
                                                resize="vertical"
                                                minHeight="48px"
                                                rows={1}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box>
                                    <LineProductType index={index} handleLineChange={memoizedHandleLineChange} />
                                </Box>
                            </Grid>
                            <Grid
                                gridTemplateColumns={{ _: '1fr', mediumScreen: '1fr 5fr 2fr 2fr 5fr' }}
                                gridColumnGap="sm"
                                gridRowGap="sm"
                            >
                                <ControlledFormField
                                    control={control}
                                    name={`lines.${index}.quantity`}
                                    Component={({ field, formState: { errors } }) => (
                                        <Input
                                            readOnly={formBlocked.isFormBlocked}
                                            error={!!errors?.lines?.[index]?.quantity?.message}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(e) => {
                                                let inputValue = e.target.value.replace(',', '.');

                                                const [whole, decimal] = inputValue.split('.');
                                                if (decimal && decimal.length > 2) {
                                                    inputValue = `${whole}.${decimal.slice(0, 2)}`;
                                                }
                                                const number = parseFloat(inputValue);
                                                memoizedHandleLineChange(index, 'quantity', number);
                                            }}
                                            id={`sales-document-concept-quantity-${index}`}
                                            label="Cantidad"
                                            required
                                            type="number"
                                            showClearButton={false}
                                        />
                                    )}
                                />
                                <ControlledFormField
                                    control={control}
                                    name={`lines.${index}.price`}
                                    Component={({ field, formState: { errors } }) => (
                                        <AmountInput
                                            error={!!errors?.lines?.[index]?.price?.message}
                                            readOnly={formBlocked.isFormBlocked}
                                            helperText={errors?.lines?.[index]?.price?.message}
                                            value={field.value}
                                            ref={field.ref}
                                            decimalScale={
                                                field.value === 0
                                                    ? 2
                                                    : ingresoConFacturaRulesClient.MAX_DECIMAL_PLACES_PER_UNIT_PRICE
                                            }
                                            customHandlerClearValue={() => memoizedHandleLineChange(index, 'price', 0)}
                                            handleChange={(value) => memoizedHandleLineChange(index, 'price', value)}
                                            id={`sales-document-concept-amount-${index}`}
                                            label="Importe unitario"
                                            required
                                        />
                                    )}
                                />
                                <LineDiscount index={index} handleLineChange={memoizedHandleLineChange} />
                                <LineVAT index={index} handleLineChange={memoizedHandleLineChange} />
                                <LineTotal index={index} handleLineChange={memoizedHandleLineChange} />

                                <Grid
                                    gridTemplateColumns={{ _: '1fr', tablet: '4fr 1fr' }}
                                    gridGap={'xs'}
                                    gridColumn={{ _: '1', tablet: '1 / 6' }}
                                >
                                    <Box>
                                        <LineTaxExemption index={index} />
                                    </Box>
                                    {fields.length > 1 && !formBlocked.isFormBlocked ? (
                                        <Box justifySelf="end">
                                            <ActionLink
                                                id={`sales-document-concept-delete-${index}`}
                                                onClick={() => remove(index)}
                                                variant="delete"
                                                size="M"
                                                icon="trash-alt"
                                                label="Eliminar línea"
                                            />
                                        </Box>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box mb="md">
                            <Divider />
                        </Box>
                        {isLastItem(index) && !formBlocked.isFormBlocked ? (
                            <ActionLink
                                onClick={() => {
                                    const lines = getValues('lines');
                                    const { client } = getValues();
                                    const shouldApplyZeroVAT = shouldApplyZeroVATByDefault({
                                        client,
                                        ignoreFieldVATDirty: true,
                                    });

                                    const getDefaultLineValues = () => {
                                        const vatToApply = shouldApplyZeroVAT
                                            ? lines[index].vat
                                            : invoiceDefaultValues.lines[0].vat;

                                        return {
                                            ...invoiceDefaultValues.lines[0],
                                            vat: vatToApply,
                                        };
                                    };

                                    append(
                                        { ...getDefaultLineValues() },
                                        { focusName: `lines.${index + 1}.description` },
                                    );
                                    /*
                        const updatedLines = getValues('lines');
                        updatedLines.forEach((_: SalesDocumentLine, index: number) => {
                          trigger(`lines.${index}.totalLine`);
                        });
                        */
                                }}
                                size="M"
                                icon="plus"
                                label="Añadir otra línea de concepto"
                            />
                        ) : null}
                    </Box>
                );
            })}
        </>
    );
};
