import GastosConFacturaTypes, {
    SubTiposGastosConFacturaTypes,
} from '../../../shared/domain/contabilidad/GastosConFacturaTypes';
import GastosSinFacturaTypes, {
    SubTiposGastosSinFacturaTypes,
} from '../../../shared/domain/contabilidad/GastosSinFacturaTypes';

export const tipos100pcDeducibles = [
    // ESP
    GastosConFacturaTypes.SERVICIO_DECLARANDO,
    GastosConFacturaTypes.SPANISH_PUBLICIDAD,
    GastosConFacturaTypes.SPANISH_TELEFONO_MOVIL,
    GastosConFacturaTypes.SPANISH_ORDENADOR,
    GastosConFacturaTypes.SPANISH_PAGINA_WEB,
    GastosConFacturaTypes.SPANISH_MAQUINARIA,
    GastosConFacturaTypes.SPANISH_ALQUILER,
    GastosConFacturaTypes.SPANISH_MATERIAS_PRIMAS,
    GastosConFacturaTypes.SPANISH_MOBILIARIO_OFICINA,
    GastosConFacturaTypes.SPANISH_VIAJES,
    GastosConFacturaTypes.SERVICIO_DECLARANDO,
    // NO ESP
    GastosConFacturaTypes.FOREIGN_MATERIAS_PRIMAS,
    GastosConFacturaTypes.FOREIGN_PUBLICIDAD,
    GastosConFacturaTypes.FOREIGN_VUELOS,
    GastosConFacturaTypes.FOREIGN_TRANSPORTE_PUBLICO,
    // Sin Factura
    GastosSinFacturaTypes.AMORTIZACION,
    GastosSinFacturaTypes.AJUSTES_CONTABLES,
    GastosSinFacturaTypes.COMISIONES_E_INTERESES_BANCARIOS,
    GastosSinFacturaTypes.CUOTA_COLEGIO_PROFESIONAL,
    GastosSinFacturaTypes.ARANCELES_ADUANA,
    GastosSinFacturaTypes.CUOTA_SEGURIDAD_SOCIAL,
    GastosSinFacturaTypes.TIQUETS,
    GastosSinFacturaTypes.SEGUROS,
];

export const subtipos100pcDeducibles = [
    SubTiposGastosConFacturaTypes.SPANISH_COMIDAS_CON_CLIENTES,
    SubTiposGastosConFacturaTypes.SPANISH_HOTELES_Y_OTROS_ALOJAMIENTOS,
    SubTiposGastosConFacturaTypes.FOREIGN_COMIDAS_CON_CLIENTES,
    SubTiposGastosConFacturaTypes.FOREIGN_HOTELES_Y_OTROS_ALOJAMIENTOS,
    SubTiposGastosSinFacturaTypes.PEAJE_AUTOPISTA,
    SubTiposGastosSinFacturaTypes.VIDA,
    SubTiposGastosSinFacturaTypes.PARKING,
    SubTiposGastosSinFacturaTypes.ZONA_AZUL,
    SubTiposGastosSinFacturaTypes.INVALIDEZ,
    SubTiposGastosSinFacturaTypes.BAJA_LABORAL,
    SubTiposGastosSinFacturaTypes.ACCIDENTES,
    SubTiposGastosSinFacturaTypes.RESPONSABILIDAD_CIVIL,
    SubTiposGastosSinFacturaTypes.VEHICULO,
    SubTiposGastosSinFacturaTypes.OFICINA,
    SubTiposGastosSinFacturaTypes.ORDENADOR,
    SubTiposGastosSinFacturaTypes.TELEFONO_MOVIL,
    SubTiposGastosSinFacturaTypes.TRANSPORTE_PUBLICO,
    SubTiposGastosSinFacturaTypes.MATRICULACION,
];
