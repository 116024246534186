import { ContabilidadPresentedOrBlocked } from '../domain/ContabilidadPresentedOrBlocked/ContabilidadPresentedOrBlocked';
import { ContabilidadPresentedOrBlockedDTO } from './ContabilidadPresentedOrBlockedDTO';

export function toContabilidadPresentedOrBlocked(
    dto: ContabilidadPresentedOrBlockedDTO,
): ContabilidadPresentedOrBlocked {
    return {
        hasContabilidadBlocked: dto.presentada_bloqueada,
    };
}
