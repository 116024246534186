import { Schema } from 'shared/validation/Schema';
import { IngresoConFacturaClientRulesService } from '../../IngresoConFactura/services/IngresoConFacturaRulesService';
import { AdditionalValidationTest } from './SalesDocumentSchema';
import { errorRequired } from './common-errors';

export const createSalesDocumentLineSchema = (args: {
    validations?: { totalLine?: { additionalTests: AdditionalValidationTest[] } };
}) => {
    const baseTotalLineTests: AdditionalValidationTest[] = [
        {
            name: 'total-lines-should-be-less-than-maximal-amount',
            message: '1.000.000€ es el máximo permitido',
            test: (_, ctx) => {
                const { from } = ctx;
                if (!from || from.length < 2) return true;
                const salesDocument = from[1]?.value;

                return IngresoConFacturaClientRulesService.isTotalAmountLessThanMaximalAmount(salesDocument);
            },
        },
    ];

    const totalLineSchema = Schema.number().transform((value, originalValue) => (originalValue === '' ? null : value));

    const finalTotalLineSchema = [
        ...baseTotalLineTests,
        ...(args?.validations?.totalLine?.additionalTests || []),
    ].reduce((schema, testConfig) => {
        return schema.test(testConfig.name, testConfig.message, testConfig.test);
    }, totalLineSchema);

    return Schema.object({
        quantity: Schema.number()
            .transform((value) => {
                const parsed = parseFloat(value);
                return isNaN(parsed) ? undefined : parsed;
            })
            .required(errorRequired),
        price: Schema.number()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .required('Debes introducir un importe unitario'),
        discount: Schema.number(),
        vat: Schema.number().required(errorRequired),
        equivalencySurcharge: Schema.number()
            .min(0, 'Equivalency surcharge cannot be negative')
            .required(errorRequired),
        description: Schema.string().required('Debes introducir una descripción'),
        productType: Schema.string().oneOf(['SERVICES', 'GOODS']).required(errorRequired),
        totalLine: finalTotalLineSchema,
    });
};
