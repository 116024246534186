import { Link, Box, Notification } from '@declarando/design_system';
import { ExpandableContent } from '../../../../shared/components/ExpandableContent';

type NotificationVIESProps = {
    isValid: boolean | null;
    error: Error | null;
};

const NotificationVIESVariant = {
    VALID: () => <NotificationVIESValid />,
    INVALID: () => <NotificationVIESInvalid />,
    UNAVAILABLE_SERVICE: () => <NotificationVIESUnavailableService />,
    EMPTY: () => null,
};

const NotificationVIESContent: React.FC<{ variant: keyof typeof NotificationVIESVariant }> = ({ variant }) => {
    const Component = NotificationVIESVariant[variant];

    return <Component />;
};

export const NotificationVIES: React.FC<NotificationVIESProps> = ({ isValid, error }) => {
    let variant: keyof typeof NotificationVIESVariant;

    if (error) {
        variant = 'UNAVAILABLE_SERVICE';
    } else if (isValid) {
        variant = 'VALID';
    } else if (isValid === false) {
        variant = 'INVALID';
    } else {
        variant = 'EMPTY';
    }

    return (
        <ExpandableContent shouldExpand={isValid !== null || !!error}>
            <Box marginTop="xxs">
                <NotificationVIESContent variant={variant} />
            </Box>
        </ExpandableContent>
    );
};

const NotificationVIESValid = () => {
    return (
        <Notification
            full
            tone="success"
            title="El número de NIF IVA es válido según el VIES. "
            message={
                <>
                    <div>Puedes operar con él con las ventajas propias de los operadores intracomunitarios.</div>
                    <Link
                        underline
                        href="https://ayuda.declarando.es/es/articles/1062370-como-hacer-una-factura-de-venta-a-una-empresa-o-autonomo-europeo"
                    >
                        Saber más
                    </Link>
                </>
            }
        />
    );
};

const NotificationVIESInvalid = () => {
    return (
        <Notification
            full
            tone="error"
            title="El número de NIF IVA no es válido según el VIES"
            message={
                <>
                    <div>No puedes operar con las ventajas propias de los operadores intracomunitarios.</div>{' '}
                    <div>1. Comprueba si el número introducido sea el correcto</div>
                    <div>2. Asegúrate de que tu cliente tenga un NIF IVA válido</div>
                    <div>3. De lo contrario, selecciona NO en la pregunta ¿Tu cliente tiene NIF IVA?</div>
                    <Link
                        underline
                        href="https://ayuda.declarando.es/es/articles/1062370-como-hacer-una-factura-de-venta-a-una-empresa-o-autonomo-europeo"
                    >
                        Saber más
                    </Link>
                </>
            }
        />
    );
};

const NotificationVIESUnavailableService = () => {
    return (
        <Notification
            full
            tone="warning"
            title={
                <>
                    Debido a un error externo del{' '}
                    <a
                        style={{ color: 'inherit', fontWeight: 'bold', textDecoration: 'underline' }}
                        href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        VIES
                    </a>{' '}
                    , no ha sido posible comprobar del NIF IVA
                </>
            }
            message="Compruébalo de nuevo pasados unos minutos."
        />
    );
};
