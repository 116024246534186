import { Skeleton, Box, ContentContainer, Button } from '@declarando/design_system';
import { invoiceCalculationsService } from 'features/Ingresos/SalesDocument/IngresoConFactura/services/IngresoConFacturaCalculationService';
import { SalesDocumentDetails } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentPreviewView/components/SalesDocumentDetails';
import { SalesDocumentPDFViewer } from 'features/Ingresos/SalesDocument/shared/UI/SalesDocumentPreviewView/components/SalesDocumentPDFViewer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TrackingEvents } from 'shared/infra/events/tracking/trackingEvents';
import { SubHeader } from 'shared/UI/components/SubHeader/SubHeader';
import { BaseSalesDocument } from '../../domain/SalesDocument';
import { SalesDocumentType } from '../SalesDocumentFormView/context/SalesDocumentFormProvider';
import { ModalSendEmailSalesDocument } from './components/ModalSentEmailSalesDocument/ModalSentEmailSalesDocument';

type SalesDocumentPreviewViewProps = {
    salesDocumentPDFUrl: string;
    salesDocumentData: BaseSalesDocument | undefined;
    salesDocumentType: SalesDocumentType;
    isLoading: boolean;
};

const config = {
    invoice: {
        subHeader: {
            title: 'Factura',
            contextPath: '/ingresos/ingresos',
            extraLinks: {
                text: 'Ir al listado de ingresos',
                cta: { eventLabel: 'Va al listado de ingresos', redirectRoute: '/ingresos/ingresos' },
            },
        } as const,
        actionButton: {
            label: 'Enviar factura',
            eventLabel: 'Abre modal enviar factura por mail',
        },
    },
    quote: {
        subHeader: {
            title: 'Presupuesto',
            contextPath: '/ingresos/presupuestos',
            extraLinks: {
                text: 'Ir al listado de presupuestos',
                cta: { eventLabel: 'Va al listado de presupuestos', redirectRoute: '/ingresos/presupuestos' },
            },
        },
        actionButton: {
            label: 'Enviar presupuesto',
            eventLabel: 'Abre modal enviar presupuesto por mail',
        },
    } as const,
} as const;

export const SalesDocumentPreviewView: React.FC<SalesDocumentPreviewViewProps> = ({
    salesDocumentPDFUrl,
    salesDocumentData,
    salesDocumentType,
    isLoading,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const history = useHistory();

    const configObject = config[salesDocumentType];

    const getTotalPayable = () => {
        if (isLoading || !salesDocumentData) return null;
        const { totalPayable } = invoiceCalculationsService.calculateInvoiceTotals({
            incomeTax: salesDocumentData.incomeTax,
            invoiceLines: salesDocumentData.lines,
            withEquivalencySurcharge: salesDocumentData.withEquivalencySurcharge,
        });
        return totalPayable;
    };

    const getClientEmail = () => {
        if (isLoading || !salesDocumentData) return null;
        return salesDocumentData.client.email;
    };

    const getClientName = () => {
        if (isLoading || !salesDocumentData) return null;
        return salesDocumentData.client.name;
    };

    return (
        <div style={{ lineHeight: '24px' }}>
            <SubHeader
                type="flow-ending"
                title={
                    isLoading ? (
                        <Skeleton height="40px" width="300px" />
                    ) : (
                        `${configObject.subHeader.title} ${salesDocumentData?.documentNumber}`
                    )
                }
                contextPath={configObject.subHeader.contextPath}
                extraLinks={[
                    {
                        icon: 'list-ul',
                        text: configObject.subHeader.extraLinks.text,
                        cta: () => {
                            TrackingEvents.clickElement({
                                event_label: configObject.subHeader.extraLinks.cta.eventLabel,
                            });
                            history.push(configObject.subHeader.extraLinks.cta.redirectRoute);
                        },
                    },
                ]}
            />
            <Box m={{ _: 'sm', desktop: 'lg' }} display="flex" justifyContent="center">
                <ContentContainer padding="none" overflow="hidden">
                    <Box height="976px" display="flex" flexDirection={{ _: 'column-reverse', tablet: 'row' }}>
                        <SalesDocumentPDFViewer salesDocumentPDFUrl={salesDocumentPDFUrl} />
                        <Box width="400px" padding="lg">
                            <Box display="flex" flexDirection="column" gap="24px">
                                <SalesDocumentDetails
                                    salesDocumentData={salesDocumentData}
                                    isLoading={isLoading}
                                    totalPayable={getTotalPayable()}
                                />
                                <Box display="flex" flexDirection="column" gap="8px">
                                    <Box>
                                        <Button
                                            id="send-email-sales-document"
                                            size="L"
                                            fill
                                            icon="envelope-alt"
                                            label={configObject.actionButton.label}
                                            onClick={() => {
                                                setModalIsOpen(true);
                                                TrackingEvents.clickElement({
                                                    event_label: configObject.actionButton.eventLabel,
                                                });
                                            }}
                                        />
                                        {!isLoading && salesDocumentData ? (
                                            <ModalSendEmailSalesDocument
                                                isOpen={modalIsOpen}
                                                onClose={() => setModalIsOpen(false)}
                                                clientName={getClientName()}
                                                clientEmail={getClientEmail()}
                                                salesDocumentId={salesDocumentData.id}
                                                salesDocumentNumber={salesDocumentData.documentNumber}
                                                salesDocumentType={salesDocumentType}
                                            />
                                        ) : null}
                                    </Box>
                                    {salesDocumentType === 'invoice' ? (
                                        <Box>
                                            <Button
                                                id="new-invoice"
                                                size="L"
                                                variant="secondary"
                                                fill
                                                icon="plus"
                                                label="Añadir otro ingreso con factura"
                                                onClick={() => history.push('/ingreso/factura/')}
                                            />
                                        </Box>
                                    ) : null}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ContentContainer>
            </Box>
        </div>
    );
};
