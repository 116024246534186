import React, { useContext, useEffect } from 'react';
import { RootContext } from 'shared/UI/context/RootContext/RootContext';
import { AdvicesSection } from './AdvicesBar/AdvicesSection';

export const ContentScrollSection = (props) => {
    const { contentScroll } = useContext(RootContext);
    const wideClass = props.wide ? 'wide-panel' : '';

    useEffect(() => {
        contentScroll.block();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.loadedUserInfo) {
            contentScroll.unBlock();
        }
    }, [contentScroll, props.loadedUserInfo]);
    return (
        <div
            id="MainPanel"
            style={
                contentScroll.blocked
                    ? { height: '100vh', overflow: 'hidden', position: 'relative' }
                    : { position: 'relative' }
            }
            className={'main-panel ' + wideClass}
        >
            <AdvicesSection routes={props.routes} userName={props?.userInfo?.name} />
            {props.children}
        </div>
    );
};
