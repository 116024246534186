import V2Repository from 'shared/infra/repositories/V2Repository';

class PhoneRepository extends V2Repository {
    checkExistPhone(prefijo: number, phone: string) {
        return this.post(
            '/public/user/telefono/exists',
            JSON.stringify({
                prefijo,
                phone,
            }),
        );
    }

    storePhone(
        prefijo: number,
        phone: string,
    ): Promise<{
        data: { valid: boolean };
        message: string;
        success: boolean;
    }> {
        sessionStorage.removeItem('test-confirm-phone');
        return this.post(
            '/user/telefono/store',
            JSON.stringify({
                prefijo,
                phone,
            }),
        );
    }

    findPhone() {
        return this.get('/user/telefono/find');
    }

    isValidated() {
        if (sessionStorage.getItem('test-confirm-phone')) {
            return new Promise((resolve, reject) => {
                setTimeout(
                    () => resolve({ data: JSON.parse(String(sessionStorage.getItem('test-confirm-phone'))) }),
                    500,
                );
            });
        }
        return this.get('/user/telefono/validado');
        //return Promise.resolve({"success":true,"data":{"validado":true,"vencimiento_telefono":"semana3","semana2_dias_restantes":0,"fecha_limite":"12-07-2021"},"message":""})
    }
}

export default PhoneRepository;
