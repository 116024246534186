//@ts-nocheck
import V2Repository from '../V2Repository';

export default class ClientsTableV2Repository extends V2Repository {
    deleteClient(id: string) {
        return this.delete(`/cliente/${id}/delete`);
    }

    clientsList(id, type) {
        return this.get('/clientes/lista/' + id + '/' + type);
    }

    checkClientCif(cif) {
        return this.get('/cliente/' + cif + '/exists');
    }

    changeInvoicesClientOwner(oldId, newId) {
        return this.post(
            '/cliente/' + oldId + '/' + newId + '/delete',
            JSON.stringify({
                oldId,
                newId,
            }),
        );
    }
}
