import V2Repository from './V2Repository';
import { DeclarandoApiService } from '../../services/DeclarandoApiService';

class FeatureFlagsRepository extends V2Repository {
    async getFlags(): Promise<string[]> {
        return this.get('/feature-flags');
    }
}

export class FeatureFlagsService extends DeclarandoApiService {
    repository = new FeatureFlagsRepository();

    getFlags() {
        return this.execute<string[]>(() => this.repository.getFlags());
    }
}
