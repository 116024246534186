import { LegacyClientRequestDTO } from '../../adapters/LegacyClient';
import { FormularioSubmitHandler, useFormularioCliente } from './form/hooks/useFormularioCliente';

type Props = {
    cliente: LegacyClientRequestDTO;
    closeModal: () => void;
    onSubmit: (args: FormularioSubmitHandler) => void;
};

export const useModalCliente = ({ cliente, onSubmit, closeModal }: Props) => {
    const formularioCliente = useFormularioCliente(cliente);

    const handleClose = () => {
        closeModal();
        formularioCliente.refresh();
    };

    const handleSubmit = () => {
        formularioCliente.submitHandler(onSubmit);
    };

    return {
        handleClose,
        handleSubmit,
        formularioCliente,
    };
};
